<ng-container *ngIf="!responsive$">
  <cl-loading-overlay [loading]="isLoading$ | async" [mode]="'full'"></cl-loading-overlay>
  <router-outlet></router-outlet>
  <cl-support-board></cl-support-board>
</ng-container>

<ng-container *ngIf="responsive$">
  <div class="app-responsive">
    <cl-loading-overlay [loading]="isLoading$ | async" [mode]="'full'"></cl-loading-overlay>
    <div class="child-responsive">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>

<div *ngIf="isLoadingGateway" class="loading-gateway">
  <div class="loader-wrapper-unq">
    <div class="loader-unq"></div>
    <div>Authenticating, Please Wait...</div>
  </div>
</div>
