import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cl-read-more-text',
  templateUrl: './read-more-text.component.html',
  styleUrls: ['./read-more-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreTextComponent {
  @Input() text: string;
  @Input() imageName: string | undefined;
  @Input() lengthOfTextForShowMoreButton: number;
  isCollapsed = true;

  get textState() {
    return this.isCollapsed ? 'collapsed' : 'expanded';
  }

  get wrapperStyle() {
    return { 'flex-wrap': this.isCollapsed ? 'nowrap' : 'wrap' };
  }

  get buttonStyle() {
    return { display: this.isCollapsed ? 'inline-block' : 'none' };
  }

  toggleText() {
    this.isCollapsed = !this.isCollapsed;
  }
}
