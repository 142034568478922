import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileResponsiveService {
  private readonly isResponsive = new BehaviorSubject(false);

  constructor() {}

  get isResponsive$() {
    return this.isResponsive.asObservable();
  }

  update(newValue: boolean) {
    this.isResponsive.next(newValue);
  }
}
