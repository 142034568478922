import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizeShared',
})
export class FileSizeSharedPipe implements PipeTransform {
  transform(size: number, decimals = 2): string {
    if (size === 0) {
      return '0Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    const fileSize = `${parseFloat((size / Math.pow(k, i)).toFixed(dm))}`;

    return `${fileSize.replace('.', ',')}${sizes[i]}`;
  }
}
