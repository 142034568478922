<div class="viewer-container">
  <div class="header-container">
    <div class="actions">
      <mat-icon
        class="action-button"
        svgIcon="assign-as-main"
        matTooltip="Assign as main"
        matTooltipPosition="above"
        (click)="assignAsMain()"
        *ngIf="!oneImageLeft && assignAsMainAvailable"
      ></mat-icon>
      <mat-icon
        class="action-button"
        svgIcon="share"
        matTooltip="Share"
        matTooltipPosition="above"
        (click)="copyLink()"
        *ngIf="shareAvailable"
      ></mat-icon>
      <mat-icon
        class="action-button"
        svgIcon="download"
        matTooltip="Download photo"
        matTooltipPosition="above"
        (click)="download()"
        *ngIf="downloadAvailable"
      ></mat-icon>
      <mat-icon
        class="action-button"
        svgIcon="delete"
        matTooltip="Delete photo"
        matTooltipPosition="above"
        (click)="delete()"
        *ngIf="deleteAvailable"
      ></mat-icon>
      <div class="divider"></div>
      <mat-icon class="action-button close-icon" (click)="close()" svgIcon="close"></mat-icon>
    </div>
  </div>

  <div *ngIf="!swiperReady" class="loading">
    <mat-spinner diameter="50"></mat-spinner>
    <span class="label">Loading data...</span>
  </div>

  <div class="arrow arrow-right" [style.opacity]="swiperReady ? 1 : 0" *ngIf="!oneImageLeft">
    <img src="./assets/images/arrow_icon_white.svg" alt="Right" />
  </div>
  <div class="arrow arrow-left" [style.opacity]="swiperReady ? 1 : 0" *ngIf="!oneImageLeft">
    <img src="./assets/images/arrow_icon_white.svg" alt="Right" />
  </div>
  <div *ngIf="zoomPercentage > 100" class="zoom-toogle">
    <div>{{ zoomPercentage }}%</div>
  </div>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let image of images">
        <img
          #imageElement
          style="cursor: zoom-in"
          class="image-banner"
          src="{{ image.url ? image.url : image.image }}"
          alt="Slide"
          (click)="zoomInOut($event)"
        />
      </div>
    </div>
  </div>
</div>
