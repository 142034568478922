<ng-container *ngIf="isLoading">
  <cl-modal-view-story-skeleton></cl-modal-view-story-skeleton>
</ng-container>

<ng-container *ngIf="!isLoading">
  <button class="right-title" mat-icon-button aria-label="close" (click)="close()">
    <mat-icon class="material-icons close-icon" svgIcon="custom_close_btn"></mat-icon>
  </button>
  <div class="container-event">    
      <div class="top-banner-default" *ngIf="!data.cover_image">
          <img src="/assets/images/Deco.png" class="img-banner" draggable="false">
      </div>
      <div class="top-banner-cover" *ngIf="data.cover_image">
        <img [src]="data.cover_image" alt="">
      </div>
      <div class="header">
          <div class="info-event">
              <div class="event-data location">
                  <span class="icon"><mat-icon svgIcon="custom_place"></mat-icon></span>
                  <div class="detail-info">
                      {{ data.cemetery.name }} 
                      {{ data.plot ? ' - '+data.plot.plot_id : '' }}
                  </div>
                  <div class="event-type" *ngIf="data?.event_type?.title">
                      <p>{{data.event_type.title}}</p>
                  </div>
                  <a *ngIf="!hideGoToPlot" (click)="goToDetailPlot()" class="btn-plot">Go to plot</a>
              </div>
              <div class="event-data">
                  <span class="icon"><mat-icon svgIcon="event-calender" style="opacity: 1;"></mat-icon></span>  
                  <div class="detail-info">
                      {{data.start_time | date: 'EEEE, MMMM d y, h:mm a':'UTC'}} - {{data?.end_time | date: 'shortTime':'UTC'}}
                  </div>
              </div>
              <div class="event-data name" *ngIf="data.related_interment"> 
                  {{data.related_interment?.person?.first_name}} {{data.related_interment?.person?.last_name}}
              </div>
          </div>
          <div class="share-menu">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon class="material-icons-outlined share-icon" color="primary">share_outlined</mat-icon>
                <span class="share-text">SHARE</span>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="shareEvent('facebook')">
                  <mat-icon svgIcon="facebook"></mat-icon>
                  <span>Facebook</span>
                </button>
                <button mat-menu-item (click)="shareEvent('twitter')">
                  <mat-icon svgIcon="twitter"></mat-icon>
                  <span>Twitter</span>
                </button>
                <button mat-menu-item (click)="shareEvent('copy')">
                  <mat-icon svgIcon="copy"></mat-icon>
                  <span>Copy Link</span>
                </button>
              </mat-menu>
          </div>
      </div>
      <div class="content">
          <div class="title-event">
              {{data.event_name}}
          </div>
          <div class="description">
              {{data.descriptions}}
          </div>
      </div>
      <div class="bottom-banner">
          <div class="question">Do you plan to attend an event?</div>
          <div class="btn-banner">
              <div class="share-menu">
                  <button 
                  mat-raised-button 
                  color="primary"
                  class="btn-reminder" 
                  [matMenuTriggerFor]="reminderEvent"
                  >
                  Add reminder to calendar
                  </button>
                    <mat-menu #reminderEvent="matMenu">
                      <button mat-menu-item (click)="addReminderCalendar('google')">
                      <img src="/assets/images/google_calender.png" width="20" height="20" class="img-reminder">
                        <span>Google</span>
                      </button>
                      <button mat-menu-item (click)="addReminderCalendar('ics')">
                      <mat-icon svgIcon="event-calender"></mat-icon>
                        <span>Calender</span>
                      </button>
                    </mat-menu>
              </div>

              <div class="share-menu">
                  <button mat-button [matMenuTriggerFor]="menu">
                      <mat-icon class="material-icons-outlined share-icon" color="primary">share_outlined</mat-icon>
                      <span class="share-text">SHARE</span>
                  </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="shareEvent('facebook')">
                        <mat-icon svgIcon="facebook"></mat-icon>
                        <span>Facebook</span>
                      </button>
                      <button mat-menu-item (click)="shareEvent('twitter')">
                        <mat-icon svgIcon="twitter"></mat-icon>
                        <span>Twitter</span>
                      </button>
                      <button mat-menu-item (click)="shareEvent('copy')">
                        <mat-icon svgIcon="copy"></mat-icon>
                        <span>Copy Link</span>
                      </button>
                    </mat-menu>
              </div>
          </div>
          <img class="img-banner" src="/assets/images/bell-notif.png" draggable="false">
      </div>
  </div>
</ng-container>

