import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UploaderType } from '../enums/uploader-type.enum';
import { LoadingService } from '../services/loading.service';
import { Image } from '../../interfaces/image';
import { MatDialog } from '@angular/material/dialog';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';

@Component({
  selector: 'cl-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LoadingService],
})
export class UploadImageComponent {
  @Input() imagesCapacity = 6;
  uploaderType = UploaderType;
  localFiles: Image[] = [];
  @Input() images: Image[] = [];
  @Input() title = 'Headstone photo';
  @Input() disabled = false;
  @Input() public;
  @Output() files = new EventEmitter<Image[]>();
  @Output() previewIndex = new EventEmitter<number>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public loadingService: LoadingService,
    public dialog: MatDialog,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'custom_plus', src: '/assets/images/plus_icon.svg' }]);
  }

  inputFiles(files: Image[]) {
    this.localFiles = files;
  }

  uploadFiles(files: Image[]) {
    if (this.disabled) {
      return;
    }

    this.files.emit(files.slice(0, this.imagesCapacity));
    this.localFiles = files.slice(0, this.imagesCapacity);
    this.changeDetectorRef.markForCheck();
  }

  preview(index: number) {
    if (this.disabled) {
      return;
    }

    this.previewIndex.emit(index);
  }
}
