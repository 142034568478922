import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpUtilsService } from '../core/http-utils.service';
import { UserInfo } from '../interfaces/user-info';
import { Organization } from '../interfaces/organization';
import { UserRole } from '../enums/user-role.enum';
import { ValidateResponseService } from '../server-response-validation/validate-response.service';
import { ValidatorType } from '../server-response-validation/validator-type.enum';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  fromAccess$ = new BehaviorSubject<boolean>(false);
  constructor(
    private readonly httpClient: HttpClient,
    private readonly httpUtils: HttpUtilsService,
    private readonly validateResponseService: ValidateResponseService
  ) {}

  users(cemeteryName: string) {
    const url = this.httpUtils.getResourceUrl([cemeteryName, 'access']);
    return this.httpClient
      .get<(UserInfo | Organization)[]>(url)
      .pipe(tap(info => this.validateResponseService.validate(ValidatorType.Organization, info)));
  }

  giveAccess(
    cemeteryName: string,
    data: { email: string; role: UserRole; isAllOrg: boolean; access: { cemetery: string; role: UserRole }[] }
  ) {
    let url: string;
    let dataSend: any;

    if (data.access.length > 0) {
      url = this.httpUtils.getResourceUrl([cemeteryName, 'multiaccess']);
      const dt = data.access.map(ac => {
        return {
          email: data.email,
          cem: ac.cemetery,
          role: ac.role,
        };
      });

      dataSend = {
        data: dt,
      };
    } else {
      dataSend = {
        email: data.email,
        role: data.role,
      };
      if (data.isAllOrg) {
        url = this.httpUtils.getResourceUrl([cemeteryName, 'orgaccess']);
      } else {
        url = this.httpUtils.getResourceUrl([cemeteryName, 'access']);
      }
    }

    return this.httpClient.post<UserInfo>(url, dataSend);
  }

  resendInvitation(cemeteryName: string, userId: number) {
    const url = this.httpUtils.getResourceUrl([cemeteryName, 'access', userId]);

    return this.httpClient.put<UserInfo>(url, {});
  }

  resendInvitationUserDetail(userId: number, orgId: number) {
    // users/resendaccess/920/?org=19
    const url = this.httpUtils.getResourceUrl(['users', 'resendaccess', userId]);
    const params = { org: `${orgId}` };

    return this.httpClient.put<UserInfo>(url, {}, { params: new HttpParams({ fromObject: params }) });
  }

  removeAccess(cemeteryName: string, userId: number) {
    const url = this.httpUtils.getResourceUrl([cemeteryName, 'access', userId]);

    return this.httpClient.delete(url);
  }

  changeAccessRole(userId: number, role: UserRole) {
    const url = this.httpUtils.getResourceUrl(['users', 'change_role', userId]);

    return this.httpClient.put<UserInfo>(url, { role });
  }

  demoAddAccess(userId: number) {
    const url = this.httpUtils.getResourceUrl(['access_demo', userId]);

    return this.httpClient.put(url, {});
  }

  demoRemoveAccess(userId: number) {
    const url = this.httpUtils.getResourceUrl(['access_demo', userId]);

    return this.httpClient.delete(url);
  }

  getUserAccessList(orgId: number, page: number, userStatus: string = 'all'): Observable<any> {
    const url = this.httpUtils.getResourceUrl(['users', 'getlist']);
    const params: any = {
      org: `${orgId}`,
      limit: '10',
      page: `${page}`,
    };

    if (userStatus === 'active') {
      params.archived = false;
      params.cem_arc = false;
      params.show_archived = false;
      params.is_active = true;
    }

    if (userStatus === 'archived') {
      params.archived = true;
      params.cem_arc = false;
      params.show_archived = false;
      params.is_active = true;
    }

    return this.httpClient.get(url, { params: new HttpParams({ fromObject: params }) });
  }

  addAccess(orgId: number, payload: { email: string[]; role: number; cemetery: number[] }) {
    const url = this.httpUtils.getResourceUrl(['organization-access']);
    const params = { org: `${orgId}` };
    return this.httpClient.post(url, payload, { params: new HttpParams({ fromObject: params }) });
  }

  getUserDetail(organizationId: number, userId: number) {
    const url = this.httpUtils.getResourceUrl(['organization-access']);
    const params = { org: organizationId.toString(), users: userId.toString() };
    return this.httpClient.get(url, { params: new HttpParams({ fromObject: params }) });
  }

  editUserAccessCemetery(organizationId: number, userId: number, payload: { role: number; cemeteries: number[] }) {
    const url = this.httpUtils.getResourceUrl(['cemetery-access', userId.toString()]);
    const params = { org: organizationId.toString() };
    return this.httpClient.put(url, payload, { params: new HttpParams({ fromObject: params }) });
  }

  deleteUserAccessCemetery(organizationId: number, userId: number, cemeteryId: number) {
    const url = this.httpUtils.getResourceUrl(['cemetery-access', userId.toString()]);
    const params = { org: organizationId.toString(), cemeteries: JSON.stringify([cemeteryId]) };
    return this.httpClient.delete(url, { params: new HttpParams({ fromObject: params }) });
  }

  assignDemoCemetery(organizationId: number, userId: number, payload: { role: number; is_demo: boolean }) {
    const url = this.httpUtils.getResourceUrl(['cemetery-access', userId.toString()]);
    const params = { org: organizationId.toString() };
    return this.httpClient.put(url, payload, { params: new HttpParams({ fromObject: params }) });
  }
}
