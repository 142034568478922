<perfect-scrollbar #perfectScroll>
  <div class="story-edit-wrapper">
    <div class="story-edit-title">Edit Story</div>
    <form [formGroup]="form">
      <div class="input-title">
        <div>Story title</div>
        <mat-form-field appearance="outline" floatLabel="auto">
          <input formControlName="title" matInput placeholder="I believe i can fly" />
        </mat-form-field>
      </div>
      <div class="input-story">
        <div class="label-story">Content story</div>
        <angular-editor [config]="configs" formControlName="text"></angular-editor>
      </div>
      <div class="input-photos">
        <div>Image Upload</div>
        <cl-upload-image
          [images]="intermentStoryImages"
          (files)="uploadIntermentStoryPhotos($event)"
          [title]="''"
          (previewIndex)="openImageStoryPreview($event)"
          [public]="public"
        ></cl-upload-image>
      </div>
    </form>
    <div class="button-contact-detail">
      <button mat-button color="primary" (click)="back()">Cancel</button>
      <button mat-raised-button color="primary" (click)="checkForm()">Submit</button>
    </div>
  </div>
</perfect-scrollbar>
