<form (submit)="handleForm($event)">
  <div class="icon-close">
    <mat-icon class="material-icons close-icon" (click)="close()" svgIcon="custom_shared_plot_details_close"></mat-icon>
  </div>
  <div class="text-payment">Finish Chronicling the life by completing the below payment</div>
  <div #cardElement>
    <!-- A Stripe Element will be inserted here. -->
  </div>

  <!-- Used to display Element errors. -->
  <p>{{ cardErrors }}</p>

  <button>Buy for {{ dialogData.currency | uppercase }} {{ dialogData.amountPayment / 100 }}.00</button>
</form>
