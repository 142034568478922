<h4>
    Delete image?
  </h4>
  <p>
    This image will not be stored on Chronicle anymore. Are you sure you want to delete this image?
  </p>
  <div class="buttons">
    <button class="cancel" mat-button color="primary" (click)="cancel()">cancel</button>
    <button class="delete" mat-raised-button color="primary" (click)="delete()">Delete</button>
  </div>
  