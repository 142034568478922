import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroToDash',
})
export class ZeroToDashPipe implements PipeTransform {
  transform(value: number): number | string {
    return value === 0 ? '-' : value;
  }
}
