import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { IdentifyName } from '../../enums/amplitude.enum';
import { SurveyService } from 'src/app/core/survey.service';

@Component({
  selector: 'cl-banner-image-story',
  templateUrl: './banner-image-story.component.html',
  styleUrls: ['./banner-image-story.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerImageStoryComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  responsive$: boolean;
  @Output() valueChange = new EventEmitter();
  open = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { isAus: boolean; plotDetails: any },
    private readonly dialogRef: MatDialogRef<BannerImageStoryComponent>,
    private ref: ChangeDetectorRef,
    private mobileResponsiveService: MobileResponsiveService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private amplitudeService: AmplitudeService,
    private surveyService: SurveyService
  ) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_shared_plot_details_close',
        src: '/assets/images/close_icon_white.svg',
      },
    ]);
  }

  ngOnInit(): void {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  openStoryFromBanner() {
    this.amplitudeService.track('2a. Button "Try Now" banner Life Chronicle clicked', this.dialogData.plotDetails, {
      [IdentifyName.CemeteryName]: this.dialogData?.plotDetails?.cemetery_info?.name,
    });
    this.dialogRef.close(this.open);
  }

  close() {
    this.amplitudeService.track('2b. Button close on banner Life Chronicle clicked');
    this.dialogRef.close();
    this.surveyService.showSurvey();
  }

  goToLink(url: string) {
    this.amplitudeService.track('2c. Link "Learn More" on banner Life Chronicle clicked');
    window.open(url, '_blank');
  }
}
