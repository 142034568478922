import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cl-modal-notification-request-plot',
  templateUrl: './modal-notification-request-plot.component.html',
  styleUrls: ['./modal-notification-request-plot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNotificationRequestPlotComponent implements OnInit {
  constructor(
    private readonly dialogRef: MatDialogRef<ModalNotificationRequestPlotComponent>,

    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      plotDetails: any;
    }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  viewPdf() {
    // nothing
  }
}
