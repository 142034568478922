import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EMPTY, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { LoadingService } from 'src/app/loading-overlay/loading.service';
import { SellPlotsService } from '../../../app/core/sell-plots.service';
import { CemeteryInfo } from 'src/app/interfaces/cemetery-info';
@Component({
  selector: 'cl-form-buy-plot',
  templateUrl: './form-buy-plot.component.html',
  styleUrls: ['./form-buy-plot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBuyPlotComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  form = this.formBuilder.group({
    first_name: [null, Validators.required],
    last_name: [null, Validators.required],
    mobile_phone: [null, [Validators.required]],
    billing_phone_number: [null],
    email: [null, [Validators.required]],
    billing_address: [null],
    billing_city: [null],
    billing_state: [null],
    billing_country: [null],
    billing_postcode: [null],
    comments: [null],
    purc_price: [null],
    plot_id: [null],
    is_old: [false],
    custom_form_value: [{}],
  });
  customForm: any;
  constructor(
    private formBuilder: FormBuilder,
    private sellPlotsService: SellPlotsService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBarNotificationService: SnackbarNotificationService,
    private loadingService: LoadingService,
    private amplitudeService: AmplitudeService,

    private readonly dialogRef: MatDialogRef<FormBuyPlotComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      plotDetails: any;
      cemetery: CemeteryInfo;
    }
  ) {}

  ngOnInit(): void {
    this.getCustomFormTemplate();
  }

  getCustomFormTemplate() {
    this.sellPlotsService.getCustomFormTemplate(this.dialogData.cemetery.organization_id)
      .pipe(
        tap((resp) => {
          (resp as any[]).map((data) => {
            this.customForm = data.sections;
            this.form.get('custom_form_value').setValue(this.customForm );
          } );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  close() {
    this.amplitudeService.track(`5b. Plot Purchase - Button "Cancel" Clicked`);
    this.dialogRef.close();
  }

  submitSellPlots() {
    if (this.form.invalid) {
      this.openErrorMessage('Please fill all field');
      return;
    }
    this.form.get('plot_id').setValue(this.dialogData.plotDetails.id);
    this.form.get('purc_price').setValue(this.dialogData.plotDetails.price);
    this.loadingService.run();
    this.amplitudeService.track(`5a. Plot Purchase - Button "Submit" Clicked`, {
      ...this.form.value,
    });

    const payload  = this.form.value;
    payload.plot = {
      plot_id: this.dialogData.plotDetails.id,
      status: this.dialogData.plotDetails.status
    };
    this.sellPlotsService
      .purchasePlot(this.dialogData.plotDetails.id, payload)
      .pipe(
        catchError(error => {
          this.openErrorMessage(error.error.message);
          return EMPTY;
        }),
        tap(data => {
          this.dialogRef.close('submitted');
          this.openSuccessMessage('Purchase plot successfully');
        }),
        finalize(() => {
          this.loadingService.stop();
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private openSuccessMessage(successMessage: string) {
    this.snackBarNotificationService.openSuccessMessage(successMessage);
  }

  private openErrorMessage(errorMessage: string) {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.snackBarNotificationService.openErrorMessage(errorMessage);
    this.changeDetectorRef.markForCheck();
  }
}
