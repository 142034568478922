import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cl-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StoryComponent),
      multi: true,
    },
  ],
})
export class StoryComponent implements ControlValueAccessor, OnDestroy {
  private readonly destroy$ = new Subject();
  stories = [
    {
      value: 1,
      checked: false,
      title: 'Story',
    },
  ];
  form: FormGroup;

  get story() {
    return this.form.get('story') as FormControl;
  }

  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      story: [''],
    });
    this.story.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        tap(value => {
          this.onChange(value);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  onSetCheckedStatus(item) {
    this.story.setValue(item.value);
    this.stories.forEach(element => (element.checked = false));
    item.checked = !item.checked;
  }

  writeValue(value) {
    if (!value) {
      this.story.setValue(0);
      this.stories.forEach(element => (element.checked = false));
      this.changeDetectorRef.detectChanges();
      return;
    }

    const neededStatus = this.stories.find(status => status.value === value);

    if (neededStatus === undefined) {
      return;
    }

    neededStatus.checked = true;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
}
