import { Injectable } from '@angular/core';
import { Token } from '../enums/token.enum';
import { AdminDataService } from './admin-data.service';
import * as amplitude from '@amplitude/analytics-browser';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  private username: string;
  private event: any;

  constructor(private tokenStorageService: TokenStorageService, private adminDataService: AdminDataService) {
    this.username = adminDataService?.userValue?.username;
    amplitude.init(environment.AMPLITUDE_API_KEY, undefined, {
      defaultTracking: true,
    });
  }

  generateUserId(length = 12) {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
  }

  async setUserId() {
    if (!this.tokenStorageService.get(Token.access)) {
      let ampliUserId = this.tokenStorageService.get(Token.ampliUserId);

      if (!ampliUserId) {
        ampliUserId = this.generateUserId();
        this.tokenStorageService.store(Token.ampliUserId, ampliUserId);
      }

      this.username = ampliUserId;
    } else {
      this.username = this.adminDataService?.userValue?.username;
    }
  }

  async track(
    eventName: string | amplitude.Types.BaseEvent,
    eventProperties?: Record<string, any>,
    identify?: { [property: string]: amplitude.Types.ValidPropertyType }
  ) {
    const identifyEvent = new amplitude.Identify();
    await this.setUserId();

    amplitude.setUserId(this.username);
    // amplitude.setSessionId(Date.now());

    if (identify) {
      identifyEvent.append(Object.keys(identify)[0], Object.values(identify)[0]);
      amplitude.identify(identifyEvent);
    }
    amplitude.track(eventName, eventProperties);
  }

  async log(eventName: string | amplitude.Types.BaseEvent, eventProperties?: Record<string, any>) {
    await this.setUserId();

    amplitude.setUserId(this.username);
    // amplitude.setSessionId(Date.now());
    amplitude.logEvent(eventName, eventProperties);
  }

  async groupTrack(groupName: string, eventName: string | amplitude.Types.BaseEvent, eventProperties?: Record<string, any>) {
    await this.setUserId();

    const event = new amplitude.Identify();
    event.set(eventName as string, eventProperties);

    amplitude.setUserId(this.username);
    // amplitude.setSessionId(Date.now());
    amplitude.groupIdentify('Chronicle', groupName, event);
  }
}
