import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { AdminDataService } from '../../core/admin-data.service';
import { UserInfo } from '../../interfaces/user-info';
import { UserRole } from '../../enums/user-role.enum';
import {AddIconsThroughMaterialService} from '../../core/add-icons-through-material.service';

@Component({
  selector: 'cl-toolbar-organization',
  templateUrl: './toolbar-organization.component.html',
  styleUrls: ['./toolbar-organization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarOrganizationComponent implements OnInit, OnDestroy {
  @Input() username: string;
  @Input() isEditForm: boolean;
  @Input() showHeader = true;
  @Output() backToPrevious = new EventEmitter();

  destroy$: Subject<void> = new Subject<void>();
  responsive$ = false;
  userInfo$: Observable<UserInfo>;
  userInfo: any;
  roleSuperAdmin = UserRole.CHRONICLE_ADMIN;
  isSuperAdmin: boolean;

  constructor(
    private ref: ChangeDetectorRef,
    private adminService: AdminDataService,
    private mobileResponsiveService: MobileResponsiveService,
    private addMaterialIconService: AddIconsThroughMaterialService) {
    this.addMaterialIconService.add([
      {
        name: 'arrow-left',
        src: 'assets/svg/arrow-left-purple.svg',
      }]);
  }

  ngOnInit() {
    this.getUser();
    this.getResponsive();
  }

  getResponsive() {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getUser() {
    this.userInfo$ = this.adminService.user;
    this.userInfo$
      .pipe(
        tap(user => {
          this.userInfo = user;
          this.isSuperAdmin = this.userInfo.role === this.roleSuperAdmin;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  backToPreviousPage() {
    this.backToPrevious.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
