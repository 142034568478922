import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
  OnInit,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';
import Swiper from 'swiper';
import { Image } from '../../interfaces/image';
import { Subject, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ImageUploaderService } from 'src/app/upload/services/image-uploader.service';
import { catchError, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { PlotsService } from '../../core/plots.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/app/loading-overlay/loading.service';
import { Plot } from '../../interfaces/plot';
import { CemeteryService } from 'src/app/core/cemetery.service';
import { ObjectFit } from '../../enums/object-fit.enum';

@Component({
  selector: 'cl-swiper-dynamic',
  templateUrl: './swiper-dynamic.component.html',
  styleUrls: ['./swiper-dynamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwiperDynamicComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterViewChecked {
  private readonly destroy$ = new Subject();

  private swiper: Swiper;

  loading = true;
  hasUpload = false;
  cemeteryUniqueName: any;
  plotDetails: Plot;

  localPlot: any;
  localCemetery: any;
  localImages: Image[] = [];
  localShowInputImg = true;
  localObjectFit = ObjectFit.Contain;
  localAutoPlay = false;
  localAnimationSpeed = 1000;

  @Input() set slides(img: Image[]) {
    this.localImages = img;
  }
  @Input() set showInputImage(val: boolean) {
    this.localShowInputImg = val;
  }
  @Input() blackArrow: boolean;
  @Input() defaultImage = '/assets/images/no-photo-story.png';
  @Input() plotDetailsInput: any;
  @Input() cemeteryInput: any;
  @Input() set objectFit(val: ObjectFit) {
    if (val) {
      this.localObjectFit = val;
    }
  }
  @Input() set autoPlay(val: boolean) {
    if (val) {
      this.localAutoPlay = val;
    }
  }
  @Input() set animationSpeed(val: number) {
    this.localAnimationSpeed = val;
  }

  @Output() previewIndex = new EventEmitter<number>();

  @ViewChildren('image') imageCollection: QueryList<ElementRef<HTMLImageElement>>;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly cemeteryService: CemeteryService,
    private imageUploaderService: ImageUploaderService,
    private plotService: PlotsService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.localPlot = changes?.plotDetailsInput?.currentValue;
    this.localCemetery = changes?.cemeteryInput?.currentValue;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.swiper?.destroy();
  }

  ngAfterViewInit() {
    this.cemeteryUniqueName = this.route.snapshot.paramMap.get('cemeteryName');
    const queryPlotId = this.route.snapshot.paramMap.get('plotId');

    if (this.route.snapshot.paramMap.has('plotId')) {
      this.getPlot(this.cemeteryUniqueName, queryPlotId);
    }

    if (!this.localImages || this.localImages.length < 1) {
      this.loading = false;
      return;
    }

    let count = 0;
    Array.from(this.imageCollection).forEach(
      (elRef, index, array) =>
        (elRef.nativeElement.onload = () => {
          count++;

          if (count !== array.length) {
            return;
          }

          this.changeDetectorRef.detectChanges();
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        })
    );
  }

  ngAfterViewChecked(): void {
    if (!this.hasUpload) {
      this.createSwiperInstance();
      this.swiper.update();
    }
  }

  preview(index: number) {
    this.previewIndex.emit(index);
  }

  onImageSelected(e) {
    if (Object.keys(this.route.snapshot.queryParams).length) {
      this.hasUpload = true;
      const editedPlot = {
        ...this.localPlot,
        burials_capacity: this.localPlot.burials_capacity.total,
        cremation_capacity: this.localPlot.cremation_capacity.total,
        entombment_capacity: this.localPlot.entombment_capacity.total,
      };

      this.loadingService.run();
      forkJoin([this.imageUploaderService.upload((e.target as HTMLInputElement).files[0])])
        .pipe(
          mergeMap(data =>
            this.plotService.editPlot({ ...editedPlot, images: [...this.localImages.map(a => a.id), data[0].id] }, this.cemeteryUniqueName)
          ),
          tap(data => {
            /* tslint:disable:no-string-literal */
            this.localImages = data['images'];
            /* tslint:disable:no-string-literal */
            this.changeDetectorRef.detectChanges();
          }),
          finalize(() => {
            this.loadingService.stop();
            this.plotService.newDataPlot(true);
            this.swiper.update();
            this.hasUpload = false;
          }),
          catchError(error => {
            return of(error);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
      return;
    }

    this.hasUpload = true;
    const editedCemetery = {
      ...this.localCemetery,
    };
    this.loadingService.run();
    forkJoin([this.imageUploaderService.upload((e.target as HTMLInputElement).files[0])])
      .pipe(
        mergeMap(data =>
          this.cemeteryService.update(this.localCemetery.id, {
            ...editedCemetery,
            images: [...this.localImages.map(a => a.id), data[0].id],
          })
        ),
        tap(data => {
          /* tslint:disable:no-string-literal */
          this.localImages = data['images'];
          /* tslint:disable:no-string-literal */
          this.changeDetectorRef.detectChanges();
        }),
        finalize(() => {
          this.loadingService.stop();
          //   this.plotService.newDataPlot(true);
          this.swiper.update();
          this.hasUpload = false;
        }),
        catchError(error => {
          return of(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getPlot(cemeteryUniqueName: string, plotId: string) {
    this.plotService
      .getPlotInfoWithLocation(cemeteryUniqueName, plotId)
      .pipe(
        tap(plotDetails => {
          this.plotDetails = plotDetails;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private createSwiperInstance() {
    this.swiper = new Swiper(this.elementRef.nativeElement, {
      direction: 'horizontal',
      loop: false,
      updateOnImagesReady: true,
      centeredSlides: true,
      initialSlide: 0,
      slidesPerView: 1,
      autoplay: this.localAutoPlay,
      speed: this.localAnimationSpeed,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.custom-swiper-button-next',
        prevEl: '.custom-swiper-button-prev',
      },
    });
  }
}
