import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CemeteryEvent, Paginate } from 'src/app/interfaces/cemetery-event';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UpcomingEventDetailDialogComponent } from './upcoming-event-detail-dialog/upcoming-event-detail-dialog.component';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { EventService } from 'src/app/core/event.service';

@Component({
  selector: 'cl-upcoming-event-list',
  templateUrl: './upcoming-event-list.component.html',
  styleUrls: ['./upcoming-event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcomingEventListComponent implements OnInit {
  @Input() upcomingEvent: CemeteryEvent[];
  @Input() paginate: Paginate;
  @Input() cemeteryName: string;
  @Input() allUpcomingEvent = false;
  @Input() isLoading = false;
  @Output() scrollDown = new EventEmitter();

  destroy$ = new Subject<void>();
  eventId: any;
  constructor(
    private readonly router: Router,
    private dialog: MatDialog,
    private amplitudeService: AmplitudeService,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    ) { }

  ngOnInit(): void {
    this.eventId = this.activatedRoute.snapshot?.paramMap?.get('upcomingEventId');
    if (this.eventId) {
      this.eventService.getOneEventPublic(this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.goToDetailEvent(data);
        }
      });
    }
  }

  goToEventDetails() {
    this.router.navigate([this.cemeteryName, 'upcoming-events']);
  }

  goToDetailEvent(event: CemeteryEvent) {
    this.amplitudeService.track('Event public is clicked', event);
    this.dialog
      .open(UpcomingEventDetailDialogComponent, {
        panelClass: 'upcoming-event-dialog',
        width: '608px',
        disableClose: true,
        autoFocus: false,
        data: event,
      })
      .afterClosed();
  }

  onScrollDown(e) {
    this.scrollDown.emit(true);
  }
}
