import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cl-delete-image-dialog-shared',
  templateUrl: './delete-image-dialog-shared.component.html',
  styleUrls: ['./delete-image-dialog-shared.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteImageDialogSharedComponent {
  constructor(public dialogRef: MatDialogRef<DeleteImageDialogSharedComponent>) {}

  delete() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
