<header>
  <div class="left">
    <h3 class="cemeteries-title">
      {{ cemeteries.length > 1 ? regionalSettingsForUser.cemeteries : regionalSettingsForUser.cemetery }}
    </h3>
    <h5 class="amount-of-cemeteries" *ngIf="!isLoading">{{ this.totalData }}</h5>
  </div>
</header>

<div class="cemetery-list-wrapper" infiniteScroll [infiniteScrollDistance]="4" [infiniteScrollThrottle]="100"
  [scrollWindow]="false">
  <section class="cemeteries-list" *ngIf="cemeteries">
    <div class="sorting">
      <span class="sorting-title">Sort by</span>

      <mat-form-field class="sorting-params">
        <mat-select [value]="defaultSortParam" (valueChange)="onSortParamChange($event)">
          <mat-option *ngFor="let param of regionalSortParams; let i = index" [value]="i">
            {{ param }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-container *ngFor="let cemetery of cemeteries">
      <div class="cemetery-card" (click)="navigateToCemetery(cemetery)">
        <img [src]="getImageSrc(cemetery.images[0])" [alt]="cemetery.name" class="cemetery-image" />
        <div class="cemetery-info">
          <h6 class="cemetery-name">{{ cemetery.name }}</h6>
          <div class="cemetery-detail-info">
            <ng-container *ngIf="!isArchived(cemetery)">
              <span class="cemetery-status" [ngClass]="cemetery.status | lowercase">{{
        cemetery.status === 'Coming_Soon' ? 'COMING SOON' : (cemetery.status | uppercase)
      }}</span>
              <span class="cemetery-creation-date">{{ cemetery.created_at | date: 'MMM d, y' }}</span>

              <ng-container *ngIf="cemetery.owner && cemetery.owner.business_name; else cemeteryLocation">
                <span
                  class="cemetery-owner">{{ cemetery.status === 'Demo' ? 'Demo Cemetery' : cemetery.owner.business_name }}</span>
              </ng-container>

              <ng-template #cemeteryLocation>
                <span class="cemetery-location">{{ cemetery.address | address }}</span>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="isArchived(cemetery)">
              <span class="cemetery-status archived">Archived</span>
              <span class="cemetery-location">Available for {{ cemetery.days_left }} days</span>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="isLoading">
      <div class="first-section-wrapper" *ngFor="let shimmer of shimmers">
        <div class="gravatar">
          <ngx-skeleton-loader appearance="circle"
            [theme]="{ width: '50px', height: '50px', 'border-radius': '10px', left: '-5px' }">
          </ngx-skeleton-loader>
        </div>
        <div class="gravatar-title">
          <ngx-skeleton-loader [theme]="{ width: '40%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ width: '100%', height: '14px', top: '-10px' }"> </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <!-- <cl-cemetery-list-loading *ngIf="isLoading"></cl-cemetery-list-loading> -->
  </section>
</div>
