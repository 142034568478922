import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cl-circular-loading',
  templateUrl: './circular-loading.component.html',
  styleUrls: ['./circular-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircularLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
