import { AfterViewInit, ContentChild, Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, pluck, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[clCapsLock]',
})
export class CapsLockDirective implements AfterViewInit, OnDestroy {
  private capsLockEnabled = null;
  private readonly destroy$ = new Subject();
  private readonly currentDocument = document;
  @ContentChild('input') input: ElementRef;
  @ContentChild('hint') hint: ElementRef;

  ngAfterViewInit() {
    this.hideHint();

    fromEvent(this.currentDocument, 'keypress')
      .pipe(
        takeUntil(this.destroy$),
        tap((event: any) => {
          let char = null;

          if (event.which === null) {
            char = String.fromCharCode(event.keyCode); // IE
          }

          if (event.which !== 0 && event.charCode !== 0) {
            char = String.fromCharCode(event.which); // rest
          }

          if (!char) {
            return;
          }

          if (char.toLowerCase() === char.toUpperCase()) {
            return;
          }

          this.capsLockEnabled = (char.toLowerCase() === char && event.shiftKey) || (char.toUpperCase() === char && !event.shiftKey);
        })
      )
      .subscribe();

    merge(fromEvent(this.currentDocument, 'keypress'), fromEvent(this.currentDocument, 'keydown'))
      .pipe(
        takeUntil(this.destroy$),
        pluck('keyCode'),
        filter(keyCode => keyCode === 20 && this.capsLockEnabled !== null),
        tap(() => (this.capsLockEnabled = !this.capsLockEnabled))
      )
      .subscribe();

    merge(fromEvent(this.input.nativeElement, 'keyup'), fromEvent(this.input.nativeElement, 'focus'))
      .pipe(
        takeUntil(this.destroy$),
        tap(() => (this.capsLockEnabled ? this.showHint() : this.hideHint()))
      )
      .subscribe();

    fromEvent(this.input.nativeElement, 'blur')
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.hideHint())
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private showHint() {
    this.hint.nativeElement.style.visibility = 'visible';
  }

  private hideHint() {
    this.hint.nativeElement.style.visibility = 'hidden';
  }
}
