import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CemeteryInfo } from '../../interfaces/cemetery-info';
import { pluck, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapActionsService } from '../../core/map-actions.service';
import { SidebarService } from 'src/app/core/sidebar.service';

@Component({
  templateUrl: './cemetery-search-results.component.html',
  styleUrls: ['./cemetery-search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CemeterySearchResultsComponent implements OnDestroy {
  private readonly destroy$ = new Subject();
  cemeteries = this.activatedRoute.snapshot.data.cemeteries;
  verticalPerfectScrollbarConfig = { minScrollbarLength: 50 };
  searchValue: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly mapActionsService: MapActionsService,
    private sidebarService: SidebarService,
  ) {
    this.activatedRoute.data
      .pipe(
        pluck('cemeteries'),
        tap(cemeteries => {
          this.cemeteries = cemeteries;
          this.searchValue = this.activatedRoute.snapshot.queryParamMap.get('query');
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openCemetery(cemetery: CemeteryInfo) {
    this.sidebarService.close();
    this.mapActionsService.showCemeteryWithAnimation(cemetery);
  }
}
