import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  key = environment.ENC_KEY;

  constructor() {}

  tokenReplacement(data: string) {
    return `${environment.ENC_KEY}__${data}`;
  }

  tokenRecover(data: string) {
    return data.split('__')[1];
  }

  encrypt(data: string) {
    try {
      return CryptoJS.AES.encrypt(data, this.key);
    } catch (e) {
      // console.log(e);
    }
  }

  decrypt(data: string) {
    try {
      return CryptoJS.AES.decrypt(data, this.key);
    } catch (e) {
      // console.log(e);
    }
  }
}
