import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {
  private readonly isLoadingLocal = new BehaviorSubject(false);
  isLoading$ = this.isLoadingLocal.asObservable();

  get isLoading() {
    return this.isLoadingLocal.value;
  }

  run() {
    this.isLoadingLocal.next(true);
  }

  stop() {
    this.isLoadingLocal.next(false);
  }
}
