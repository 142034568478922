export enum PlotStatusString {
  Vacant = 'Vacant',
  Reserved = 'Reserved',
  Occupied = 'Occupied',
  Unavailable = 'Unavailable',
  'For Sale' = 'For Sale',
}

export enum PlotFeatureString {
  Vacant = 'Vacant',
  Reserved = 'Reserved',
  Occupied = 'Occupied',
  Unavailable = 'Unavailable',
  Featured = 'Featured',
}
