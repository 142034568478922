import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { environment } from 'src/environments/environment';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'cl-support-board',
  templateUrl: './support-board.component.html',
  styleUrls: ['./support-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportBoardComponent implements OnInit, OnDestroy {
  responsive$: boolean;
  destroy$ = new Subject<void>();
  isUserLogged = false;
  elementSupportBoardId = 'chat-init';

  constructor(private mobileResponsiveService: MobileResponsiveService, private authService: AuthService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.authService.isUserLogged$.subscribe(isLoggedIn => {
      this.isUserLogged = isLoggedIn;

      if (this.isUserLogged) {
        this.loadSupportBoard();
      } else {
        this.removeSupportBoard();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadSupportBoard(): void {
    if (!this.responsive$ && environment.SUPPORT_BOARD_ENABLED && !document.getElementById(this.elementSupportBoardId)) {
      const script = document.createElement('script');
      script.id = this.elementSupportBoardId;
      const timestamp = new Date().getTime();
      script.src = `https://cloud.board.support/account/js/init.js?id=${environment.SUPPORT_BOARD_ID}&ts=${timestamp}`;
      script.async = true;
      script.onload = () => {
        this.initChat();
      };
      document.body.appendChild(script);
    }
  }

  initChat(): void {
    if (environment.SUPPORT_BOARD_ENABLED && (window as any).ChatSupport) {
      (window as any).ChatSupport.init();
    }
  }

  removeSupportBoard(): void {
    const script = document.getElementById(this.elementSupportBoardId);

    if (script) {
      script.remove();
    }
  }

  hideChatButton(): void {
    const chatButton = document.getElementById('chat-button');
    if (chatButton) {
      chatButton.style.display = 'none';
    }
  }

  showChatButton(): void {
    const chatButton = document.getElementById('chat-button');
    if (chatButton) {
      chatButton.style.display = 'block';
    }
  }
}
