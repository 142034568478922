import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { PlotType } from '../../enums/plot-type.enum';

@Component({
  selector: 'cl-filter-sell-plots',
  templateUrl: './filter-sell-plots.component.html',
  styleUrls: ['./filter-sell-plots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSellPlotsComponent implements OnInit {
  selectedStatus = 'Monumental';
  plotTypes = PlotType as Record<string, string>;
  minValue: number = this.dialogData.price.price_min;
  maxValue: number = this.dialogData.price.price_max;
  options: Options = {
    floor: 0,
    ceil: this.dialogData.price.price_max + 50,
    hideLimitLabels: true,
    hidePointerLabels: true,
    enforceStep: false,
    enforceRange: false,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { price: any; dataFilter: any },
    public dialog: MatDialog,
    private fb: FormBuilder,
    private amplitudeService: AmplitudeService,
    private readonly dialogRef: MatDialogRef<FilterSellPlotsComponent>
  ) {}

  ngOnInit(): void {
    if (!this.dialogData.dataFilter) {
      return;
    }
    this.patchValue();
  }

  patchValue() {
    const valuePrice = this.dialogData?.dataFilter?.price_range?.split(',');
    this.selectedStatus = this.dialogData?.dataFilter?.plot_type;
    if (valuePrice) {
      this.minValue = parseInt(valuePrice[0], 10);
      this.maxValue = parseInt(valuePrice[1], 10);
    }
  }

  eventSelection() {
    const data = {
      plot_type: this.selectedStatus,
      price_range: [this.minValue, this.maxValue].join(','),
    };
    this.amplitudeService.track(`4a. Plot Purchase - Button Filter "DONE" Clicked`, {
      ...data,
    });
    this.dialogRef.close(data);
  }

  clearAllFilter() {
    this.amplitudeService.track(`4b. Plot Purchase - Button Filter "CLEAR ALL" Clicked`);
    this.selectedStatus = '';
    this.minValue = this.dialogData.price.price_min;
    this.maxValue = this.dialogData.price.price_max;
  }
}
