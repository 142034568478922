import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminDataService } from '../core/admin-data.service';
import { map, pluck, tap } from 'rxjs/operators';
import { UserRole } from '../enums/user-role.enum';
import { AppRoutes } from '../enums/app-routes.enum';

@Injectable({
  providedIn: 'root',
})
export class ManageGuard implements CanLoad {
  constructor(private adminDataService: AdminDataService, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.adminDataService.getUserInfo().pipe(
      pluck('role'),
      map(
        role =>
          role === UserRole.CHRONICLE_ADMIN ||
          role === UserRole.CUSTOMER_ORGANIZATION ||
          role === UserRole.CUSTOMER_ADMIN ||
          role === UserRole.CUSTOMER_MANAGER
      ),
      tap(isHaveAccessToManage => {
        if (isHaveAccessToManage) {
          return;
        }

        this.router.navigate([AppRoutes.CustomerViewer]);
      })
    );
  }
}
