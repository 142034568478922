<ng-container>
    <cl-toolbar-organization 
        (backToPrevious)="backToPrev()"
        [showHeader]="false">
    </cl-toolbar-organization>

<div class="main-content">
        <h1 class="title">
            {{ sourceForm?.title }}
        </h1>
        <div class="form-container">
            <!-- Left content -->
            <div class="left-content">
                <cl-render-custom-form 
                    [sourceForm]="sourceForm"
                    [showTitle]="false"
                    (allSectionValid)="sectionValid($event)">
                    >
                </cl-render-custom-form>
            </div>

            <!-- Right content -->
            <div class="right-content">
                <div class="header">
                    <div class="thumbnail">
                        <ng-container *ngIf="plotDetails === undefined">
                            <img class="plot-img" *ngIf="cemeteryInfo.images.length === 0" src="/assets/images/photo_stub.svg" alt="plot image">
                            <img class="plot-img" [src]="cemeteryInfo?.images[0]?.url" alt="cemeteryimage">
                        </ng-container>
                        <ng-container *ngIf="plotDetails">
                            <img class="plot-img" *ngIf="plotDetails.images.length === 0" src="/assets/images/photo_stub.svg" alt="plot image">
                            <img class="plot-img" *ngIf="plotDetails.images.length" [src]="plotDetails?.images[0]?.url" alt="plot image">
                        </ng-container>
                    </div>
                    <div class="info" *ngIf="plotDetails">
                        <p class="cemetery-name">
                            {{ plotDetails !== undefined ? plotDetails?.cemetery_name : cemeteryInfo?.name }}
                        </p>
                        <h3 class="plot-id">
                            {{ plotDetails.plot_id }}
                        </h3>
                    </div>
                    <div class="info" *ngIf="plotDetails === undefined && cemeteryInfo">
                        <h3 class="plot-id">
                            {{ cemeteryInfo?.name }}
                        </h3>
                    </div>
                </div>
                <div class="body">
                    <span *ngIf="sourceForm?.event_type === 'Monumental Works'; else defaultText">
                        Your application will be assessed by the cemetery administration team. The team will
                        be in touch
                        to complete the purchase process. By submitting this form you are agreeing to purchase the Right of
                        Interment of
                        this plot if approved.
                    </span>
                    <ng-template #defaultText>
                        Your application will be assessed by the cemetery administration team.
                    </ng-template>
                </div>
                <div class="action">
                    <!-- [disabled]="!allSectionValid || loaders?.createPlotPurchase?.isLoading"  -->
                    <button class="submit-request-btn" 
                        mat-stroked-button 
                        color="primary" 
                        (click)="submit()"
                        [disabled]="!allSectionValid"
                    >
                        Submit a Request
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>