import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeProcessor',
})
export class TimeProcessorPipe implements PipeTransform {
  transform(value: string): string {
    const fullDateArr = value.split('T');
    const timeArr = fullDateArr[1].split(':');
    return timeArr[0] + ':' + timeArr[1];
  }
}
