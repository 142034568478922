<ul *ngIf="parseCustomFields.length" class="custom-field-info-container">
  <ng-container *ngFor="let value of parseCustomFields; let i = index">
    <li *ngIf="value.values" class="custom-field-info-list">
      <p class="content-left">{{ value.name }}</p>
      <ng-container *ngIf="value.values.length > 50">
        <p *ngIf="!stateCustomFields[i]" style="white-space: pre-line" class="content-right">
          {{ value.values | truncateText: 100 }} <a class="toggle-button" (click)="toggle(i)">more</a>
        </p>
        <p *ngIf="stateCustomFields[i]" style="white-space: pre-line" class="content-right">
          {{ value.values }} <a class="toggle-button" (click)="toggle(i)">less</a>
        </p>
      </ng-container>
      <ng-container *ngIf="value.values.length <= 50">
        <p class="content-right" style="white-space: pre-line">{{ value.values }}</p>
      </ng-container>
    </li>
  </ng-container>
</ul>
