import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cl-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOverlayComponent {
  @Input() loading: boolean;
  @Input() mode: string;
}
