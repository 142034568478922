<cl-toolbar-manage></cl-toolbar-manage>

<section class="not-found">
  <mat-icon svgIcon="not-found" class="image"></mat-icon>
  <div class="note">
    <h1 class="note-header">Page Not Found</h1>

    <p class="note-description">We can't find the page you're looking for</p>
  </div>
  <button [disabled]="isLoading" class="gohome" (click)="goHome()" mat-raised-button color="primary">go home</button>
</section>
