import { Injectable } from '@angular/core';
import { ImageUploaderService } from './image-uploader.service';
import { UploaderType } from '../enums/uploader-type.enum';
import { FileUploaderService } from './file-uploader.service';

@Injectable()
export class UploaderManagerFactoryService {
  constructor(private imageUploaderService: ImageUploaderService, private fileUploaderService: FileUploaderService) {}

  create(type: UploaderType) {
    switch (type) {
      case UploaderType.image:
        return this.imageUploaderService;
      case UploaderType.file:
        return this.fileUploaderService;
    }
  }
}
