import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '../interfaces/person';

@Pipe({
  name: 'personsName',
})
export class PersonsNamePipe implements PipeTransform {
  transform(value: Person[]) {
    if (value.length === 0) {
      return '';
    }

    return value.map(({ first_name, last_name }) => `${first_name} ${last_name}`).join(' , ');
  }
}
