import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QRCodeComponent } from 'angularx-qrcode';
import { QrCodeGenerator } from 'src/app/interfaces/qr-code';

@Component({
  selector: 'cl-qr-code-generator',
  templateUrl: './qr-code-generator.component.html',
  styleUrls: ['./qr-code-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeGeneratorComponent implements OnInit {
  @ViewChild('qr') qrElement: ElementRef<QRCodeComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public qrCodeData: QrCodeGenerator, private readonly dialog: MatDialog) {}

  ngOnInit(): void {}

  saveAsImage() {
    /* tslint:disable:no-string-literal */
    const blobData = this.convertBase64ToBlob(this.qrElement['qrcElement'].nativeElement.firstChild.toDataURL());
    /* tslint:enable:no-string-literal */
    const blob = new Blob([blobData], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Qrcode';
    link.click();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  private convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }
}
