<div class="mock-result-container">
  <div class="icons">
    <div class="people-icon">
      <mat-icon svgIcon="custom_search_autocomplete_account"></mat-icon>
      <span>People</span>
    </div>

    <div class="plot-icon">
      <mat-icon svgIcon="custom_search_autocomplete_plot"></mat-icon>
      <span>
        {{ cemeteryName?.length > 0 ? regionalSettingsForCemetery.plots : regionalSettingsForUser.plots }}
      </span>
    </div>
  </div>
  <ng-container *ngIf="(org && cemeteryUniqueName?.length > 0) || cemeteryUniqueName?.length > 0">
    <h5 class="cemetery-name a">
      within
      {{ cemeteryName?.length > 0 ? cemeteryName : 'your ' + regionalSettingsForUser.cemeteries }}
    </h5>
  </ng-container>
  <ng-container *ngIf="org && cemeteryUniqueName?.length <= 0">
    <h5 class="cemetery-name b">
      within
      {{ org }}
    </h5>
  </ng-container>
</div>
