import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'cl-snackbar-location',
  templateUrl: './snackbar-location.component.html',
  styleUrls: ['./snackbar-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarLocationComponent {
  constructor(
    private snackRef: MatSnackBarRef<SnackbarLocationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { error: string; buttonLabel?: string; copyUrl?: boolean }
  ) {
    if (data.buttonLabel === undefined) {
      data.buttonLabel = 'Got it!';
    }

    if (data.error === undefined || data.error === null) {
      data.error = 'Something went wrong';
    }
  }

  gotIt() {
    this.snackRef.dismiss();
  }

  close() {
    this.snackRef.dismiss();
  }
}
