<perfect-scrollbar [config]="mainScrollConfig">
  <cl-shared-all-plots
    (goBackToCemetery)="goBackToCemeteryInfo()"
    (navigateToPlot)="onNavigateToPlot($event.plotId)"
    [cemeteryName]="cemetery?.name"
    [sections]="sections"
    [unknownPlot]="unknownPlot"
    [cemeteryUniqueName]="cemetery?.unique_name"
  >
    <div filter>
      <button mat-button color="primary" (click)="openFilter()">
        <mat-icon svgIcon="custom_filter"></mat-icon>
        <span>FILTER</span>
      </button>
    </div>

    <div selectedFilters>
      <perfect-scrollbar
        *ngIf="!isFilterEmpty"
        #allPlotsSelectedFilterScrollbar="ngxPerfectScrollbar"
        [config]="horizontalPerfectScrollbarConfig"
      >
        <cl-selected-filters
          [selectedFilters]="selectedFilters"
          (filterForDelete)="removeFilter($event)"
          (click)="openFilter()"
        ></cl-selected-filters>
      </perfect-scrollbar>
    </div>
  </cl-shared-all-plots>
</perfect-scrollbar>
