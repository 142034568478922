import * as Joi from 'joi';
import { addressValidator } from './address-validator';
import { boundsValidator } from './bounds-validator';
import { imageValidator } from './image-validator';
import { locationValidator } from './location-validator';
import { phoneValidator } from './phone-validator';
import { userRoleValidator } from './user-role-validator';
import { UserInfo } from '../../interfaces/user-info';
import { regionalSettingsValidator } from './regional-settings-validator';

export const cemeteryInfoValidator = Joi.object({
  access_count: Joi.number(),
  address: addressValidator.allow(null),
  administration: Joi.object({
    interest: Joi.number(),
    occupied: Joi.number(),
    reserved: Joi.number(),
    total: Joi.number(),
    vacant: Joi.number(),
    unavailable: Joi.number(),
    total_interment: Joi.number(),
  }),
  archived: Joi.boolean(),
  bounds: boundsValidator,
  created_at: Joi.date().iso(),
  database: Joi.string().min(1),
  days_left: Joi.number().allow(null),
  description: Joi.string().allow(''),
  emails: Joi.array().items(Joi.string()),
  id: Joi.number(),
  images: Joi.array().items(imageValidator),
  location: locationValidator,
  maintenance: Joi.boolean(),
  name: Joi.string().min(1),
  ortho_layer: Joi.string().allow(null),
  owner: Joi.object({
    archived: Joi.boolean(),
    business_name: Joi.string(),
    business_code: Joi.string().allow(null),
    business_address: addressValidator,
    cemeteries: Joi.array().items(Joi.number()),
    created_at: Joi.date().iso(),
    email: Joi.string(),
    first_name: Joi.string().allow(''),
    id: Joi.number(),
    last_login: Joi.date().iso().allow(null),
    last_name: Joi.string().allow(''),
    middle_name: Joi.string().allow(null, ''),
    phones: Joi.array().items(phoneValidator),
    postal_address: addressValidator.allow(null),
    role: userRoleValidator,
    show_public: Joi.boolean(),
    username: Joi.string(),
    regional_settings: regionalSettingsValidator,
  }),
  phones: Joi.array().items(phoneValidator),
  status: Joi.string(),
  unique_name: Joi.string(),
  working_hours: Joi.string().allow(null),
  web_site: Joi.string().allow(null),
});
