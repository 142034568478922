import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SliderRange } from './slider-range.enum';
import { RangeData } from './range-data';

@Component({
  selector: 'cl-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent implements ControlValueAccessor {
  minValue = SliderRange.min;
  maxValue = SliderRange.max;
  rangeData: RangeData = { minValue: this.minValue, maxValue: this.maxValue };

  onChange: any = () => {};
  onTouch: any = () => {};

  onRangeValueChange() {
    this.rangeData = {
      minValue: this.minValue,
      maxValue: this.maxValue,
    };
  }

  onChangeSliderValue(value: Array<number>) {
    this.minValue = value[0];
    this.maxValue = value[1];
    this.onChange(value);
    this.onTouch();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: number[] | null | undefined): void {
    if (value === null || value === undefined) {
      this.resetSliderValues();
      this.onRangeValueChange();
      return;
    }

    this.minValue = Math.min(...value);
    this.maxValue = Math.max(...value);
    this.onRangeValueChange();
  }

  resetSliderValues() {
    this.minValue = SliderRange.min;
    this.maxValue = SliderRange.max;
  }
}
