import { AfterViewInit, ContentChildren, Directive, ElementRef, EventEmitter, OnDestroy, Output, QueryList } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[clObserveLastChild]',
})
export class ObserveLastChildDirective implements AfterViewInit, OnDestroy {
  @Output() lastItemIntersecting = new EventEmitter<IntersectionObserverEntry>();

  @ContentChildren('clObserveItems', { read: ElementRef }) set els(data: QueryList<ElementRef>) {
    if (data && data?.toArray()?.length > 0) {
      this.els$.next(data);
    }
  }

  destroy$ = new Subject<void>();

  els$ = new BehaviorSubject<QueryList<ElementRef>>(null);

  observer: IntersectionObserver;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.initIntersectionElement();
  }

  initIntersectionElement() {
    this.els$
      .pipe(
        filter(els => !!els?.toArray()?.length),
        switchMap(elements =>
          elements.changes.pipe(
            startWith(elements),
            tap(() => {
              if (this.observer) {
                this.observer.disconnect();
              }
            })
          )
        ),
        filter(els => !!els?.toArray()?.length),
        tap((elements: QueryList<ElementRef<any>>) => {
          const lastItem = elements.last.nativeElement;
          this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                this.lastItemIntersecting.emit(entry);
              }
            });
          });
          this.observer.observe(lastItem);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
