import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { RegionalSettingsService } from '../../core/regional-settings.service';

@Component({
  selector: 'cl-search-cemeteries-annotation',
  templateUrl: './search-cemeteries-annotation.component.html',
  styleUrls: ['./search-cemeteries-annotation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchCemeteriesAnnotationComponent {
  @Input() org;
  get regionalSettingsForUser() {
    return this.regionalSettingsService.regionalSettingsForUser$.value;
  }

  constructor(
    private regionalSettingsService: RegionalSettingsService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_church',
        src: '/assets/images/church_icon.svg',
      },
    ]);
  }
}
