import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogData } from './confirmation-dialog-data';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cl-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent implements OnDestroy {
  private readonly destroy$ = new Subject();
  isLoading = false;

  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  cancel() {
    this.dialogRef.close(false);
  }

  action() {
    if (this.data.action$ === undefined) {
      this.dialogRef.close(true);
      return;
    }

    this.isLoading = true;

    this.data.action$
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.dialogRef.close(true);
          this.isLoading = false;
        })
      )
      .subscribe();
  }
}
