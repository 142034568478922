<div class="advance-search-container">
  <div *ngIf="!isLoading" class="toggle-button-boundaries">
    <mat-slide-toggle class="primary-toggle" [formControl]="this.toogleOn">Search by boundaries</mat-slide-toggle>
  </div>
  <div class="title">
    <div class="left-title" *ngIf="isLoading">
      <h3>Search in progress...</h3>
      <p>Please be patient.</p>
    </div>
    <div class="left-title" *ngIf="!isLoading">
      <h3>{{ pagination.total_data }} plots found...</h3>
      <p>in {{ totalCemetery.length }} cemeteries</p>
    </div>

    <button class="right-title" (click)="close()" mat-icon-button aria-label="close advance search">
      <mat-icon class="material-icons close-icon" svgIcon="custom_shared_plot_details_close"></mat-icon>
    </button>
  </div>
  <div
    class="search-result"
    infiniteScroll
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="100"
    [scrollWindow]="false"
    (scrolled)="onScrollDown()"
  >
    <ng-container *ngIf="!isLoading && searchList.length === 0">
      <div class="wrapper-not-found">
        <div class="no-result">No results at this place</div>
        <div class="no-result-small-text">Please zoom out to see more items</div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && searchList.length > 0">
      <div class="search-list" (click)="onClick(record)" *ngFor="let record of searchList">
        <div class="picture">
          <!-- <div *ngIf="!record.images[0]?.url" class="no-photo"></div> -->
          <mat-icon
            *ngIf="!record.images[0]?.url"
            svgIcon="custom_shared_search_item_plot"
            class="{{ record.status | lowercase }}"
          ></mat-icon>
          <img *ngIf="record.images[0]?.url" [src]="record.images[0]?.url" alt="plot photo" class="photo" />
        </div>
        <div class="content">
          <div class="content-title">
            <span>{{ formatPlotId(record.plot_id) }}</span>
            <span
              class="icon-image"
              *ngIf="
                checkIfFeaturedStory(record.persons) || checkIfLifeChronicle(record.persons) || checkIfReturnServicePerson(record.persons)
              "
            >
              <img
                *ngIf="checkIfFeaturedStory(record.persons)"
                title="Official Story"
                src="/assets/images/featured-story-ban.png"
                style="width: 20px"
                alt=""
              />
              <img
                *ngIf="checkIfLifeChronicle(record.persons)"
                title="Life Chronicle Online Memorial"
                src="/assets/images/life-chronicle-ban.png"
                style="width: 20px"
                alt=""
              />
              <img
                *ngIf="checkIfReturnServicePerson(record.persons)"
                title="Return Service Person"
                src="/assets/images/Veteran.png"
                style="width: 20px"
                alt=""
              />
            </span>
            <span class="divider">|</span>
            <span>{{ record.cem_name }}</span>
          </div>
          <div class="content-main">
            <div class="main-left">
              <ng-container *ngFor="let person of record.persons">
                <span class="name" clHighlight [content]="highlightedContent(person)" [searchTerm]="searchValue">
                  {{ person | name }}
                </span>
                <span *ngIf="record.persons.length > 1">{{ ', ' }}</span>
              </ng-container>
              <span *ngIf="record.persons[0]?.date_of_birth && record.persons[0]?.date_of_death" class="date"
                >({{ record.persons[0]?.date_of_birth | dateIsoToJs | date: 'yyyy' }}-{{
                  record.persons[0]?.date_of_death | dateIsoToJs | date: 'yyyy'
                }})</span
              >
            </div>

            <div class="main-right" *ngIf="record.capacity > 1">
              <mat-icon class="material-icons people-icon" svgIcon="custom_people"></mat-icon>
              <span>{{ record.capacity }}</span>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isLoadingNewPage">
        <cl-advance-search-loading-skeleton *ngFor="let a of [1]"></cl-advance-search-loading-skeleton>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <cl-advance-search-loading-skeleton *ngFor="let a of [1, 2, 3, 4, 5, 6, 7, 8]"></cl-advance-search-loading-skeleton>
    </ng-container>
  </div>
</div>
