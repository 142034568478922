<!-- <ng-container *ngIf="!dialogData.isAus">
  <div [ngClass]="responsive$ === true ? 'banner-story-wrapper-mobile' : 'banner-story-wrapper'">
    <img
      [src]="!responsive$ ? '/assets/images/bg-story-rectangle.png' : '/assets/images/bg-banner-line-mobile.png'"
      [ngClass]="responsive$ === true ? 'banner-mobile' : 'banner-desktop'"
    />
    <img [src]="!responsive$ ? '/assets/images/bg-story-banner.png' : ''" class="banner-women" />

    <div class="content">
      <div *ngIf="!responsive$" class="title-main">
        Remember and share the life of your <br />
        loved ones with Life Chronicle memorials
      </div>
      <div *ngIf="responsive$" class="title-main-mobile">
        Remember and share the <br />
        life of your loved ones with<br />
        Life Chronicle memorials
      </div> -->

<!-- <div class="desc-price">Only <span class="au-symbol">AU</span> $65 (paid once)</div>
          <div class="desc-main">
            Heartfelt memorials. <br />
            Connected to their final resting place. <br />
            Cherished by generations to come.
          </div> -->
<!-- <div class="list-promotion">
        <div class="promotion-item">
          <div>
            <img [ngClass]="responsive$ === true ? 'image-item-responsive' : 'image-item'" src="/assets/images/promotion-1.png" />
          </div>
          <div [ngClass]="responsive$ === true ? 'text-item-mobile' : 'text-item'">Securely store life stories, photos, and videos</div>
        </div>
        <div class="promotion-item">
          <div>
            <img [ngClass]="responsive$ === true ? 'image-item-responsive' : 'image-item'" src="/assets/images/promotion-2.png" />
          </div>
          <div [ngClass]="responsive$ === true ? 'text-item-mobile' : 'text-item'">
            Share one link for the memorial and the final resting place location
          </div>
        </div>
        <div class="promotion-item">
          <div>
            <img [ngClass]="responsive$ === true ? 'image-item-responsive' : 'image-item'" src="/assets/images/promotion-3.png" />
          </div>
          <div *ngIf="!responsive$" class="text-item">
            <div>Personalised themes with templates</div>
            <div class="soon-label">soon</div>
          </div>
          <div *ngIf="responsive$" class="text-item-mobile">
            <div>Personalised themes with templates</div>
            <div class="soon-label-mobile">soon</div>
          </div>
        </div>
      </div>
      <div class="text-price">
        {{ 49 | currency: 'USD' }}<br />
        <span>one-time payment</span>
      </div>
      <div [ngClass]="responsive$ === true ? 'button-bottom-mobile' : 'button-bottom'">
        <button mat-raised-button mat-button-base mat-primary color="primary" (click)="openStoryFromBanner()">GET STARTED</button>
        <a target="_blank" rel="noopener noreferrer" class="link" (click)="goToLink('http://life.chronicle.rip/')">LEARN MORE</a>
      </div>
    </div>

    <div class="icon-close">
      <mat-icon class="material-icons close-icon" (click)="close()" svgIcon="custom_shared_plot_details_close"></mat-icon>
    </div>
  </div>
</ng-container> -->

<ng-container>
  <div [ngClass]="responsive$ === true ? 'banner-story-wrapper-mobile' : 'banner-story-wrapper'">
    <div class="icon-close">
      <mat-icon class="material-icons close-icon" (click)="close()" svgIcon="custom_shared_plot_details_close"></mat-icon>
    </div>
    <div class="image-container">
      <img
        *ngIf="!responsive$"
        [src]="this.dialogData.isAus ? 'https://chronicle.rip/lc-audesktop/' : 'https://chronicle.rip/lc-usdesktop/'"
      />
      <img
        *ngIf="responsive$"
        [src]="this.dialogData.isAus ? 'https://chronicle.rip/lc-aumobile/' : 'https://chronicle.rip/lc-usmobile/'"
      />
    </div>

    <div class="footer">
      <div class="price-wrapper" *ngIf="!responsive$">
        <div class="symbol">{{ dialogData.isAus ? 'AU$' : 'US$' }}</div>
        <div class="number">{{ dialogData.isAus ? '65' : '49' }}</div>
        <!-- <div class="number">/</div>
        <div class="symbol" style="margin-left: 7px">US$</div>
        <div class="number">49</div> -->

        <div class="text-info-italic">one-time payment</div>
      </div>

      <div class="button-wrapper">
        <button class="button-event-create" mat-raised-button color="primary" (click)="openStoryFromBanner()">CREATE NOW</button>
        <a target="_blank" rel="noopener noreferrer" class="link" (click)="goToLink('https://chronicle.rip/life-chronicle/')">Learn More</a>
      </div>
    </div>
  </div>
</ng-container>
