import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlotStatusString } from '../../enums/plot-status-string.enum';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { Plot } from 'src/app/interfaces/plot';
import { transformName, transformNameAdvanceSearch } from '../name-transform-function';
import { PersonRoles } from 'src/app/enums/person-roles.enum';

@Component({
  selector: 'cl-search-plot-item',
  templateUrl: './search-plot-item.component.html',
  styleUrls: ['./search-plot-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPlotItemComponent {
  @Input() searchValue: string;

  @Input() image: string;
  @Input() plotId: string;
  @Input() status: PlotStatusString;

  @Input() plot: Plot | any;
  @Input() isReturnServicePerson = false;
  @Input() isFeaturedStory = false;
  @Input() isLifeChronicle = false;
  @Input() isAdmin: boolean;
  @Input() org: string | null;
  @Input() cemeteryUniqueName: string;
  get transFormName() {
    return transformName(this.plot?.interments[0]?.person);
  }

  get highlightedContentRoi() {
    return transformName(this.plot?.roi[0]);
  }

  constructor(private addIconsThroughMaterialService: AddIconsThroughMaterialService) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_shared_search_item_plot',
        src: '/assets/images/plot_icon.svg',
      },
    ]);
  }

  getRoiHolderLabelTitle(roi): string {
    if (roi.role.includes(PersonRoles.RoiHolder)) {
      return 'Roi holder';
    }
    if (roi.role.includes(PersonRoles.RoiApplicant)) {
      return 'Roi applicant';
    }
    if (roi.role.includes(PersonRoles.RoiHolderRoiApplicant)) {
      return 'Roi holder & applicant';
    }
  }

  formatPlotId(plotId: string) {
    return plotId.includes('Unassigned') ? 'Unassigned' : plotId;
  }
}
