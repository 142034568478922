import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'cl-mat-calendar-picker',
  templateUrl: './mat-calendar-picker.component.html',
  styleUrls: ['./mat-calendar-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatCalendarPickerComponent),
      multi: true,
    },
  ],
})
export class MatCalendarPickerComponent implements ControlValueAccessor {
  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      start: [null],
      end: [null],
    });

    this.form.valueChanges
      .pipe(
        filter(({ start, end }) => start || end),
        tap(({ start, end }) => {
          if (start !== null && end === null) {
            this.form.get('end').setValue(start);
            return;
          }

          if (start === null && end !== null) {
            this.form.get('start').setValue(end);
            return;
          }

          if (start > end) {
            this.form.get('start').setValue(end);
            this.form.get('end').setValue(start);
            return;
          }
        }),
        tap(() => {
          const start = this.form.get('start').value;
          const end = this.form.get('end').value;
          this.onChange([start, end]);
          this.onTouch();
        })
      )
      .subscribe();
  }

  isBeforeToday(d = new Date()) {
    return d <= new Date();
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(dates: Date[] | null = []) {
    if (!dates || dates.length === 0) {
      this.form.reset();
      this.changeDetectorRef.detectChanges();
      return;
    }

    const [start, end] = dates;

    this.form.get('start').setValue(start);
    this.form.get('end').setValue(end);
  }
}
