<ng-container [formGroup]="group">
  <mat-form-field class="input-roi-wrapper">
    <mat-label>ROI Certificate number</mat-label>
    <input formControlName="{{ roiNumberControlName }}" type="" matInput placeholder="112221" />
  </mat-form-field>
  <div class="date-roi-wrapper">
    <mat-form-field appearance="fill" class="calendar-date">
      <mat-label>Application date</mat-label>
      <mat-date-range-input [formGroup]="group.get('application_date')" [rangePicker]="pickerAppDate">
        <input matStartDate #dateMask formControlName="startApplicationDate" placeholder="Start date" />
        <input matEndDate #dateMask formControlName="endApplicationDate" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="pickerAppDate"></mat-datepicker-toggle>
      <mat-date-range-picker #pickerAppDate></mat-date-range-picker>
      <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
      <mat-error *ngIf="group.get('application_date').invalid">Invalid date</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="calendar-date">
      <mat-label>Expiry date</mat-label>
      <mat-date-range-input [formGroup]="group.get('expiry_date')" [rangePicker]="pickerExpiryDate">
        <input matStartDate #dateMask formControlName="startExpiryDate" placeholder="Start date" />
        <input matEndDate #dateMask formControlName="endExpiryDate" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="pickerExpiryDate"></mat-datepicker-toggle>
      <mat-date-range-picker #pickerExpiryDate></mat-date-range-picker>
      <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
      <mat-error *ngIf="group.get('expiry_date').invalid">Invalid date</mat-error>
    </mat-form-field>
  </div>
  <div class="dropdown-wrapper">
    <mat-form-field class="input-dropdown">
      <mat-label>Right Type</mat-label>
      <mat-select formControlName="{{ rightTypeControlName }}">
        <mat-option *ngFor="let item of rightType | keyvalue" [value]="item.value">{{ item.value }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-dropdown">
      <mat-label>Term of rights</mat-label>
      <mat-select formControlName="{{ torTypeControlName }}" [disabled]="isChronicleAdmin">
        <!-- <mat-option *ngFor="let type of plotTypes | keyvalue" [value]="type.key">{{ type.value }}</mat-option> -->
        <mat-option *ngFor="let item of termOfRights" [value]="item.value">{{ item.key }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="payment-wrapper">
    <div class="fee-wrapper">
      <div class="fee-text">Fee</div>
      <div class="fee-input-range" [formGroup]="group.get('fee')">
        <mat-form-field class="qty-input">
          <mat-label>From</mat-label>
          <input formControlName="fromFee" matInput />
        </mat-form-field>
        <div class="separator">-</div>
        <mat-form-field class="qty-input">
          <mat-label>to</mat-label>
          <input formControlName="toFee" matInput />
        </mat-form-field>
      </div>
    </div>
    <mat-checkbox formControlName="{{ chargedControlName }}" class="example-margin">Charged</mat-checkbox>
    <mat-form-field appearance="fill" class="calendar-date">
      <mat-label>Payment date</mat-label>
      <mat-date-range-input [formGroup]="group.get('payment_date')" [rangePicker]="pickerPaymentDate">
        <input matStartDate #dateMask formControlName="startPaymentDate" placeholder="Start date" />
        <input matEndDate #dateMask formControlName="endPaymentDate" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="pickerPaymentDate"></mat-datepicker-toggle>
      <mat-date-range-picker #pickerPaymentDate></mat-date-range-picker>
      <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
      <mat-error *ngIf="group.get('payment_date').invalid">Invalid date</mat-error>
    </mat-form-field>
  </div>
</ng-container>
