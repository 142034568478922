import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { RegionalSettingsService } from '../core/regional-settings.service';

@Pipe({
  name: 'dateInRegionalFormat',
})
export class DateInRegionalFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private regionalSettingsService: RegionalSettingsService) {}

  transform(value: Date | string): string {
    const format = this.regionalSettingsService.getDatePipeFormat();
    const isUTC = value.toString().substr(value.toString().length - 1) === 'Z';

    return isUTC ? new DatePipe('en-US').transform(value, format, 'GMT+1') : this.datePipe.transform(value, format);
    // return this.datePipe.transform(value, format, '+0200', 'en');
  }
}
