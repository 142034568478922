import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { SectionData } from '../../interfaces/section-data';
import { FilterWithoutStatusesComponent } from '../../shared/filter-without-statuses/filter-without-statuses.component';
import { FilterFormData } from '../../interfaces/filter-form-data';
import { filter, finalize, map, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../loading-overlay/loading.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { FilterViewAdapterService } from '../../core/filter-view-adapter.service';
import { PlotsService } from '../../core/plots.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CemeteryInfo } from '../../interfaces/cemetery-info';
import { MapActionsService } from '../../core/map-actions.service';
import { NavigateBackService } from '../../core/navigate-back.service';
import { MapDataService } from '../../core/map-data.service';
import { Subject } from 'rxjs';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { RouterUtilsService } from '../../core/router-utils.service';
import { PlotListItem } from '../../interfaces/plot-list-item';

@Component({
  selector: 'cl-public-all-plots',
  templateUrl: './public-all-plots.component.html',
  styleUrls: ['./public-all-plots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicAllPlotsComponent implements OnDestroy {
  private readonly destroy$ = new Subject();
  private filter: FilterFormData = {};
  selectedFilters: Record<string, string>;
  sections: SectionData[] = this.activatedRoute.snapshot.data.plots;
  cemetery: CemeteryInfo;
  unknownPlot: PlotListItem = this.activatedRoute.snapshot.data.unknownLocationPlot;
  cemeteryUniqueName = this.activatedRoute.snapshot.paramMap.get('cemeteryName');
  mainScrollConfig = { minScrollbarLength: 50 };
  horizontalPerfectScrollbarConfig = { useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false };
  @ViewChild('allPlotsSelectedFilterScrollbar', { static: false }) allPlotsScrollbar: PerfectScrollbarComponent;

  get isFilterEmpty() {
    return !this.selectedFilters || Object.keys(this.selectedFilters).length === 0;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private loadingService: LoadingService,
    private filterViewAdapterService: FilterViewAdapterService,
    private plotsService: PlotsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mapActionsService: MapActionsService,
    private mapDataService: MapDataService,
    private navigateBackService: NavigateBackService,
    private routerUtilsService: RouterUtilsService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([
      { name: 'custom_site', src: '/assets/images/site_icon.svg' },
      { name: 'custom_arrow', src: '/assets/images/arrow_left.svg' },
      { name: 'custom_account', src: '/assets/images/account_gray_icon.svg' },
      { name: 'custom_filter', src: '/assets/images/filter_icon.svg' },
      { name: 'custom_admin_shared_all_plots', src: '/assets/images/question_icon.svg' },
    ]);

    this.activatedRoute.data
      .pipe(
        pluck('cemetery'),
        filter(Boolean),
        tap<CemeteryInfo>(cemetery => {
          this.cemetery = cemetery;
          const isCemeteryInViewport = this.mapDataService.isCemeteryInViewport(cemetery);

          if (!isCemeteryInViewport) {
            this.mapActionsService.showCemetery(this.cemetery);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNavigateToPlot(plotId: string | null = null) {
    const path = this.routerUtilsService.getPath(this.cemeteryUniqueName, 'plots', plotId);

    this.router.navigate(path, {
      queryParams: { backTo: this.navigateBackService.previousUrl },
    });
  }

  goBackToCemeteryInfo() {
    const path = this.routerUtilsService.getPath(this.cemeteryUniqueName);
    this.router.navigate(path);
  }

  openFilter() {
    this.matDialog
      .open<FilterWithoutStatusesComponent, any, FilterFormData>(FilterWithoutStatusesComponent, {
        width: '530px',
        panelClass: 'filter-modal',
        disableClose: true,
        data: { ...this.filter },
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        tap((data: FilterFormData) => {
          this.filter = data;
          this.selectedFilters = this.filterViewAdapterService.getSelectedFilters(this.filter);
          this.changeDetectorRef.markForCheck();
        }),
        tap(() => this.loadingService.run()),
        switchMap(data => this.plotsService.getPlotsList(this.cemeteryUniqueName, data)),
        map(sections => (typeof sections === 'object' && Object.keys(sections).length > 0 ? sections : [])),
        tap((sections: SectionData[]) => {
          this.sections = sections;
          this.changeDetectorRef.markForCheck();
        }),
        switchMap(() => this.plotsService.getUnknownLocationPlot(this.cemeteryUniqueName, this.filter)),
        tap(plot => {
          this.unknownPlot = plot;
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.destroy$),
        finalize(() => {
          this.loadingService.stop();
          if (this.allPlotsScrollbar && this.allPlotsScrollbar.directiveRef) {
            this.allPlotsScrollbar.directiveRef.update();
          }
        })
      )
      .subscribe();
  }

  removeFilter(key: string) {
    this.loadingService.run();
    delete this.filter[key];
    this.selectedFilters = this.filterViewAdapterService.getSelectedFilters(this.filter);
    this.plotsService
      .getPlotsList(this.cemeteryUniqueName, this.filter)
      .pipe(
        map(sections => (typeof sections === 'object' && Object.keys(sections).length > 0 ? sections : [])),
        tap((sections: SectionData[]) => {
          this.sections = sections;
          this.changeDetectorRef.markForCheck();
        }),
        switchMap(() => this.plotsService.getUnknownLocationPlot(this.cemeteryUniqueName, this.filter)),
        tap(plot => {
          this.unknownPlot = plot;
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.destroy$),
        finalize(() => {
          this.loadingService.stop();

          if (this.allPlotsScrollbar && this.allPlotsScrollbar.directiveRef) {
            this.allPlotsScrollbar.directiveRef.update();
          }
        })
      )
      .subscribe();
  }
}
