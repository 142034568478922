import { Image } from './image';
import { Person } from './person';

export interface StoryPublicForm {
  first_name: string;
  last_name: string;
  phone_number: number;
  email: string;
  relationship_interment: string;
  title: string;
  text: string;
  amplitude_user_id?: string;
  success_page: string;
  error_page: string;
  images: [];
}

export interface StoryPublicFormEdit {
  title: string;
  text: string;
  images: [];
}

export interface StoryType {
  id: number;
  value: string;
  text: string;
}

export interface StoryStatus {
  id: number;
  value: string;
  text: string;
}

export interface StoryPublicResponse {
  data: StoryPublic[];
  paginate: StoryPublicPagination;
}

export interface CreateEditStoryResponse {
  story_id: number;
  user_id: number;
  client_secret: string;
  expiration_date: string;
  amount: number;
  currency: string | string;
}

export interface ApproveRejectStoryResponse {
  story_id: number;
  message: string;
}

export interface StoryPublic {
  id: number;
  text: string;
  cemetery_unique_name: string;
  is_featured: number;
  approval_step: number;
  rejected_reason: string;
  relationship_interment: string;
  status_story: string;
  title: string;
  is_admin: number;
  external_id: string;
  cemetery: CemeteryInfo;
  interment_record: IntermentInfo;
  approval_user: ApprovalCreatedUser;
  created_user: ApprovalCreatedUser;
  payment: string;
  images: Image[];
  created_at: string;
  updated_at: string;
  approved_at: string;
  rejected_at: string;
}

export interface CemeteryInfo {
  id: number;
  unique_name: string;
  database: string;
  ortho_layer: string;
  name: string;
  maintenance: boolean;
  status: string;
  working_hours: string;
  web_site: string;
  location: object;
  sell_story: number;
}

export interface IntermentInfo {
  id: number;
  external_id: string;
  interment_type: string;
  returned_serviceman: number;
  cause_of_death: string;
  occupation: string;
  religion: string;
  headstone: string;
  comment: string;
  plot: PlotInfo;
  person?: Person;
}

export interface PlotInfo {
  id: number;
  plot_id: string;
  section: string;
  row: string;
  plot_no: number;
  status: string;
}

export interface ApprovalCreatedUser {
  id: number;
  is_superuser: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: number;
  is_active: number;
  role: number;
  archived: number;
  show_public: number;
  middle_name: string;
}

export interface StoryPublicPagination {
  page: string;
  limit: string;
  total: string;
}

export const storyType: StoryType[] = [
  { id: 1, value: 'Son', text: 'Son' },
  { id: 2, value: 'Daughter', text: 'Daughter' },
  { id: 3, value: 'Father', text: 'Father' },
  { id: 4, value: 'Mother', text: 'Mother' },
  { id: 5, value: 'Spouse', text: 'Spouse' },
  { id: 6, value: 'Other', text: 'Other' },
];

export const storyStatus: StoryStatus[] = [
  { id: 1, value: 'waiting', text: 'Waiting for approval' },
  { id: 2, value: 'declined', text: 'Declined' },
  { id: 3, value: 'approved', text: 'Approved' },
];
