<ng-container *ngIf="{ 
        measureResultModel: measureResultModel$ | async, 
        isCalendarView: isCalendarView$ | async,
        isSideBarOpen: isSideBarOpen$ | async } 
        as vm">
    <button 
        [disabled]="vm.isCalendarView && vm.isSideBarOpen" 
        class="btn-legend-trigger" 
        mat-stroked-button
        #trigger="matMenuTrigger" 
        [matMenuTriggerFor]="markerLegend" 
        (menuOpened)="startMeasure()"
        (menuClosed)="clearMeasure()">
        <mat-icon class="filter-icon" svgIcon="ruler"></mat-icon>
    </button>
    <mat-menu #markerLegend="matMenu" class="mat-elevation-z0 legend-panel" xPosition="before" hasBackdrop="false">
        <ng-template matMenuContent>
            <div class="container" (click)="$event.stopPropagation()">
                <div class="legend-panel-header">
                    <h4 class="legend-panel-title">Measure</h4>
                    <button mat-icon-button class="btn-close" (click)="openMeasureOptionModal();">
                        <mat-icon svgIcon="gear_icon"></mat-icon>
                    </button>
                </div>
                <div class="legend-panel-body" [formGroup]="measureLegendForm">
                    <div>
                        <p class="hint-text">
                            Start creating a measurement by adding points to the map
                            <mat-icon class="disclaimer-icon"
                                matTooltip="Coordinates and measurements are provided for indicative purposes only. Please consult a professional surveyor for any work requiring precise data"
                                svgIcon="warning"></mat-icon>
                        </p>
                    </div>
                    <div class="field result" *ngIf="vm.measureResultModel">
                        <div class="left">
                            <h4>Last point</h4>
                        </div>
                        <div class="right">
                            <p>{{ parseLatLng(vm.measureResultModel.lastCoord.dd.y)}}, {{
                                parseLatLng(vm.measureResultModel.lastCoord.dd.x) }}</p>
                        </div>
                    </div>
                    <ng-container *ngIf="vm.measureResultModel?.pointCount >= 2">
                        <div class="field result">
                            <div class="left">
                                <h4>Distance</h4>
                            </div>
                            <div class="right">
                                <p>{{ vm.measureResultModel.lengthDisplay }}</p>
                            </div>
                        </div>
                        <div class="field result" *ngIf="vm.measureResultModel?.pointCount > 2">
                            <div class="left">
                                <h4>Area</h4>
                            </div>
                            <div class="right">
                                <p>{{ vm.measureResultModel.areaDisplay }}</p>
                            </div>
                        </div>
                    </ng-container>

                    <div class="field action">
                        <!-- <button mat-flat-button class="cancel-btn" (click)="clearMeasure()">
                            <mat-icon svgIcon="close_cancel_purple"></mat-icon> CANCEL
                        </button> -->
                        <button mat-flat-button [disabled]="vm.measureResultModel?.pointCount < 2" color="primary"
                            (click)="trigger.closeMenu()">
                            <mat-icon svgIcon="check_circle"></mat-icon>
                            FINISH
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </mat-menu>
</ng-container>

<ng-template #measureOptionsTpl>
    <div class="measure-options-container">
        <h2 mat-dialog-title>Measurement options</h2>
        <mat-dialog-content [formGroup]="measureLegendForm">
            <mat-form-field appearance="legacy">
                <mat-label>Distance</mat-label>
                <mat-select formControlName="distanceUnit">
                    <mat-option class="measure-option" *ngFor="let unit of measurementOptions.distance" [value]="unit.value">
                        {{unit.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Area</mat-label>
                <mat-select formControlName="areaUnit">
                    <mat-option class="measure-option" *ngFor="let unit of measurementOptions.area" [value]="unit.value">
                        {{unit.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>CANCEL</button>
            <button mat-flat-button color="primary" [mat-dialog-close]="true">DONE</button>
        </mat-dialog-actions>
    </div>
</ng-template>