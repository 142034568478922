import { Component, OnInit, ChangeDetectionStrategy, Input, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { from, fromEvent } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MaskPipe } from 'ngx-mask';
import { RegionalSettings } from 'src/app/interfaces/regional-settings';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'cl-interment-form',
  templateUrl: './interment-form.component.html',
  styleUrls: ['./interment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class IntermentFormComponent implements OnInit, AfterViewInit {
  @ViewChildren('dateMask') dateMaskTmplRef: QueryList<ElementRef>;
  @Input() group: FormGroup;
  @Input() intermentIdControlName: string;
  @Input() ageFrom: FormControl;
  @Input() ageTo: FormControl;
  @Input() optionsValue: any;
  @Input() isAdmin: any;
  @Input() regionalSettings: RegionalSettings;
  @Input() destroy$: any;
  constructor(private ngxMaskPipe: MaskPipe) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    from(this.dateMaskTmplRef.toArray())
      .pipe(
        mergeMap(tplRef => fromEvent<InputEvent>(tplRef.nativeElement, 'input')),
        tap(event => {
          const target = event.target as HTMLInputElement;
          target.value = this.ngxMaskPipe.transform(target.value, 'd0/M0/0000');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
