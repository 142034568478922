<div class="default-image">
  <img [src]="defaultImage" alt="" (click)="localShowInputImg && inputNewImage.click()" />
</div>
<ng-container *ngIf="localImages?.length >= 1">
  <div class="swiper-wrapper" [class.initializing]="loading">
    <div class="swiper-slide" *ngFor="let slide of localImages; let i = index">
      <img
        [ngStyle]="{ 'object-fit': '' + localObjectFit + '' }"
        class="main-slide"
        [src]="slide.url ? slide.url : slide.image"
        alt=""
        (click)="preview(i)"
        #image
      />
      <img [src]="slide.url ? slide.url : slide.image" alt="" class="background-slide" />
    </div>
    <div class="swiper-slide" *ngIf="localShowInputImg">
      <img
        [ngStyle]="{ 'object-fit': '' + localObjectFit + '' }"
        class="main-slide"
        (click)="inputNewImage.click()"
        [src]="defaultImage"
        alt=""
        #image
      />
      <img [src]="defaultImage" alt="" class="background-slide" />
    </div>
  </div>
  <ng-container *ngIf="!blackArrow">
    <div class="custom-swiper-button-prev">
      <img src="/assets/images/arrow_icon_white.svg" alt="" />
    </div>
    <div class="custom-swiper-button-next">
      <img src="/assets/images/arrow_icon_white.svg" alt="" />
    </div>
  </ng-container>

  <ng-container *ngIf="blackArrow">
    <div class="custom-swiper-button-prev">
      <img style="filter: invert(1); height: 25px" src="/assets/images/arrow_icon_white.svg" alt="" />
    </div>
    <div class="custom-swiper-button-next">
      <img style="filter: invert(1); height: 25px" src="/assets/images/arrow_icon_white.svg" alt="" />
    </div>
  </ng-container>

  <ng-container *ngIf="!blackArrow">
    <div class="swiper-pagination"></div>
  </ng-container>
  <ng-container *ngIf="blackArrow">
    <div style="filter: invert(1)" class="swiper-pagination"></div>
  </ng-container>
</ng-container>

<input id="file-input" type="file" accept="image/*" (change)="onImageSelected($event)" #inputNewImage />
