<ng-container *ngIf="loaders$ | async as loaders">
  <cl-toolbar-organization (backToPrevious)="!loaders?.createPlotPurchase?.isLoading && cancel()" [showHeader]="false">
  </cl-toolbar-organization>

  <div class="main-content">
    <h1 class="title">{{ formTitle }}</h1>
    <div class="form-container">
      <!-- Left content -->
      <div class="left-content">
        <cl-render-custom-form [sourceForm]="sourceForm" [showTitle]="false" [tempApplicantLabel]="tempApplicantLabel" (allSectionValid)="sectionValid($event)">
        </cl-render-custom-form>
      </div>

      <!-- Right content -->
      <div class="right-content" *ngIf="!isPreview">
        <div class="header">
          <div class="thumbnail">
            <img class="plot-img" *ngIf="!plotDetails.images.length" src="/assets/images/photo_stub.svg" alt="plot image" />
            <img class="plot-img" *ngIf="plotDetails.images.length" [src]="plotDetails.images[0].url" alt="plot image" />
          </div>
          <div class="info">
            <p class="cemetery-name">{{ plotDetails.cemetery_name }}</p>
            <h3 class="plot-id">{{ plotDetails.plot_id }}</h3>
            <div class="status-badge">for sale</div>
          </div>
          <div *ngIf="plotDetails.show_price === 1" class="price">
            <span>${{ plotDetails.price }}</span>
          </div>
        </div>
        <div class="body">
          Your application will be assessed by the cemetery administration team. The team will be in touch to complete the purchase process.
          By submitting this form you are agreeing to purchase the Right of Interment of this plot if approved.
        </div>
        <div class="action">
          <button
            class="submit-request-btn"
            mat-stroked-button
            color="primary"
            [disabled]="!allSectionValid || loaders?.createPlotPurchase?.isLoading"
            (click)="submit()"
          >
            Submit a Request
          </button>
        </div>
      </div>
      <div class="right-content" *ngIf="isPreview">
        <div class="header">
          <div class="thumbnail">
            <img class="plot-img" src="/assets/images/photo_stub.svg" alt="plot image" />
          </div>
          <div class="info">
            <p class="cemetery-name">Demo US Cemetery</p>
            <h3 class="plot-id">DE US 77</h3>
            <div class="status-badge">for sale</div>
          </div>
          <div class="price"><span>$10975</span></div>
        </div>
        <div class="body">
          Your application will be assessed by the cemetery administration team. The team will be in touch to complete the purchase process.
          By submitting this form you are agreeing to purchase the Right of Interment of this plot if approved.
        </div>
        <div class="action">
          <button
            class="submit-request-btn"
            mat-stroked-button
            color="primary"
            [disabled]="!allSectionValid || loaders?.createPlotPurchase?.isLoading"
            (click)="submit()"
          >
            Submit a Request
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #dialogSuccessPurchase>
    <div class="dialog-success-purchase">
      <h1 mat-dialog-title>Pre-need plot purchase</h1>
      <mat-dialog-content>
        <div class="cemetery-info">
          <div class="image-container">
            <img class="plot-img" *ngIf="!plotDetails.images.length" src="/assets/images/photo_stub.svg" alt="plot image" />
            <img class="plot-img" *ngIf="plotDetails.images.length" [src]="plotDetails.images[0].url" alt="plot image" />
          </div>
          <div class="detail-container">
            <p style="margin: 0">{{ plotDetails.cemetery_name }}</p>
            <h3 style="margin: 0">{{ plotDetails.plot_id }}</h3>
            <div class="status-badge">for sale</div>
          </div>
          <div class="price-container" *ngIf="plotDetails.show_price === 1">${{ plotDetails.price }}</div>
        </div>
        <div class="paragraph-container">
          <h3>✅ Request was sent</h3>
          <p>Cemetery administration will contact you to proceed with a purchasing a ROI on a certain plot</p>
        </div>
      </mat-dialog-content>
      <div class="actions-btn">
        <button [disabled]="isLoadingPdf" (click)="viewPdf()" mat-button color="primary">View submission as PDF</button>
        <button mat-flat-button color="primary" [mat-dialog-close]="'continue'">Continue</button>
      </div>
    </div>
  </ng-template>
</ng-container>
