import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { environment } from '../../environments/environment';
import { endpointList } from '../enums/endpoint.list';

export enum CommandType {
  PATH = 'path',
  PARAM = 'param',
}
export interface ComandUrl {
  id: string;
  value: any;
  type: CommandType;
}

@Injectable({
  providedIn: 'root',
})
export class HttpUtilsService {
  static readonly COMMAND_PREFIX = 'api';
  static readonly API_VERSION = 'v1';
  static readonly BACKUP_IMPORT_API_VERSION = 'v2';

  constructor(private readonly router: Router, private readonly urlSerializer: UrlSerializer) {}

  /**
   * Gets URL to the given resource.
   *
   * @param commands An array of commands to apply.
   *
   * @example this.httpUtils.getResourceUrl(['users', 1, 'comments']) // => '%url%/api/v1/users/1/comments/'
   */
  // getResourceUrl(commands: any[] = []) {
  //   const tree = this.router.createUrlTree([HttpUtilsService.COMMAND_PREFIX, HttpUtilsService.API_VERSION, ...commands, '']);
  //   const url = this.urlSerializer.serialize(tree);

  //   return `${environment.API_URL}${url}`;
  // }

  getResourceUrl(commands: any[] = []) {
    const join = commands.join('/');
    return `${environment.API_URL}/${HttpUtilsService.COMMAND_PREFIX}/${HttpUtilsService.API_VERSION}/${join}/`;
  }
  getResourceUrlForBackupAndImport(commands: any[] = []) {
    const join = commands.join('/');
    return `${environment.API_URL}/${HttpUtilsService.COMMAND_PREFIX}/${HttpUtilsService.BACKUP_IMPORT_API_VERSION}/${join}/`;
  }
}
