import * as Joi from 'joi';
import { personValidator } from './person-validator';
import { IntermentType } from '../../enums/interment-type.enum';
import { fileValidator } from './file-validator';
import { businessValidator } from './business-validator';
import { addressValidator } from './address-validator';
import { intermentStoryValidator } from './interment-story-validator';
import { ReligionType } from '../../enums/religion-type.enum';

/*TODO make applicant type Business or Person*/
/*TODO make plot not required*/

export const intermentValidator = Joi.object({
  age: Joi.string().allow(null),
  cause_of_death: Joi.string().allow('', null),
  cemetery_unique_name: Joi.string(),
  date_of_birth: Joi.string().allow(null),
  date_of_death: Joi.string().allow(null),
  files: Joi.array().items(fileValidator),
  funeral_director: businessValidator.allow(null),
  id: Joi.number(),
  container_dimensions: Joi.string().allow(null),
  container_type: Joi.string().allow(null),
  cremation_location: Joi.string().allow(null),
  interment_date: Joi.date().iso().allow(null),
  interment_depth: Joi.number().allow(null),
  interment_minister: businessValidator,
  interment_note: Joi.string().allow(null),
  interment_number: Joi.number().allow(null),
  interment_type: Joi.string().valid(IntermentType.burial, IntermentType.cremation).allow(null),
  headstone: Joi.string().allow(null),
  next_of_kin: Joi.array().items(personValidator),
  occupation: Joi.string().allow(null),
  person: personValidator,
  plot_id: Joi.string(),
  primary_address: addressValidator,
  story: intermentStoryValidator,
  returned_serviceman: Joi.boolean(),
  religion: Joi.string()
    .valid(
      ReligionType.allCreeds,
      ReligionType.anglican,
      ReligionType.baptist,
      ReligionType.jewish,
      ReligionType.catholic,
      ReligionType.jehovahWitness,
      ReligionType.methodist,
      ReligionType.muslim,
      ReligionType.presbyterian,
      ReligionType.quaker,
      ReligionType.salvationArmy
    )
    .allow(null),
  /*applicant: businessValidator | personValidator*/
  /*plot: plotValidator,*/
});
