import { Injectable } from '@angular/core';
import { HttpUtilsService } from '../../core/http-utils.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Organization } from '../../interfaces/organization';
import { tap } from 'rxjs/operators';
import { ValidateResponseService } from '../../server-response-validation/validate-response.service';
import { ValidatorType } from '../../server-response-validation/validator-type.enum';
import { OrganizationDetails } from '../../interfaces/organization-details';
import { BehaviorSubject } from 'rxjs';
import { StorageStrategyService } from 'src/app/core/storage-strategy.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private readonly isEditGeneral = new BehaviorSubject(false);
  key = 'custom-forms';

  constructor(
    private readonly httpUtils: HttpUtilsService,
    private readonly httpClient: HttpClient,
    private readonly validateResponseService: ValidateResponseService,
    private readonly storageStrategyService: StorageStrategyService
  ) {}

  get isEditGeneral$() {
    return this.isEditGeneral.asObservable();
  }

  updateIsEditGeneral(status: boolean) {
    return this.isEditGeneral.next(status);
  }

  create(organization: Organization) {
    const url = this.httpUtils.getResourceUrl(['organization']);

    return this.httpClient.post<Organization>(url, organization);
  }

  getOrganization(id: number) {
    const url = this.httpUtils.getResourceUrl(['organization', id]);

    return this.httpClient.get<OrganizationDetails>(url).pipe(
      tap(organizationData => {
        this.validateResponseService.validate(ValidatorType.OrganizationDetails, organizationData);
      })
    );
  }

  archive(id: number) {
    const url = this.httpUtils.getResourceUrl(['user', id, 'archive']);

    return this.httpClient.put(url, {});
  }

  restore(id: number) {
    const url = this.httpUtils.getResourceUrl(['user', id, 'restore']);

    return this.httpClient.put(url, {});
  }

  update(id: number, organization: Organization) {
    const url = this.httpUtils.getResourceUrl(['organization', id]);

    return this.httpClient.put(url, organization);
  }

  getCustomField(id: number, fieldType: string, cemeteryId: string) {
    let paramsObj;
    if (cemeteryId && cemeteryId.length > 0) {
      paramsObj = {
        field_type: fieldType,
        cem_id: cemeteryId,
      };
    } else {
      paramsObj = {
        field_type: fieldType,
      };
    }

    const params = new HttpParams({ fromObject: paramsObj });

    const url = this.httpUtils.getResourceUrl(['organization', id, 'custom_fields']);

    return this.httpClient.get(url, { params });
  }

  getCustomFieldByOrganizationId(id: number, fieldType: string, cemeteryId: string) {
    let paramsObj;
    if (cemeteryId && cemeteryId.length > 0) {
      paramsObj = {
        field_type: fieldType,
        cem_id: cemeteryId,
      };
    } else {
      paramsObj = {
        field_type: fieldType,
      };
    }

    const params = new HttpParams({ fromObject: paramsObj });

    const url = this.httpUtils.getResourceUrl(['organization', id, 'custom-fields']);

    return this.httpClient.get(url, { params });
  }

  createCustomField(id: number, data) {
    const url = this.httpUtils.getResourceUrl(['organization', id, 'custom_fields']);

    return this.httpClient.post(url, data);
  }

  editCustomField(id: number, data, customFieldId) {
    const url = this.httpUtils.getResourceUrl(['organization', id, 'custom_fields', customFieldId.id]);

    return this.httpClient.put(url, data);
  }

  deleteCustomField(id: number, customFieldId) {
    const url = this.httpUtils.getResourceUrl(['organization', id, 'custom_fields', customFieldId.id]);
    return this.httpClient.delete(url);
  }

  orderCustomField(id: number, data) {
    const url = this.httpUtils.getResourceUrl(['organization', id, 'custom_fields', 'order']);
    return this.httpClient.put(url.slice(0, -1), data);
  }

  getCustomFormsDetails(orgId: number, formId: number) {
    const url = this.httpUtils.getResourceUrl(['customform', orgId, formId]);
    return this.httpClient.get(url);
  }

  getCustomForms(orgId: number) {
    const url = this.httpUtils.getResourceUrl(['customform', orgId]);
    return this.httpClient.get(url);
  }

  getSectionCustomForms(orgId: number, sectionType: 'Pre-need' | 'At-need') {
    // https://dev.chronicle.rip/api/v1/customform/default/7/?form=At-need
    const url = this.httpUtils.getResourceUrl(['customform', 'default', orgId]);
    const params = new HttpParams().set('form', sectionType);
    return this.httpClient.get(url, { params });
  }

  getCustomFormsSection(orgId: number, sectionType: string) {
    const url = this.httpUtils.getResourceUrl(['customform_field', orgId, sectionType]);
    return this.httpClient.get<any[]>(url);
  }

  editCustomForms(orgId: number, formId: number, data) {
    const url = this.httpUtils.getResourceUrl(['customform', orgId, formId]);
    return this.httpClient.put(url, data);
  }

  deleteCustomForm(orgId: number, formId: number) {
    const url = this.httpUtils.getResourceUrl(['customform', orgId, formId]);
    return this.httpClient.delete(url);
  }

  createCustomForms(orgId: number, data) {
    const url = this.httpUtils.getResourceUrl(['customform', orgId]);
    return this.httpClient.post(url, data);
  }

  resetCustomForms(ordId, formId, subType: 'At-need' | 'Pre-need') {
    const body = {
      sub_type: subType,
      form_id: formId,
    };

    const url = this.httpUtils.getResourceUrl(['customform', ordId, 'reset']);
    return this.httpClient.post(url, body);
  }

  getListPublicForm(orgId: number) {
    const url = this.httpUtils.getResourceUrl(['customform', 'public', orgId]);
    return this.httpClient.get<any[]>(url);
  }

  getListGeneralForm(orgId: number) {
    const params = new HttpParams().append('form', 'General');

    const url = this.httpUtils.getResourceUrl(['customform', 'public', orgId]);
    return this.httpClient.get<any[]>(url, { params });
  }

  getGeneralFormPublic(orgId: number, formId: number) {
    const url = this.httpUtils.getResourceUrl(['customform', 'public', orgId, formId]);
    return this.httpClient.get<any[]>(url);
  }

  saveGeneralCustomForm(payload: any) {
    const url = this.httpUtils.getResourceUrl(['public', 'request-table']);
    return this.httpClient.post(url, payload);
  }

  saveFormsToLocalStorage(data: any) {
    this.storageStrategyService.storage.setItem(this.key, JSON.stringify(data));
  }

  saveTempLabelApplicantToLocalStorage(data: any) {
    this.storageStrategyService.storage.setItem('temp-applicant-label', JSON.stringify(data));
  }

  getFormsFromLocalStorage(): any {
    return JSON.parse(this.storageStrategyService.storage.getItem(this.key));
  }

  getTempLabelApplicantFromLocalStorage(): any {
    return JSON.parse(this.storageStrategyService.storage.getItem('temp-applicant-label'));
  }

  deleteFormsFromLocalStorage() {
    this.storageStrategyService.storage.removeItem(this.key);
  }

  deleteTempLabelApplicantFromLocalStorage() {
    this.storageStrategyService.storage.removeItem('temp-applicant-label');
  }
}
