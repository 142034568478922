<mat-card class="event-card">
  <mat-card-subtitle class="main-title">
    <div class="left">
      <ngx-skeleton-loader [theme]="{ width: '70px' }"></ngx-skeleton-loader>
    </div>
  </mat-card-subtitle>

  <mat-card-title class="event-title">
    <ngx-skeleton-loader [theme]="{ width: '40px' }"></ngx-skeleton-loader>
  </mat-card-title>
  <mat-card-subtitle class="category-title">
    <div class="category">
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </div>
  </mat-card-subtitle>
  <mat-card-footer class="card-footer">
    <div class="right-footer">
      <ngx-skeleton-loader [theme]="{ width: '60px' }"></ngx-skeleton-loader>
    </div>
  </mat-card-footer>
</mat-card>
