import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  private surveyVisibleSubject = new BehaviorSubject<boolean>(false);
  surveyVisible$ = this.surveyVisibleSubject.asObservable();

  constructor() {}

  showSurvey(): void {
    if (environment.SURVEY_MONKEY_SCRIPT && environment.SURVEY_MONKEY_SCRIPT.length > 0) {
      this.surveyVisibleSubject.next(true);
    }
  }

  hideSurvey(): void {
    this.surveyVisibleSubject.next(false);
  }
}
