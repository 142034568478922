import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserRole } from './enums/user-role.enum';
import { AdminDataService } from './core/admin-data.service';
import { catchError, first, pluck, switchMap } from 'rxjs/operators';
import { AppRoutes } from './enums/app-routes.enum';
import { Token } from './enums/token.enum';
import { TokenStorageService } from './core/token-storage.service';

export abstract class RegisteredGuard implements CanActivate {
  protected abstract role: UserRole;
  protected abstract nextRoute: AppRoutes;

  protected constructor(
    protected readonly adminDataService: AdminDataService,
    protected readonly router: Router,
    protected readonly tokenStorageService: TokenStorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.handleByRole(state.url);
  }

  handleByRole(url: string) {
    const currentRoute = {
      [UserRole.CHRONICLE_ADMIN]: AppRoutes.ChronicleAdmin,
      [UserRole.CUSTOMER_MANAGER]: AppRoutes.CustomerManager,
      [UserRole.CHRONICLE_MANAGER]: AppRoutes.CustomerViewer,
      [UserRole.CUSTOMER_ADMIN]: AppRoutes.CustomerAdmin,
      [UserRole.CUSTOMER_ORGANIZATION]: AppRoutes.CustomerOrganization,
      [UserRole.CUSTOMER_VIEWER]: AppRoutes.CustomerViewer,
    }[this.role];

    return this.adminDataService.user.pipe(
      first(),
      pluck('role'),
      switchMap(role => {
        const updateUrl = this.getUrl(url);

        if (role === undefined || role === null) {
          this.tokenStorageService.clear(Token.access, Token.refresh);
          return this.router.navigate([updateUrl(currentRoute, AppRoutes.Public)]);
        }

        if (role === UserRole.CHRONICLE_MANAGER && this.role === UserRole.CUSTOMER_VIEWER) {
          return of(true);
        }
        // const currentRouteData = subscription === 'Free' && role === UserRole.CUSTOMER_ORGANIZATION ? AppRoutes.Freemium : currentRoute;
        return this.role === role ? of(true) : this.router.navigate([updateUrl(currentRoute, this.nextRoute)]);
      }),
      catchError(error => this.router.navigate(['']))
    );
  }

  private getUrl(url: string) {
    return (oldRouteStart: string, newRouteStart: string) => {
      const updatedUrl = url.replace(oldRouteStart, newRouteStart);
      return decodeURIComponent(updatedUrl);
    };
  }
}
