import { isPlatformBrowser } from '@angular/common';
import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { MapActionsService } from 'src/app/core/map-actions.service';
import { PlotsService } from 'src/app/core/plots.service';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { SidebarService } from 'src/app/core/sidebar.service';
import { PlotDetails } from 'src/app/interfaces/plot-details';

import { DEFAULT_SIZE_FOR_READ_MORE } from '../default-read-more-size';
import { AmplitudeService } from 'src/app/core/amplitude.service';

@Component({
  selector: 'cl-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreComponent implements AfterViewChecked, OnDestroy {
  private readonly destroy$ = new Subject();
  @Input() text: string;
  @Input() cemeteryName: string;
  isReadMore = true;
  chronicleLinks: string[] = [];

  get buttonAction(): string {
    return this.isReadMore ? 'more' : 'less';
  }

  get textSize(): number {
    return this.isReadMore ? DEFAULT_SIZE_FOR_READ_MORE : this.text.length;
  }

  get gradientStyle(): object {
    return { visibility: this.isReadMore && this.isTextLengthEnough ? 'visible' : 'hidden' };
  }

  get isTextLengthEnough(): boolean {
    return DEFAULT_SIZE_FOR_READ_MORE < this.text.length;
  }

  get story() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const component = document.createElement('div');
    component.innerHTML = this.text;
    const links = component.querySelectorAll('a');
    links.forEach(link => {
      if (link.href.includes('plots')) {
        const linkTemp = link.href;
        this.chronicleLinks.push(linkTemp);
        link.removeAttribute('href');
        link.removeAttribute('target');
        link.classList.add('chronicle-link');
        link.style.cursor = 'pointer';
        link.style.color = 'blue';
      }
    });
    return component.outerHTML;
  }

  constructor(
    private elementRef: ElementRef,
    private plotService: PlotsService,
    private routerUtilsService: RouterUtilsService,
    private mapActionService: MapActionsService,
    private sidebarService: SidebarService,
    private router: Router,
    private amplitudeService: AmplitudeService,
    @Inject(PLATFORM_ID)
    private platformId: any
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewChecked(): void {
    const elements = this.elementRef.nativeElement.querySelectorAll('.chronicle-link');
    elements.forEach((element, i) => {
      element.addEventListener(
        'click',
        event => {
          event.preventDefault();

          this.onClick(this.chronicleLinks[i]);
        },
        false
      );
    });
  }

  onClick(url: string) {
    const arrayUrl = new URL(url).pathname.split('/');
    const plotId = decodeURI(arrayUrl[arrayUrl.length - 1]);
    const cemeteryName = arrayUrl[arrayUrl.length - 3];

    const path = this.routerUtilsService.getPath(cemeteryName, 'plots', plotId);

    this.router.navigate(path);
    this.sidebarService.closeMovePlot(true);
  }

  toggleTextSize() {
    if (this.cemeteryName) {
      this.amplitudeService.track('Read More Clicked', { cemetery_name: this.cemeteryName });
    }
    this.isReadMore = !this.isReadMore;
  }
}
