<div class="story-dialog-wrapper">
  <div class="story-dialog-title">Edit Story</div>

  <div class="story-dialog-content">Are you sure want to edit this story?</div>

  <div class="story-dialog-button">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSubmit('confirm')">Confirm</button>
  </div>
</div>
