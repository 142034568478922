import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PlotStatusString } from '../../enums/plot-status-string.enum';

@Component({
  selector: 'cl-statuses',
  templateUrl: './statuses.component.html',
  styleUrls: ['./statuses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusesComponent),
      multi: true,
    },
  ],
})
export class StatusesComponent implements ControlValueAccessor, OnDestroy {
  private readonly destroy$ = new Subject();
  statuses = [
    {
      value: PlotStatusString.Vacant,
      checked: false,
    },
    {
      value: PlotStatusString.Reserved,
      checked: false,
    },
    {
      value: PlotStatusString.Unavailable,
      checked: false,
    },
    {
      value: PlotStatusString.Occupied,
      checked: false,
    },
    {
      value: PlotStatusString['For Sale'],
      checked: false,
    },
  ];
  form: FormGroup;

  get status() {
    return this.form.get('status') as FormControl;
  }

  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      status: [''],
    });

    this.status.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        tap(value => {
          this.onChange(value);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  onSetCheckedStatus(item) {
    this.status.setValue(item.value);
    this.statuses.forEach(element => (element.checked = false));
    item.checked = !item.checked;
  }

  getStylePlotStatus(plotStatus: string) {
    return plotStatus.replace(/\s+/g, '').toLowerCase();
  }

  writeValue(value) {
    if (!value) {
      this.status.setValue('');
      this.statuses.forEach(element => (element.checked = false));
      this.changeDetectorRef.detectChanges();
      return;
    }

    const neededStatus = this.statuses.find(status => status.value.toLowerCase() === value.toLowerCase());

    if (neededStatus === undefined) {
      return;
    }

    neededStatus.checked = true;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
}
