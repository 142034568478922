import { Injectable } from '@angular/core';
import { Token } from '../enums/token.enum';
import { StorageStrategyService } from './storage-strategy.service';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(private storageStrategyService: StorageStrategyService) {}

  store(key: Token, token: string) {
    if (!this.storageStrategyService.storage) {
      return;
    }
    this.storageStrategyService.storage.setItem(key, token);
  }

  get(key: Token) {
    if (!this.storageStrategyService.storage) {
      return null;
    }
    return this.storageStrategyService.storage.getItem(key);
  }

  clear(...keys) {
    if (keys.length === 0) {
      this.storageStrategyService.storage.clear();
    }

    keys.forEach(key => this.storageStrategyService.storage.removeItem(key));
  }
}
