import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import moment from 'moment';
import { ICustomField } from 'src/app/interfaces/custom-field';

@Component({
  selector: 'cl-custom-field-info',
  templateUrl: './custom-field-info.component.html',
  styleUrls: ['./custom-field-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldInfoComponent implements OnInit {
  @Input() set customFields(val: ICustomField[]) {
    val
      .filter(value => !!value.values)
      .forEach(value => {
        this.stateCustomFields.push(false);
      });

    this.parseCustomFields = val
      .map(value => ({
        ...value,
        values: value.type === 'date' && value.values ? moment(value.values).format('DD/MM/YYYY') : value.values,
      }))
      .filter(value => !!value.values);
  }

  parseCustomFields: any[];
  stateCustomFields: boolean[] = [];

  constructor() {}

  toggle(i: number) {
    this.stateCustomFields[i] = !this.stateCustomFields[i];
  }

  ngOnInit(): void {}
}
