import * as Joi from 'joi';
import { fileValidator } from './file-validator';
import { intermentValidator } from './interment-validator';

export const cemeteryEventValidator = Joi.object({
  id: Joi.number(),
  plot_id: Joi.string().allow(null),
  cemetery_unique_name: Joi.string().allow(null),
  event_name: Joi.string(),
  types: Joi.string(),
  descriptions: Joi.string().allow(null),
  make_public: Joi.boolean(),
  location_type: Joi.string().allow(null),
  cremation_location: Joi.string().allow(null),
  start_time: Joi.string().allow(null),
  end_time: Joi.string().allow(null),
  repeating: Joi.string().allow(null),
  repeat_until: Joi.string().allow(null),
  responsible_person: Joi.string().allow(null),
  assigned_party: Joi.string().allow(null),
  created_by: Joi.string().allow(null),
  files: Joi.array().items(fileValidator),
  is_completed: Joi.boolean(),
  event_payment: {
    payment_amount: Joi.string().allow(null),
    payment_method: Joi.string().allow(null),
    payment_status: Joi.boolean(),
  },
  event_purchaser: {
    purchaser: Joi.string().allow(null),
    phone_number: Joi.string().allow(null),
    email: Joi.string().allow(null),
    street: Joi.string().allow(null),
    suburb: Joi.string().allow(null),
    postcode: Joi.string().allow(null),
    state: Joi.string().allow(null),
    country: Joi.string().allow(null),
    is_samebilling: Joi.boolean(),
  },
  related_interment: intermentValidator,
});
