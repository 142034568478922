<div class="wrapper">
  <h5 class="title">{{ qrCodeData.title }}</h5>
  <h2 class="subtitle">{{ qrCodeData.subTitle }}</h2>
  <div class="qr-code">
    <ng-container *ngIf="qrCodeData.showQrCode">
      <qrcode #qr [qrdata]="qrCodeData.url" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </ng-container>
    <ng-container *ngIf="!qrCodeData.showQrCode">
      <p class="warning-message">{{ qrCodeData.message }}</p>
    </ng-container>
  </div>
  <button *ngIf="qrCodeData.showQrCode" (click)="saveAsImage()" mat-raised-button color="primary">Download Image</button>
  <button *ngIf="!qrCodeData.showQrCode" (click)="closeDialog()" mat-raised-button color="primary">Close</button>
</div>
