<div class="mock-result-container">
  <div class="icons">
    <div class="church-icon">
      <mat-icon svgIcon="custom_church"></mat-icon>
      <span>{{ regionalSettingsForUser.cemeteries }}</span>
    </div>
  </div>
  <h5 *ngIf="!org" class="scope">within Chronicle</h5>
  <h5 *ngIf="org" class="scope">within {{ org }}</h5>
  <p>Zoom in to search people and {{ regionalSettingsForUser.plots }}</p>
</div>
