import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private activeMap = new BehaviorSubject(null);

  get map() {
    return this.activeMap.getValue();
  }

  get map$() {
    return this.activeMap.asObservable();
  }

  init(map) {
    this.activeMap.next(map);
  }

  destroy() {
    this.activeMap.next(null);
  }
}
