import { Component, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { UploaderType } from 'src/app/upload/enums/uploader-type.enum';
import { Image } from '../../interfaces/image';
import { LoadingUploaderSharedService } from '../services/loading-uploader-shared.service';
import { UploadWrapperSharedComponent } from '../upload-wrapper-shared/upload-wrapper-shared.component';

@Component({
  selector: 'cl-upload-image-shared',
  templateUrl: './upload-image-shared.component.html',
  styleUrls: ['./upload-image-shared.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadImageSharedComponent {
  @ViewChild(UploadWrapperSharedComponent) uploadWrapper: UploadWrapperSharedComponent;
  @Input() imagesCapacity = 6;
  uploaderType = UploaderType;
  localFiles: Image[] = [];
  @Input() images: Image[] = [];
  @Input() showTitle = true;
  @Input() title = 'Headstone photo';
  @Input() disabled = false;
  @Input() public;
  @Input() acceptFileType = '*';
  @Input() maxSizePlaceholder = 8000;
  @Output() files = new EventEmitter<Image[]>();
  @Output() previewIndex = new EventEmitter<number>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public loadingService: LoadingUploaderSharedService,
    public dialog: MatDialog,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'custom_plus', src: '/assets/images/plus_icon.svg' }]);
  }

  inputFiles(files: Image[]) {
    this.localFiles = files;
  }

  uploadFiles(files: Image[]) {
    if (this.disabled) {
      return;
    }

    this.files.emit(files.slice(0, this.imagesCapacity));
    this.localFiles = files.slice(0, this.imagesCapacity);
    this.changeDetectorRef.markForCheck();
  }

  preview(index: number) {
    if (this.disabled) {
      return;
    }

    this.previewIndex.emit(index);
  }

}
