<div class="interment-item-wrapper">
  <mat-accordion>
    <mat-expansion-panel
      class="mat-elevation-z0 interment-item"
      hideToggle="true"
      [expanded]="amountOfInterments === 1"
      (expandedChange)="onChangeExpandedState()"
      [ngStyle]="{ height: amountOfInterments <= 3 ? '100%' : '24px' }"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header [ngClass]="{ 'more-than-five': amountOfInterments > 5 }">
        <div class="up-to-3-persons" *ngIf="amountOfInterments <= 3 || isIntermentDetailsOpened">
          <div style="display: none" class="divider"></div>
          <div class="person-info">
            <!-- <ng-container *ngIf="interment.story?.is_featured">
              <p class="featured-story">Featured</p>
            </ng-container> -->
            <div class="container-name-label">
              <h3 class="person-full-name">
                <span>{{ interment.person | name: 'firstlast' }}</span>
                <!-- <ng-container *ngIf="interment.story">
                  <img class="story-icon" src="./assets/images/story.svg" alt="" />
                </ng-container> -->
              </h3>
              <div>
                <!-- <div *ngIf="storyLists?.length > 0 && !interment.story" class="story-label">STORY</div> -->
                <div class="container-icons">
                  <img
                    *ngIf="this.interment.story"
                    title="Official Story"
                    src="/assets/images/featured-story-ban.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="this.interment.life_chronicle"
                    title="Life Chronicle Online Memorial"
                    src="/assets/images/life-chronicle-ban.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="this.interment.returned_serviceman"
                    title="{{ this.regionalSettings.returned_service_person }}"
                    src="/assets/images/Veteran.png"
                    style="width: 20px"
                  />
                </div>
              </div>
            </div>
            <p class="person-dates_age">
              <ng-container *ngIf="interment.date_of_birth || interment.date_of_death; else na"
                >{{
                  interment.date_of_birth
                    ? interment.custom_date_of_birth === 'year'
                      ? (year_of_birth | yearInRegionalFormat)
                      : (interment.date_of_birth | dateInRegionalFormat)
                    : 'n/a'
                }}
                -
                {{
                  interment.date_of_death
                    ? interment.custom_date_of_death === 'year'
                      ? (year_of_death | yearInRegionalFormat)
                      : (interment.date_of_death | dateInRegionalFormat)
                    : 'n/a'
                }}</ng-container
              >
              <ng-container *ngIf="isAgeAvailable; else naAge"> ({{ age }} {{ isShowAgeYearsOldText ? 'y.o' : '' }}) </ng-container>
            </p>
          </div>
        </div>

        <div class="more-than-3-persons" *ngIf="amountOfInterments > 3 && !isIntermentDetailsOpened">
          <div class="person-info">
            <!-- <ng-container *ngIf="interment.story?.is_featured">
              <p class="featured-story">Featured</p>
            </ng-container> -->
            <div class="container-name-label">
              <h3 class="person-full-name">
                <span>{{ interment.person | name: 'firstlast' }}</span>
                <!-- <ng-container *ngIf="interment.story">
                  <img class="story-icon" src="./assets/images/story.svg" alt="" />
                </ng-container> -->
              </h3>
              <div>
                <div *ngIf="storyLists?.length > 0 && !interment.story" class="story-label">STORY</div>
                <div class="container-icons">
                  <img
                    *ngIf="this.interment.story"
                    title="Official Story"
                    src="/assets/images/featured-story-ban.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="this.interment.life_chronicle"
                    title="Life Chronicle Online Memorial"
                    src="/assets/images/life-chronicle-ban.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="this.interment.returned_serviceman"
                    title="Return Service Person"
                    src="/assets/images/Veteran.png"
                    style="width: 20px"
                  />
                </div>
              </div>
            </div>
            <!-- <span class="person-full-name">{{ interment.person | name }}</span> -->
            <!-- <ng-container *ngIf="interment.story">
              <img class="story-icon" src="./assets/images/story.svg" alt="" />
            </ng-container> -->
            <p class="person-dates_age">
              <ng-container *ngIf="interment.date_of_birth || interment.date_of_death; else na"
                >{{
                  interment.date_of_birth
                    ? interment.custom_date_of_birth === 'year'
                      ? (year_of_birth | yearInRegionalFormat)
                      : (interment.date_of_birth | dateInRegionalFormat)
                    : 'n/a'
                }}
                -
                {{
                  interment.date_of_death
                    ? interment.custom_date_of_death === 'year'
                      ? (year_of_death | yearInRegionalFormat)
                      : (interment.date_of_death | dateInRegionalFormat)
                    : 'n/a'
                }}</ng-container
              >
              <ng-container *ngIf="isAgeAvailable; else naAge"> ({{ age }} {{ isShowAgeYearsOldText ? 'y.o' : '' }}) </ng-container>
            </p>
          </div>
        </div>

        <mat-panel-description class="interment-panel-desc" *ngIf="!panelOpenState">
          <mat-icon svgIcon="collapse"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <cl-custom-field-info *ngIf="customFieldValues?.length" [customFields]="customFieldValues"></cl-custom-field-info>

      <div class="interment-date" *ngIf="interment.interment_date">
        <span class="interment-date-title">Interment date:</span>
        {{
          interment.custom_interment_date === 'year'
            ? (year_of_interment | yearInRegionalFormat)
            : (interment.interment_date | dateInRegionalFormat)
        }}
      </div>

      <div class="cremation-location" *ngIf="interment.cremation_location">
        <label>Cremation location: </label>
        {{ interment.cremation_location }}
      </div>

      <cl-interment-story-view
        *ngIf="interment.story"
        [plotDetails]="plotDetails"
        [story]="interment.story"
        [interment]="interment"
        [fromAdmin]="false"
      >
      </cl-interment-story-view>
      <div class="story-divider"></div>
      <ng-container *ngIf="isLoading">
        <ng-container *ngFor="let event of [1, 2, 3, 4]">
          <cl-card-story-loading-skeleton></cl-card-story-loading-skeleton>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <cl-timeline-story-view
          [interment]="interment"
          (addStory)="onAddStory($event)"
          [storyLists]="storyLists"
          [sellStory]="sellStory"
          [isAus]="isAus"
        ></cl-timeline-story-view>
      </ng-container>
      <div class="create-new-story-wrapper" *ngIf="sellStory">
        <img class="flower" src="/assets/images/flowers-lc.png" alt="flower" />
        <img class="photos" src="/assets/images/photos.png" alt="photos" />
        <div class="header">
          <h4>Create a lasting tribute for {{ interment.person?.first_name }}</h4>
        </div>
        <button (click)="createMemorialInterment(interment)" style="font-size: 12px" mat-flat-button color="primary">
          CREATE AN ONLINE MEMORIAL
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #na>n/a</ng-template>
<ng-template #naAge> (n/a y.o)</ng-template>
