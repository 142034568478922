import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[clMisclick]',
})
export class MisclickDirective implements AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject();
  @Output() readonly outside = new EventEmitter();
  @Output() readonly inside = new EventEmitter();

  constructor(private readonly elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit() {
    const click$ = fromEvent(this.document, 'click');
    const touchStart$ = fromEvent(this.document, 'touchstart');
    const touchEnd$ = fromEvent(this.document, 'touchend');
    const touchMove$ = fromEvent(this.document, 'touchmove');

    const touch$ = touchStart$.pipe(switchMap(() => touchEnd$.pipe(takeUntil(touchMove$))));

    merge(click$, touch$)
      .pipe(
        takeUntil(this.destroy$),
        map((event: Event) => this.elementRef.nativeElement.contains(event.target)),
        tap(isInside => (isInside ? this.inside.emit() : this.outside.emit()))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
