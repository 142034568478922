import { Injectable } from '@angular/core';
import { CemeteryInfo } from '../interfaces/cemetery-info';
import { Bounds } from '../interfaces/bounds';
import { CemeteryStatus } from '../enums/cemetery-status.enum';

@Injectable({
  providedIn: 'root',
})
export class LeafletAdapterService {
  getCemeteryMarker(cemetery: CemeteryInfo, markerAction: () => void, totalData: number = 0, zoom?: number) {
    const markerIconLookup = {
      [CemeteryStatus.ComingSoon]: {
        url: '/assets/images/public-cem-soon-icon.svg',
        iconSize: [32, 32],
        offset: [8, 0],
      },
      [CemeteryStatus.Public]: {
        url: '/assets/images/cemetery_icon.svg',
        iconSize: [32, 32],
        offset: [8, 0],
      },
      [CemeteryStatus.Visible]: {
        url: '/assets/images/world-cem-icon.svg',
        iconSize: [12, 12],
        offset: [2, 0],
      },
    };

    const options = {
      icon: L.icon({
        iconUrl: markerIconLookup[cemetery.status]?.url || '/assets/images/cemetery_icon.svg',
        iconSize: markerIconLookup[cemetery.status]?.iconSize || [32, 32],
      })
    };
    const marker = L.marker(L.geoJSON(cemetery.location).getBounds().getCenter(), options);

    let content = '';
    if (totalData > 0) {
      content = `<div class='number-data-cemetery'>${totalData}</div>`;
    }

    const cemeteryName = cemetery.status === CemeteryStatus.Visible && zoom >= 10 && zoom <= 13 ? '' : cemetery.name;
    marker.bindTooltip(`${content} ${cemeteryName} `, {
      permanent: true,
      direction: 'right',
      className: `cemetery-label ${cemetery.status}`,
      offset: markerIconLookup[cemetery.status]?.offset || [8, 0],
    });
    marker.on('click', markerAction);
    return marker;
  }

  getOverlayTileLayer(url: string, bounds?: Bounds) {
    return L.tileLayer(`${url}/{z}/{x}/{y}.png`, {
      tms: true,
      opacity: 1,
      minZoom: 15,
      maxZoom: 25,
      bounds,
    });
  }
}
