import { Pipe, PipeTransform } from '@angular/core';
import { transformName, transformNameAdvanceSearch } from './name-transform-function';
import { Name } from '../interfaces/name';

@Pipe({
  name: 'name',
})
export class NamePipe implements PipeTransform {
  transform(item: Name | null, mode: 'full' | 'firstlast' = 'full'): string {
    if (mode === 'firstlast') {
      return transformNameAdvanceSearch(item);
    }
    return transformName(item);
  }
}
