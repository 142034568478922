import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './core/auth.service';
import { AppRoutes } from './enums/app-routes.enum';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PublicGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router, private routers: ActivatedRoute) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isUserLoggedIn = this.authService.isUserLogged();

    if (isUserLoggedIn) {
      const url = state.url.split('/');
      if (url.length >= 3 && url[2] === 'plots') {
        if (url[3].includes('advance') && url[3].includes('search') && url[3].includes('backTo')) {
          return isUserLoggedIn;
        } else {
          const lastUrl = url[3].split('?');
          this.router.navigate([AppRoutes.ChronicleAdmin, url[1], url[2], decodeURIComponent(lastUrl[0])]);
        }
      } else {
        this.router.navigate([AppRoutes.ChronicleAdmin]);
      }
    }

    return !isUserLoggedIn;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isUserLoggedIn = this.authService.isUserLogged();

    if (isUserLoggedIn) {
      this.router.navigate([AppRoutes.ChronicleAdmin]);
    }

    return !isUserLoggedIn;
  }
}
