import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarErrorComponent } from '../shared/snackbar-error/snackbar-error.component';
import { SnackbarSuccessComponent } from '../shared/snackbar-success/snackbar-success.component';
import { SnackbarCancelNavigationComponent } from '../shared/snackbar-cancel-navigation/snackbar-cancel-navigation.component';
import { SnackbarConfirmationComponent } from '../shared/snackbar-confirmation/snackbar-confirmation.component';
import { SnackbarLocationComponent } from '../shared/snackbar-location/snackbar-location.component';
import { SnackbarFreemiumComponent } from '../shared/snackbar-freemium/snackbar-freemium.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarNotificationService {
  constructor(private matSnackBar: MatSnackBar) {}

  openSuccessMessage(message: string) {
    this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
      data: { message },
      panelClass: 'custom-snackbar-success',
      duration: 5000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  openWarningMessage(message: string, duration?: number) {
    this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
      data: { message },
      panelClass: 'snackbar-warning',
      duration: duration || 5000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  openErrorMessage(error: string, duration?: number) {
    this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
      data: { error },
      panelClass: 'custom-snackbar-error',
      duration: duration || 5000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  openErrorLocation(error: string, duration?: number, copyUrl = false) {
    this.matSnackBar.openFromComponent(SnackbarLocationComponent, {
      data: { error, copyUrl },
      panelClass: 'custom-snackbar-location',
      duration: duration || 5000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  openErrorFreemium() {
    this.matSnackBar.openFromComponent(SnackbarFreemiumComponent, {
      panelClass: 'custom-snackbar-location',
      duration: 10000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  openCancelNavigation(destination: any, message: string, path: any, map: any) {
    this.matSnackBar.openFromComponent(SnackbarCancelNavigationComponent, {
      data: { destination, message, path, map },
      panelClass: 'custom-snackbar-warning',
      duration: 10000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  getOpenedErrorMessage(error: string): MatSnackBarRef<SnackbarErrorComponent> {
    return this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
      data: { error },
      panelClass: 'custom-snackbar-error',
      duration: 5000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  getConfiramtionMessage(message: string, time: number = 30000): MatSnackBarRef<SnackbarConfirmationComponent> {
    return this.matSnackBar.openFromComponent(SnackbarConfirmationComponent, {
      data: { message },
      panelClass: 'custom-snackbar-confirmation',
      duration: time,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }
}
