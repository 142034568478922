import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageGuard } from './admin/manage.guard';
import { CustomerAdminGuard } from './customer-admin.guard';
import { CustomerOrganizationGuard } from './customer-organization.guard';
import { FreemiumGuard } from './freemium.guard';
import { ChronicleAdminGuard } from './chronicle-admin.guard';
import { CustomerManagerGuard } from './customer-manager.guard';
import { CustomerViewerGuard } from './customer-viewer.guard';
import { PublicGuard } from './public.guard';
import { AppRoutes } from './enums/app-routes.enum';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'admin',
    redirectTo: AppRoutes.ChronicleAdmin,
  },
  {
    path: 'plot-location-report',
    loadChildren: () => import('./plot-location-report/plot-location-report.module').then(m => m.PlotLocationReportModule),
  },
  {
    path: 'cemetery-report',
    loadChildren: () => import('./cemetery-reports/cemetery-reports.module').then(m => m.CemeteryReportsModule),
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: AppRoutes.ChronicleAdmin,
    loadChildren: () => import('./admin/chronicle-admin/chronicle-admin.module').then(m => m.ChronicleAdminModule),
    canActivate: [ChronicleAdminGuard],
  },
  {
    path: AppRoutes.CustomerOrganization,
    loadChildren: () => import('./admin/customer-organization/customer-organization.module').then(m => m.CustomerOrganizationModule),
    canActivate: [CustomerOrganizationGuard],
  },
  {
    path: AppRoutes.Freemium,
    loadChildren: () => import('./admin/free/free.module').then(m => m.FreeModule),
    canActivate: [FreemiumGuard],
  },
  {
    path: AppRoutes.CustomerAdmin,
    loadChildren: () => import('./admin/customer-admin/customer-admin.module').then(m => m.CustomerAdminModule),
    canActivate: [CustomerAdminGuard],
  },
  {
    path: AppRoutes.CustomerManager,
    loadChildren: () => import('./admin/customer-manager/customer-manager.module').then(m => m.CustomerManagerModule),
    canActivate: [CustomerManagerGuard],
  },
  {
    path: AppRoutes.CustomerViewer,
    loadChildren: () => import('./admin/customer-viewer/customer-viewer.module').then(m => m.CustomerViewerModule),
    canActivate: [CustomerViewerGuard],
  },
  {
    path: ':role/:cemeteryName/:plotId/manage',
    loadChildren: () => import('./admin/manage/manage.module').then(m => m.ManageModule),
    canLoad: [ManageGuard],
  },
  {
    path: ':role/advance-table/manage',
    loadChildren: () => import('./admin/manage/manage.module').then(m => m.ManageModule),
    canLoad: [ManageGuard],
  },
  {
    path: '',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
    canActivate: [PublicGuard],
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
