<ng-container *ngIf="dialogData.data === 'approve'">
  <div class="story-dialog-wrapper">
    <div class="story-dialog-title">Approve Story?</div>

    <div class="story-dialog-content">Story will be automatically added to the interment Story.</div>

    <div class="story-dialog-button">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="valueEvent('approve')">Confirm</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dialogData.data === 'decline'">
  <div class="story-dialog-wrapper">
    <div class="story-dialog-title">Reject Story?</div>

    <div class="story-dialog-content">
      Story won’t be added to the interment and the payment will be refunded to the author. Please give a reason for rejecting the Story in
      the box below.
    </div>

    <mat-form-field class="story-input-reject" appearance="fill">
      <mat-label>Leave a comment</mat-label>
      <textarea required [(ngModel)]="rejectReason" matInput placeholder="Ex. It makes me feel..."></textarea>
    </mat-form-field>

    <div class="story-dialog-button">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button [disabled]="!rejectReason" mat-raised-button color="primary" (click)="valueEvent('reject')">Reject Story</button>
    </div>
  </div>
</ng-container>
