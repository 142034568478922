<div class="left">
  <mat-icon *ngIf="!cemeteryInfo.images[0]?.url" svgIcon="custom_cemetery"></mat-icon>
  <img *ngIf="cemeteryInfo.images[0]?.url" src="{{ cemeteryInfo.images[0]?.url }}" alt="cemetery logo" />
</div>
<div class="right">
  <p class="name" clHighlight [content]="cemeteryInfo.name" [searchTerm]="searchValue">{{ cemeteryInfo.name }}</p>
  <p class="address"> 
    <span class="label-status" [ngClass]="cemeteryInfo.status" [class.login]="userLogIn">
      {{ getCemeteryStatus(cemeteryInfo.status) }}
    </span>
    
    <span class="op-6">
      {{ cemeteryInfo.address | address }}  
    </span>
  </p>
</div>
