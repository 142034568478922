<div id="scroll" class="container-form">
  <div class="text-header">Request to buy a plot</div>
  <div class="container-label-desc">
    <div class="container-image">
      <img
        class="image-thumbnail"
        src="{{ dialogData.plotDetails?.images[0]?.url ? dialogData.plotDetails?.images[0]?.url : '/assets/images/photo_stub.svg' }}"
      />
    </div>
    <div class="container-text">
      <div class="cemetery-name">{{ dialogData.plotDetails.cemetery_info?.name }}</div>
      <div class="plot-id">{{ dialogData.plotDetails?.plot_id }}</div>
      <div class="for-sale">{{ dialogData.plotDetails?.status }}</div>
    </div>
    <div class="container-price" *ngIf="dialogData.plotDetails?.show_price === 1">
      <div class="price-plot">{{ dialogData.plotDetails?.price | currency: 'USD':'symbol':'2.0-2' }}</div>
    </div>
  </div>
  <div class="text-warning">
    Your application will be assessed by the cemetery administration team. The team will be in touch complete the purchase process. By
    submitting this form you are agreeing to purchase the Right of Interment of this plot if approved.
  </div>
  <div class="form-fill">
    <div class="applicant">Applicant</div>
    <form [formGroup]="form">
      <div class="two-fields">
        <mat-form-field floatLabel="never" class="double-field">
          <input matInput placeholder="First name" formControlName="first_name" />
        </mat-form-field>
        <mat-form-field floatLabel="never" class="double-field">
          <input matInput placeholder="Last name" formControlName="last_name" />
        </mat-form-field>
      </div>
      <div class="two-fields">
        <mat-form-field floatLabel="never" class="double-field">
          <input matInput placeholder="Phone (mobile)" formControlName="mobile_phone" />
        </mat-form-field>
        <mat-form-field floatLabel="never" class="double-field">
          <input matInput placeholder="Phone (home - optional)" formControlName="billing_phone_number" />
        </mat-form-field>
      </div>
      <div class="one-field">
        <mat-form-field floatLabel="never" class="single-field">
          <input matInput placeholder="Email" formControlName="email" />
        </mat-form-field>
      </div>
      <div class="one-field">
        <mat-form-field floatLabel="never" class="single-field">
          <input matInput placeholder="Address" formControlName="billing_address" />
        </mat-form-field>
      </div>
      <div class="two-fields">
        <mat-form-field floatLabel="never" class="double-field">
          <input matInput placeholder="City / Town / Suburb" formControlName="billing_city" />
        </mat-form-field>
        <mat-form-field floatLabel="never" class="double-field">
          <input matInput placeholder="State" formControlName="billing_state" />
        </mat-form-field>
      </div>
      <div class="two-fields">
        <mat-form-field floatLabel="never" class="double-field">
          <input matInput placeholder="Country" formControlName="billing_country" />
        </mat-form-field>
        <mat-form-field floatLabel="never" class="double-field">
          <input matInput placeholder="Post code" formControlName="billing_postcode" />
        </mat-form-field>
      </div>
      <div class="one-field">
        <mat-form-field floatLabel="never" class="single-field">
          <textarea matInput placeholder="Additional comments" formControlName="comments"></textarea>
        </mat-form-field>
      </div>
      <div class="button-wrapper">
        <button mat-button (click)="close()">Cancel</button>
        <button mat-raised-button color="primary" (click)="submitSellPlots()">Submit</button>
      </div>
    </form>
  </div>
</div>
