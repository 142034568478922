import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { Image } from 'src/app/interfaces/image';
import { ObjectFit } from '../../enums/object-fit.enum';
@Component({
  selector: 'cl-main-promotion',
  templateUrl: './main-promotion.component.html',
  styleUrls: ['./main-promotion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPromotionComponent implements OnInit, OnDestroy {
  responsive$: boolean;
  destroy$ = new Subject();
  images: Image[] = [
    {
      id: 1,
      url: 'https://storage.googleapis.com/chronicle-rip.appspot.com/banner/slide-1rev.png',
      image: '',
    },
  ];

  imagesMobile: Image[] = [
    {
      id: 1,
      url: 'https://storage.googleapis.com/chronicle-rip.appspot.com/banner/slide-mobile-1.png',
      image: '',
    },
  ];
  imageFit = ObjectFit.Cover;

  constructor(
    private mobileResponsiveService: MobileResponsiveService,
    private ref: ChangeDetectorRef,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private readonly dialogRef: MatDialogRef<MainPromotionComponent>
  ) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_main_promotion_close',
        src: '/assets/images/close_icon_white.svg',
      },
    ]);
  }

  openPreview(event: any) {}

  ngOnInit(): void {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.dialogRef.close();
  }
}
