<input #file type="file" class="hidden" multiple (change)="uploadFiles($event.target?.files)" [accept]="fileType" />

<h4>{{ title }}</h4>

<div class="content">
  <ng-template *ngTemplateOutlet="templateRef; context: { files: files, loadFile: openFileLoad.bind(this), remove: remove.bind(this) }">
    <ng-content select="[nestedItem]"></ng-content>
  </ng-template>

  <div
    clDrag
    (files)="uploadFiles($event)"
    (isOver)="isOver($event)"
    [ngClass]="{ over: (this.loadingService.isLoading$ | async) || (isOver$ | async) }"
    (click)="openFileLoad()"
  >
    <div class="active-item">
      <div class="active-item-wrapper">
        <ng-content select="[activeItem]"></ng-content>
      </div>
      <mat-spinner [diameter]="spinnerDiameter" *ngIf="this.loadingService.isLoading$ | async"></mat-spinner>
    </div>
  </div>
</div>
