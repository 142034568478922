import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { RegionalSettingsService } from 'src/app/core/regional-settings.service';
import { RegionalSettings } from 'src/app/interfaces/regional-settings';

@Pipe({
  name: 'roiTransformText',
})
export class RoiTransformTextPipe implements PipeTransform {
  constructor(private regionalSettingsService: RegionalSettingsService, private route: ActivatedRoute) {}

  transform(value: string): any {
    const roiText: RegionalSettings = this.route.snapshot.data.roiRegional;

    switch (value) {
      case (value = 'roi'):
        return roiText.roi;
      case (value = 'rois'):
        return roiText.rois;
      case (value = 'roi_holder'):
        return roiText.roi_holder;
      case (value = 'roi_holders'):
        return roiText.roi_holders;
      case (value = 'roi_applicant'):
        return roiText.roi_applicant;
      case (value = 'roi_applicants'):
        return roiText.roi_applicants;
      case (value = 'right_of_interment'):
        return roiText.right_of_interment;
      case (value = 'right_of_interments'):
        return roiText.right_of_interments;
      default:
        return value;
    }
    // return value;
  }
}
