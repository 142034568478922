<div class="content" *ngIf="!isLoading">
  <h4 class="header">
    {{ data.header }}
  </h4>

  <p class="description">
    {{ data?.description }}
  </p>

  <div class="buttons">
    <button mat-button class="cancel" color="primary" (click)="cancel()">
      {{ data.cancelButtonLabel }}
    </button>
    <button class="action" mat-raised-button color="primary" (click)="action()">
      {{ data.actionButtonLabel }}
    </button>
  </div>
</div>

<div class="loading" *ngIf="isLoading">
  <mat-spinner [diameter]="40"></mat-spinner>
  <p class="text">{{ data.actionDescription }}</p>
</div>
