import { Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';

@Pipe({
  name: 'order',
})
export class OrderPipe implements PipeTransform {
  transform(value: any[], sortOrder: SortOrder | string = 'asc', sortKey: string): any {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

    if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) {
      return value;
    }

    let numberArray = [];
    let stringArray = [];

    numberArray = value.filter(item => typeof item[sortKey] === 'number').sort((a, b) => a[sortKey] - b[sortKey]);
    stringArray = value
      .filter(item => typeof item[sortKey] === 'string')
      .sort((a, b) => {
        if (a[sortKey] < b[sortKey]) {
          return -1;
        } else if (a[sortKey] > b[sortKey]) {
          return 1;
        } else {
          return 0;
        }
      });

    const sorted = numberArray.concat(stringArray);
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }
}
