<ng-container>
  <div class="timeline-wrapper" #timelineWrapper>
    <div class="timeline"></div>
    <div class="card-wrapper-timeline" *ngFor="let a of sortByApprovedAt; let j = index">
      <mat-card (click)="openModalViewStory(a)">
        <ng-container *ngIf="a?.images.length > 0">
          <div class="card-container">
            <div class="image-story-timeline">
              <img src="{{ a?.images[0]?.image }}" class="preview-image-story" alt="story-image" />
            </div>
            <div class="timeline-header-wrapper">
              <div class="timeline-card-title">{{ a.title }}</div>
              <!-- <cl-read-more style="margin-bottom: 0px !important" *ngIf="a.text" [text]="a.text"></cl-read-more> -->
              <div class="text-content" [innerHTML]="a.text | readMorePipe: 108:'story' | safeHtml"></div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="a?.images.length <= 0">
          <div class="card-container-no-images">
            <div class="timeline-header-wrapper">
              <div class="timeline-card-title">{{ a.title }}</div>
              <!-- <cl-read-more style="margin-bottom: 0px !important" *ngIf="a.text" [text]="a.text"></cl-read-more> -->
              <div class="text-content" [innerHTML]="a.text | readMorePipe: 108:'story' | safeHtml"></div>
            </div>
          </div>
        </ng-container>
      </mat-card>
      <div class="bullet">
        <div class="dot"></div>
      </div>
    </div>
  </div>
</ng-container>
