import { Injectable } from '@angular/core';
import { DataSection } from '../interfaces/section-data';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from './http-utils.service';
import { shareReplay } from 'rxjs/operators';
import { PlotDetails } from '../interfaces/plot-details';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  constructor(private httpClient: HttpClient, private readonly httpUtils: HttpUtilsService) {}

  getSection(cemeteryIdentifier: string | number, sectionId: string | number) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryIdentifier, 'sections', sectionId]);

    const res = this.httpClient.get<DataSection>(url).pipe(shareReplay(1));

    return res;
  }

  getSectionPlots(cemeteryUniqueName: string | number, sectionId: string | number) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryUniqueName, 'section', sectionId, 'plots']);

    const res = this.httpClient.get<PlotDetails>(url).pipe(shareReplay(1));

    return res;
  }
}
