<div class="navbar-pano">
  <img src="../../../assets/images/chronicle_logo.svg" alt="">
  <button mat-raised-button color="primary" (click)="backToCemetery()">Back to cemetery</button>
</div>
<div id="panoramaContainer">
  <div id="controls">
    <div class="ctrl" id="zoom-in" (click)="zoomIn()">
      <mat-icon>add</mat-icon>
    </div>
    <div class="ctrl" id="zoom-out" (click)="zoomOut()">
      <mat-icon>remove</mat-icon>
    </div>
    <div class="ctrl" id="fullscreen" (click)="toggleFullscreen()">
      <mat-icon>fullscreen</mat-icon>
    </div>
  </div>
</div>

<div class="control-mini-map" #miniMap [class.showMap]="showMap">
  <div class="btn-map" *ngIf="showMap">
    <div class="mini-map">
      <cl-map-panorama [currentLocation]="currentLocation" [cemeteryInfo]="cemeteryInfo" [markerPoint]="dataPano">
      </cl-map-panorama>
    </div>
    <span class="label" (click)="setVisibilityMap()">
      <mat-icon>keyboard_arrow_up</mat-icon>
      Hide Map
    </span>
  </div>
  <div class="btn-map" *ngIf="!showMap" (click)="setVisibilityMap()">
    <img class="mini-img" src="../../../assets/images/pano-map.png">
    <span class="label">Show Map</span>
  </div>
</div>

<div id="myCustomLoader"
  style="display: block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
  <img src="../../../assets/images/Chronicle.gif" alt="Loading..." width="50%" />
</div>
