import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SidebarService } from '../../core/sidebar.service';

@Component({
  selector: 'cl-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  isOpen$ = this.sidebarService.isOpen$;
  isCalendarView$ = this.sidebarService.isCalendarView$;
  showTogleButton = true;
  constructor(private sidebarService: SidebarService) {
    this.sidebarService.showTogleButton$.subscribe(res => {
      this.showTogleButton = res;
    });
  }

  toggle() {
    this.sidebarService.toggle();
  }
}
