<div [formGroup]="formGroup" class="example-form">
  <mat-form-field class="section-field">
    <input type="text" placeholder="Section" aria-label="Number" matInput [formControlName]="'section'" [matAutocomplete]="auto" />
    <mat-autocomplete class="autocomplete-section" #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option class="option-text" *ngFor="let option of filteredOptions | async" [value]="option.section">
        {{ option.section }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="legacy">
    <mat-label>Row</mat-label>

    <ng-container *ngIf="fromAdvanceSearch">
      <mat-select formControlName="row">
        <mat-option [value]="''"> All </mat-option>
        <mat-option *ngFor="let item of rowValue" [value]="item.row">
          {{ item.row }}
        </mat-option>
      </mat-select>
    </ng-container>
    <ng-container *ngIf="!fromAdvanceSearch">
      <input type="text" placeholder="Row" aria-label="Number" matInput [formControlName]="'row'" [matAutocomplete]="autorow" />
      <mat-autocomplete class="autocomplete-section" #autorow="matAutocomplete">
        <mat-option class="option-text" *ngFor="let option of filteredRowOptions" [value]="option.row">
          {{ option.row }}
        </mat-option>
      </mat-autocomplete>
    </ng-container>
  </mat-form-field>

  <mat-form-field class="example-full-width" appearance="legacy">
    <mat-label>Number</mat-label>
    <ng-container *ngIf="fromAdvanceSearch">
      <input *ngIf="fromAdvanceSearch" matInput placeholder="12" formControlName="plot_number" />
    </ng-container>
    <ng-container *ngIf="!fromAdvanceSearch">
      <input type="text" placeholder="Number" aria-label="Number" matInput [formControlName]="'plot_no'" [matAutocomplete]="autonumber" />
      <mat-autocomplete class="autocomplete-section" #autonumber="matAutocomplete">
        <mat-option class="option-text" *ngFor="let option of filteredNumberOptions" [value]="option.plot_no">
          {{ option.plot_no }}
        </mat-option>
      </mat-autocomplete>
    </ng-container>
  </mat-form-field>
</div>
