import { environment } from '../../environments/environment';

export function shareEventOnSocialMedia(socialMedia: string, data: any) {
  switch (socialMedia) {
    case 'facebook':
      let facebookURL;
      if (data) {
        if (data.plot) {
            facebookURL = encodeURIComponent(
                `${environment.APP_BASE_URL}/${data.cemetery_unique_name}/plots/${data.plot.plot_id}/upcoming-event/${data.id}`
            );
        } else {
          facebookURL = encodeURIComponent(
            `${environment.APP_BASE_URL}/${data.cemetery_unique_name}/upcoming-events/${data.id}`
        );
        }
      }
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${facebookURL}`, 'sharer');
      break;
    case 'twitter':
      let twitterUrl;
      if (data) {
        if (data.plot) {
          twitterUrl = encodeURIComponent(
            `${environment.APP_BASE_URL}/${data.cemetery_unique_name}/plots/${data.plot.plot_id}/upcoming-event/${data.id}`
          );
        } else {
          twitterUrl = encodeURIComponent(
            `${environment.APP_BASE_URL}/${data.cemetery_unique_name}/upcoming-events/${data.id}`
          );
        }
      }
      const desc = encodeURIComponent('Chronicle Best Cemetery App');
      window.open(`https://twitter.com/intent/tweet?url=${twitterUrl}&text=${desc}`, 'sharer');
      break;
  }
}
