import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Interment } from '../../interfaces/interment';
import { IntermentFormContent } from './interment-form-content';
import { IntermentMoveParameters } from './interment-move-parameters';
import { HttpUtilsService } from '../../core/http-utils.service';
import { ValidateResponseService } from '../../server-response-validation/validate-response.service';
import { ValidatorType } from '../../server-response-validation/validator-type.enum';
import { tap } from 'rxjs/operators';
import { Person } from '../../interfaces/person';
import { ReplaySubject } from 'rxjs';
import { CemeteryInfo, CemeteryInfoInterment } from 'src/app/interfaces/cemetery-info';

@Injectable()
export class IntermentService {
  private readonly personAsInterment = new ReplaySubject<Person>(1);

  get personAsInterment$() {
    return this.personAsInterment.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private readonly httpUtils: HttpUtilsService,
    private readonly validateResponseService: ValidateResponseService
  ) { }

  addPersonAsInterment(person: Person) {
    this.personAsInterment.next(person);
  }

  add(interment: IntermentFormContent) {
    const cemeterName = interment.cemetery_unique_name || interment.cemetery_name;
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeterName, 'interment']);

    return this.httpClient.post<Interment>(url, interment);
  }

  get(id: number, cemeteryIdentifier: string | number) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryIdentifier, 'interment', id]);

    return this.httpClient
      .get<Interment>(url)
      .pipe(tap(interment => this.validateResponseService.validate(ValidatorType.Interment, interment)));
  }

  getBySection(cemeteryIdentifier: string | number, section: string) {
    const url = this.httpUtils.getResourceUrl(['details', 'cemetery', cemeteryIdentifier, 'section', section]);

    return this.httpClient.get<CemeteryInfoInterment[]>(url);
  }

  update(interment: IntermentFormContent) {
    const url = this.httpUtils.getResourceUrl(['cemetery', interment.cemetery_unique_name, 'interment', interment.id]);

    return this.httpClient.put(url, interment);
  }

  delete(id: number, cemeteryIdentifier: string | number) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryIdentifier, 'interment', id]);

    return this.httpClient.delete(url);
  }

  move(parameters: IntermentMoveParameters) {
    const url = this.httpUtils.getResourceUrl(['move_interment']);

    return this.httpClient.post(url, parameters);
  }

  setHighlight(data: any, cemeteryName, id) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryName, 'interment', id]);

    return this.httpClient.put(url, data);
  }
}
