import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormAdvanceSearch } from 'src/app/interfaces/advance-search';

@Component({
  selector: 'cl-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() data: any;
  @Input() dataForm: FormAdvanceSearch;
  value1: string[];
  defaultValue = '';
  fields = [];
  types = [
    {
      value: 'person',
    },
    {
      value: 'business',
    },
  ];
  model = {
    fn: {
      type: 'text',
      value: '',
      label: 'First name',
    },
    ln: {
      type: 'text',
      value: '',
      label: 'Last name',
    },
    phone: {
      type: 'text',
      value: '',
      label: 'Phone (mobile/home/office)',
    },
    mail: {
      type: 'text',
      value: '',
      label: 'E-mail',
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.getFormControlFields();
    if (this.dataForm !== undefined && this.fields && this.fields.length > 0) {
      this.patchValues();
    }
  }

  patchValues() {
    for (const field of this.fields) {
      const concatFieldName = `${field.fieldName}_${field.data}_${this.defaultValue}`;
      this.group.patchValue({
        [`${field.fieldName}_${field.data}_${this.defaultValue}`]: this.dataForm[concatFieldName],
      });
      field.value = this.dataForm[concatFieldName];
    }
  }

  getFormControlFields() {
    for (const field of Object.keys(this.model)) {
      if (this.dataForm !== undefined) {
        this.defaultValue = this.dataForm[`${field}_${this.data.value}_person`] === undefined ? 'business' : 'person';
      } else {
        this.defaultValue = 'person';
      }
      const fieldsProps = this.model[field];
      this.group.addControl(`${field}_${this.data.value}_${this.defaultValue}`, new FormControl(null));
      this.fields.push({ ...fieldsProps, fieldName: field, data: this.data.value, identifier: this.defaultValue });
    }
  }

  onValChange(value) {
    if (value === 'business') {
      this.defaultValue = 'business';
      for (const field of this.fields) {
        // const concatFieldName = `${field.fieldName}_${field.data}_person`;
        // const prevValue = this.group.get(concatFieldName).value;
        field.identifier = 'business';
        this.group.removeControl(`${field.fieldName}_${field.data}_person`);
        this.group.addControl(
          `${field.fieldName}_${field.data}_business`,
          new FormControl(field.value === null || field.value.length === 0 ? null : field.value)
        );
        // this.group.setValue({
        //   [`${field.fieldName}_${field.data}_${this.defaultValue}`]: field.value,
        // });
      }
    }
    if (value === 'person') {
      this.defaultValue = 'person';
      for (const field of this.fields) {
        // const concatFieldName = `${field.fieldName}_${field.data}_business`;
        // const prevValue = this.group.get(concatFieldName).value;
        field.identifier = 'person';
        this.group.removeControl(`${field.fieldName}_${field.data}_business`);
        this.group.addControl(
          `${field.fieldName}_${field.data}_person`,
          new FormControl(field.value === null || field.value.length === 0 ? null : field.value)
        );
        // this.group.setValue({
        //   [`${field.fieldName}_${field.data}_${this.defaultValue}`]: field.value,
        // });
      }
    }
  }

  changeValueFormInput(value, fieldName) {
    this.fields.find(field => field.fieldName === fieldName).value = value;
    this.group.patchValue(
      {
        [`${fieldName}_${this.data.value}_${this.defaultValue}`]: value.length === 0 ? null : value,
      },
      { onlySelf: true, emitEvent: false }
    );
    // this.group.get(`${fieldName}_${this.data.value}_${this.defaultValue}`).setValue(value, { selfOnly: true, emitEvent: false });
  }
}
