<div class="default-image">
  <img [src]="defaultImage" alt="" (click)="showInputImage && inputNewImage.click()" />
</div>

<!-- <div class="single-slide" *ngIf="localImages?.length === 1">
  <img class="main-slide" [src]="localImages[0].url" alt="" (click)="preview(0)" />
  <img class="background-slide" [src]="localImages[0].url" alt="" />
</div> -->

<ng-container *ngIf="localImages?.length >= 1">
  <div class="swiper-wrapper" [class.initializing]="loading">
    <div class="swiper-slide" *ngFor="let slide of localImages; let i = index">
      <img class="main-slide" [src]="slide.url" alt="" (click)="preview(i)" #image />
      <img [src]="slide.url" alt="" class="background-slide" />
    </div>
    <div class="swiper-slide" *ngIf="showInputImage">
      <img class="main-slide" (click)="inputNewImage.click()" [src]="defaultImage" alt="" #image />
      <img [src]="defaultImage" alt="" class="background-slide" />
    </div>
  </div>
  <div class="custom-swiper-button-prev">
    <img src="/assets/images/arrow_icon_white.svg" alt="" />
  </div>
  <div class="custom-swiper-button-next">
    <img src="/assets/images/arrow_icon_white.svg" alt="" />
  </div>

  <div class="swiper-pagination"></div>
</ng-container>

<input id="file-input" type="file" accept="image/*" (change)="onImageSelected($event)" #inputNewImage />
