import * as Joi from 'joi';
import { phoneValidator } from './phone-validator';
import { userRoleValidator } from './user-role-validator';
import { accessCemeteryValidator } from './access-cemetery-validator';

export const userBaseValidator = Joi.object({
  id: Joi.number(),
  archived: Joi.boolean(),
  created_at: Joi.date().iso(),
  email: Joi.string().allow(''),
  first_name: Joi.string().allow(null),
  last_login: Joi.date().iso().allow(null),
  last_name: Joi.string().allow(null),
  middle_name: Joi.string().allow(null),
  phones: Joi.array().items(phoneValidator),
  show_public: Joi.boolean(),
  role: userRoleValidator,
  username: Joi.string(),
  cemeteries: Joi.array().items(accessCemeteryValidator),
});
