<div class="wrapper-modal">
  <div mat-dialog-title class="list-variable-modal-title">
    <h3  class="modal-title">List of All Variables</h3>
  </div>
  <mat-dialog-content>
    <mat-tab-group mat-stretch-tabs (selectedTabChange)="onTabChange($event)">
      <ng-container *ngFor="let label of tabLabel">
        <mat-tab [label]="label">
          <mat-table [dataSource]="dataSource" fixed>
            <!-- Key Column -->
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef>Variable Code</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div 
                  #cellKey 
                  class="content"
                  matTooltipClass="cell-key-tooltip" 
                  [matTooltip]="element.key" 
                  [matTooltipDisabled]="cellKey.scrollWidth <= cellKey.clientWidth" 
                  ><span>{{ element.key }}</span>
                </div>
                <mat-icon 
                  class="copy-to-clipboard" 
                  #copyToClipBoard="matTooltip" 
                  matTooltip="Copied to clipboard"
                  matTooltipDisabled
                  [cdkCopyToClipboard]="element.key"
                  (click)="copyToClipboard(element.key)"
                  (cdkCopyToClipboardCopied)="onCopyToClipboard($event, copyToClipBoard)" 
                  >
                  content_copy
                </mat-icon>
              </mat-cell>
            </ng-container>

            <!-- Remakrs Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>Remarks</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </mat-dialog-content>
</div>
