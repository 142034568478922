import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { filter, finalize, first, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadingService } from '../loading-overlay/loading.service';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from './http-utils.service';
import { webSocket } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class PlotLocationReportService implements OnDestroy {
  private readonly destroy$ = new Subject();

  constructor(
    private readonly loadingService: LoadingService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilsService: HttpUtilsService
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  create(plotId: string, cemeteryUniqueName: string, interment?: any) {
    let urlGenereatePlot = `${environment.API_URL}/plot-location-report?cemeteryUniqueName=${cemeteryUniqueName}&plotId=${encodeURI(plotId)}`;
    if (interment) {
      urlGenereatePlot += `&interment_id=${interment}`;
    }
    this.createReport(urlGenereatePlot);
  }

  createUnknownLocationReport(plotId: string, cemeteryUniqueName: string) {
    this.createReport(
      `${environment.API_URL}/plot-location-report/unknown?cemeteryUniqueName=${cemeteryUniqueName}&plotId=${encodeURI(plotId)}`
    );
  }

  private createReport(pageWithReportUrl: string) {
    this.loadingService.run();

    this.createReportWithPlotLocation(pageWithReportUrl)
      .pipe(
        pluck('ws'),
        filter(Boolean),
        switchMap(link => webSocket(environment.WSS_URL + link)),
        first(),
        pluck('report_url'),
        filter(Boolean),
        tap<string>(reportUrl => window.open(reportUrl, '_blank')),
        takeUntil(this.destroy$),
        finalize(() => this.loadingService.stop())
      )
      .subscribe();

    // this.createReportWithPlotLocation(pageWithReportUrl)
    //   .pipe(
    //     tap(data => {
    //       const url = window.URL.createObjectURL(data);
    //       window.open(url, '_blank');
    //     }),

    //     takeUntil(this.destroy$),
    //     finalize(() => this.loadingService.stop())
    //   )
    //   .subscribe();
  }

  private createReportWithPlotLocation(pageWithReportUrl: string) {
    const url = this.httpUtilsService.getResourceUrlForBackupAndImport(['reports', 'generate']);
    // const url = this.httpUtilsService.getResourceUrl(['ms', 'reports', 'generate']);
    // const url = 'http://localhost:8001/api/v1/ms/reports/generate/';
    return this.httpClient.post<{ ws: string }>(url, { url: pageWithReportUrl });
  }
}
