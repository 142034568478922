<div class="search-list">
  <div class="picture">
    <ngx-skeleton-loader [theme]="{ width: '40x', height: '40px' }"></ngx-skeleton-loader>
  </div>
  <div class="content">
    <div class="content-title">
      <ngx-skeleton-loader [theme]="{ width: '60px' }"></ngx-skeleton-loader>
      <span class="divider"></span>
      <ngx-skeleton-loader [theme]="{ width: '190px' }"></ngx-skeleton-loader>
    </div>
    <div class="content-main">
      <ngx-skeleton-loader [theme]="{ width: '265px' }"></ngx-skeleton-loader>
    </div>
  </div>
</div>
