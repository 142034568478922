import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cl-story-dialog-confirmation',
  templateUrl: './story-dialog-confirmation.component.html',
  styleUrls: ['./story-dialog-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryDialogConfirmationComponent implements OnInit {
  rejectReason: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { data: string },
    private readonly dialogRef: MatDialogRef<StoryDialogConfirmationComponent>
  ) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close();
  }

  valueEvent(value: string) {
    this.dialogRef.close({ data: value, reason: this.rejectReason });
  }
}
