import { CemeteryInfoBase } from './cemetery-info-base';

export interface ICustomField {
  name: string;
  type: string;
  key: string;
  values: string[];
  visibility?: 'Public' | 'Internal';
}

export interface ICustomFieldApi {
  id: number;
  order: number;
  field_type: string;
  fields: ICustomField;
  cemeteries: CemeteryInfoBase[];
}

export enum CustomFieldType {
  plot = 'Plot',
  interment = 'Interment',
  roi = 'ROI',
}
