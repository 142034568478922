import { Name } from '../interfaces/name';

export function transformName(item: Name | null): string {
  const { first_name, middle_name, last_name } = item || {};

  return [first_name, middle_name, last_name].filter(Boolean).join(' ');
}

export function transformNameAdvanceSearch(item: Name | null): string {
  const { first_name, last_name } = item || {};

  return [first_name, last_name].filter(Boolean).join(' ');
}
