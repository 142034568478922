import { Component, OnInit, ChangeDetectionStrategy, ViewChild, QueryList, ViewChildren, Inject, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormArrayName, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';

@Component({
  selector: 'cl-custom-fields-modal',
  templateUrl: './custom-fields-modal.component.html',
  styleUrls: ['./custom-fields-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsModalComponent implements OnInit {
  @ViewChildren('checkboxMultiple') private checkboxesMultiple: QueryList<any>;
  checked = false;
  allChecked = false;
  changeColor = false;
  allCemeteries = false;
  cemeteryValue = [];
  isSelectAll = false;
  types = [
    { value: 'text', viewValue: 'Text' },
    { value: 'select', viewValue: 'Select' },
    { value: 'switch', viewValue: 'Switch' },
    { value: 'date', viewValue: 'Date' },
    { value: 'number', viewValue: 'Number' },
    { value: 'textarea', viewValue: 'Text Area' },
  ];
  categories = [
    { value: 'deceased_person', viewValue: 'Deceased person' },
    { value: 'interment_details', viewValue: 'Interment details' },
  ];
  listId: any;
  form: FormGroup;
  convertedValues: any;
  values: any;
  get cemeteries(): FormArray {
    return this.form.get('cemeteries') as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      modalCondition: any;
      listCemeteries: any;
      tab: any;
      row: any;
      dataSource: any;
      disabled: boolean;
      selectedOrg: any;
    },
    private readonly dialogRef: MatDialogRef<CustomFieldsModalComponent>,
    private formBuilder: FormBuilder,
    private snackBarNotificationService: SnackbarNotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'custom_info', src: '/assets/images/info.svg' }]);
  }

  ngOnInit(): void {
    this.convertArray();
    this.form = this.formBuilder.group({
      cemeteries: this.buildListCemetery(),
      field_type: null,
      order: null,
      fields: this.formBuilder.group({
        name: [null, [Validators.required, Validators.pattern('.*\\S.*[a-zA-z0-9_-]')]],
        type: null,
        values: null,
        key: null,
        category: null,
        visibility: null,
      }),
    });
    this.form.controls.fields
      .get('type')
      .valueChanges.pipe(
        tap(value => {
          if (value === 'select') {
            this.form.controls.fields.get('values').setValue(null);
            this.form.controls.fields.get('values').setValidators([Validators.required]);
          } else {
            this.form.controls.fields.get('values').clearValidators();
          }
          this.form.controls.fields.get('values').updateValueAndValidity();
        })
      )
      .subscribe();

    if (this.dialogData.modalCondition === 'edit') {
      this.patchValues();
    }
    if (this.dialogData.modalCondition !== 'edit') {
      this.form.get('order').setValue(this.dialogData.dataSource.length + 1);
    }
    this.form.get('field_type').setValue(this.dialogData.tab);

    if (this.dialogData.listCemeteries.length <= 1) {
      this.cemeteries.controls.forEach(control => {
        control.setValue(true);
        control.disable();
      });
    }
  }

  patchValues() {
    if (this.dialogData.row.fields.values) {
      this.values = this.dialogData.row.fields.values.join(',');
    }
    this.form.patchValue({ fields: { name: this.dialogData.row.fields.name } });
    this.form.patchValue({ fields: { type: this.dialogData.row.fields.type } });
    this.form.patchValue({ fields: { values: this.values } });
    this.form.patchValue({ fields: { key: this.dialogData.row.fields.key } });
    this.form.patchValue({ fields: { category: this.dialogData.row.fields.category } });
    this.form.patchValue({ fields: { visibility: this.dialogData.row.fields?.visibility === 'Public' ? true : false } });
    this.form.get('order').setValue(this.dialogData.row.order);
    if (this.dialogData.row.cemeteries.length === this.cemeteries.controls.length) {
      this.isSelectAll = true;
      this.cemeteries.controls.forEach(control => {
        control.setValue(true);
        control.disable();
      });
    }
  }

  buildListCemetery() {
    const arr = this.cemeteryValue.map(s => {
      return this.formBuilder.control(s.selected);
    });
    return this.formBuilder.array(arr);
  }

  convertArray() {
    this.cemeteryValue = this.dialogData.listCemeteries.map(data => {
      return {
        id: data.id,
        name: data.name,
        selected: this.dialogData.modalCondition === 'edit' ? this.checkExist(data) : false,
      };
    });
  }

  checkExist(cemetery) {
    const data = this.dialogData.row.cemeteries.find(dataCem => dataCem.id === cemetery.id);
    if (data) {
      return true;
    }
    return false;
  }

  onSelectAll(event) {
    if (event.checked) {
      this.cemeteries.controls.forEach(control => {
        control.setValue(true);
        control.disable();
      });
      return;
    }

    this.cemeteries.controls.forEach(control => {
      control.setValue(false);
      control.enable();
    });
  }

  convertValue(event) {
    const convert = event;
    this.convertedValues = convert;
  }

  convertKey() {
    return this.form
      .get('fields')
      .get('name')
      .value?.replace(/[^A-Z0-9]+/gi, '_')
      .toLowerCase();
  }

  sendValues() {
    return this.form
      .get('fields')
      .get('values')
      .value?.split(/,\s*/)
      .map(data => data);
  }

  parsePrefixKey(organizationName: string): string {
    return organizationName.replace(/\s+/g, '_') + '_';
  }

  onAdd() {
    let data;
    data = {
      ...this.form.value,
      fields: {
        ...this.form.get('fields').value,
        values: this.sendValues(),
        key: `${this.parsePrefixKey(this.dialogData.selectedOrg.business_name.trim().toLowerCase())}${this.convertKey()}`,
        visibility: this.form.get('fields').get('visibility').value ? 'Public' : 'Internal',
      },
      cemeteries: this.form
        .get('cemeteries')
        .value.map((dataCem, i) => {
          if (dataCem === true) {
            return this.dialogData.listCemeteries[i].id;
          }
        })
        .filter(dataCemNew => dataCemNew),
    };

    if (this.form.invalid && this.dialogData.modalCondition !== 'delete') {
      this.openErrorMessage('Please fill all field');
      return;
    }
    if (data.cemeteries.length <= 0 && this.dialogData.modalCondition !== 'delete') {
      this.openErrorMessage('Please select at least one cemetery before submitting custom field');
      return;
    }

    this.dialogRef.close({ data, modalCondition: this.dialogData.modalCondition, row: this.dialogData.row });
  }

  onDelete() {
    return;
  }

  onCancel() {
    this.dialogRef.close();
  }

  changeTextTab() {
    if (this.dialogData.tab === 'Plot') {
      switch (this.dialogData.modalCondition) {
        case 'add':
          return 'Add Field for Plots';
        case 'edit':
          return 'Edit Field for Plots';
        default:
          return 'Delete Custom Field';
      }
    }
    if (this.dialogData.tab === 'Interment') {
      switch (this.dialogData.modalCondition) {
        case 'add':
          return 'Add Field for Interments';
        case 'edit':
          return 'Edit Field for Interments';
        default:
          return 'Delete Custom Field';
      }
    }
    if (this.dialogData.tab === 'ROI') {
      switch (this.dialogData.modalCondition) {
        case 'add':
          return 'Add Field for ROI';
        case 'edit':
          return 'Edit Field for ROI';
        default:
          return 'Delete Custom Field';
      }
    }
  }

  private openErrorMessage(errorMessage: string) {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.snackBarNotificationService.openErrorMessage(errorMessage);
    this.changeDetectorRef.markForCheck();
  }
}
