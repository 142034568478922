<ng-container *ngIf="upcomingEvent && upcomingEvent.length">
    <div class="event-header" *ngIf="!allUpcomingEvent">
        <div class="title">Upcoming</div>
        <div class="btn-events" *ngIf="paginate?.total >= upcomingEvent.length" (click)="goToEventDetails()"><p>SEE ALL EVENTS</p></div>
    </div>
    <div class="list-card" 
        infiniteScroll
        [infiniteScrollDistance]="3"
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false" 
        (scrolled)="onScrollDown($event)"
    >
        <div class="card-container" [class.all-list]="allUpcomingEvent" *ngFor="let event of upcomingEvent" (click)="goToDetailEvent(event)">
            <div class="event-date">
                <div class="month">{{event?.start_time | date: 'LLL':'UTC'}}</div>
                <div class="day">{{event?.start_time | date: 'd':'UTC'}}</div>
            </div>
            <div class="event-detail">
                <p class="event-name">{{event.event_name}}</p>
                <div class="detail-text">
                    <p *ngIf="event.related_interment">{{event?.related_interment?.person?.first_name}} {{event?.related_interment?.person?.last_name}}</p>
                    <p>{{event?.start_time | date: 'EE':'UTC'}}, {{event?.start_time | date: 'shortTime':'UTC'}} - {{event?.end_time | date: 'shortTime':'UTC'}} <span *ngIf="event.plot">| {{event?.plot?.plot_id}}</span></p>
                </div>
            </div>
        </div>
        <cl-event-loading-skeleton *ngIf="isLoading && allUpcomingEvent"></cl-event-loading-skeleton>
    </div>
</ng-container>
  

