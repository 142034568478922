<div class="result">
  <div class="top">
    <div class="top-content">
      <ng-content select="[top]"></ng-content>
      <div class="header">
        <h3>{{ header }}</h3>
        <ng-content select="[filter]"></ng-content>
      </div>
    </div>
    <ng-content select="[selectedFilters]"></ng-content>
  </div>
  <div class="items">
    <ng-content></ng-content>
  </div>
</div>
