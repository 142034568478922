import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'cl-snackbar-success',
  templateUrl: './snackbar-success.component.html',
  styleUrls: ['./snackbar-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarSuccessComponent {
  constructor(
    private snackRef: MatSnackBarRef<SnackbarSuccessComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string; buttonLabel?: string }
  ) {
    if (data.buttonLabel === undefined) {
      data.buttonLabel = 'Got it!';
    }
  }

  gotIt() {
    this.snackRef.dismiss();
  }
}
