import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'cl-toolbar-manage',
  templateUrl: './toolbar-manage.component.html',
  styleUrls: ['./toolbar-manage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarManageComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  responsive$: boolean;

  constructor(private ref: ChangeDetectorRef, private mobileResponsiveService: MobileResponsiveService) {}

  ngOnInit() {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
