<div [ngSwitch]="fieldType">
  <div *ngSwitchCase="'text'">
    <div [innerHTML]="values"></div>
  </div>

  <div *ngSwitchCase="'text_field'">
    <mat-form-field>
      <mat-label>{{label}}</mat-label>
      <input type="text" matInput [formControl]="controlName" [required]="required">
    </mat-form-field>
  </div>

  <div *ngSwitchCase="'number'">
    <mat-form-field>
      <mat-label>{{label}}</mat-label>
      <input type="text" matInput [formControl]="controlName" type="number" [required]="required">
    </mat-form-field>
  </div>

  <div *ngSwitchCase="'email'">
    <mat-form-field>
      <mat-label>{{label}}</mat-label>
      <input type="email" matInput [formControl]="controlName" [required]="required">
    </mat-form-field>
  </div>

  <div *ngSwitchCase="'checkbox'" class="checkbox">
    <mat-checkbox [formControl]="controlName" [required]="required">{{label}}<span *ngIf="required"
        class="required-field">*</span></mat-checkbox>
  </div>
  <div *ngSwitchCase="'textarea'">
    <mat-form-field>
      <mat-label>{{label}}</mat-label>
      <textarea matInput [formControl]="controlName" [required]="required"></textarea>
    </mat-form-field>
  </div>
  <div *ngSwitchCase="'datetime'" class="datetime" [formGroup]="controlName">
    <mat-form-field>
      <mat-label>Date <span class="asterisk">*</span></mat-label>
      <input matInput formControlName="date" [matDatepicker]="picker" aria-label="Date" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error style="font-size: 12px">
        Required
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Start time</mat-label>
      <input formControlName="startTime" class="time" type="time" matInput autocomplete="off" aria-label="Start time" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>End time</mat-label>
      <input formControlName="endTime" class="time" type="time" matInput autocomplete="off" aria-label="End Time" />
    </mat-form-field>
  </div>
  <div *ngSwitchCase="'label'">
    <p class="label-form">{{label}}</p>
  </div>
  <div *ngSwitchCase="'select'">
    <mat-form-field class="left-margin">
      <mat-label>{{label}}</mat-label>
      <mat-select placeholder="none" [formControl]="controlName">
        <ng-container *ngIf="values?.length">
          <mat-option [value]="option" *ngFor="let option of values">
            {{option}}
          </mat-option>
        </ng-container>
        <ng-container *ngIf="values?.options?.length">
          <mat-option [value]="option.id" *ngFor="let option of values.options">
            {{option.title}}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngSwitchCase="'date'">
    <mat-form-field>
      <mat-label>{{ label }}</mat-label>
      <input [formControl]="controlName" matInput [matDatepicker]="applicationDate" #applicationDateInput
        (keypress)="validateDate($event)" />
      <mat-datepicker-toggle matSuffix [for]="applicationDate"></mat-datepicker-toggle>
      <mat-datepicker #applicationDate color="primary"></mat-datepicker>

    </mat-form-field>
  </div>
  <div *ngSwitchCase="'signature'">
    <div class="row" *ngIf="controlName.value" (contextmenu)="onContextMenu($event)">
      <img [src]="sanitizer.bypassSecurityTrustResourceUrl(controlName.value)" alt="" class="signature-image" />
    </div>
  </div>
  <div *ngIf="['toggle', 'switch'].includes(fieldType)">
    <mat-slide-toggle [formControl]="controlName" [required]="required" class="toogle">
      {{ label }} <span style="color: red" *ngIf="required">*</span>
    </mat-slide-toggle>
  </div>
</div>
