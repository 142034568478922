import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../interfaces/address';
import addressTransform from './address-transform';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(address: Address | null) {
    return addressTransform(address);
  }
}
