<div class="left">
  <span>This link could direct you to the respective plot on the map</span>
</div>
<a mat-button (click)="upgrade()" class="got-it-link">
  UPGRADE
</a>

<button (click)="close()" mat-icon-button>
  <mat-icon>close</mat-icon>
</button>
