import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-selected-filters',
  templateUrl: './selected-filters.component.html',
  styleUrls: ['./selected-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedFiltersComponent {
  filterNaming = {
    status: 'Status',
    dob: 'Date of birth',
    dod: 'Date of death',
    age: 'Age',
    rs: 'Returned Service Person',
    feature: 'Featured Story',
    story: 'Story',
    plot_type: 'Plot Type',
    price_range: 'Price',
  };
  @Input() selectedFilters: Record<string, string>;
  @Output() filterForDelete = new EventEmitter<string>();

  removeFilter(event: Event, key: string) {
    event.stopPropagation();
    this.filterForDelete.emit(key);
  }

  getTitleLabel(val) {
    if (val === 'Featured Story') {
      return 'Story';
    }
    return val;
  }

  getValueLabel(val, key) {
    if (key === 'feature') {
      return 'Featured Story';
    }
    if (key === 'story') {
      return 'Story';
    }
    if (key === 'price_range') {
      const split = val.split(',');
      return `$${split[0]}-${split[1]}`;
    }
    return val;
  }
}
