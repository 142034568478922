import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AccessCemetery } from '../../interfaces/access-cemetery';

@Component({
  selector: 'cl-access-cemetery',
  templateUrl: './access-cemetery.component.html',
  styleUrls: ['./access-cemetery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessCemeteryComponent implements OnInit {
  @Input() userRole: string;
  @Input() accessCemetery: AccessCemetery;
  constructor() {}

  ngOnInit(): void {}
}
