<div #topSection class="top-section">
    <div class="title">
        <button mat-button color="primary" (click)="backToCemetery()">
            <mat-icon svgIcon="arrow-back"></mat-icon>
        </button>
        <div class="cemetery-section-info">
            <p class="cemetery-name">{{ currentCemetery?.name }}</p>
            <h3 class="section-name">{{ currentNicheWallSection?.name }}</h3>
        </div>
    </div>
    <div class="buttons-action">
        <button color="primary" class="btn-pdf-report" mat-button>PDF Report</button>
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="40" viewBox="0 0 2 40" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 40L0.5 0H1.5L1.5 40H0.5Z" fill="#181429"
                fill-opacity="0.2" />
        </svg>
        <button color="primary" class="btn-share" mat-button>
            <mat-icon style="margin-right:8px" svgIcon="share-icon-purple"></mat-icon>Share
        </button>
    </div>
</div>

<div class="plot-list-section" [style.height]="(componentHeight - topSection.clientHeight) + 'px'">
    <ng-container *ngIf="(loaders$ | async)?.getNicheWallPlotList?.isLoading; else plotList">
        <cl-advance-search-loading-skeleton
            *ngFor="let a of [1, 2, 3, 4, 5, 6, 7, 8]"></cl-advance-search-loading-skeleton>
    </ng-container>
    <ng-template #plotList>
        <div *ngFor="let plot of nicheWallPlotList" class="plot-list" (click)="onPlotClick(plot)">
            <div class="icon-container">
                <mat-icon svgIcon="custom_shared_search_item_plot" class="{{ plot.status | lowercase }}"></mat-icon>
            </div>
            <div class="info-container">
                <h6 class="section-name">{{ plot?.plot_id }}</h6>
                <p class="interment-name" *ngIf="plot?.interments?.length">
                    {{plot?.interments[0]?.person?.first_name}} {{plot?.interments[0]?.person?.last_name}}
                    <span>({{plot?.interments[0]?.date_of_birth ? (plot?.interments[0]?.date_of_birth | yearInRegionalFormat) : 'n/a'}}
                        -
                        {{plot?.interments[0]?.date_of_death ? (plot?.interments[0]?.date_of_death | yearInRegionalFormat) : 'n/a'}})
                    </span>
                </p>
                <!-- <p class="interment-name" *ngIf="!plot?.interments?.length">{{ plot.interments.length | zeroToDash }}</p> -->
                <p *ngIf="!plot?.interments?.length" class="interment" [ngClass]="{
                        'for-sale': plot.status === 'For Sale', 
                        'unavailable': plot.status === 'Unavailable', 
                        'reserved': plot.status === 'Reserved',
                        'vacant': plot.status === 'Vacant'
                    }">{{ plot.status }}</p>
            </div>
        </div>
    </ng-template>


</div>