<perfect-scrollbar>
  <div class="plots-view">
    <div class="top">
      <div class="top-content">
        <a class="back-to-cemetery" (click)="goBackToCemeteryInfo()">
          <mat-icon svgIcon="custom_arrow"></mat-icon>
          <span class="name">{{ cemeteryName }}</span>
        </a>
        <div class="header">
          <h3 *ngIf="!sellPlots">All {{ regionalSettings.plots }}</h3>
          <h3 *ngIf="sellPlots">Plots for sale</h3>
          <ng-content select="[filter]"></ng-content>
        </div>
      </div>
      <ng-content select="[selectedFilters]"></ng-content>
    </div>
    <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <cdk-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="example-tree-node">
        <button mat-icon-button [attr.aria-label]="'toggle ' + node.name" cdkTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <span class="header" cdkTreeNodeToggle>
          <span class="section-name">{{ node.name }} </span>
          <span class="thin-text">{{ regionalSettings.section }}</span>
        </span>
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ul class="plots-list">
            <li class="plot" *ngFor="let plot of node.plots" (click)="onNavigateToPlot(plot.plot_id, sellPlots)">
              <span class="plot-id">
                {{ formatPlotId(plot.plot_id) }}
              </span>
              <span class="persons" *ngIf="plot.status === plotStatus.Occupied">
                {{ plot.persons | personsName }}
              </span>
              <span class="quantity" *ngIf="plot.status === plotStatus.Occupied && plot.persons.length > 1">
                <mat-icon class="material-icons-outlined person-icon" svgIcon="custom_account"></mat-icon>
                <span class="quantity-number">{{ plot.persons.length }}</span>
              </span>
              <span class="plot-label {{ plot.status | lowercase }}" *ngIf="plot.status !== plotStatus.Occupied">
                {{ plot.status }}
              </span>
            </li>
          </ul>
        </div>
      </cdk-tree-node>
    </cdk-tree>
    <a *ngIf="isUnknownPlotAvailable" (click)="onNavigateToPlot(unknownPlot.plot_id, sellPlots)" class="unknown-location">
      <span class="left">
        <mat-icon svgIcon="custom_admin_shared_all_plots"></mat-icon>
        <span class="section-name"> Unknown location </span>
      </span>
    </a>

    <cl-search-result-nothing-found paragraph="Check your request" *ngIf="sections?.length === 0"></cl-search-result-nothing-found>
  </div>
</perfect-scrollbar>
