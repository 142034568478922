<div class="wrapper-notification">
  <div class="text-header">Request to buy a plot</div>
  <div class="wrapper">
    <div class="container-label-desc">
      <div class="container-image">
        <img
          class="image-thumbnail"
          src="{{ dialogData.plotDetails?.images[0]?.url ? dialogData.plotDetails?.images[0]?.url : '/assets/images/photo_stub.svg' }}"
        />
      </div>
      <div class="container-text">
        <div class="cemetery-name">{{ dialogData.plotDetails.cemetery_info?.name }}</div>
        <div class="plot-id">{{ dialogData.plotDetails?.plot_id }}</div>
        <div class="for-sale">{{ dialogData.plotDetails?.status }}</div>
      </div>
      <div class="container-price" *ngIf="dialogData.plotDetails?.show_price === 1">
        <div class="price-plot">{{ dialogData.plotDetails?.price | currency: 'USD':'symbol':'2.0-2' }}</div>
      </div>
    </div>
  </div>

  <div class="wrapper-check-img-text">
    <img src="/assets/images/logo-check.png" />
    <div class="text-request">Request was sent</div>
  </div>

  <div class="text-info">Cemetery administration will contact you to proceed with a purchasing a ROI on a certain plot</div>

  <div class="wrapper-button">
    <button mat-button color="primary" (click)="viewPdf()">VIEW SUBMISSION AS PDF</button>
    <button (click)="close()" mat-raised-button color="primary">GOT IT</button>
  </div>
</div>
