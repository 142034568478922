import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cl-dialog-save-confirm',
  templateUrl: './dialog-save-confirm.component.html',
  styleUrls: ['./dialog-save-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogSaveConfirmComponent implements OnInit {
  constructor(private readonly dialogRef: MatDialogRef<DialogSaveConfirmComponent>) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit(value: string) {
    this.dialogRef.close({ res: value });
  }
}
