import * as pannellum from 'node_modules/pannellum/build/pannellum.js';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CemeteryService } from 'src/app/core/cemetery.service';
import { CemeteryInfo } from 'src/app/interfaces/cemetery-info';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { PanoramaServiceService } from 'src/app/core/panorama-service.service';
import {
  OnInit,
  Component,
  ViewChild,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';

declare const pannellum: any;

@Component({
  selector: 'cl-panorama-view',
  templateUrl: './panorama-view.component.html',
  styleUrls: ['./panorama-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanoramaViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('miniMap') miniMap: ElementRef;
  destroy$: Subject<void> = new Subject<void>();
  imageLocation: string;
  idImage: string;
  showMap = false;
  panorama: any;
  cemeteryName: string;
  currentLocation: string;
  cemeteryInfo: CemeteryInfo;
  dataPano: any;
  cemeteriesUrl360: string[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private cemeteryService: CemeteryService,
    private panoramaServiceService: PanoramaServiceService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private routerUtilsService: RouterUtilsService
  ) {
    this.cemeteriesUrl360 = typeof environment.CEMETERIES_URL_360 !== 'string' ? environment.CEMETERIES_URL_360 : [];
    this.cemeteryName = this.activatedRoute.snapshot.paramMap.get('cemeteryName');
    this.setImageLocationFromUrl();
    this.getDataPanorama();
  }

  ngOnInit(): void {
    combineLatest([this.cemeteryService.getCemetery(this.cemeteryName), this.activatedRoute.paramMap])
      .pipe(
        tap(([cemetery, result]) => {
          if (this.panorama) {
            this.panorama.destroy();
          }

          this.cemeteryInfo = cemetery;
          this.idImage = result.get('idImage');
          this.currentLocation = result.get('geoLocation');
          this.updatePanoramaView();
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngAfterViewInit() {}

  updatePanoramaView() {
    this.panorama = pannellum.viewer('panoramaContainer', {
      type: 'equirectangular',
      autoLoad: true,
      compass: true,
      showControls: false,
      panorama: `${this.imageLocation}/${this.idImage}`,
      author: 'Chronicle Copyright © 2024.',
      // hotSpotDebug: true,
      hotSpots: [],
    });

    this.panorama.on('load', () => {
      if (document.getElementById('myCustomLoader')) {
        document.getElementById('myCustomLoader').style.display = 'none';
      }
    });

    const currentLocationCoords = this.currentLocation.split(',').map(Number);
    const filteredDataPano = this.dataPano.filter(item => {
      if (item.id === this.idImage) {
        return false;
      }
      const itemCoords = item.coordinates;
      const distance = this.calculateDistance(currentLocationCoords[0], currentLocationCoords[1], itemCoords[0], itemCoords[1]);
      return distance <= 1.5;
    });

    if (filteredDataPano.length) {
      filteredDataPano.forEach(dataPano => {
        const bearing = this.calculateBearing(
          currentLocationCoords[0],
          currentLocationCoords[1],
          dataPano.coordinates[0],
          dataPano.coordinates[1]
        );
        this.panorama.addHotSpot({
          pitch: -22,
          yaw: bearing,
          cssClass: 'custom-hotspot',
          createTooltipFunc: (hotSpotDiv, args) => {
            hotSpotDiv.classList.add('custom-tooltip');
            hotSpotDiv.innerHTML = `<img class="arrow-hotspot" src="../../../assets/images/up_arrow_white.svg">`;
          },
          clickHandlerFunc: evt => {
            const path = this.routerUtilsService.getPath(this.cemeteryName, 'panorama', dataPano.id, dataPano.coordinates.join(','));
            this.router.navigate(path);
          },
        });
      });
    }
  }

  getDataPanorama() {
    this.panoramaServiceService
      .getJsonPanorama(this.cemeteryName)
      .pipe(
        tap(list => {
          this.dataPano = list;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  zoomIn() {
    this.panorama.setHfov(this.panorama.getHfov() - 10);
  }

  zoomOut() {
    this.panorama.setHfov(this.panorama.getHfov() + 10);
  }

  toggleFullscreen() {
    this.panorama.toggleFullscreen();
  }

  backToCemetery() {
    const path = this.routerUtilsService.getPath(this.cemeteryName);
    this.router.navigate(path, {
      queryParams: { location: this.currentLocation },
    });
  }

  setVisibilityMap() {
    this.showMap = !this.showMap;
    this.miniMap.nativeElement.style.width = this.showMap ? '220px' : '45px';
    this.miniMap.nativeElement.style.height = this.showMap ? '220px' : '68px';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.panorama) {
      this.panorama.destroy();
    }
  }

  private calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371e3; // Radius of the earth in meters
    const φ1 = lat1 * (Math.PI / 180); // φ, λ in radians
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = (lat2 - lat1) * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // Distance in meters
    return d;
  }

  private calculateBearing(startLat: number, startLng: number, destLat: number, destLng: number): number {
    const degreesToRadians = (degrees: number): number => {
      return degrees * (Math.PI / 180);
    };

    const radiansToDegrees = (radians: number): number => {
      return radians * (180 / Math.PI);
    };
    const startLatRad = degreesToRadians(startLat);
    const startLngRad = degreesToRadians(startLng);
    const destLatRad = degreesToRadians(destLat);
    const destLngRad = degreesToRadians(destLng);

    const y = Math.sin(destLngRad - startLngRad) * Math.cos(destLatRad);
    const x =
      Math.cos(startLatRad) * Math.sin(destLatRad) - Math.sin(startLatRad) * Math.cos(destLatRad) * Math.cos(destLngRad - startLngRad);
    let bearing = radiansToDegrees(Math.atan2(y, x));

    bearing = (bearing + 360) % 360; // Normalize the bearing to be between 0 and 360 degrees
    return bearing;
  }

  private setImageLocationFromUrl() {
    const currentUrl = this.router.url.split('/');
    const cemeteryUrl = currentUrl.find(segment => this.cemeteriesUrl360.includes(segment));

    if (cemeteryUrl) {
      this.imageLocation = `${environment.PATH_PANORAMA}/${cemeteryUrl}/panorama`;
    }
  }
}
