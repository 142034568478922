import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cl-event-loading-skeleton',
  templateUrl: './event-loading-skeleton.component.html',
  styleUrls: ['./event-loading-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventLoadingSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
