<ng-container [formGroup]="group">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input matInput [matDatepicker]="datepicker" formControlName="{{ controlName }}" [max]="maxDate" [min]="minDate" />
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-icon matSuffix class="icon-flip" (click)="onToggleDatePickerMode()">swap_vertical_circle</mat-icon>
    <mat-datepicker #datepicker startView="multi-year" panelClass="year-picker"> </mat-datepicker>
    <mat-hint *ngIf="required">required*</mat-hint>
  </mat-form-field>
</ng-container>
