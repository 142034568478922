<div class="wrapper-form-filter">
  <div class="title">Filter</div>
  <div class="select-type-plot">
    <mat-form-field appearance="fill">
      <mat-label>Plot type</mat-label>
      <mat-select [(ngModel)]="selectedStatus">
        <mat-option value="Monumental">Monumental</mat-option>
        <mat-option *ngFor="let type of plotTypes | keyvalue" [value]="type.key">{{ type.value }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="filter-price">
    <div class="text-price">Price ($)</div>
    <div class="wrapper-slider-price">
      <div class="wrapper-input-price">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input type="number" [(ngModel)]="minValue" matInput />
        </mat-form-field>
        <div class="divider">-</div>
        <mat-form-field>
          <mat-label>To</mat-label>
          <input type="number" [(ngModel)]="maxValue" matInput />
        </mat-form-field>
      </div>
      <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
    </div>
  </div>

  <div class="button-wrapper">
    <button (click)="clearAllFilter()" class="clear-button" mat-button>CLEAR ALL</button>
    <button mat-raised-button color="primary" (click)="eventSelection()">DONE</button>
  </div>
</div>
