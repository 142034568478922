import * as Joi from 'joi';

export const regionalSettingsValidator = Joi.object({
  cemetery: Joi.string(),
  cemeteries: Joi.string(),
  section: Joi.string(),
  sections: Joi.string(),
  row: Joi.string(),
  rows: Joi.string(),
  plot: Joi.string(),
  plots: Joi.string(),
  date_format: Joi.string(),
});
