import { Pipe, PipeTransform } from '@angular/core';
import { Relationship } from '../enums/relationship.enum';

export interface RelationshipArray {
  key: string;
  value: string;
}

export type RelationshipMember = keyof typeof Relationship;

@Pipe({
  name: 'sortRelationshipsToLast'
})

export class SortRelationshipsToLastPipe implements PipeTransform {

  transform(value: RelationshipArray[], key: RelationshipMember): RelationshipArray[] {
    return value.sort((a, b) => (a.key === key ? 1 : b.key === key ? -1 : 0));
  }

}
