import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AddressPipe } from './address.pipe';
import { CapsLockDirective } from './caps-lock.directive';
import { SwiperComponent } from './swiper/swiper.component';
import { PersonsNamePipe } from './persons-name.pipe';
import { DateIsoToJsPipe } from './date-iso-to-js.pipe';
import { TimeProcessorPipe } from './time-processor-pipe';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SliderComponent } from './slider/slider.component';
import { SliderDirective } from './slider/slider.directive';
import { StatusesComponent } from './statuses/statuses.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SearchPlotItemComponent } from './search-plot-item/search-plot-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MisclickDirective } from './misclick.directive';
import { SearchRecordItemComponent } from './search-record-item/search-record-item.component';
import { SearchPlotsRecordsAnnotationComponent } from './search-plots-records-annotation/search-plots-records-annotation.component';
import { SearchAutocompleteNothingFoundComponent } from './search-autocomplete-nothing-found/search-autocomplete-nothing-found.component';
import { MatButtonModule } from '@angular/material/button';
import { NamePipe } from './name.pipe';
import { MatTableModule } from '@angular/material/table';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchResultNothingFoundComponent } from './search-result-nothing-found/search-result-nothing-found.component';
import { SelectedFiltersComponent } from './selected-filters/selected-filters.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterWithoutStatusesComponent } from './filter-without-statuses/filter-without-statuses.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FilterWithStatusesComponent } from './filter-with-statuses/filter-with-statuses.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatCalendarPickerComponent } from './mat-calendar-picker/mat-calendar-picker.component';
import { SearchCemeteriesAnnotationComponent } from './search-cemeteries-annotation/search-cemeteries-annotation.component';
import { SearchCemeteryItemComponent } from './search-cemetery-item/search-cemetery-item.component';
import { AllPlotsComponent } from './all-plots/all-plots.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DragDirective } from './drag.directive';
import { PlotDetailsPublicComponent } from './plot-details-public/plot-details-public.component';
import { SharedIntermentItemComponent } from './plot-details-public/interment-item/interment-item.component';
import { PasswordValidationComponent } from './password-validation/password-validation.component';
import { CemeterySearchResultsComponent } from './cemetery-search-results/cemetery-search-results.component';
import { RemoveAutofillDirective } from './remove-autofill.directive';
import { SnackbarErrorComponent } from './snackbar-error/snackbar-error.component';
import { SnackbarSuccessComponent } from './snackbar-success/snackbar-success.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { AccessCemeteryComponent } from './access-cemetery/access-cemetery.component';
import { ToolbarManageComponent } from './toolbar-manage/toolbar-manage.component';
import { SwiperModalComponent } from './swiper-modal/swiper-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CemeteryOnMaintenanceComponent } from '../public/cemetery-info/cemetery-on-maintenance/cemetery-on-maintenance.component';
import { PublicAllPlotsComponent } from '../public/public-all-plots/public-all-plots.component';
import { ReadMorePipe } from './read-more.pipe';
import { ReadMoreComponent } from './read-more/read-more.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { IntermentStoryViewComponent } from './interment-story-view/interment-story-view.component';
import { ZeroToDashPipe } from './zero-to-dash.pipe';
import { ReadMoreTextComponent } from './read-more-text/read-more-text.component';
import { NullDefaultValueDirective } from './null-default-value.directive';
import { DisableInputAutocompleteDirective } from './disable-input-autocomplete.directive';
import { DateInRegionalFormatPipe } from './date-in-regional-format.pipe';
import { PreventNegativeNumbersDirective } from './prevent-negative-numbers.directive';
import { HighlightDirective } from './highlight.directive';
import { LayoutModule } from '@angular/cdk/layout';
import { SidebarResponsiveComponent } from './sidebar-responsive/sidebar-responsive.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HammerModule } from '@angular/platform-browser';
import { MatYearPickerComponent } from './mat-year-picker/mat-year-picker.component';
import { WebcamModule } from 'ngx-webcam';
import { MatMenuModule } from '@angular/material/menu';
import { YearInRegionalFormatPipe } from './year-in-regional-format.pipe';
import { FeaturedComponent } from './featured/featured.component';
import { StoryComponent } from './story/story.component';
import { SnackbarCancelNavigationComponent } from './snackbar-cancel-navigation/snackbar-cancel-navigation.component';
import { QrCodeGeneratorComponent } from './qr-code-generator/qr-code-generator.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TimelineStoryViewComponent } from './timeline-story-view/timeline-story-view.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatSelectModule } from '@angular/material/select';
import { CardStoryLoadingSkeletonComponent } from './card-story-loading-skeleton/card-story-loading-skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ElementStripeComponent } from './element-stripe/element-stripe.component';
import { ModalViewStoryComponent } from './modal-view-story/modal-view-story.component';
import { StoryDialogConfirmationComponent } from './story-dialog-confirmation/story-dialog-confirmation.component';
import { ModalViewStorySkeletonComponent } from './modal-view-story-skeleton/modal-view-story-skeleton.component';
import { BannerImageStoryComponent } from './banner-image-story/banner-image-story.component';
import { LandscapeWarningComponent } from './landscape-warning/landscape-warning.component';
import { DialogSaveConfirmComponent } from './dialog-save-confirm/dialog-save-confirm.component';
import { RoiTransformTextPipe } from './roi-transform-text.pipe';
import { CopyClipboardTextComponent } from './copy-clipboard-text/copy-clipboard-text.component';
import { DisplayAgeComponent } from './display-age/display-age.component';
import { MainPromotionComponent } from './main-promotion/main-promotion.component';
import { SwiperDynamicComponent } from './swiper-dynamic/swiper-dynamic.component';
import { FilterSellPlotsComponent } from './filter-sell-plots/filter-sell-plots.component';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ModalNotificationRequestPlotComponent } from './modal-notification-request-plot/modal-notification-request-plot.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomFieldsModalComponent } from './custom-fields-modal/custom-fields-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { CircularLoadingComponent } from './circular-loading/circular-loading.component';
import { ListCertificateVariablesModalComponent } from './list-certificate-variables-modal/list-certificate-variables-modal.component';
import { AdvanceSearchResultComponent } from './advance-search-result/advance-search-result.component';
import { AdvanceSearchLoadingSkeletonComponent } from './advance-search-result/advance-search-loading-skeleton/advance-search-loading-skeleton.component';
import { AdvancedSearchFormComponent } from './advanced-search-form/advanced-search-form.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SortRelationshipsToLastPipe } from './sort-relationships-to-last.pipe';
import { OrderPipe } from './order.pipe';
import { PlotFormComponent } from './advanced-search-form/plot-form/plot-form.component';
import { IntermentFormComponent } from './advanced-search-form/interment-form/interment-form.component';
import { RoiFormComponent } from './advanced-search-form/roi-form/roi-form.component';
import { PersonFormComponent } from './advanced-search-form/person-form/person-form.component';
import { BusinessFormComponent } from './advanced-search-form/business-form/business-form.component';
import { CustomFieldInfoComponent } from './custom-field-info/custom-field-info.component';
import { LegendComingSoonComponent } from './legend-coming-soon/legend-coming-soon.component';
import { EventLoadingSkeletonComponent } from './upcoming-event-list/event-loading-skeleton/event-loading-skeleton.component';
import { UpcomingEventDetailDialogComponent } from './upcoming-event-list/upcoming-event-detail-dialog/upcoming-event-detail-dialog.component';
import { UpcomingEventListComponent } from './upcoming-event-list/upcoming-event-list.component';
import { SortCremationLocationPipe } from './sort-cremation-location.pipe';
import { NicheWallsListComponent } from './niche-walls-list/niche-walls-list.component';
import { SnackbarConfirmationComponent } from './snackbar-confirmation/snackbar-confirmation.component';
import { SortKeyValueToLastPipe } from './sort-key-value-to-last.pipe';
import { ToolbarOrganizationComponent } from './toolbar-organization/toolbar-organization.component';
import { AddAccessOrgModalComponent } from './add-access-org-modal/add-access-org-modal.component';
import { CustomFormFieldItemsComponent } from './custom-form-field-items/custom-form-field-items.component';
import { TruncateTextPipe } from './truncate-text.pipe';
import { UploadModule } from '../upload/upload.module';
import { ModalEditSignatureComponent } from './modal-edit-signature/modal-edit-signature.component';
import { RenderCustomFormComponent } from './render-custom-form/render-custom-form.component';
import { UploadWrapperSharedComponent } from './upload-wrapper-shared/upload-wrapper-shared.component';
import { UploadImageSharedComponent } from './upload-image-shared/upload-image-shared.component';
import { UploadDocumentSharedComponent } from './upload-document-shared/upload-document-shared.component';
import { FileUploaderSharedService } from './services/file-uploader-shared.service';
import { ImageUploaderSharedService } from './services/image-uploader-shared.service';
import { LoadingUploaderSharedService } from './services/loading-uploader-shared.service';
import { UploaderManagerFactorySharedService } from './services/uploader-manager-factory-shared.service';
import { DeleteFileDialogSharedComponent } from './delete-file-dialog-shared/delete-file-dialog-shared.component';
import { DeleteImageDialogSharedComponent } from './delete-image-dialog-shared/delete-image-dialog-shared.component';
import { FileSizeSharedPipe } from './file-size-shared.pipe';
import { FormPurchasePreNeedComponent } from './form-purchase-pre-need/form-purchase-pre-need.component';
import { TableLoadingSkeletonComponent } from './table-loading-skeleton/table-loading-skeleton.component';
import { SurveyFormComponent } from './survey-form/survey-form.component';
import { LegendMeasurementComponent } from './legend-measurement/legend-measurement.component';
import { FormGeneralPurposeComponent } from './form-general-purpose/form-general-purpose.component';
import { DialogRequestSentComponent } from './dialog-request-sent/dialog-request-sent.component';
import { ObserveLastChildDirective } from './observe-last-child.directive';
import { MapPanoramaComponent } from './map-panorama/map-panorama.component';
import { PanoramaViewComponent } from './panorama-view/panorama-view.component';
import { GlobeMapComponent } from './globe-map/globe-map.component';
import { LifeChroniclePageComponent } from './life-chronicle-page/life-chronicle-page.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ModalConfirmSelectPersonComponent } from './life-chronicle-page/modal-confirm-select-person/modal-confirm-select-person.component';
import { ModalExitLifeChronicleComponent } from './life-chronicle-page/modal-exit-life-chronicle/modal-exit-life-chronicle.component';
import { CemeteryListPublicComponent } from './cemetery-list-public/cemetery-list-public.component';
import { FilterSectionRowComponent } from './filter-section-row/filter-section-row.component';
import { SnackbarLocationComponent } from './snackbar-location/snackbar-location.component';
import { AddAccessOrgEditModalComponent } from './add-access-org-edit-modal/add-access-org-edit-modal.component';
import { SnackbarFreemiumComponent } from './snackbar-freemium/snackbar-freemium.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SupportBoardComponent } from './support-board/support-board.component';

@NgModule({
  declarations: [
    CapsLockDirective,
    AddressPipe,
    SwiperComponent,
    PersonsNamePipe,
    DateIsoToJsPipe,
    TimeProcessorPipe,
    SidebarComponent,
    SliderComponent,
    SliderDirective,
    StatusesComponent,
    NamePipe,
    SearchPlotItemComponent,
    MisclickDirective,
    SearchRecordItemComponent,
    SearchPlotsRecordsAnnotationComponent,
    SearchAutocompleteNothingFoundComponent,
    SearchResultsComponent,
    SearchResultNothingFoundComponent,
    SelectedFiltersComponent,
    FilterWithoutStatusesComponent,
    FilterWithStatusesComponent,
    MatCalendarPickerComponent,
    SearchCemeteriesAnnotationComponent,
    SearchCemeteryItemComponent,
    AllPlotsComponent,
    PlotDetailsPublicComponent,
    SharedIntermentItemComponent,
    DragDirective,
    PasswordValidationComponent,
    CemeterySearchResultsComponent,
    RemoveAutofillDirective,
    SnackbarErrorComponent,
    SnackbarSuccessComponent,
    AccessCemeteryComponent,
    ToolbarManageComponent,
    SwiperModalComponent,
    ConfirmationDialogComponent,
    CemeteryOnMaintenanceComponent,
    PublicAllPlotsComponent,
    ReadMorePipe,
    ZeroToDashPipe,
    ReadMoreComponent,
    IntermentStoryViewComponent,
    ReadMoreTextComponent,
    NullDefaultValueDirective,
    DisableInputAutocompleteDirective,
    PreventNegativeNumbersDirective,
    DateInRegionalFormatPipe,
    HighlightDirective,
    SidebarResponsiveComponent,
    MatYearPickerComponent,
    YearInRegionalFormatPipe,
    FeaturedComponent,
    StoryComponent,
    SnackbarCancelNavigationComponent,
    QrCodeGeneratorComponent,
    SafeHtmlPipe,
    TimelineStoryViewComponent,
    CardStoryLoadingSkeletonComponent,
    ElementStripeComponent,
    ModalViewStoryComponent,
    StoryDialogConfirmationComponent,
    ModalViewStorySkeletonComponent,
    BannerImageStoryComponent,
    LandscapeWarningComponent,
    DialogSaveConfirmComponent,
    RoiTransformTextPipe,
    CopyClipboardTextComponent,
    DisplayAgeComponent,
    MainPromotionComponent,
    SwiperDynamicComponent,
    FilterSellPlotsComponent,
    ModalNotificationRequestPlotComponent,
    CustomInputComponent,
    CustomFieldsModalComponent,
    CircularLoadingComponent,
    ListCertificateVariablesModalComponent,
    AdvanceSearchLoadingSkeletonComponent,
    AdvanceSearchResultComponent,
    AdvancedSearchFormComponent,
    SortRelationshipsToLastPipe,
    OrderPipe,
    PlotFormComponent,
    IntermentFormComponent,
    RoiFormComponent,
    PersonFormComponent,
    BusinessFormComponent,
    CustomFieldInfoComponent,
    LegendComingSoonComponent,
    UpcomingEventListComponent,
    EventLoadingSkeletonComponent,
    UpcomingEventDetailDialogComponent,
    NicheWallsListComponent,
    SortCremationLocationPipe,
    SnackbarConfirmationComponent,
    SortKeyValueToLastPipe,
    FileSizeSharedPipe,
    ToolbarOrganizationComponent,
    AddAccessOrgModalComponent,
    CustomFormFieldItemsComponent,
    TruncateTextPipe,
    ModalEditSignatureComponent,
    RenderCustomFormComponent,
    UploadWrapperSharedComponent,
    UploadImageSharedComponent,
    UploadDocumentSharedComponent,
    DeleteFileDialogSharedComponent,
    DeleteImageDialogSharedComponent,
    FormPurchasePreNeedComponent,
    TableLoadingSkeletonComponent,
    SurveyFormComponent,
    LegendMeasurementComponent,
    FormGeneralPurposeComponent,
    DialogRequestSentComponent,
    ObserveLastChildDirective,
    MapPanoramaComponent,
    PanoramaViewComponent,
    GlobeMapComponent,
    LifeChroniclePageComponent,
    ModalConfirmSelectPersonComponent,
    ModalExitLifeChronicleComponent,
    CemeteryListPublicComponent,
    FilterSectionRowComponent,
    SnackbarLocationComponent,
    AddAccessOrgEditModalComponent,
    SnackbarFreemiumComponent,
    NotFoundComponent,
    SupportBoardComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTableModule,
    MatDatepickerModule,
    MatInputModule,
    PerfectScrollbarModule,
    CdkTreeModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTooltipModule,
    ClipboardModule,
    MatExpansionModule,
    LayoutModule,
    DragDropModule,
    HammerModule,
    WebcamModule,
    MatMenuModule,
    QRCodeModule,
    MatCardModule,
    MatDividerModule,
    AngularEditorModule,
    MatSelectModule,
    NgxSkeletonLoaderModule,
    MatSliderModule,
    NgxSliderModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatNativeDateModule,
    InfiniteScrollModule,
    MatStepperModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonModule,
    CapsLockDirective,
    AddressPipe,
    SwiperComponent,
    PersonsNamePipe,
    DateIsoToJsPipe,
    TimeProcessorPipe,
    SidebarComponent,
    SliderComponent,
    SliderDirective,
    StatusesComponent,
    NamePipe,
    SearchPlotItemComponent,
    MisclickDirective,
    SearchRecordItemComponent,
    SearchPlotsRecordsAnnotationComponent,
    SearchAutocompleteNothingFoundComponent,
    SearchResultsComponent,
    SearchResultNothingFoundComponent,
    SelectedFiltersComponent,
    FilterWithoutStatusesComponent,
    FilterWithStatusesComponent,
    MatCalendarPickerComponent,
    SearchCemeteryItemComponent,
    AllPlotsComponent,
    PlotDetailsPublicComponent,
    SharedIntermentItemComponent,
    DragDirective,
    SearchCemeteriesAnnotationComponent,
    PasswordValidationComponent,
    CemeterySearchResultsComponent,
    RemoveAutofillDirective,
    AccessCemeteryComponent,
    ToolbarManageComponent,
    ConfirmationDialogComponent,
    CemeteryOnMaintenanceComponent,
    PublicAllPlotsComponent,
    ReadMorePipe,
    ReadMoreComponent,
    ZeroToDashPipe,
    IntermentStoryViewComponent,
    ReadMoreTextComponent,
    NullDefaultValueDirective,
    DisableInputAutocompleteDirective,
    PreventNegativeNumbersDirective,
    DateInRegionalFormatPipe,
    HighlightDirective,
    SidebarResponsiveComponent,
    DragDropModule,
    MatYearPickerComponent,
    YearInRegionalFormatPipe,
    QrCodeGeneratorComponent,
    SafeHtmlPipe,
    TimelineStoryViewComponent,
    LandscapeWarningComponent,
    RoiTransformTextPipe,
    CopyClipboardTextComponent,
    DisplayAgeComponent,
    SwiperDynamicComponent,
    NgxSliderModule,
    CustomInputComponent,
    CircularLoadingComponent,
    SortRelationshipsToLastPipe,
    UpcomingEventListComponent,
    EventLoadingSkeletonComponent,
    UpcomingEventDetailDialogComponent,
    SortCremationLocationPipe,
    AdvanceSearchLoadingSkeletonComponent,
    NicheWallsListComponent,
    SortKeyValueToLastPipe,
    ToolbarOrganizationComponent,
    CustomFormFieldItemsComponent,
    TruncateTextPipe,
    FileSizeSharedPipe,
    ToolbarOrganizationComponent,
    ModalEditSignatureComponent,
    RenderCustomFormComponent,
    UploadWrapperSharedComponent,
    UploadImageSharedComponent,
    UploadDocumentSharedComponent,
    TableLoadingSkeletonComponent,
    ObserveLastChildDirective,
    GlobeMapComponent,
    MapPanoramaComponent,
    LegendMeasurementComponent,
    FilterSectionRowComponent,
    NotFoundComponent,
    SupportBoardComponent,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        duration: 5000,
      },
    },
    {
      provide: MaskPipe,
      useClass: MaskPipe,
    },
    DatePipe,
    FileUploaderSharedService,
    ImageUploaderSharedService,
    LoadingUploaderSharedService,
    UploaderManagerFactorySharedService,
  ],
  entryComponents: [FilterWithoutStatusesComponent, FilterWithStatusesComponent],
})
export class SharedModule {}
