import { Injectable } from '@angular/core';
import { PlotFeatureString } from '../enums/plot-status-string.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, DomPortal } from '@angular/cdk/portal';
import { RouterUtilsService } from './router-utils.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LegendService {
  legendButton: any;
  legend: any;
  layerButton: any;
  legendComingSoon: OverlayRef;
  measurementLegend: OverlayRef;
  threeDLegend: any;
  legendCommingSoonValue$ = new BehaviorSubject<any>([]);
  threeDLegendClicked$ = new Subject<void>();

  constructor(
    private cdkOverlay: Overlay,
    private router: Router,
    private routerUtilsService: RouterUtilsService
  ) { }

  addLegendButton(leafletMap) {
    this.legendButton = L.control({ position: 'topright' });
    this.legendButton.onAdd = () => {
      const container = L.DomUtil.create('input');
      container.classList.add('button-legend');
      container.type = 'button';

      container.style.backgroundColor = 'white';
      container.style.backgroundSize = '30px 30px';
      container.style.width = '48px';
      container.style.height = '48px';
      container.style.position = 'absolute';
      container.style.right = '0';
      container.style.top = '4rem';
      container.style.zIndex = '99';
      container.style.border = '2px solid rgba(0, 0, 0, 0.2)';
      container.style.backgroundClip = 'padding-box';
      container.style.borderRadius = '5px';
      container.style.cursor = 'pointer';

      container.onclick = e => {
        if (!e.isTrusted) {
          return;
        }
        this.legendButtonAction();
      };

      return container;
    };
    this.legendButton.addTo(leafletMap);
  }

  addLegend(leafletMap) {
    this.legend = L.control({ position: 'topright' });
    this.legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'desc legend none');
      const labels = ['<div style="margin-bottom: 10px; color: #181429;"><strong>Plot Status</strong></div>'];
      const categories = Object.keys(PlotFeatureString);
      categories.forEach(category => {
        div.innerHTML += labels.push(
          `<div class="legend-label"><i style="background:${this.getColor(category)}; border: ${this.getBorderColor(category)}"></i>` +
          (category ? category : '+') +
          `</div>`
        );
      });
      div.innerHTML = labels.join(' ');
      return div;
    };
    this.legend.addTo(leafletMap);
  }

  addLayerButton(leafletMap) {
    this.layerButton = L.control({ position: 'topright' });
    this.layerButton.onAdd = () => {
      const container = L.DomUtil.create('input');
      container.classList.add('button-layer');
      container.type = 'button';

      container.style.backgroundColor = 'white';
      container.style.backgroundSize = '30px 30px';
      container.style.width = '48px';
      container.style.height = '48px';
      container.style.position = 'absolute';
      container.style.right = '0';
      container.style.top = '0';
      container.style.zIndex = '9999';
      container.style.border = '2px solid rgba(0, 0, 0, 0.2)';
      container.style.backgroundClip = 'padding-box';
      container.style.borderRadius = '5px';
      container.style.cursor = 'pointer';

      container.onclick = e => {
        if (!e.isTrusted) {
          return;
        }
        this.layerButtonAction();
      };

      return container;
    };
    this.layerButton.addTo(leafletMap);
  }

  legendButtonAction() {
    const a = document.getElementsByClassName('desc legend');
    const layer = document.getElementsByClassName('leaflet-control');
    const list = document.getElementsByClassName('leaflet-control-layers-list');
    layer[0].classList.remove('expanded-on');
    list[0]?.classList?.remove('expanded-list');
    if (a[0].classList.contains('none')) {
      a[0].classList.remove('none');
    } else {
      a[0].classList.add('none');
    }
  }

  layerButtonAction() {
    const a = document.getElementsByClassName('desc legend');
    a[0].classList.add('none');

    const layer = document.getElementsByClassName('leaflet-control');
    const list = document.getElementsByClassName('leaflet-control-layers-list');
    if (layer[0].classList.contains('expanded-on')) {
      layer[0].classList.remove('expanded-on');
      list[0]?.classList.remove('expanded-list');
    } else {
      layer[0].classList.add('expanded-on');
      list[0]?.classList?.add('expanded-list');
    }
  }

  removeLayerButton(leafletMap) {
    if (this.layerButton) {
      leafletMap.removeControl(this.layerButton);
    }
  }

  removeLegendButton(leafletMap) {
    if (this.legendButton) {
      leafletMap.removeControl(this.legendButton);
    }
  }

  removeLegend(leafletMap) {
    if (this.legend) {
      leafletMap.removeControl(this.legend);
    }
  }

  removeAll(leafletMap) {
    this.removeLayerButton(leafletMap);
    this.removeLegendButton(leafletMap);
    this.removeLegend(leafletMap);
  }

  addAll(leafletMap) {
    this.addLegend(leafletMap);
    this.addLegendButton(leafletMap);
    this.addLayerButton(leafletMap);
  }

  addComingSoonLegend(legendComponent: ComponentType<any>) {
    this.legendComingSoon = this.cdkOverlay.create({
      positionStrategy: this.cdkOverlay.position().global().top('90px').right('10px'),
      disposeOnNavigation: true,
    });
    this.legendComingSoon.attach(new ComponentPortal(legendComponent));
  }

  addMeasurementLegend(legendComponent: ComponentType<any>) {
    // attach the overlay to the overlay container
    this.measurementLegend = this.cdkOverlay.create({
      // place the overlay in the bottom right of the screen and automatically place it under exising overlays
      positionStrategy: this.cdkOverlay.position().global().top('218px').right('10px'),
      disposeOnNavigation: true,
    });
    this.measurementLegend.attach(new ComponentPortal(legendComponent));
  }

  removeMeasurementLegend() {
    if (this.measurementLegend) {
      this.measurementLegend.dispose();
    }
  }

  removeComingSoonLegend() {
    if (this.legendComingSoon) {
      this.legendComingSoon.dispose();
      this.legendCommingSoonValue$.next([]);
    }
  }

  addThreeDLegend(leafletMap): Observable<void> {
    if (document.querySelector('.three-d-map-btn')) {
      return this.threeDLegendClicked$.asObservable();
    }
    const legend = L.control({ position: 'topright' });
    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'three-d-map-btn');
      div.innerHTML = '3D';
      document.body.appendChild(div);

      const customCursor = document.createElement('img');
      customCursor.id = 'customCursor';
      document.body.appendChild(customCursor);

      div.onclick = () => {
        this.threeDLegendClicked$.next();
      };

      return div;
    };
    legend.addTo(leafletMap);
    return this.threeDLegendClicked$.asObservable();
  }

  removeThreeDLegend(leafletMap) {
    const element = document.querySelector('.three-d-map-btn');
    if (element) {
      element.remove();
    }
    if (this.threeDLegend) {
      leafletMap.removeControl(this.threeDLegend);
    }
  }

  private getColor(d: string) {
    return d === PlotFeatureString.Vacant
      ? 'rgba(39, 174, 96, 0.5)'
      : d === PlotFeatureString.Reserved
        ? 'rgba(242, 201, 76, 0.5)'
        : d === PlotFeatureString.Occupied
          ? 'rgba(47, 128, 237, 0.5)'
          : d === PlotFeatureString.Unavailable
            ? 'rgba(196, 196, 196, 0.5)'
            : d === PlotFeatureString.Featured
              ? 'rgba(0, 255, 255, 0.3)'
              : 'rgba(24, 20, 41, 0.5)';
  }
  private getBorderColor(d: string) {
    return d === PlotFeatureString.Vacant
      ? '2px solid rgba(39, 174, 96, 1)'
      : d === PlotFeatureString.Reserved
        ? '2px solid rgba(242, 201, 76, 1)'
        : d === PlotFeatureString.Occupied
          ? '2px solid rgba(47, 128, 237, 1)'
          : d === PlotFeatureString.Unavailable
            ? '2px solid rgba(196, 196, 196, 1)'
            : d === PlotFeatureString.Featured
              ? '2px solid rgba(0, 255, 255, 1)'
              : '2px solid rgba(24, 20, 41, 1)';
  }
}
