<cl-upload-wrapper-shared
  [files]="documents"
  [title]="title"
  [showTitle]="showTitle"
  [public]="public"
  [disabled]="disabled"
  [uploaderType]="uploaderType"
  [fileType]="acceptFileType"
  [maxSizePlaceholder]="maxSizePlaceholder"
  (inputFiles)="inputFiles($event)"
  (uploadedFiles)="uploadFiles($event)"
  (isLoadingDoc)="getLoadingDocEvent($event)"
>
  <div activeItem>
    <div class="upload-container">
      <button mat-button color="primary">
        <mat-icon svgIcon="custom_download"></mat-icon>
        <span class="text">{{ 'Select file' | uppercase }}</span>
      </button>

      <p class="additional-text">or drag it here</p>
      <p class="limitation-text">Maximum file size: {{ ((maxSizePlaceholder * 1024) | fileSizeShared) }}</p>
    </div>
  </div>

  <ng-template #filesList let-deleteFromServer="remove">
    <ul *ngIf="localFiles?.length > 0">
      <li *ngFor="let file of localFiles">
        <a class="item" [href]="file.url" target="_blank">
          <div class="icon">
            <mat-icon svgIcon="custom_document"></mat-icon>
          </div>
          <span class="name" [title]="uploaderType === 0 ? file.file_name : getFileNameFromURL(file.url)">
            {{ uploaderType === 0 ? file.file_name : getFileNameFromURL(file.url) }}
          </span>
          <mat-icon (click)="delete($event, file, deleteFromServer)" class="remove-icon" svgIcon="custom_close"></mat-icon>
        </a>
      </li>
    </ul>
  </ng-template>
</cl-upload-wrapper-shared>
