export enum ValidatorType {
  CemeteryInfo,
  PlotDetails,
  Organization,
  OrganizationDetails,
  UserInfo,
  UserDetails,
  Rois,
  Interment,
  Person,
  Business,
  Section,
  Plot,
  CemeteryEvent,
}
