import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { LegendService } from 'src/app/core/legend.service';
import { SidebarService } from 'src/app/core/sidebar.service';
import { CemeteryStatus } from 'src/app/enums/cemetery-status.enum';

@Component({
  selector: 'cl-legend-coming-soon',
  templateUrl: './legend-coming-soon.component.html',
  styleUrls: ['./legend-coming-soon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendComingSoonComponent {
  legendsForm = this.fb.group({
    publicCemeteries: [true],
    publicSoonCemeteries: [true],
    worldCemeteries: [true],
  });

  searchMobileActive$ = this.sidebarService.searchMobileActive$;

  mapLegendForms$ = combineLatest([
    this.fc.publicCemeteries.valueChanges.pipe(
      map(value => value && CemeteryStatus.Public),
      startWith(CemeteryStatus.Public)
    ),
    this.fc.publicSoonCemeteries.valueChanges.pipe(
      map(value => value && CemeteryStatus.ComingSoon),
      startWith(CemeteryStatus.ComingSoon)
    ),
    this.fc.worldCemeteries.valueChanges.pipe(
      map(value => value && CemeteryStatus.Visible),
      startWith(CemeteryStatus.Visible)
    ),
  ]).pipe(
    map(options => options.filter(option => !!option)),
    tap(result => this.legendService.legendCommingSoonValue$.next(result))
  );

  constructor(
    private fb: FormBuilder,
    private legendService: LegendService,
    private sidebarService: SidebarService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'custom_filter', src: '/assets/images/filter_icon.svg' }]);
  }

  get fc() {
    return this.legendsForm.controls;
  }
}
