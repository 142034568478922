<div class="content">
  <ng-container *ngIf="imageName !== undefined">
    <img [src]="'./assets/images/' + imageName" alt="" />
  </ng-container>

  <ng-container *ngIf="text">
    <div class="text-wrapper" [ngStyle]="wrapperStyle" *ngIf="text.length > lengthOfTextForShowMoreButton; else displayOneLineText">
      <p [ngClass]="textState">{{ text }} <span class="button" (click)="toggleText()">less</span></p>
      <span class="button" (click)="toggleText()" [ngStyle]="buttonStyle">more</span>
    </div>
  </ng-container>

  <ng-template #displayOneLineText>
    <p>{{ text }}</p>
  </ng-template>
</div>
