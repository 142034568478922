import * as Joi from 'joi';
import { PlotStatusString } from '../../enums/plot-status-string.enum';
import { personDataValidator } from './person-data-validator';

export const plotsListItemValidator = Joi.object({
  plot_id: Joi.string(),
  persons: Joi.array().items(personDataValidator),
  status: Joi.string().allow(
    PlotStatusString.Occupied,
    PlotStatusString.Reserved,
    PlotStatusString.Unavailable,
    PlotStatusString.Vacant,
    ''
  ),
  section: Joi.string().allow(''),
});
