import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { RequestManagementCemeteryService } from 'src/app/admin/admin-shared/request-management-cemetery.service';

@Component({
  selector: 'cl-dialog-request-sent',
  templateUrl: './dialog-request-sent.component.html',
  styleUrls: ['./dialog-request-sent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogRequestSentComponent implements OnInit {
  isLoadingPdf = false;
  destroy$ = new Subject<void>();
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      title: string;
      cemeteryInfo?: any;
      plotDetails?: any;
      result?: any;
    },
    private dialogRef: MatDialogRef<DialogRequestSentComponent>,
    private ref: ChangeDetectorRef,
    private requestManagementCemeteryService: RequestManagementCemeteryService
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }

  viewPdf() {
    this.isLoadingPdf = true;
    this.ref.detectChanges();
    const requestId = this.dialogData.result.request_id;
    this.requestManagementCemeteryService
      .getSubmissionPdfByRequestId(requestId)
      .pipe(
        tap((res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'pdf';
          link.click();
        }),
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoadingPdf = false;
          this.ref.detectChanges();
        })
      )
      .subscribe();
  }
}
