import { Inject, Injectable, Optional } from '@angular/core';
import { SHOULD_VALIDATE_SERVER_DATA } from './should-validate-server-data';
import { ValidatorType } from './validator-type.enum';
import { cemeteryInfoValidator } from './validate-functions/cemetery-info-validator';
import { plotDetailsValidator } from './validate-functions/plot-details-validator';
import { organizationValidator } from './validate-functions/organization-validator';
import { roisValidator } from './validate-functions/rois-validator';
import { userValidator } from './validate-functions/user-validator';
import { intermentValidator } from './validate-functions/interment-validator';
import { businessValidator } from './validate-functions/business-validator';
import { personValidator } from './validate-functions/person-validator';
import { sectionDataValidator } from './validate-functions/section-data';
import { plotValidator } from './validate-functions/plot-validator';
import { userDetailsValidator } from './validate-functions/user-details-validator';
import { organizationDetailsValidator } from './validate-functions/organization-details-validator';
import { cemeteryEventValidator } from './validate-functions/cemetery-event-validator';

@Injectable({
  providedIn: 'root',
})
export class ValidateResponseService {
  constructor(@Optional() @Inject(SHOULD_VALIDATE_SERVER_DATA) private readonly shouldValidate: boolean) {}

  validate<ServerResponseType>(type: ValidatorType, response: ServerResponseType): void {
    if (!this.shouldValidate) {
      return;
    }

    const validator = {
      [ValidatorType.CemeteryInfo]: cemeteryInfoValidator,
      [ValidatorType.PlotDetails]: plotDetailsValidator,
      [ValidatorType.Organization]: organizationValidator,
      [ValidatorType.OrganizationDetails]: organizationDetailsValidator,
      [ValidatorType.UserInfo]: userValidator,
      [ValidatorType.UserDetails]: userDetailsValidator,
      [ValidatorType.Rois]: roisValidator,
      [ValidatorType.Interment]: intermentValidator,
      [ValidatorType.Business]: businessValidator,
      [ValidatorType.Person]: personValidator,
      [ValidatorType.Section]: sectionDataValidator,
      [ValidatorType.Plot]: plotValidator,
      [ValidatorType.CemeteryEvent]: cemeteryEventValidator,
    }[type];

    if (!validator) {
      return;
    }

    const result = validator.validate(response, { abortEarly: false });

    if (result.error) {
      // nothing todo
    }
  }
}
