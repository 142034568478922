import * as Joi from 'joi';
import { roiForViewValidator } from './roi-for-view-validator';
import { PlotStatusString } from '../../enums/plot-status-string.enum';
import { imageValidator } from './image-validator';
import { intermentValidator } from './interment-validator';

export const plotValidator = Joi.object({
  id: Joi.number(),
  cemetery_unique_name: Joi.string(),
  plot_id: Joi.string(),
  plot_type: Joi.string().allow(null),
  section: Joi.string().allow(null),
  row: Joi.string().allow(null),
  plot_no: Joi.string().allow(null),
  width: Joi.number(),
  length: Joi.number(),
  status: Joi.string().valid(PlotStatusString.Occupied, PlotStatusString.Reserved, PlotStatusString.Unavailable, PlotStatusString.Vacant),
  total_capacity: Joi.number(),
  burials_capacity: Joi.object({
    total: Joi.number(),
    current: Joi.number(),
  }).allow(null),
  cremation_capacity: Joi.object({
    total: Joi.number(),
    current: Joi.number(),
  }).allow(null),
  entombment_capacity: Joi.object({
    total: Joi.number(),
    current: Joi.number(),
  }).allow(null),
  direction: Joi.string().allow(null),
  inscription: Joi.string().allow(null),
  note: Joi.string().allow(null),
  images: Joi.array().items(imageValidator),
  interments: Joi.array().items(intermentValidator),
  roi: roiForViewValidator,
});
