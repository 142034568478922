import { Image } from './image';
import { PlotFeatureString, PlotStatusString } from '../enums/plot-status-string.enum';

export interface FormAdvanceSearch {
  plot_id?: string;
  plot_type?: string;
  price_range?: string;
  status?: string[];
  fn?: string;
  ln?: string;
  dob?: string;
  dod?: string;
  doi?: string;
  application_date?: any;
  expiry_date?: any;
  payment_date?: any;
  right_of_interment?: string;
  roi_certificate?: string;
  term_of_right?: number | string;
  fee: number[];
  is_charged: boolean;
  age?: number[];
  rs?: boolean;
  feat_story?: boolean;
  avail_story?: boolean;
  limit?: number;
  offset?: number;
  user_cem_ids?: number[];
  interment_number?: string;
  row?: string;
  section?: string;
  plot_number?: string;
}

export interface Counter {
  cemetery_name: string;
  total: number;
}

export interface SectionCounter {
  section_name: string;
  cemetery_name: string;
  total_section: number;
}

export interface Person {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  date_of_death: string;
  age: number;
  returned_serviceman: boolean;
  is_featured: boolean;
  story: boolean;
  is_admin: boolean;
}

export interface Data {
  plot_id: string;
  cem_name: string;
  persons: Person[];
  status: string;
  section: string;
  price: number;
  images: Image[];
  coordinates: any;
}

export interface Page {
  total_data: number;
  total_plot: number;
  total_page: number;
  limit: number;
  page: number;
}

export interface AdvanceSearchResult {
  counter: Counter[];
  section_counter: SectionCounter[];
  data: Data[];
  paginate: Page;
}

export interface OptionValue {
  value: boolean;
  viewValue: string;
  name: string;
}

export const optionsValue: OptionValue[] = [
  { value: false, viewValue: 'Returned Service Person', name: 'rs' },
  { value: false, viewValue: 'Official Story', name: 'feat_story' },
  { value: false, viewValue: 'Life Chronicle Online Memorial', name: 'avail_story' },
];

export const statuses = [
  {
    value: PlotStatusString['For Sale'],
    checked: false,
  },
  {
    value: PlotStatusString.Vacant,
    checked: false,
  },
  {
    value: PlotStatusString.Reserved,
    checked: false,
  },
  {
    value: PlotStatusString.Occupied,
    checked: false,
  },
  {
    value: PlotFeatureString.Featured,
    checked: false,
  },
];

export const PersonForm = [
  {
    value: 'ROIApplicant',
    viewValue: 'ROI Applicant',
  },
  {
    value: 'ROIHolder',
    viewValue: 'ROI Holder',
  },
  {
    value: 'IntermentApplicant',
    viewValue: 'Interment Applicant',
  },
  {
    value: 'Nextofkin',
    viewValue: 'Next of Kin',
  },
];

export const BusinessForm = [
  {
    value: 'FuneralDirector',
    viewValue: 'Funeral Director',
  },
  {
    value: 'FuneralMinister',
    viewValue: 'Funeral Minister',
  },
];
