import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RegionalSettings } from '../../../interfaces/regional-settings';
import { RegionalSettingsService } from '../../../core/regional-settings.service';

@Component({
  selector: 'cl-cemetery-on-maintenance',
  templateUrl: './cemetery-on-maintenance.component.html',
  styleUrls: ['./cemetery-on-maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CemeteryOnMaintenanceComponent {
  regionalSettings: RegionalSettings;

  constructor(private regionalSettingsService: RegionalSettingsService) {
    this.regionalSettings = regionalSettingsService.getRegionalSettingsForCemeteryFromLocalStorage();
  }
}
