import { Injectable, OnDestroy } from '@angular/core';
import * as turf from '@turf/turf';
import { CemeteryInfo } from '../interfaces/cemetery-info';
import { PlotDetails } from '../interfaces/plot-details';
import { SnackbarNotificationService } from './snackbar-notification.service';
import { MobileResponsiveService } from './mobile-responsive.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarkerLocateService implements OnDestroy {
  private leafletMap: any;
  private cemeteryPoints: any;
  private markerLocation: any;
  private isDirection: boolean;
  private controlLocation: any;
  private currentLocation: any;
  private lineDestination: any;
  private controlDirection: any;
  private destinationPoint: any;
  private markerDestination: any;
  private automaticLine = true;
  private isMobile: boolean;
  private destroy$ = new Subject<void>();
  graveDestination: any;

  constructor(private snackBarNotificationService: SnackbarNotificationService, private mobileResponsiveService: MobileResponsiveService) {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.isMobile = res;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  init(leafletMap: any) {
    try {
      this.leafletMap.stopLocate();
    } catch (error) { }

    this.leafletMap = leafletMap
      .locate({ watch: true, timeout: 1000, enableHighAccuracy: true })
      .on('locationfound', (e: any) => this.onLocationFound(leafletMap, e))
      .on('locationerror', (e: any) => {
        // skip when code is 3 (Geolocation error: Timeout expired.)
        if (e.code !== 3) {
          // TODO: fix warning message "Circular dependency detected"
          if (this.isMobile) {
            this.snackBarNotificationService.openErrorLocation(
              'Geolocation is blocked by this browser. To improve your experience, please enable location services. Go to your phone settings and activate it.'
            );
          } else {
            this.snackBarNotificationService.openErrorLocation(
              'Geolocation is blocked by this browser. Please enable to use this feature.'
            );
          }
        }
      })
      .on('dragend', (e: any) => {
        if (this.isDirection && this.graveDestination) {
          this.stopLocate(this.graveDestination);
        }
      })
      ;
  }

  addControlMyLocation(leafletMap: any) {
    L.Control.Location = L.Control.extend({
      options: { position: 'bottomright' },
      onAdd: map => {
        if (this.controlLocation) {
          map.removeControl(this.controlLocation);
        }

        this.controlLocation = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
        L.DomEvent.disableClickPropagation(this.controlLocation);
        L.DomEvent.addListener(this.controlLocation, 'click', L.DomEvent.stopPropagation)
          .addListener(this.controlLocation, 'click', L.DomEvent.preventDefault)
          .addListener(this.controlLocation, 'click', () => {
            this.setMyLocation(leafletMap);
          });
        L.DomEvent.disableScrollPropagation(this.controlLocation);

        const controlUI = L.DomUtil.create('a', 'leaflet-location', this.controlLocation);
        controlUI.title = 'Current location';
        controlUI.href = '#';

        return this.controlLocation;
      },
      onRemove: () => {
        L.DomEvent.off(this.controlLocation);
      },
    });

    const controlLocation = new L.Control.Location();
    controlLocation.addTo(leafletMap);
  }

  addControlDirection(leafletMap: any, cemeteryCoordinates: any, destination: any) {
    if (this.currentLocation) {
      this.destinationPoint = destination;
      this.cemeteryPoints = cemeteryCoordinates;

      if (!this.isInsideCemetery(cemeteryCoordinates)) {
        this.reset(leafletMap);
        return;
      }

      L.Control.Direction = L.Control.extend({
        options: { position: 'bottomright' },
        onAdd: map => {
          if (this.controlDirection) {
            map.removeControl(this.controlDirection);
          }

          this.controlDirection = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
          L.DomEvent.disableClickPropagation(this.controlDirection);
          L.DomEvent.addListener(this.controlDirection, 'click', L.DomEvent.stopPropagation)
            .addListener(this.controlDirection, 'click', L.DomEvent.preventDefault)
            .addListener(this.controlDirection, 'click', () => {
              if (!this.isDirection) {
                this.startLocate(destination);
              } else {
                this.stopLocate(destination);
              }
            });
          L.DomEvent.disableScrollPropagation(this.controlDirection);

          const controlUI = L.DomUtil.create('a', !this.isDirection ? 'leaflet-compass' : 'leaflet-cancel', this.controlDirection);
          controlUI.title = 'Control Direction';
          controlUI.href = '#';

          return this.controlDirection;
        },
        onRemove: () => {
          L.DomEvent.off(this.controlDirection);
        },
      });

      const controlDirection = new L.Control.Direction();
      controlDirection.addTo(leafletMap);
    }
  }

  updateLineDirection(leafletMap: any, destination: any) {
    if (this.controlDirection && this.isDirection) {
      this.addMarkerDestination(leafletMap, destination);
      this.addLineDestination(leafletMap, destination);
    }
  }

  getIsDirection() {
    return this.isDirection;
  }

  reset(leafletMap: any) {
    // this.isDirection = false;

    if (this.controlDirection) {
      leafletMap.removeControl(this.controlDirection);
    }

    if (this.markerDestination) {
      leafletMap.removeLayer(this.markerDestination);
    }

    if (this.lineDestination) {
      leafletMap.removeLayer(this.lineDestination);
    }
  }

  getAutomaticLine() {
    return this.automaticLine;
  }

  stopLocate(destination: any) {
    if (this.leafletMap && this.isDirection) {
      const destinationCenters = L.polygon(destination).getBounds().getCenter();
      const destinationPoints = [destinationCenters.lng, destinationCenters.lat];

      if (this.markerDestination) {
        this.leafletMap.removeLayer(this.markerDestination);
      }

      if (this.lineDestination) {
        this.leafletMap.removeLayer(this.lineDestination);
      }

      this.leafletMap.stopLocate();
      this.leafletMap.fire('locatedeactivate');
      this.leafletMap.off('locationfound');
      this.leafletMap.off('locationerror');
      this.leafletMap.flyTo(destinationPoints);
      this.isDirection = false;
      this.graveDestination = null;

      // locate without line direction
      this.leafletMap.locate({ watch: true, timeout: 1000, enableHighAccuracy: true });
      this.leafletMap.fire('locateactivate');
      this.leafletMap.on('locationfound', (e: any) => this.onLocationFound(this.leafletMap, e));
      this.leafletMap.on('locationerror', () => { });

      // toggle button direction
      this.addControlDirection(this.leafletMap, this.cemeteryPoints, destination);
    }
  }

  showLineDirection(cemetery: CemeteryInfo, plot: PlotDetails) {
    if (this.automaticLine && this.isInsideCemetery(cemetery.bounds.coordinates)) {
      this.destinationPoint = plot.coordinates.coordinates;
      this.cemeteryPoints = cemetery.bounds.coordinates;
      this.startLocate(plot.coordinates.coordinates);
      this.automaticLine = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private startLocate(destination: any) {
    if (this.leafletMap && !this.isDirection) {
      this.addLineDestination(this.leafletMap, destination);
      this.addMarkerDestination(this.leafletMap, destination);

      this.leafletMap.stopLocate();
      this.leafletMap.locate({ watch: true, timeout: 1000, enableHighAccuracy: true });
      this.leafletMap.fire('locateactivate');
      this.leafletMap.on('locationfound', (e: any) => this.onLocationFound(this.leafletMap, e));
      this.leafletMap.on('locationerror', () => { });
      this.isDirection = true;
      this.graveDestination = destination;

      // toggle button direction
      this.addControlDirection(this.leafletMap, this.cemeteryPoints, destination);

      // fly to current position
      const zoom = this.leafletMap.getZoom();
      const zoomLevel = zoom >= 21 ? 21 : zoom;

      this.leafletMap.flyTo(this.currentLocation, zoomLevel);

    }
  }

  private onLocationFound(leafletMap: any, e: any) {
    // if (this.currentLocation) {
    //   const newLat = (this.currentLocation.lat + e.latlng.lat) / 2;
    //   const newLng = (this.currentLocation.lng + e.latlng.lng) / 2;
    //   this.currentLocation = L.latLng(newLat, newLng);
    //   // this.currentLocation = e.latlng;
    // } else {
    // }
    //   this.currentLocation = e.latlng;
    // }

    this.currentLocation = e.latlng;

    if (this.isDirection && this.graveDestination) {
      leafletMap.setView(this.currentLocation, leafletMap.getZoom());
    }

    this.addMarkerLocation(leafletMap, this.currentLocation);

    // realtime line destination
    if (this.lineDestination && this.destinationPoint && this.isDirection) {
      this.addLineDestination(leafletMap, this.destinationPoint);
    }

    // check inside cemetery
    if (this.cemeteryPoints && !this.isInsideCemetery(this.cemeteryPoints)) {
      this.onOutsideCemetery(leafletMap);
    }
  }

  private onOutsideCemetery(leafletMap: any) {
    this.reset(leafletMap);
    this.isDirection = false;
  }

  private setMyLocation(leafletMap: any) {
    if (this.currentLocation) {
      const currentZoom = leafletMap.getZoom();
      const zoomLevel = currentZoom < 21 ? currentZoom : 22;

      leafletMap.flyTo(this.currentLocation, zoomLevel);
    } else {
      this.init(leafletMap);
    }
  }

  private isInsideCemetery(cemeteryCoordinates: any) {
    if (this.currentLocation) {
      const currentLocationPoints = [this.currentLocation.lng, this.currentLocation.lat];
      const polygonCemetery = turf.polygon(cemeteryCoordinates);
      return turf.inside(currentLocationPoints, polygonCemetery);
    }
  }

  private addMarkerLocation(leafletMap: any, location: any) {
    if (this.markerLocation) {
      leafletMap.removeLayer(this.markerLocation);
    }

    const icon = {
      icon: L.divIcon({
        iconSize: [10, 10],
        className: 'marker-pulse',
      }),
    };

    this.markerLocation = L.marker(location, icon).addTo(leafletMap);
  }

  private addLineDestination(leafletMap: any, destination: any) {
    if (this.currentLocation) {
      if (this.lineDestination) {
        leafletMap.removeLayer(this.lineDestination);
      }

      const currentLocationPoints = [this.currentLocation.lat, this.currentLocation.lng];
      const destinationCenters = L.polygon(destination).getBounds().getCenter();
      const destinationPoints = [destinationCenters.lng, destinationCenters.lat];
      const polylinePoints = [currentLocationPoints, destinationPoints];

      this.lineDestination = L.polyline(polylinePoints, { className: 'leaflet-line-animation' }).addTo(leafletMap);
    }
  }

  private addMarkerDestination(leafletMap: any, destination: any) {
    if (this.currentLocation) {
      if (this.markerDestination) {
        leafletMap.removeLayer(this.markerDestination);
      }

      const destinationCenters = L.polygon(destination).getBounds().getCenter();
      const destinationPoints = [destinationCenters.lng, destinationCenters.lat];
      const icon = new L.Icon({
        iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        // className: 'leaflet-marker-animation',
      });

      this.markerDestination = L.marker(destinationPoints, { icon }).addTo(leafletMap);
    }
  }

  private macSafariOrIOS(navigator: Navigator) {
    let mark = false;

    if (
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Mac') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1
    ) {
      mark = true;
      return mark;
    }

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      mark = true;

      return mark;
    }

    return mark;
  }

  // private ControlCustom(options) {
  //   L.Control.Custom = L.Control.extend({
  //     options: {
  //       position: 'bottomright',
  //       id: '',
  //       title: '',
  //       classes: '',
  //       content: '',
  //       style: {},
  //       events: {},
  //     },
  //     onAdd: map => {
  //       if (this.controlDiv) {
  //         map.removeControl(this.controlDiv);
  //       }

  //       this.controlDiv = L.DomUtil.create('div');
  //       this.controlDiv.id = options.id;
  //       this.controlDiv.title = options.title;
  //       this.controlDiv.className = options.classes;
  //       this.controlDiv.innerHTML = options.content;

  //       for (var option in options.style) {
  //         this.controlDiv.style[option] = options.style[option];
  //       }

  //       L.DomEvent.disableClickPropagation(this.controlDiv);
  //       L.DomEvent.on(this.controlDiv, 'contextmenu', ev => {
  //         L.DomEvent.stopPropagation(ev);
  //       });
  //       L.DomEvent.disableScrollPropagation(this.controlDiv);

  //       for (var event in options.events) {
  //         L.DomEvent.on(this.controlDiv, event, options.events[event], this.controlDiv);
  //       }

  //       return this.controlDiv;
  //     },
  //     onRemove: () => {
  //       for (var event in options.events) {
  //         L.DomEvent.off(this.controlDiv, event, options.events[event], this.controlDiv);
  //       }
  //     },
  //   });

  //   return new L.Control.Custom(options);
  // }
}
