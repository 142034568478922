<div class="story-wrapper">
  <div class="story-header">
    <div class="story-cemetery-name">
      <ngx-skeleton-loader
        [theme]="{
          width: '140px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="story-interment-id">
      <ngx-skeleton-loader
        [theme]="{
          width: '50px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <div class="story-title-header">
    <div class="story-title">
      <ngx-skeleton-loader
        [theme]="{
          width: '150px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="story-author">
      <ngx-skeleton-loader
        [theme]="{
          width: '120px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="story-approved-at">
      <ngx-skeleton-loader
        [theme]="{
          width: '110px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-story">
    <ngx-skeleton-loader
      count="5"
      [theme]="{
        width: '300px'
      }"
    ></ngx-skeleton-loader>
  </div>

  <div class="image-story">
    <ngx-skeleton-loader
      [theme]="{
        height: '90px',
        width: '90px'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>
