import * as moment from 'moment';
import { DateFormats } from '../enums/date-formats.enum';

// allow only numbers and slash

export function isDateInputValid(value: string): boolean {
  return /^[0-9/]*$/.test(value);
}

export function isMyDateFormat(date: string, format: DateFormats): string {
  if (!date || date === '') {
    return 'Please choose a date.';
  }

  if (date.length !== 10) {
    return `Please input a string in the form of ${format}`;
  } else {
    if (!moment(date, format, true).isValid()) {
      return `Please input a string in the form of ${format}`;
    } else if (moment(date, format, true).isAfter()) {
      return `Please input date no later that today`;
    } else {
      return 'Date not valid';
    }
  }
}
