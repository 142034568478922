import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, Subject, from, of } from 'rxjs';
import { concatMap, toArray, tap, finalize, catchError, takeUntil, filter } from 'rxjs/operators';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { FileResponse } from 'src/app/interfaces/file-response';
import { UploaderFile } from 'src/app/interfaces/uploader-file';
import { convertHeic } from '../file-converter-utils';
import { Image } from '../../interfaces/image';
import { LoadingUploaderSharedService } from '../services/loading-uploader-shared.service';
import { UploaderManagerFactorySharedService } from '../services/uploader-manager-factory-shared.service';
import { UploaderTypeEnum } from 'src/app/enums/uploader-type.enum';

@Component({
  selector: 'cl-upload-wrapper-shared',
  templateUrl: './upload-wrapper-shared.component.html',
  styleUrls: ['./upload-wrapper-shared.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadWrapperSharedComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private uploaderService: UploaderFile<FileResponse | Image>;
  private localFiles: any[] = [];
  spinnerDiameter = 32;
  isOver$ = new Subject<boolean>();
  loaders$ = new BehaviorSubject<any>({});
  @Input() public;
  @Input() set files(files: any[]) {
    this.localFiles = files || [];
    this.inputFiles.emit(files);
  }
  get files() {
    return this.localFiles;
  }
  @Input() disabled = false;
  @Input() title: string;
  @Input() showTitle = true;
  @Input() uploaderType = UploaderTypeEnum.file;
  @Input() fileType = '*';
  @Input() maxSizePlaceholder = 8000;
  @Output() inputFiles = new EventEmitter<any[]>();
  @Output() uploadedFiles = new EventEmitter<any[]>();
  @Output() isLoadingDoc = new EventEmitter<boolean>();

  @ViewChild('file') inputFile: ElementRef;
  @ContentChild('filesList') templateRef: TemplateRef<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private uploaderManagerFactoryService: UploaderManagerFactorySharedService,
    public loadingService: LoadingUploaderSharedService,
    private snackBarNotificationService: SnackbarNotificationService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'custom_download', src: '/assets/images/download_icon.svg' }]);
  }

  ngOnInit() {
    this.uploaderService = this.uploaderManagerFactoryService.create(this.uploaderType);
  }

  isOver(isDraggedOver) {
    if (this.disabled) {
      return;
    }

    this.isOver$.next(isDraggedOver);
  }

  openFileLoad() {
    if (this.loadingService.isLoading || this.disabled) {
      return;
    }

    this.inputFile.nativeElement.click();
  }

  async uploadFiles(fileList: FileList) {
    if (this.loadingService.isLoading || this.disabled) {
      return;
    }

    this.loadingService.run();
    this.isLoadingDoc.emit(true);

    const filesPromise = Array.from(fileList).map(async file => {
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        return convertHeic(file, 'png');
      } else {
        return file;
      }
    });

    const newFiles = await Promise.all(filesPromise);

    if (this.public) {
      return from(newFiles)
        .pipe(
          filter((file: File) => {
            // check size
            if (file.size  > ((+this.maxSizePlaceholder) * 1024)) {
              this.openErrorMessage('File size is too large');
              return false;
            }
            return true;
          }),
          concatMap((file: File) => {
            return this.uploaderService.uploadFromPublic(file).pipe(
              this.loadingService.setLoaders('upload', this.loaders$),
            );
          }),
          toArray(),
          tap(files => {
            this.localFiles = this.files.concat(files);
            this.uploadedFiles.emit(this.files);
          }),
          finalize(() => {
            this.inputFile.nativeElement.value = '';
            this.loadingService.stop();
            this.isLoadingDoc.emit(false);
            this.changeDetectorRef.detectChanges();
          }),
          catchError(error => {
            if ((error.status === 400 || error.status === 415) && error.message) {
              this.openErrorMessage(error.error?.message);
            }

            return of(error);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }

    from(newFiles)
      .pipe(
        concatMap((file: File) => {
          return this.uploaderService.upload(file);
        }),
        toArray(),
        tap(files => {
          this.localFiles = this.files.concat(files);
          this.uploadedFiles.emit(this.files);
        }),
        finalize(() => {
          this.inputFile.nativeElement.value = '';
          this.loadingService.stop();
          this.isLoadingDoc.emit(false);
          this.changeDetectorRef.detectChanges();
        }),
        catchError(error => {
          if ((error.status === 400 || error.status === 415) && error.message) {
            this.openErrorMessage(error.error?.message);
          }

          return of(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  remove(file: { id: number }) {
    if (this.public) {
      this.files = this.files.filter(item => item.id !== file.id);
      this.uploaderService.removeFromPublic(file).subscribe();
      this.uploadedFiles.emit(this.files);
      return;
    }

    this.files = this.files.filter(item => item.id !== file.id);
    this.uploaderService.remove(file).subscribe();
    this.uploadedFiles.emit(this.files);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private openErrorMessage(errorMessage: string) {
    this.snackBarNotificationService.openErrorMessage(errorMessage);
  }
}
