import * as Joi from 'joi';

export const personDataValidator = Joi.object({
  age: Joi.string().allow(null),
  date_of_birth: Joi.date().iso().allow(null),
  date_of_death: Joi.date().iso().allow(null),
  first_name: Joi.string().allow(''),
  last_name: Joi.string().allow(''),
  returned_serviceman: Joi.boolean(),
});
