import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cl-modal-exit-life-chronicle',
  templateUrl: './modal-exit-life-chronicle.component.html',
  styleUrls: ['./modal-exit-life-chronicle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalExitLifeChronicleComponent implements OnInit {
  constructor(private readonly dialogRef: MatDialogRef<ModalExitLifeChronicleComponent>) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close(true);
  }

  onConfirm() {
    this.dialogRef.close(false);
  }
}
