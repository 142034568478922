<form [formGroup]="form">
  <div class="range-from" (click)="startPicker.open()">
    <div class="left">
      <input class="hidden" matInput [matDatepickerFilter]="isBeforeToday" [matDatepicker]="startPicker" formControlName="start" />
      <div>{{ form.get('start').value | date: 'mediumDate' }}</div>
    </div>
    <div class="right">
      <mat-datepicker-toggle disableRipple [tabIndex]="-1" matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </div>
  </div>
  <div class="divider"></div>
  <div class="range-to" (click)="endPicker.open()">
    <div class="left">
      <div>{{ form.get('end').value | date: 'mediumDate' }}</div>
      <input class="hidden" matInput [matDatepickerFilter]="isBeforeToday" [matDatepicker]="endPicker" formControlName="end" />
    </div>
    <div class="right">
      <mat-datepicker-toggle disableRipple [tabIndex]="-1" matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </div>
  </div>
</form>
