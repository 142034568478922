import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AdvanceTableServiceService } from 'src/app/core/advance-table-service.service';
export interface Section {
  section: string;
}

@Component({
  selector: 'cl-filter-section-row',
  templateUrl: './filter-section-row.component.html',
  styleUrls: ['./filter-section-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSectionRowComponent implements OnInit, OnChanges {
  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  filteredRowOptions: any[];
  filteredNumberOptions: any[];
  onChanges = new Subject<SimpleChanges>();
  isLoadingCemetery = false;
  @Input() sectionData;
  @Input() cemeteryId: any[];
  @Input() formGroup: FormGroup;
  @Input() sectionValue: any;
  @Input() rowValue: any;
  @Input() sectionLoading;
  @Input() fromAdvanceSearch;
  @Input() cemeteryValue: string;
  constructor(private ref: ChangeDetectorRef, private advanceTableService: AdvanceTableServiceService) {}

  ngOnInit(): void {
    this.onChanges
      .pipe(
        tap(changes => {
          if (changes.sectionData) {
            this.filteredOptions = this.formGroup.controls.section.valueChanges.pipe(
              startWith(''),
              map(value => {
                return typeof value === 'string' ? value : value;
              }),
              map(name => {
                return name ? this._filter(name) : this.sectionData.slice();
              })
            );
          }

          this.ref.detectChanges();
        })
      )
      .subscribe();

    if (this.cemeteryId) {
      this.filteredOptions = this.formGroup.controls.section.valueChanges.pipe(
        startWith(''),
        map(value => {
          return typeof value === 'string' ? value : value;
        }),
        map(name => {
          return name ? this._filter(name) : this.sectionData.slice();
        })
      );
    }

    if (!this.fromAdvanceSearch && this.cemeteryValue) {
      this.formGroup.controls.row.valueChanges
        .pipe(
          startWith(''),
          filter(text => text && text.length > 0),
          debounceTime(400),
          distinctUntilChanged(),
          tap(val => {
            return this.filterRow(val || '');
          })
        )
        .subscribe();

      this.formGroup.controls.plot_no.valueChanges
        .pipe(
          startWith(''),
          filter(text => text && text.length > 0),
          debounceTime(400),
          distinctUntilChanged(),
          tap(val => {
            return this.filterNumber(val || '');
          })
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }

  displayFn(user): string {
    return user ? user : '';
  }

  private _filter(name: string): Section[] {
    const filterValue = name.toLowerCase();

    return this.sectionData.filter(option => option.section?.toLowerCase().indexOf(filterValue) === 0);
  }

  private filterRow(val: string) {
    const section = this.formGroup.controls.section.value;

    const queryParams: any = {
      cem_id: this.cemeteryValue,
      search: val,
    };
    if (section) {
      queryParams.sectionId = section;
    }

    this.advanceTableService
      .getRowsOptions(queryParams)
      .pipe(
        tap((response: any) => {
          this.filteredRowOptions = response.data;
          this.ref.detectChanges();
        })
      )
      .subscribe();
  }

  private filterNumber(val: string) {
    const section = this.formGroup.controls.section.value;
    const row = this.formGroup.controls.row.value;

    const queryParams: any = {
      cem_id: this.cemeteryValue,
      search: val,
    };
    if (section) {
      queryParams.sectionId = section;
    }
    if (row) {
      queryParams.row = row;
    }

    this.advanceTableService
      .getNumberOptions(queryParams)
      .pipe(
        tap((response: any) => {
          this.filteredNumberOptions = response.data;
          this.ref.detectChanges();
        })
      )
      .subscribe();
  }
}
