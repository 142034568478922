<div class="plot-details">
  <ng-container *ngIf="cemetery.sell_story && isOccupiedStatus()">
    <!-- <div *ngIf="hideBanner" class="cta-stories-banner">
      <div class="icon-close">
        <mat-icon class="material-icons close-icon" (click)="bannerShow(false)" svgIcon="custom_shared_plot_details_close"></mat-icon>
      </div>
      <div class="banner-content">Do you want to share the legacy of this life?</div>
      <button (click)="onClickNewStory(true)" mat-raised-button mat-button-base mat-primary mat-stroked-button color="primary">
        WRITE A LIFE CHRONICLE
      </button>
    </div> -->
    <ng-container *ngIf="plotDetails.interments.length > 0">
      <ng-container *ngIf="!isLocked">
        <div class="story-banner" *ngIf="false">
          <div class="icon-close">
            <mat-icon class="material-icons close-icon" (click)="bannerShow(false)" svgIcon="custom_shared_plot_details_close"></mat-icon>
          </div>

          <div class="yellow-line"></div>
          <div class="content">
            <div class="text-content">Create a lasting tribute for your loved one</div>
            <button (click)="onClickNewStory(true)" mat-raised-button color="accent">CREATE A MEMORIAL</button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="wrapper-swiper" style="position: relative">
    <cl-swiper (previewIndex)="openPreview($event)" [slides]="plotDetails.images" [showInputImage]="false"></cl-swiper>
    <div class="close-default">
      <mat-icon class="material-icons close-icon" (click)="onBackToCemeteryInfo()" svgIcon="custom_shared_plot_details_close"></mat-icon>
    </div>
  </div>
  <div class="text-content">
    <div class="plot-details-header">
      <div class="plot-info">
        <p class="cemetery-name">{{ plotDetails.cemetery_info.name }}</p>
        <div [ngClass]="responsive$ ? 'wrapper-responsive' : 'wrapper'">
          <div class="plot-id_plot-status">
            <h3 class="plot-id">
              <div class="main-title">
                <span>{{ formatPlotId(plotDetails.plot_id) }}</span>
              </div>
              <span *ngIf="!isOccupiedStatus()" class="plot-status {{ getStylePlotStatus() | lowercase }}">
                {{ getPlotStatus() | uppercase }}
              </span>
            </h3>
          </div>

          <div class="interments-info">
            <div class="amounts_direction">
              <div class="total-capacity item-with-separator">
                <img class="icon" src="/assets/images/person_icon_grey.svg" alt="" />
                <span class="amount">
                  {{ plotDetails.interments.length | zeroToDash }} / {{ plotDetails.total_capacity | zeroToDash }}
                </span>

                <div class="separator"></div>
              </div>

              <div class="burial-capacity item-with-separator" *ngIf="isBurialsCapacity">
                <img class="icon" src="/assets/images/casket_interment_grey.svg" alt="" />
                <span class="amount">
                  {{ plotDetails.burials_capacity.current | zeroToDash }}
                  / {{ plotDetails.burials_capacity.total | zeroToDash }}
                </span>

                <div class="separator"></div>
              </div>

              <div *ngIf="isCremationCapacity" class="cremation-capacity item-with-separator">
                <img class="icon" src="/assets/images/cremation_urn.svg" alt="" />
                <span class="amount">
                  {{ plotDetails.cremation_capacity.current | zeroToDash }}
                  / {{ plotDetails.cremation_capacity.total | zeroToDash }}
                </span>

                <div class="separator"></div>
              </div>

              <div *ngIf="isEntombmentCapacity" class="entombment-capacity item-with-separator">
                <img class="icon" src="/assets/images/entombment_icon.svg" alt="" />
                <span class="amount">
                  {{ plotDetails.entombment_capacity.current | zeroToDash }}
                  / {{ plotDetails.entombment_capacity.total | zeroToDash }}
                </span>

                <div class="separator"></div>
              </div>

              <div *ngIf="plotDetails.direction !== null" class="item-with-separator">
                <span class="material-icons direction-icon">{{ directionData.icon }}</span>

                <div>{{ directionData.title }}</div>

                <div class="separator"></div>
              </div>

              <div *ngIf="plotDetails.plot_type !== null" class="item-with-separator">
                <span>{{ plotDetails.plot_type }}</span>

                <div class="separator"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="responsive$ ? 'share-responsive' : 'share-default'">
        <button class="plot-share" mat-button (click)="copyPlotDetailsLink()">
          <mat-icon class="material-icons-outlined share-icon" color="primary">share_outlined</mat-icon>
        </button>
      </div>
    </div>

    <div class="inscription" *ngIf="plotDetails.inscription !== null">
      <p class="content">
        <cl-read-more-text
          imageName="headstone_inscription_icon.svg"
          [lengthOfTextForShowMoreButton]="lengthOfSymbolsForShowMoreButton"
          [text]="plotDetails.inscription"
        ></cl-read-more-text>
      </p>
    </div>
    <div [ngClass]="responsive$ ? 'share_report-buttons-responsive' : 'share_report-buttons'">
      <ng-container *ngIf="listGeneralForm.length">
        <button class="mat-stroked-button mat-button-base mat-primary btn-service" [matMenuTriggerFor]="listForm">
          <mat-icon svgIcon="list"></mat-icon> 
            Requests
          <mat-icon svgIcon="arrow"></mat-icon> 
        </button>
        <mat-menu #listForm="matMenu">
          <div *ngFor="let list of listGeneralForm">
              <button mat-menu-item (click)="goToGeneralForm(list.id)">{{ list.title }}</button>
          </div>
        </mat-menu>
        <button mat-icon-button [disabled]="isReportDisabled$ | async" (click)="onCreatePlotLocationReport()">
          <mat-icon svgIcon="custom_pdf_report" class="pdf-icon"></mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="copyPlotDetailsLink()">
          <mat-icon class="material-icons-outlined share-icon" color="primary">share_outlined</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngIf="listGeneralForm.length === 0">
        <button mat-button [disabled]="isReportDisabled$ | async" class="plot-pdf-report" (click)="onCreatePlotLocationReport()">
        <mat-icon svgIcon="custom_pdf_report" class="pdf-icon"></mat-icon>
        <span class="pdf-text">PDF report</span>
      </button>

      <div class="separator"></div>

      <button class="plot-share" mat-button (click)="copyPlotDetailsLink()">
        <mat-icon class="material-icons-outlined share-icon" color="primary">share_outlined</mat-icon>
        <span class="share-text">Share</span>
      </button>
      </ng-container>
    </div>
    <ng-container *ngIf="!isLocked">
      <ng-container *ngIf="atNeedVisibility || preNeedVisibility">
        <div *ngIf="plotDetails.status === 'For Sale'" class="sell-plots-card-container">
        <div class="sell-plots-price" *ngIf="plotDetails.show_price === 1">${{ plotDetails.price }}</div>
        <div [ngClass]="plotDetails.show_price === 1 ? 'button-buy-plot' : 'button-buy-plot-center'">
          <!-- <button class="buy-plot" mat-flat-button color="primary" (click)="openFormBuyPlot()">
            <mat-icon svgIcon="custom_cart"></mat-icon>
            {{ 'Request to buy' | uppercase }}
          </button>  -->
          <button class="buy-plot" mat-flat-button color="primary" [matMenuTriggerFor]="requestToBuyMenu">
            <mat-icon svgIcon="custom_cart"></mat-icon>
            {{ 'Request to buy' | uppercase }}
          </button>
          <mat-menu #requestToBuyMenu="matMenu">
            <button *ngIf="preNeedVisibility" (click)="openFormBuyPlotPurchase('Pre-need')" mat-menu-item>Pre-need plot purchase</button>
            <button *ngIf="atNeedVisibility" (click)="openFormBuyPlotPurchase('At-need')" mat-menu-item>At-need plot purchase</button>
          </mat-menu>
        </div>
      </div>
      </ng-container>
    </ng-container>

    <cl-custom-field-info *ngIf="customFieldValues?.length" [customFields]="customFieldValues"></cl-custom-field-info>

    <div class="plot-persons">
      <mat-tab-group *ngIf="((isOccupiedStatus() && isIntermentsExist) || (isUnavailableStatus() && isIntermentsExist)) && upcomingEvent.length; else oneItem">
        <mat-tab label="{{ 'interments' | uppercase }}" *ngIf="isOccupiedStatus() || (isUnavailableStatus() && isIntermentsExist)">
          <div
            class="interment"
            *ngFor="let interment of sortInterment(plotDetails.interments)"
            [ngStyle]="{ 'margin-bottom': plotDetails.interments.length <= 3 ? '32px' : '8px' }"
          >
            <cl-shared-interment-item
              [interment]="interment"
              [plotDetails]="plotDetails"
              [amountOfInterments]="plotDetails.interments.length"
              [sellStory]="sellStory"
              [regionalSettings]="regionalSettings"
            ></cl-shared-interment-item>
          </div>
        </mat-tab>

        <mat-tab label="{{ 'events' | uppercase }}" *ngIf="upcomingEvent.length">
          <cl-upcoming-event-list [upcomingEvent]="upcomingEvent" [cemeteryName]="cemetery?.unique_name" [allUpcomingEvent]="true">
          </cl-upcoming-event-list>
        </mat-tab>
      </mat-tab-group>

      <ng-template #oneItem>
        <ng-container *ngIf="isOccupiedStatus() || (isUnavailableStatus() && isIntermentsExist)">
          <div
            class="interment"
            *ngFor="let interment of sortInterment(plotDetails.interments)"
          >
            <cl-shared-interment-item
            [interment]="interment"
            [plotDetails]="plotDetails"
            [amountOfInterments]="plotDetails.interments.length"
            [sellStory]="sellStory"
            [regionalSettings]="regionalSettings"
          ></cl-shared-interment-item>
        </div>
        </ng-container>
  
        <mat-tab-group *ngIf="upcomingEvent.length">
          <mat-tab label="{{ 'events' | uppercase }}" >
            <cl-upcoming-event-list [upcomingEvent]="upcomingEvent" [cemeteryName]="cemetery?.unique_name" [allUpcomingEvent]="true">
            </cl-upcoming-event-list>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </div>
  </div>
</div>
