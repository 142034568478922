import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dateIsoToJs',
})
export class DateIsoToJsPipe implements PipeTransform {
  transform(value: string): Date | string {
    const parsed = DateTime.fromISO(value);
    return parsed.isValid ? parsed.toJSDate() : value;
  }
}
