import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Interment } from 'src/app/interfaces/interment';
import { DateTime } from 'luxon';

@Component({
  selector: 'cl-display-age',
  templateUrl: './display-age.component.html',
  styleUrls: ['./display-age.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAgeComponent implements OnInit {
  @Input() interment: Interment;
  get isAgeAvailable() {
    return this.interment.age || (this.interment.date_of_birth && this.interment.date_of_death);
  }

  get areDatesAvailable() {
    return this.interment.date_of_birth || this.interment.date_of_death;
  }

  get age() {
    if (this.interment.age) {
      return this.interment.age;
    }

    const dob = DateTime.fromISO(this.interment.date_of_birth);
    const dod = DateTime.fromISO(this.interment.date_of_death);
    const result = dod.diff(dob, 'year').years;

    return Math.trunc(result);
  }

  get isShowAgeYearsOldText() {
    return this.age.toString().length <= 2;
  }

  get year_of_birth() {
    return this.interment.date_of_birth;
  }

  get year_of_death() {
    return this.interment.date_of_death;
  }

  get year_of_interment() {
    return this.interment.interment_date;
  }
  constructor() {}

  ngOnInit(): void {}
}
