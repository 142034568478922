import * as Joi from 'joi';
import { UserRole } from '../../enums/user-role.enum';

export const userRoleValidator = Joi.number()
  .valid(
    UserRole.CUSTOMER_VIEWER,
    UserRole.CUSTOMER_ORGANIZATION,
    UserRole.CUSTOMER_MANAGER,
    UserRole.CUSTOMER_ADMIN,
    UserRole.CHRONICLE_ADMIN,
    UserRole.CHRONICLE_MANAGER
  )
  .allow(null);
