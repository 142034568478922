import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { Router } from '@angular/router';
import { AdminDataService } from 'src/app/core/admin-data.service';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserRole } from 'src/app/enums/user-role.enum';
import { AppRoutes } from 'src/app/enums/app-routes.enum';

@Component({
  selector: 'cl-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  isLoading = false;
  constructor(
    private readonly addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private router: Router,
    private adminDataService: AdminDataService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'not-found', src: '/assets/images/not_found.svg' }]);
  }

  ngOnInit(): void {}

  goHome() {
    this.isLoading = true;
    this.adminDataService
      .getUserInfo()
      .pipe(
        tap(user => {
          if (!user) {
            return this.router.navigateByUrl('/');
          }

          const role = user.role;
          let path = '';
          switch (role) {
            case UserRole.CHRONICLE_ADMIN:
              path = AppRoutes.ChronicleAdmin;
              break;
            case UserRole.CUSTOMER_ORGANIZATION:
              if (user.subscription === 'Free') {
                path = AppRoutes.Freemium;
              } else {
                path = AppRoutes.CustomerOrganization;
              }
              break;
            case UserRole.CUSTOMER_ADMIN:
              path = AppRoutes.CustomerAdmin;
              break;
            case UserRole.CUSTOMER_MANAGER:
              path = AppRoutes.CustomerManager;
              break;
            case UserRole.CUSTOMER_VIEWER:
              path = AppRoutes.CustomerViewer;
              break;
            case UserRole.PUBLIC_USER:
              path = '';
              break;
          }

          this.router.navigate([path]);
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
