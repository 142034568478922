import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isDateInputValid } from '../date-input-validator';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cl-custom-form-field-items',
  templateUrl: './custom-form-field-items.component.html',
  styleUrls: ['./custom-form-field-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFormFieldItemsComponent implements OnInit {
  @Input() dataCustomForm: any;
  @Input() controlName: FormControl;
  @Input() typeSection: string;
  @Input() fieldType: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() values: any;

  @Output() postalCodeChange = new EventEmitter<string>();

  constructor(
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    if (this.label === 'Postal Address Different') {
      this.controlName.valueChanges.subscribe((value) => {
        this.postalCodeChange.emit(value);
      });
    }
  }

  validateDate(e) {
    if (!isDateInputValid(e.key)) {
      e.preventDefault();
    }
  }

  onContextMenu(event: MouseEvent): void {
    event.preventDefault();
  }

}
