import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, combineLatest, from, fromEvent, zip } from 'rxjs';
import { filter, finalize, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { CemeteryService } from 'src/app/core/cemetery.service';
import { MapActionsService } from 'src/app/core/map-actions.service';
import { MapDataService } from 'src/app/core/map-data.service';
import { MapService } from 'src/app/core/map.service';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { SectionService } from 'src/app/core/section.service';
import { SidebarService } from 'src/app/core/sidebar.service';
import { CemeteryInfo } from 'src/app/interfaces/cemetery-info';
import { PlotDetails } from 'src/app/interfaces/plot-details';
import { DataSection } from 'src/app/interfaces/section-data';
import { LoadingMapService } from 'src/app/loading-map/loading-map.service';
import { LoadingService } from 'src/app/loading-overlay/loading.service';

@Component({
  selector: 'cl-niche-walls-list',
  templateUrl: './niche-walls-list.component.html',
  styleUrls: ['./niche-walls-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NicheWallsListComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();

  loaders$ = this.loadingService.loaders$;

  currentNicheWallSection: DataSection;

  currentCemetery: CemeteryInfo;

  nicheWallPlotList;

  componentHeight: number;
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private sectionService: SectionService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private mapActionService: MapActionsService,
    private loadingService: LoadingService,
    private mapService: MapService,
    private sidebarService: SidebarService,
    private mobileResponsiveService: MobileResponsiveService
  ) {
    this.addIconsThroughMaterialService.add([
      { name: 'share-icon-purple', src: '/assets/images/share_icon_purple.svg' },
      { name: 'arrow-back', src: '/assets/images/arrow_left.svg' },
      {
        name: 'custom_shared_search_item_plot',
        src: '/assets/images/plot_icon.svg',
      },
    ]);
  }

  ngOnInit(): void {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          if (!res) {
            this.sidebarService.close();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.getRouteData();
    this.getNicheWallPlotList();
  }

  ngAfterViewInit(): void {
    const windowListener$ = fromEvent(window, 'resize').pipe(startWith(this.el.nativeElement?.clientHeight));
    windowListener$
      .pipe(
        tap(() => {
          this.componentHeight = this.el.nativeElement?.clientHeight;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  getRouteData(): void {
    combineLatest([this.activatedRoute.data, this.mapService.map$])
      .pipe(
        filter(([result, map]) => !!result.section && !!result.cemetery && !!map),
        tap(([result, mapInstance]) => {
          if (mapInstance) {
            mapInstance.setMaxZoom(30);
            mapInstance.setZoom(27);
          }
          const dataSection: DataSection = result.section;
          const cemetery: CemeteryInfo = result.cemetery;
          this.currentNicheWallSection = dataSection;
          this.currentCemetery = cemetery;
          this.cdr.detectChanges();

          if (result.section) {
            this.mapActionService.showNicheWall(result.section);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getNicheWallPlotList(): void {
    this.activatedRoute.paramMap
      .pipe(
        switchMap(paramMap => {
          const cemeteryUniqueName = paramMap.get('cemeteryName');
          const sectionId = paramMap.get('sectionId');
          return this.sectionService
            .getSectionPlots(cemeteryUniqueName, sectionId)
            .pipe(this.loadingService.setLoaders('getNicheWallPlotList'));
        }),
        tap(result => {
          this.nicheWallPlotList = result;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onPlotClick(plot: PlotDetails): void {
    this.router.navigate([plot.cemetery_unique_name, 'plots', plot.plot_id], {
      queryParams: { view: 'niche-wall', sectionId: plot.section_link.section_id },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute.parent,
    });
  }

  backToCemetery(): void {
    this.router.navigate([this.currentCemetery?.unique_name], { relativeTo: this.activatedRoute.parent });
    this.mapActionService.showCemeteryWithoutAnimation(this.currentCemetery);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
