import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'cl-mat-year-picker',
  templateUrl: './mat-year-picker.component.html',
  styleUrls: ['./mat-year-picker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ],
})
export class MatYearPickerComponent {
  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() required?: boolean;
  @Input() label = '';
  @Output() toggleDatePickerMode = new EventEmitter<any>();

  maxDate: Moment;
  @Input()
  get max(): number | Date {
    return this.maxDate ? this.maxDate.year() : undefined;
  }
  set max(max: number | Date) {
    if (max) {
      const momentDate = typeof max === 'number' ? moment([max, 0, 1]) : moment(max);
      this.maxDate = momentDate.isValid() ? momentDate : undefined;
    }
  }

  minDate: Moment;
  @Input()
  get min(): number | Date {
    return this.minDate ? this.minDate.year() : undefined;
  }
  set min(min: number | Date) {
    if (min) {
      const momentDate = typeof min === 'number' ? moment([min, 0, 1]) : moment(min);
      this.minDate = momentDate.isValid() ? momentDate : undefined;
    }
  }

  onToggleDatePickerMode() {
    this.toggleDatePickerMode.emit();
  }
}
