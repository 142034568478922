import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { SidebarService } from '../../core/sidebar.service';
@Component({
  selector: 'cl-sidebar-responsive',
  templateUrl: './sidebar-responsive.component.html',
  styleUrls: ['./sidebar-responsive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarResponsiveComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ghostEl') ghostEl!: ElementRef<any>;
  @ViewChild('togglerClose') togglerClose!: ElementRef<any>;
  @ViewChild('togglerOpen') togglerOpen!: ElementRef<any>;

  initHeight = 75;
  initHeightPublic = 89;
  height = 75;
  maxHeight = 0;
  navElementHeight = 110;
  isOpen$ = this.sidebarService.isOpen$;
  open = false;
  destroy$ = new Subject<void>();

  constructor(
    private sidebarService: SidebarService,
    private renderer: Renderer2,
    private authService: AuthService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    if (!this.authService.isUserLogged()) {
      this.initHeight = this.initHeightPublic;
    }
    this.ghostEl.nativeElement.style.height = `${this.initHeight}px`;
    this.maxHeight = window.innerHeight - this.navElementHeight;
    this.togglerClose.nativeElement.classList.add('toggler2');
    this.sidebarService.isMovePlot$
      .pipe(
        tap((isMovePlot: boolean) => {
          if (isMovePlot) {
            this.onMovePlot();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.isOpen$
      .pipe(
        tap(isOpen => {
          // const windowHight = window?.innerHeight;
          const leafletControlElement = document.querySelector('.leaflet-bottom.leaflet-right');

          if (isOpen) {
            const isUserLogged = this.authService.isUserLogged();
            const ghostHeight = isUserLogged ? '55px' : `${this.initHeightPublic.toString()}px`;
            this.renderer.setStyle(leafletControlElement, 'bottom', ghostHeight);
            return;
          }
          this.renderer.setStyle(leafletControlElement, 'bottom', '0px');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.sidebarService.isOpenSearchMobile$
      .pipe(
        tap(search => {
          if (search) {
            this.onClickUp();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onClickUp() {
    this.sidebarService.setSearchMobileActive(false);
    if (this.open === false) {
      this.ghostEl.nativeElement.style.height = `${this.maxHeight}px`;
      this.ghostEl.nativeElement.classList.add('open-up');
      this.togglerClose.nativeElement.classList.remove('toggler2');
      this.togglerOpen.nativeElement.classList.add('toggler');
      this.open = true;
      const isUserLogged = this.authService.isUserLogged();
      setTimeout(() => {
        if (!isUserLogged) {
          document.querySelector('.cdk-overlay-container')?.classList?.add('bring-to-back');
        }
      }, 0);
    } else {
      this.height = this.initHeight;
      this.ghostEl.nativeElement.style.height = `${this.initHeight}px`;
      this.ghostEl.nativeElement.classList.remove('open-up');
      this.togglerOpen.nativeElement.classList.remove('toggler');
      this.togglerClose.nativeElement.classList.add('toggler2');
      this.open = false;
      const isUserLogged = this.authService.isUserLogged();
      setTimeout(() => {
        if (!isUserLogged) {
          document.querySelector('.cdk-overlay-container')?.classList?.remove('bring-to-back');
        }
      }, 0);
    }
  }

  onMovePlot() {
    this.height = this.initHeight;
    this.ghostEl.nativeElement.style.height = `${this.initHeight}px`;
    this.ghostEl.nativeElement.classList.remove('open-up');
    this.togglerOpen.nativeElement.classList.remove('toggler');
    this.togglerClose.nativeElement.classList.add('toggler2');
    this.open = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
