<h4>
    Delete document?
  </h4>
  <p>
    Document will not be stored on Chronicle anymore. Check for any duplicate on other sources in order not to lose important information.
  </p>
  <div class="buttons">
    <button class="cancel" mat-button color="primary" (click)="cancel()">cancel</button>
    <button class="delete" mat-raised-button color="primary" (click)="delete()">Delete</button>
  </div>
  