import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  PLATFORM_ID,
  Inject,
  ElementRef,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { PlotDetailLifeChronicle, PlotDetails } from '../../interfaces/plot-details';
import { PlotStatusString } from '../../enums/plot-status-string.enum';
import { environment } from '../../../environments/environment';
import { PlotsService } from '../../core/plots.service';
import { LoadingService } from '../../loading-overlay/loading.service';
import { SwiperModalComponent } from '../swiper-modal/swiper-modal.component';
import { SwiperModalData } from '../swiper-modal/swiper-modal-data';
import { Image } from '../../interfaces/image';
import { MatDialog } from '@angular/material/dialog';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { PlotLocationReportService } from '../../core/plot-location-report.service';
import { PlotDirection } from '../../enums/plot-direction.enum';
import { catchError, filter, finalize, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { CemeteryService } from 'src/app/core/cemetery.service';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { EMPTY, Subject } from 'rxjs';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { BannerImageStoryComponent } from '../banner-image-story/banner-image-story.component';
import { StepperFormAddStoryComponent } from 'src/app/public/stepper-form-add-story/stepper-form-add-story.component';
import { SellStoryService } from 'src/app/core/sell-story.service';
import { StoryPublic } from 'src/app/interfaces/story-public';
import { BannerSuccessStoryComponent } from 'src/app/public/banner-success-story/banner-success-story.component';
import { Interment } from 'src/app/interfaces/interment';
import { FormBuyPlotComponent } from 'src/app/public/form-buy-plot/form-buy-plot.component';
import { ModalNotificationRequestPlotComponent } from '../modal-notification-request-plot/modal-notification-request-plot.component';
import { AuthService } from 'src/app/core/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { orderPerson, orderType } from 'src/app/shared/person.util';
import { IdentifyName } from '../../enums/amplitude.enum';
import { ICustomField } from 'src/app/interfaces/custom-field';
import { RegionalSettings } from 'src/app/interfaces/regional-settings';
import { EventService } from 'src/app/core/event.service';
import { SurveyFormComponent } from '../survey-form/survey-form.component';
import { OrganizationService } from 'src/app/admin/chronicle-admin/organization.service';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { FilterSellPlotsComponent } from '../filter-sell-plots/filter-sell-plots.component';
@Component({
  selector: 'cl-plot-details-public',
  templateUrl: './plot-details-public.component.html',
  styleUrls: ['./plot-details-public.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlotDetailsPublicComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  @Input() plotDetails: PlotDetails;
  @Input() sellStory: boolean;
  @Input() regionalSettings: RegionalSettings;
  @Input() currentCemetery;
  @Output() backToCemeteryInfo = new EventEmitter<void>();

  readonly lengthOfSymbolsForShowMoreButton = 35;
  responsive$: boolean;
  isLoading = false;
  detail: StoryPublic;
  status: string;
  edit: string;
  storiesId: string;
  isAus: any;
  loading = false;
  done: boolean;
  zoom: string;
  hideBanner = false;
  selectedInterment: Interment;
  cemetery: any;
  isLocked = false;
  isLoadingEvent = false;
  upcomingEvent: any[] = [];
  listGeneralForm: any[] = [];
  preNeedVisibility: any;
  atNeedVisibility: any;
  hasSurveyScript: boolean = environment.SURVEY_MONKEY_SCRIPT && environment.SURVEY_MONKEY_SCRIPT.length > 0;

  get isUnknownPlot() {
    return this.plotDetails.plot_type === 'Unknown Location';
  }

  get isBurialsCapacity(): boolean {
    if (this.plotDetails.burials_capacity === null) {
      return false;
    }

    return this.plotDetails.burials_capacity.total > 0 || this.plotDetails.burials_capacity.current > 0;
  }

  get isCremationCapacity(): boolean {
    if (this.plotDetails.cremation_capacity === null) {
      return false;
    }

    return this.plotDetails.cremation_capacity.total > 0 || this.plotDetails.cremation_capacity.current > 0;
  }

  get isEntombmentCapacity(): boolean {
    if (this.plotDetails.entombment_capacity === null) {
      return false;
    }

    return this.plotDetails.entombment_capacity.total > 0 || this.plotDetails.entombment_capacity.current > 0;
  }

  get directionData(): { icon: string; title: string } {
    return {
      icon: this.plotDetails.direction.replace('-', '_').toLowerCase(),
      title: Object.keys(PlotDirection).find(key => PlotDirection[key] === this.plotDetails.direction),
    };
  }

  get isReportDisabled$() {
    return this.loadingService.isLoading$().pipe(
      map(isLoading => {
        const isCoordinatesAvailable = this.plotDetails.coordinates !== null;

        return !(this.isUnknownPlot || isCoordinatesAvailable) || isLoading;
      })
    );
  }
  get isIntermentsExist() {
    return this.plotDetails.interments.length;
  }

  get listInterments() {
    return this.plotDetails.interments.map(interment => interment);
  }

  get isReturnServicePerson() {
    const returnServicePersons = this.plotDetails.interments.filter(int => {
      return int.returned_serviceman === true;
    });
    if (returnServicePersons.length > 0) {
      return true;
    }
    return false;
  }

  get isFeaturedStory() {
    const featuredStories = this.plotDetails.interments.filter(int => {
      if (int.story) {
        return int;
      }
    });
    if (featuredStories.length > 0) {
      return true;
    }
    return false;
  }

  get isLifeChronicle() {
    const procPlotDetail = this.plotDetails as PlotDetailLifeChronicle;
    if (procPlotDetail.life_chronicle && procPlotDetail.life_chronicle.is_life_chronicle === true) {
      return true;
    }
    return false;
  }

  get customFieldValues(): ICustomField[] {
    if (this.plotDetails.status === 'Vacant' || this.plotDetails.status === 'For Sale') {
      return this.plotDetails.plot_custom_fields?.filter(value => value?.visibility === 'Public');
    }

    if (this.plotDetails.status === 'Reserved') {
      return this.plotDetails.roi?.custom_fields?.filter(value => value?.visibility === 'Public');
    }
  }

  constructor(
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private plotsService: PlotsService,
    private dialog: MatDialog,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private readonly plotLocationReportService: PlotLocationReportService,
    private ref: ChangeDetectorRef,
    public loadingService: LoadingService,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private cemeteryService: CemeteryService,
    private mobileResponsiceService: MobileResponsiveService,
    private amplitudeService: AmplitudeService,
    private sellStoryService: SellStoryService,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private authService: AuthService,
    private routerUtilsService: RouterUtilsService,
    private organizationService: OrganizationService,

    @Inject(PLATFORM_ID)
    private platformId: any
  ) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_shared_plot_details_close',
        src: '/assets/images/close_icon_white.svg',
      },
      {
        name: 'custom_pdf_report',
        src: '/assets/images/pdf_report.svg',
      },
      {
        name: 'custom_cart',
        src: '/assets/images/cart-white.svg',
      },
      {
        name: 'list',
        src: '/assets/images/list.svg',
      },
      {
        name: 'arrow',
        src: '/assets/images/arrow-purple.svg',
      },
    ]);
    this.cemetery = this.activatedRoute.snapshot.data.cemetery;
    this.getListForm(this.cemetery.organization_id);

    this.cemeteryService.isLock
      .pipe(
        tap(res => {
          this.isLocked = res;
          this.ref.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnInit() {
    this.status = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.edit = this.activatedRoute.snapshot.queryParamMap.get('story_id');
    this.storiesId = this.activatedRoute.snapshot?.paramMap?.get('storyId');
    this.zoom = this.activatedRoute.snapshot.queryParamMap.get('zoom');
    // const showSurvey = this.activatedRoute.snapshot.queryParamMap.get('fromLc');
    // if (showSurvey !== null && this.hasSurveyScript) {
    //   setTimeout(() => {
    //     this.dialog
    //       .open(SurveyFormComponent, {
    //         panelClass: 'survey-form=component',
    //         width: '756px',
    //         disableClose: true,
    //       })
    //       .afterClosed()
    //       .pipe(
    //         filter(Boolean),
    //         tap(() => {
    //           this.router.navigate([], {
    //             queryParams: {
    //               fromLc: null,
    //             },
    //             queryParamsHandling: 'merge',
    //           });
    //         })
    //       )
    //       .subscribe();
    //   }, 2000);
    // }
    this.mobileResponsiceService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.showBanner();
    this.getDataEventPlot();
  }

  ngAfterViewInit() {
    if (this.edit) {
      this.sellStoryService
        .getStoryPublicById(parseInt(this.edit, 10))
        .pipe(
          tap<StoryPublic>(data => {
            this.detail = data;
          }),
          finalize(() => {
            this.loading = false;
            this.done = true;
          })
        )
        .subscribe(d => {
          if (isPlatformBrowser(this.platformId)) {
            this.onClickNewStory(false);
          }
        });
    }

    if (this.status === 'success') {
      return this.openBannerSuccess();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.plotDetails.currentValue !== changes.plotDetails.previousValue) {
      this.bannerShow(true);
    }
    this.getDataEventPlot();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBackToCemeteryInfo() {
    this.backToCemeteryInfo.emit();
  }

  getDataEventPlot() {
    this.isLoadingEvent = true;
    this.eventService
      .getUpcomingEvent(this.cemetery.unique_name, 25, 1, this.plotDetails?.id)
      .pipe(
        takeUntil(this.destroy$),
        tap(result => {
          this.upcomingEvent = result.data;
          this.isLoadingEvent = false;
        })
      )
      .subscribe();
  }

  getListForm(orgId: number) {
    this.organizationService
      .getListPublicForm(orgId)
      .pipe(
        catchError(error => {
          return EMPTY;
        }),
        tap(data => {
          this.listGeneralForm = data.filter(
            form =>
              form.is_active &&
              form.event_type !== 'Purchase Plot' &&
              form.request_from.includes('Public Users') &&
              (form.display_on === 'plot_purchase' || form.display_on === 'plot_and_cemetery') &&
              form.conditions &&
              form.conditions.includes(this.plotDetails.status)
          );

          this.preNeedVisibility = this.findVisibility(data, 'Pre-need plot purchase');
          this.atNeedVisibility = this.findVisibility(data, 'At-need plot purchase');

          this.ref.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  goToGeneralForm(formId: number) {
    const orgId = this.currentCemetery.organization_id;
    const path = this.routerUtilsService.getPath(
      this.currentCemetery.unique_name,
      String(orgId),
      this.plotDetails.plot_id,
      'request',
      String(formId)
    );
    this.router.navigate(path);
  }

  showBanner() {
    this.isLoading = true;
    this.sellStoryService
      .bannerStory(this.plotDetails.cemetery_unique_name)
      .pipe(
        catchError(error => {
          return EMPTY;
        }),
        tap(data => {
          const key = 'isAustralia';
          // this.onOpenBannerStory(data[key]);
          this.isAus = data[key];
          this.ref.detectChanges();
        }),
        finalize(() => {
          this.isLoading = false;
          this.ref.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onClickNewStory(newData: boolean) {
    let obj: any;
    if (!newData) {
      obj = { listInterments: this.listInterments, detail: this.detail, cemeteryName: this.plotDetails.cemetery_info.name };
      this.amplitudeService.track('Review existed Life Chronicle');
    } else {
      obj = { listInterments: this.listInterments, cemeteryName: this.plotDetails.cemetery_info.name };
      this.amplitudeService.track('1a. Button "Write A Life Chronicle" Clicked', this.plotDetails, {
        [IdentifyName.CemeteryName]: this.plotDetails.cemetery_info.name,
      });
    }

    if (!newData) {
      this.dialog
        .open(StepperFormAddStoryComponent, {
          panelClass: 'modal-view-story',
          width: '465px',
          disableClose: true,
          autoFocus: false,
          data: obj,
        })
        .componentInstance.notifyParent.subscribe(data => {
          this.selectedInterment = data;
          this.amplitudeService.track('Button "Write a Life Chronicle" clicked', this.selectedInterment);
        })
        .unsubscribe();
      // .takeUntil(this.destroy$);

      return;
    }

    this.dialog
      .open(BannerImageStoryComponent, {
        panelClass: 'banner-image-story',
        maxWidth: '80vw',
        disableClose: true,
        autoFocus: false,
        data: { isAus: this.isAus, plotDetails: this.plotDetails },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        if (v) {
          const dialogRef = this.dialog.open(StepperFormAddStoryComponent, {
            panelClass: 'modal-view-story',
            width: '465px',
            disableClose: true,
            autoFocus: false,
            data: obj,
          });
          const sub = dialogRef.componentInstance.notifyParent.subscribe(data => {
            this.selectedInterment = data;
            this.amplitudeService.track('Button "Write a Life Chronicle" clicked', this.selectedInterment);
          });
          sub.add();
          dialogRef.afterClosed().subscribe(data => {
            if (data === undefined && this.hasSurveyScript) {
              this.dialog
                .open(SurveyFormComponent, {
                  panelClass: 'survey-form=component',
                  width: '756px',
                })
                .afterClosed()
                .subscribe();
            }
          });
        } else if (this.hasSurveyScript) {
          this.dialog
            .open(SurveyFormComponent, {
              panelClass: 'survey-form=component',
              width: '756px',
            })
            .afterClosed()
            .subscribe();
        }
      });
  }

  openBannerSuccess() {
    this.dialog
      .open(BannerSuccessStoryComponent, {
        panelClass: 'banner-success-submit-story',
        width: '465px',
        disableClose: true,
        autoFocus: false,
        data: { interment: this.selectedInterment },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        if (v) {
          return this.router.navigate(['/', this.listInterments[0].cemetery_unique_name, 'plots', this.listInterments[0].plot_id], {
            queryParams: { from: 'map', zoom: this.zoom },
          });
        }
      });
  }

  copyPlotDetailsLink() {
    const encodedPlotId = encodeURIComponent(this.plotDetails.plot_id);
    const plotDetailsLink = `${environment.API_URL}/${this.plotDetails.cemetery_info.unique_name}/plots/${encodedPlotId}`;

    if (navigator.share === undefined) {
      this.clipboard.copy(plotDetailsLink);
      this.snackBar.open('Link`s copied to the clipboard', '', { duration: 4000 });
    } else {
      navigator.share({ url: plotDetailsLink });
    }
  }

  onCreatePlotLocationReport() {
    const cemeteryUniqueName = this.plotDetails.cemetery_info.unique_name;
    const plotId = this.plotDetails.plot_id;
    this.amplitudeService.track('Public - PDF Report Clicked', { plotId, cemeteryUniqueName });
    if (this.isUnknownPlot) {
      this.plotLocationReportService.createUnknownLocationReport(plotId, cemeteryUniqueName);
    } else {
      this.plotLocationReportService.create(plotId, cemeteryUniqueName);
    }
  }

  isUnavailableStatus(): boolean {
    return this.plotDetails.status === PlotStatusString.Unavailable;
  }

  isOccupiedStatus(): boolean {
    return this.plotDetails.status === PlotStatusString.Occupied;
  }

  isForSaleStatus(): boolean {
    return this.plotDetails.status === PlotStatusString['For Sale'];
  }

  bannerShow(param) {
    if (param === false) {
      this.amplitudeService.track('1b. Button Close Banner Write A Life Chronicle Clicked');
    }
    this.hideBanner = param;
  }

  getPlotStatus() {
    const status = this.plotDetails.status;

    if (status) {
      if (status === PlotStatusString['For Sale'] && this.plotDetails.price !== null) {
        return status;
      }

      return status.replace(/\s+/g, '');
    }

    return status;
  }

  getStylePlotStatus() {
    const status = this.plotDetails.status;

    if (status) {
      if (status === PlotStatusString['For Sale'] && this.plotDetails.price !== null) {
        return status.replace(/\s+/g, '').concat(` ($${this.plotDetails.price})`);
      }
      return status.replace(/\s+/g, '');
    }

    return status;
  }
  openPreview(index: number) {
    this.dialog.open<SwiperModalComponent, SwiperModalData, Image[]>(SwiperModalComponent, {
      panelClass: 'slider-container',
      data: {
        entityTitle: this.plotDetails.plot_id,
        images: this.plotDetails.images,
        startFrom: index,
        download: true,
        share: true,
      },
    });
  }

  openFormBuyPlot() {
    this.amplitudeService.track(`3. Plot Purchase - Button "REQUEST TO BUY" Clicked`, {
      ...this.plotDetails,
    });
    this.dialog
      .open(FormBuyPlotComponent, {
        panelClass: 'container-form-buy-plot',
        width: '495px',
        disableClose: true,
        autoFocus: false,
        data: { plotDetails: this.plotDetails, cemetery: this.cemetery },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        if (v === 'submitted') {
          this.dialog
            .open(ModalNotificationRequestPlotComponent, {
              panelClass: 'container-notification-request-plot',
              width: '480px',
              disableClose: false,
              autoFocus: false,
              data: { plotDetails: this.plotDetails },
            })
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        }
      });
  }

  formatPlotId(plotId: string) {
    return plotId.includes('Unassigned') ? 'Unassigned' : plotId;
  }

  openFormBuyPlotPurchase(type: 'Pre-need' | 'At-need') {
    this.amplitudeService.track(`3. Plot Purchase - Button "REQUEST TO BUY" Clicked`, {
      ...this.plotDetails,
    });
    const cemeteryName = this.plotDetails.cemetery_unique_name;
    const plotId = this.plotDetails.plot_id;
    this.router.navigate(['/', cemeteryName, 'plots', plotId, 'purchase', type, this.cemetery.organization_id], {
      relativeTo: this.activatedRoute,
    });
  }

  sortInterment(values: Interment[]) {
    return orderPerson(values, orderType.DESC);
  }

  private findVisibility(data: any[], title: string): any {
    return data.find(item => item.title === title && item.is_default && item.is_active);
  }
}
