import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { REGISTERED_USER_PATH_PREFIX_TOKEN } from './registered-user-path-prefix-token';

@Injectable({
  providedIn: 'root',
})
export class RouterUtilsService {
  constructor(
    private readonly router: Router,
    @Optional() @Inject(REGISTERED_USER_PATH_PREFIX_TOKEN) private readonly routePrefix: string | undefined
  ) {}

  getPath(...path: string[]): string[] {
    return this.routePrefix ? ['/', this.routePrefix, ...path] : ['/', ...path];
  }
}
