import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RegisteredGuard } from './registered.guard';
import { AdminDataService } from './core/admin-data.service';
import { TokenStorageService } from './core/token-storage.service';
import { UserRole } from './enums/user-role.enum';
import { AppRoutes } from './enums/app-routes.enum';

@Injectable({
  providedIn: 'root'
})
export class FreemiumGuard extends RegisteredGuard {
  role = UserRole.CUSTOMER_ORGANIZATION;
  nextRoute = AppRoutes.Freemium;

  constructor(readonly adminDataService: AdminDataService, readonly router: Router, readonly tokenStorageService: TokenStorageService) {
    super(adminDataService, router, tokenStorageService);
  }

}
