export enum PersonRoles {
  Interment = 'Interment',
  IntermentApplicant = 'Interment Applicant',
  IntermentMinister = 'Interment Minister',
  FuneralDirector = 'Funeral director',
  NextOfKin = 'Next of kin',
  RoiHolder = 'ROI holder',
  RoiApplicant = 'ROI Applicant',
  RoiHolderRoiApplicant = 'ROI holder & ROI Applicant',
}
