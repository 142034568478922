import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as noUiSlider from 'nouislider';
import { RangeData } from './range-data';
import { SliderRange } from './slider-range.enum';

@Directive({
  selector: '[clSlider]',
})
export class SliderDirective implements OnInit {
  private localRangeData: RangeData;

  slider: any;

  @Output() changeSliderValue = new EventEmitter<Array<number>>();

  @Input() set rangeData(rangeData) {
    this.localRangeData = rangeData;
    if (this.slider) {
      this.slider.set([rangeData.minValue, rangeData.maxValue]);
    }
  }

  get rangeData() {
    return this.localRangeData;
  }

  constructor(private readonly elRef: ElementRef) {}

  ngOnInit(): void {
    this.slider = noUiSlider.create(this.elRef.nativeElement, {
      start: [SliderRange.min, SliderRange.max],
      step: 1,
      range: {
        min: [SliderRange.min],
        max: [SliderRange.max],
      },
      format: {
        to: value => Math.ceil(value),
        from: value => Math.ceil(value),
      },
      connect: true,
    });

    this.slider.set([this.rangeData.minValue, this.rangeData.maxValue]);

    this.onChangeValue();
  }

  onChangeValue() {
    this.slider.on('slide.one', (value: Array<number>) => {
      this.changeSliderValue.emit(value);
    });
  }
}
