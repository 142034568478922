import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { environment } from 'src/environments/environment';
import { SurveyService } from 'src/app/core/survey.service';
import { Subscription } from 'rxjs';

declare global {
  interface Window {
    SMCX: any;
  }
}

@Component({
  selector: 'cl-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private surveyVisibleSubscription: Subscription;
  isPopupVisible = false;

  constructor(
    private readonly dialogRef: MatDialogRef<SurveyFormComponent>,
    private amplitudeService: AmplitudeService,
    private surveyService: SurveyService
  ) {}

  ngOnInit(): void {
    this.surveyVisibleSubscription = this.surveyService.surveyVisible$.subscribe(visible => {
      this.isPopupVisible = visible;
    });
  }

  ngOnDestroy(): void {
    if (this.surveyVisibleSubscription) {
      this.surveyVisibleSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if (this.isPopupVisible) {
      const scriptUrl = environment.SURVEY_MONKEY_SCRIPT;

      if (scriptUrl) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.onload = this.onScriptLoad.bind(this);
        document.getElementById('smcx-sdk').appendChild(script);
      }
    }
  }

  onScriptLoad(): void {
    window.SMCX = window.SMCX || [];
    window.SMCX.push([
      'survey',
      {
        container: 'smcx-sdk',
      },
    ]);
  }

  close(): void {
    this.amplitudeService.track('Button close survey clicked');
    this.dialogRef.close();
    this.surveyService.hideSurvey();
  }
}
