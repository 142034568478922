import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidateResponseService } from '../server-response-validation/validate-response.service';
import { HttpUtilsService } from './http-utils.service';
import {
  AssignedBusinessResponseBase,
  CemeteryEvent,
  CemeteryEventAction,
  CemeteryEventResponse,
  CemeteryEventResponseBase,
  EventForm,
  EventPurchaser,
  EventTypeResponseBase,
  FilterFormEvent,
  ResponsiblePersonResponseBase,
} from 'src/app/interfaces/cemetery-event';
import { tap } from 'rxjs/operators';
import { ValidatorType } from '../server-response-validation/validator-type.enum';
import moment, { Moment } from 'moment';
import { StorageStrategyService } from './storage-strategy.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly todayCalendar = new BehaviorSubject(false);
  key = 'event-filter';

  get todayCalendar$() {
    return this.todayCalendar.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private readonly httpUtils: HttpUtilsService,
    private readonly validateResponseService: ValidateResponseService,
    private readonly storageStrategyService: StorageStrategyService
  ) { }

  setTodayCalendar(value: boolean) {
    this.todayCalendar.next(value);
  }

  getEvents(cemeteryName: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryName, 'allevents']);
    // const url = 'https://dev.chronicle.rip/api/v1/cemetery/demo-cemetery/allevents/';
    return this.httpClient
      .get<CemeteryEventResponse[]>(url)
      .pipe(tap(cemeteryEvents => this.validateResponseService.validate(ValidatorType.CemeteryEvent, cemeteryEvents)));
  }

  getEventsAll(
    cemeteryName: string,
    isMonth: boolean,
    date?: Date,
    filter?: FilterFormEvent,
    page?: number,
    limit?: number,
    // sortParam?: number,
    isCalendar?: boolean
  ) {
    let url: string;
    const paramsObj = {
      page: page ? page.toString() : '1',
      limit: limit && !isMonth && !isCalendar ? limit.toString() : '0',
    };

    if (filter.cemetery) {
      url = this.httpUtils.getResourceUrl(['ms', 'events']);
      url = url.slice(0, -1);
      url += `?order_by=1`;
    } else {
      url = this.httpUtils.getResourceUrl(['ms', 'events']);
      url = url.slice(0, -1);
      url += `?order_by=1`;
      url += `&cemeteries=${cemeteryName}`;
    }

    if (
      date &&
      (isMonth || filter?.status === null || filter?.status === undefined) &&
      (isMonth || filter?.is_completed === null || filter?.is_completed === undefined) &&
      !filter.status
    ) {
      const dateFormat = this.formatDate(date, isMonth);
      url += `&datefilter=${dateFormat}`;
    }

    if (isMonth || (!isMonth && (filter?.status === null || filter?.status === undefined))) {
      url += `&ismonth=${isMonth ? 1 : 0}`;
    }

    if (filter?.type) {
      url += `&event_types_id=${filter.type}`;
    }

    if (filter?.subType) {
      url += `&event_subtype_id=${filter.subType}`;
    }

    if (filter?.statusType) {
      url += `&event_status_id=${filter.statusType}`;
    }

    if (filter?.status && filter?.status.length > 0) {
      url += `&event_status=${filter.status}`;
    }

    if (filter?.responsible_person) {
      if (filter.responsible_person.hasOwnProperty('id')) {
        url += `&responsible_person=${filter.responsible_person.id}`;
      } else {
        url += `&responsible_person=${filter.responsible_person}`;
      }
    }

    if (filter?.purchaser && filter?.purchaser.length > 0) {
      url += `&purchaser=${filter.purchaser}`;
    }

    if (filter?.assigned_party && filter?.assigned_party > 0) {
      url += `&assigned_party=${filter.assigned_party}`;
    }

    if (filter?.plotId && filter?.plotId.length > 0) {
      url += `&plot_id=${filter.plotId}`;
    }

    if (filter?.cemetery && filter.cemetery.length > 0) {
      url += `&cemeteries=${filter.cemetery}`;
    }

    if (filter?.is_completed || filter?.is_completed === 0) {
      url += `&is_completed=${filter.is_completed}`;
    }

    const params = new HttpParams({ fromObject: paramsObj });

    return this.httpClient.get<CemeteryEventResponseBase>(url, { params });
  }

  getEvent(cemeteryName: string, eventId: number, requestId?: number, indexEvent?: number) {
    const event = eventId ? eventId : -1;
    const url = this.httpUtils.getResourceUrl(['ms', 'event', event]);
    let params = new HttpParams();

    if (requestId) {
      const showDeleted = 1;
      params = params.append('show_deleted', showDeleted.toString());
      params = params.append('req_id', requestId.toString());
      params = params.append('index', indexEvent.toString());
    }

    return this.httpClient
      .get<CemeteryEventResponse>(url.slice(0, -1), { params })
      .pipe(tap(cemeteryEventDetails => this.validateResponseService.validate(ValidatorType.CemeteryEvent, cemeteryEventDetails)));
  }

  getEventPurchaserByName(cemeteryName: string, name: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryName, 'event-purchaser', 'name', name]);
    return this.httpClient.get<EventPurchaser[]>(url);
  }

  createEvent(event: EventForm, cemeteryName: string) {
    const saveEvent = this.transformEvent(event, cemeteryName);
    const url = this.httpUtils.getResourceUrl(['ms', 'event']);
    // const url = `https://dev.chronicle.rip/api/v1/ms/event`;
    return this.httpClient.post<CemeteryEventResponse>(url.slice(0, -1), saveEvent);
  }

  updateEvent(event: EventForm, cemeteryName: string) {
    const saveEvent = this.transformEvent(event, cemeteryName);
    const url = this.httpUtils.getResourceUrl(['ms', 'event', event.id]);
    return this.httpClient.put<CemeteryEventResponse>(url.slice(0, -1), saveEvent);
  }

  deleteEvent(event: CemeteryEventResponse) {
    const url = this.httpUtils.getResourceUrl(['ms', 'events']);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { ids: [event.id] },
    };
    return this.httpClient.delete<CemeteryEventResponse>(url.slice(0, -1), httpOptions);
  }

  declineEvent(event: CemeteryEventResponse, reason: string, sendEmail: boolean, email: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'events', 'decline']);
    const httpOptions = {
      id: event.id,
      reason,
      send_email: sendEmail ? 1 : 0,
      email_requestor: email,
    };
    return this.httpClient.put<CemeteryEventResponse>(url.slice(0, -1), httpOptions);
  }

  approvePurchaseEvent(event: CemeteryEventResponse, notes: string, sendEmail: boolean) {
    const url = this.httpUtils.getResourceUrl(['cemetery', event?.cemetery_unique_name, 'events', event?.id, 'approve']);
    const httpOptions = {
      notes,
      is_email_sent: sendEmail,
    };
    return this.httpClient.post<CemeteryEventResponse>(url.slice(0, -1), httpOptions);
  }

  completeEvent(event: CemeteryEventResponse) {
    const url = this.httpUtils.getResourceUrl(['ms', 'events', 'complete']);
    const completeEvent = {
      ids: [event.id],
    };
    return this.httpClient.put<CemeteryEventResponse>(url.slice(0, -1), completeEvent);
  }

  approveEvent(eventId: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'events', 'approve', eventId]);

    return this.httpClient.put<any>(url.slice(0, -1), {});
  }

  reopenEvent(event: CemeteryEventResponse) {
    const url = this.httpUtils.getResourceUrl(['ms', 'events', 'reopen']);
    const completeEvent = {
      ids: [event.id],
    };
    return this.httpClient.put<CemeteryEventResponse>(url.slice(0, -1), completeEvent);
  }

  completeEvents(event: CemeteryEventResponse[]) {
    const url = this.httpUtils.getResourceUrl(['ms', 'events', 'complete']);
    const completeEvent = {
      ids: event.map(e => e.id),
    };
    return this.httpClient.put<CemeteryEventResponse>(url.slice(0, -1), completeEvent);
  }

  responsiblePerson(cemeteryUniqueName: string, keyword: string, page?: number, limit?: number) {
    const paramsObj = {
      keyword,
      cemetery_unique_name: cemeteryUniqueName,
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
    };
    const params = new HttpParams({ fromObject: paramsObj });
    const url = this.httpUtils.getResourceUrl(['ms', 'user-cemeteries', 'events']);
    return this.httpClient.get<ResponsiblePersonResponseBase>(url.slice(0, -1), { params });
  }

  assignedBusiness(cemeteryUniqueName: string, keyword: string, page?: number, limit?: number) {
    const paramsObj = {
      keyword,
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
    };
    const params = new HttpParams({ fromObject: paramsObj });
    const url = this.httpUtils.getResourceUrl(['ms', 'business', cemeteryUniqueName, 'search']);
    return this.httpClient.get<AssignedBusinessResponseBase>(url.slice(0, -1), { params });
  }

  assignedBusinessOrg(cemeteryUniqueName: string, search: string) {
    const paramsObj = {
      search,
    };
    const params = new HttpParams({ fromObject: paramsObj });
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryUniqueName, 'business-org']);
    return this.httpClient.get<AssignedBusinessResponseBase>(url.slice(0, -1), { params });
  }

  typeList(cemeteryUniqueName: string, keyword: string, page?: number, limit?: number) {
    const paramsObj = {
      keyword,
      parentOnly: false.toString(),
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
    };
    const params = new HttpParams({ fromObject: paramsObj });
    const url = this.httpUtils.getResourceUrl(['ms', 'event-type', 'list', cemeteryUniqueName]);
    return this.httpClient.get<EventTypeResponseBase>(url.slice(0, -1), { params });
  }

  transformEvent(eventForm: EventForm, cemeteryName: string) {
    const event: CemeteryEventAction = {
      request_id: eventForm.request_id,
      cemetery_unique_name: cemeteryName,
      plot_id: eventForm.eventLocation.locationType === 'Plot' ? eventForm.eventLocation.location : null,
      section_name: eventForm.eventLocation.locationType === 'Section' ? eventForm.eventLocation.location : null,
      event_name: eventForm.eventName,
      event_types_id: eventForm.event_types_id,
      event_status_id: eventForm.event_status_id,
      event_subtype_id: eventForm.event_subtype_id,
      cover_image: eventForm.cover_image,
      types: '',
      start_time: this.tranformToISO(eventForm.eventDate.date, eventForm.eventDate.startTime),
      end_time: this.tranformToISO(eventForm.eventDate.date, eventForm.eventDate.endTime),
      descriptions: eventForm.description,
      make_public: eventForm.makePublic,
      location_type: eventForm.eventLocation.locationType,
      cremation_location: eventForm.eventLocation.cremationLocation,
      repeating: eventForm.eventDate.repeating,
      repeat_until: eventForm.eventDate.repeatingUntil && eventForm.eventDate.repeatingUntil.toISOString(),
      responsible_person: eventForm.responsiblePerson && eventForm.responsiblePerson.toString(),
      assigned_party: eventForm.assignParty && eventForm.assignParty.toString(),
      created_by: eventForm.assignPartyCode && eventForm.assignPartyCode.toString(),
      related_interment: eventForm.eventLocation.relatedInterment,
      files: eventForm.files ? eventForm.files : [],
      is_completed: 0,
      is_email_sent: this.transformIsEmailSent(eventForm.is_email_sent),
      event_payment: {
        payment_amount: eventForm.purchaseDetail.paymentAmount && eventForm.purchaseDetail.paymentAmount.toString(),
        payment_status: eventForm.purchaseDetail.isPaid,
        payment_method: 'Cash',
      },
      event_purchaser: {
        person: eventForm.purchaseDetail.person,
        purchaser: eventForm.purchaseDetail.purchaser,
        phone_number: eventForm.purchaseDetail.phone,
        email: eventForm.purchaseDetail.email,
        street: eventForm.purchaseDetail.address,
        suburb: eventForm.purchaseDetail.suburb,
        postcode: eventForm.purchaseDetail.postcode,
        state: eventForm.purchaseDetail.state,
        country: eventForm.purchaseDetail.country,
        is_samebilling: eventForm.shouldShowPostal,
        street_2: eventForm.postalAddress.street && eventForm.postalAddress.street,
        suburb_2: eventForm.postalAddress.suburb && eventForm.postalAddress.suburb,
        postcode_2: eventForm.postalAddress.postcode && eventForm.postalAddress.postcode,
        state_2: eventForm.postalAddress.state && eventForm.postalAddress.state,
        country_2: eventForm.postalAddress.country && eventForm.postalAddress.country,
      },
    };

    if (eventForm.plot_purchase) {
      event.plot_purchase = eventForm.plot_purchase;
    }
    return event;
  }

  transformIsEmailSent(data: boolean) {
    let newData;
    if (data === true) {
      newData = 1;
    } else {
      newData = 0;
    }
    return newData;
  }

  tranformToISO(date: Moment, time?: string): string {
    const newDate = moment(date);
    if (time && time.split.length > 0) {
      const temp = time.split(':');

      newDate.set({
        hour: parseInt(temp[0], 10),
        minute: parseInt(temp[1], 10),
        second: 0,
      });
    }

    return newDate.format('YYYY-MM-DDTHH:mm:ss');
  }

  formatDate(date: Date, isMonth: boolean) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const dt = date.getDate();

    const monthStr = '0' + month;
    const dtStr = '0' + dt;

    if (isMonth) {
      return year + '-' + monthStr.slice(-2);
    }

    return year + '-' + monthStr.slice(-2) + '-' + dtStr.slice(-2);
  }

  saveFilterToLocalStorage(filter: FilterFormEvent) {
    this.storageStrategyService.storage.setItem(this.key, JSON.stringify(filter));
  }

  getFilterFromLocalStorage(): FilterFormEvent {
    return JSON.parse(this.storageStrategyService.storage.getItem(this.key));
  }

  getUpcomingEvent(cemeteryUniqueName: string, limit: number = 3, page: number = 1, plotId?: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'events-public']);
    const paramsObj: { [key: string]: string } = {
      page: page.toString(),
      limit: limit.toString(),
      order_by: '5',
      cemeteries: cemeteryUniqueName,
      dateFilter: moment(new Date()).format('YYYY-MM-DD'),
    };
    if (plotId) {
      paramsObj.plot_id = plotId.toString();
    } else {
      paramsObj.event_status = 'Upcoming';
    }

    const params = new HttpParams({ fromObject: paramsObj });
    return this.httpClient.get<CemeteryEventResponseBase>(url.slice(0, -1), { params });
  }

  getOneEventPublic(idEvent: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'events-public', idEvent]);
    return this.httpClient.get<CemeteryEvent>(url.slice(0, -1), {});
  }
}
