import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserInfo } from '../interfaces/user-info';
import { of, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpUtilsService } from './http-utils.service';
import { ValidateResponseService } from '../server-response-validation/validate-response.service';
import { ValidatorType } from '../server-response-validation/validator-type.enum';
import { RegionalSettingsService } from './regional-settings.service';
import { Router } from '@angular/router';
import { TokenStorageService } from './token-storage.service';
import { Token } from '../enums/token.enum';

@Injectable({
  providedIn: 'root',
})
export class AdminDataService {
  private readonly userInfo = new ReplaySubject<UserInfo>(1);
  private readonly canEditInfo = new ReplaySubject<boolean>(1);
  userValue: UserInfo;

  get user() {
    return this.userInfo.asObservable();
  }

  get userCemeteries() {
    return this.userValue.cemeteries.map(cem => cem.id);
  }

  get canEdit() {
    return this.canEditInfo.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private readonly httpUtils: HttpUtilsService,
    private readonly validateResponseService: ValidateResponseService,
    private regionalSettingsService: RegionalSettingsService,
    private router: Router,
    private tokenStorageService: TokenStorageService
  ) {
    this.updateUserInfo().subscribe();
  }

  updateUserInfo() {
    return this.getUserInfo().pipe(
      tap(user => {
        this.userValue = user;
        this.userInfo.next(user);
      })
    );
  }

  updateUserInfoServer(access: string, refresh: string) {
    return this.getUserInfoServer(access, refresh).pipe(
      tap(user => {
        if (user.roles && user.roles.length > 0) {
          let userRoles = {};
          user.roles.forEach(role => {
            const objTemp = {
              access: `${access},${role.role_user}`,
              refresh: `${refresh},${role.role_user}`,
            };
            userRoles = { ...userRoles, [role.role_user]: objTemp };
            this.tokenStorageService.store(Token.listAccessToken, JSON.stringify(userRoles));
          });
        }
        this.userValue = user;
        this.userInfo.next(user);
      })
    );
  }

  getUserInfoServer(access: string, refresh: string) {
    const url = this.httpUtils.getResourceUrl(['user']);

    return this.httpClient
      .get<UserInfo>(url, {
        params: {
          access_token: access,
          refresh_token: refresh,
        },
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .pipe(
        tap(userInfo => this.validateResponseService.validate(ValidatorType.UserInfo, userInfo)),
        tap(userInfo => this.regionalSettingsService.setForUser(userInfo.regional_settings))
      );
  }

  getUserInfo() {
    const url = this.httpUtils.getResourceUrl(['user']);

    return this.httpClient.get<UserInfo>(url).pipe(
      tap(userInfo => this.validateResponseService.validate(ValidatorType.UserInfo, userInfo)),
      tap(userInfo => this.regionalSettingsService.setForUser(userInfo.regional_settings)),
      catchError(() => of(null))
    );
  }

  switchRole(newRole: number) {
    const newUserRole = { ...this.userValue, role: newRole };
    this.userInfo.next(newUserRole);
  }

  updateShowPublic(isShowPublic: boolean) {
    const url = this.httpUtils.getResourceUrl(['users', 'show_public']);

    return this.httpClient.put<UserInfo>(url, { show_public: isShowPublic }).pipe(
      tap(user => {
        this.userInfo.next(user);
        this.userValue = user;
      })
    );
  }

  update(user: Partial<UserInfo>, id: number) {
    const url = this.httpUtils.getResourceUrl(['user', id]);

    return this.httpClient.patch<UserInfo>(url, user).pipe(
      tap(userInfo => {
        this.userInfo.next(userInfo);
        this.userValue = userInfo;
      })
    );
  }

  updateCanEdit(newStatus: boolean) {
    this.canEditInfo.next(newStatus);
  }

  changePassword(currentPass: string, newPass: string) {
    const url = this.httpUtils.getResourceUrl(['users', 'change_password']);

    return this.httpClient.put(url, { current_password: currentPass, new_password: newPass });
  }

  resetPassword(email: string, redirectUrl: string) {
    const url = this.httpUtils.getResourceUrl(['password_reset']);

    return this.httpClient.post(url, { email, set_password_url: redirectUrl });
  }

  isAccessToCemetery(cemeteryId: number): boolean {
    if (!cemeteryId) {
      return false;
    }

    const cemeteriesId = this.userValue.cemeteries.map(cem => cem.id);

    return cemeteriesId.includes(cemeteryId);
  }
}
