import { Injectable } from '@angular/core';
import { FilterFormData } from '../interfaces/filter-form-data';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FilterViewAdapterService {
  getSelectedFilters(filterFormData: FilterFormData) {
    const selectedFilters: Record<string, string> = {};

    if (filterFormData.rs !== undefined && filterFormData.rs) {
      selectedFilters.rs = '';
    }

    if (filterFormData.age !== undefined) {
      selectedFilters.age = filterFormData.age.join(' - ');
    }

    if (filterFormData.status !== undefined) {
      selectedFilters.status = filterFormData.status[0].toUpperCase() + filterFormData.status.slice(1);
    }

    if (filterFormData.dod !== undefined) {
      selectedFilters.dod = this.getPreparedDate(filterFormData.dod);
    }

    if (filterFormData.dob !== undefined) {
      selectedFilters.dob = this.getPreparedDate(filterFormData.dob);
    }

    if (filterFormData.feature !== undefined) {
      selectedFilters.feature = filterFormData.feature;
    }

    if (filterFormData.story !== undefined) {
      selectedFilters.story = filterFormData.story;
    }

    return selectedFilters;
  }

  private getPreparedDate(dates: string[]) {
    return dates
      .filter(Boolean)
      .map(date => moment(date).format('MMM D, YYYY'))
      .filter(Boolean)
      .join(' - ');
  }
}
