import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[clHighlight]',
})
export class HighlightDirective {
  private localContent: string;
  private localSearchTerm: string;
  private separatedText = [];
  private separatedSearchedText = [];
  private final = '';
  searchPattern: RegExp;
  matchPattern: RegExp;
  splitFlag = '';
  matchFlag = '';
  spanStart = `<span class='text-highlight'>`;
  spanEnd = '</span>';

  @Input('content') set content(content: string) {
    this.localContent = content;
  }

  get content(): string {
    return this.localContent;
  }

  @Input('searchTerm')
  set searchTerm(searchTerm: string) {
    this.localSearchTerm = searchTerm;
    this.highlight();
  }
  get searchTerm(): string {
    return this.localSearchTerm;
  }

  @Input() caseSensitive = false;

  constructor(private el: ElementRef) {
    this.caseSensitive = false;
  }

  highlight() {
    this.final = '';
    if (!this.caseSensitive) {
      this.splitFlag = 'i';
      this.matchFlag = 'gi';
    } else {
      this.splitFlag = '';
      this.matchFlag = 'g';
    }
    this.searchPattern = new RegExp(this.localSearchTerm, this.splitFlag);
    this.matchPattern = new RegExp(this.localSearchTerm, this.matchFlag);

    if (
      this.localSearchTerm !== undefined &&
      this.localSearchTerm != null &&
      this.localSearchTerm.length > 0 &&
      this.localSearchTerm[0] !== '.'
    ) {
      this.separatedText = this.localContent.split(this.searchPattern);
      this.separatedSearchedText = this.localContent.match(this.matchPattern);

      if (this.separatedSearchedText != null && this.separatedSearchedText.length > 0) {
        for (let i = 0; i < this.separatedText.length; i++) {
          if (i <= this.separatedSearchedText.length - 1) {
            this.final += this.separatedText[i] + this.spanStart + this.separatedSearchedText[i] + this.spanEnd;
          } else {
            this.final += this.separatedText[i];
          }
        }
      }
      if (this.final.length > 0) {
        this.el.nativeElement.innerHTML = this.final;
      } else {
        this.el.nativeElement.innerText = this.localContent;
      }
    } else {
      this.el.nativeElement.innerText = this.localContent;
    }
  }
}
