<cl-upload-wrapper
  [files]="images"
  [disabled]="disabled"
  [title]="title"
  [uploaderType]="uploaderType.image"
  [public]="public"
  fileType="image/*, .heic, .heif"
  (inputFiles)="inputFiles($event)"
  (uploadedFiles)="uploadFiles($event)"
>
  <div *ngIf="localFiles?.length === 0" activeItem>
    <div class="upload-container">
      <button mat-button color="primary" [disabled]="disabled">
        <mat-icon svgIcon="custom_download"></mat-icon>
        <span class="text">{{ 'select photo' | uppercase }}</span>
      </button>

      <p class="additional-text">or drag it here</p>
    </div>
  </div>

  <ng-template #filesList let-files="files" let-loadFile="loadFile" let-deleteFromServer="remove">
    <ul *ngIf="files?.length > 0">
      <li *ngFor="let file of files.slice(0, imagesCapacity); let i = index" [class.first-image]="i === 0" (click)="preview(i)">
        <img src="{{ file.url ? file.url : file.image }}" alt="" />
      </li>
      <li *ngIf="files.length < imagesCapacity" class="add-more" (click)="loadFile()">
        <mat-icon *ngIf="!loadingService.isLoading" svgIcon="custom_plus"></mat-icon>
        <mat-spinner [diameter]="32" *ngIf="loadingService.isLoading$ | async"></mat-spinner>
      </li>
    </ul>
  </ng-template>
</cl-upload-wrapper>
