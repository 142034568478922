import * as Joi from 'joi';
import { Gender } from '../../enums/gender.enum';
import { addressValidator } from './address-validator';
import { phoneValidator } from './phone-validator';
import { Relationship } from '../../enums/relationship.enum';

export const personValidator = Joi.object({
  id: Joi.number(),
  buried: Joi.boolean(),
  emails: Joi.array().items(Joi.string()),
  first_name: Joi.string().allow(null),
  last_name: Joi.string().allow(null),
  middle_name: Joi.string().allow(null),
  gender: Joi.string().valid(Gender.female, Gender.male, Gender.unspecified).allow(null),
  relationship: Joi.string()
    .valid(
      Relationship.Cousin,
      Relationship.Daughter,
      Relationship.Father,
      Relationship.Grandparent,
      Relationship.Husband,
      Relationship.Mother,
      Relationship.Other,
      Relationship.Son,
      Relationship.Wife,
      Relationship.Sibling,
      Relationship.Brother,
      Relationship.Sister
    )
    .allow(null),
  notes: Joi.string().allow(null),
  phones: Joi.array().items(phoneValidator),
  title: Joi.string().allow(null),
  type: Joi.string().allow(null),
  primary_address: addressValidator,
  postal_address: addressValidator,
}).allow(null);
