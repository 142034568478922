<!-- <div class="interment-story-view">
  <h6 class="title">Story</h6>
  <cl-read-more *ngIf="story.text" [text]="story.text">
    <ng-content select="[nextStory]" ngProjectAs="[nextStory]"> </ng-content>
  </cl-read-more>
  <div class="images" *ngIf="story.images">
    <ul>
      <li *ngFor="let image of story.images; let i = index" (click)="openPreview(i)">
        <img src="{{ image.url }}" alt="" />
      </li>
    </ul>
  </div>
</div> -->
<div class="interment-wrapper-story">
  <mat-card (click)="openModalViewStory()">
    <ng-container *ngIf="story?.images.length > 0">
      <div class="card-container">
        <div class="timeline-header-wrapper">
          <div class="timeline-card-title">OFFICIAL STORY</div>
          <!-- <cl-read-more style="margin-bottom: 0px !important" *ngIf="a.text" [text]="a.text"></cl-read-more> -->
          <div class="text-content" [innerHTML]="story.text | readMorePipe: 108:'story' | safeHtml"></div>
        </div>
        <div class="image-story-timeline">
          <img src="{{ story?.images[0]?.url }}" class="preview-image-story" alt="story-image" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="story?.images.length <= 0">
      <div class="card-container-no-images">
        <div class="timeline-header-wrapper">
          <div class="timeline-card-title">Official Story</div>
          <!-- <cl-read-more style="margin-bottom: 0px !important" *ngIf="a.text" [text]="a.text"></cl-read-more> -->
          <div class="text-content" [innerHTML]="story.text | readMorePipe: 108:'story' | safeHtml"></div>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>
