export enum UserRole {
  CUSTOMER_VIEWER,
  CUSTOMER_MANAGER,
  CUSTOMER_ADMIN,
  CUSTOMER_ORGANIZATION,
  CHRONICLE_MANAGER,
  CHRONICLE_ADMIN,
  PUBLIC_USER,
  FREEMIUM
}

export const UserRoleConvert = [
  'Customer Viewer',
  'Customer Manager',
  'Customer Admin',
  'Customer Organization',
  'Chronicle Manager',
  'Chronicle Admin',
  'Public User',
];

export const UserRoleUrl = [
  'customer-viewer',
  'customer-manager',
  'customer-admin',
  'customer-organization',
  'chronicle-manager',
  'chronicle-admin',
  'Public User',
];
