import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigateBackService {
  previousUrl: string;

  constructor(private location: Location, private router: Router, private activatedRoute: ActivatedRoute) {}

  getPreviousUrl() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(({ url }: NavigationEnd) => {
          const urlTree = this.router.parseUrl(url);

          if (urlTree.queryParams.backTo) {
            delete urlTree.queryParams.backTo;
          }

          return urlTree.toString();
        }),
        tap(url => {
          this.previousUrl = url;
        })
      )
      .subscribe();
  }

  goBack() {
    if (this.activatedRoute.snapshot.queryParams.backTo) {
      this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.backTo);
      return;
    }

    this.location.back();
  }
}
