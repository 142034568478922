import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MarkerLocateService } from 'src/app/core/marker-locate.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'cl-snackbar-cancel-navigation',
  templateUrl: './snackbar-cancel-navigation.component.html',
  styleUrls: ['./snackbar-cancel-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarCancelNavigationComponent {
  constructor(
    private snackRef: MatSnackBarRef<SnackbarCancelNavigationComponent>,
    private markerLocateService: MarkerLocateService,
    private router: Router,

    @Inject(MAT_SNACK_BAR_DATA) public data: { destination: any; message: string; buttonLabel?: string; path: any; map: any }
  ) {
    if (data.buttonLabel === undefined) {
      data.buttonLabel = 'Sure!';
    }
  }

  onMoveDirection() {
    this.snackRef.dismiss();
    // this.markerLocateService.stopLocate(this.data.destination);
    this.router.navigate(this.data.path, { queryParamsHandling: 'merge' });
    this.markerLocateService.reset(this.data.map);
  }

  onCancel() {
    this.snackRef.dismiss();
  }
}
