let heic2any;

export function blobToFile(blobFile: Blob, fileName: string) {
  const newFile = new File([blobFile], fileName, {
    type: blobFile.type,
  });

  return newFile;
}

export async function convertHeic(file: File, fileTypeDestination: string) {
  if (typeof window) {
    const blob: Blob = file;
    const f: File = file;
    if (!heic2any) {
      heic2any = await import('heic2any');
    }

    const convertImage = (await heic2any.default({ blob, toType: `image/${fileTypeDestination}`, quality: 1 })) as Blob;
    const newName = f.name.replace(/\.[^/.]+$/, `.${fileTypeDestination}`);
    return blobToFile(convertImage, newName);
  } else {
    return file;
  }
}
