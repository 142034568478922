import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { Token } from '../enums/token.enum';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private tokenStorage: TokenStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.PATH_PANORAMA)) {
      return next.handle(req);
    }

    const data = JSON.parse(this.tokenStorage.get(Token.listAccessToken));
    const isMultiRoleAvailable = this.tokenStorage.get(Token.roleAccess) !== null;
    const getRoleAccess = this.tokenStorage.get(Token.roleAccess);
    let accessToken;
    if (isMultiRoleAvailable) {
      accessToken = data[getRoleAccess].access;
    } else {
      accessToken = this.tokenStorage.get(Token.access);
    }

    const isAccessTokenAvailable = accessToken !== null;
    const request = isAccessTokenAvailable ? req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } }) : req;
    return next.handle(request);
  }
}
