export enum Relationship {
  Son = 'Son',
  Daughter = 'Daughter',
  Mother = 'Mother',
  Father = 'Father',
  Wife = 'Wife',
  Husband = 'Husband',
  Cousin = 'Cousin',
  Grandparent = 'Grandparent',
  Spouse = 'Spouse',
  Sibling = 'Sibling',
  Brother = 'Brother',
  Sister = 'Sister',
  Other = 'Other',
}
