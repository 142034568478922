<form class="control-buttons" [formGroup]="form">
  <div
    class="control-button"
    *ngFor="let feature of features"
    [class]="feature.title | lowercase"
    (click)="onSetCheckedStatus(feature)"
    [ngClass]="feature.checked ? 'checked' : ''"
  >
    <label [for]="feature.title | lowercase" class="status-button">
      <input formControlName="feature" [id]="feature.title | lowercase" type="radio" [attr.aria-label]="feature.title | lowercase" />
      <span>{{ feature.title }}</span>
    </label>
  </div>
</form>
