import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { LoadingOverlayModule } from './loading-overlay/loading-overlay.module';
import { SHOULD_VALIDATE_SERVER_DATA } from './server-response-validation/should-validate-server-data';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    CoreModule,
    BrowserAnimationsModule,
    LoadingOverlayModule,
    MatNativeDateModule,
    MatIconModule,
  ],
  providers: [{ provide: SHOULD_VALIDATE_SERVER_DATA, useValue: !environment.PRODUCTION }],
  bootstrap: [AppComponent],
})
export class AppModule {}
