import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Person } from '../../interfaces/person';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { transformName, transformNameAdvanceSearch } from '../name-transform-function';
import { Plot } from 'src/app/interfaces/plot';

@Component({
  selector: 'cl-search-record-item',
  templateUrl: './search-record-item.component.html',
  styleUrls: ['./search-record-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchRecordItemComponent {
  localSearchValue: string;

  @Input() set searchValue(searchValue: string) {
    this.localSearchValue = searchValue;
  }

  get searchValue() {
    return this.localSearchValue;
  }

  @Input() photo: string | undefined;
  @Input() person: Person | undefined;
  @Input() plotId: string | undefined;
  @Input() dob?: string;
  @Input() dod?: string;
  @Input() isReturnServicePerson = false;
  @Input() isFeaturedStory = false;
  @Input() isLifeChronicle = false;
  @Input() plot: Plot | any;
  @Input() org: string | null;
  @Input() cemeteryUniqueName: string;

  get highlightedContent() {
    return transformName(this.person);
  }

  constructor(private addIconsThroughMaterialService: AddIconsThroughMaterialService) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_search_record_plot',
        src: '/assets/images/plot_icon.svg',
      },
    ]);
  }

  isSearchPersonNotRoiHolder(): boolean {
    return Object.keys(this.person).length > 0;
  }
}
