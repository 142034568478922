import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[clPreventNegativeNumbers]',
})
export class PreventNegativeNumbersDirective {
  @HostListener('keypress', ['$event']) onPreventInputNegativeValue(event) {
    if (event.key === '-') {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPreventPasteNegativeValue(event) {
    const pasteData = event.clipboardData?.getData('text');

    if (pasteData && /[-]/.test(pasteData)) {
      event.preventDefault();
    }
  }
}
