<ng-container [formGroup]="group">
  <div class="wrapper-form">
    <div class="wrapper-field-container">
      <div class="wrapper-field-0">
        <mat-form-field class="input-field" *ngFor="let field of fields.slice(0, 2); let i = index">
          <mat-label>{{ field.label }}</mat-label>
          <input
            [formControlName]="field.fieldName + '_' + data.value + '_' + this.defaultValue"
            type="{{ field.type }}"
            matInput
            placeholder="{{ field.label }}"
            (change)="changeValueFormInput($event.target.value, field.fieldName)"
          />
        </mat-form-field>
      </div>
      <div class="wrapper-field-1">
        <div class="title-contact-details">Contact Details</div>
        <div>
          <mat-form-field class="input-field" *ngFor="let field of fields.slice(2, 4); let i = index">
            <mat-label>{{ field.label }}</mat-label>
            <input
              [formControlName]="field.fieldName + '_' + data.value + '_' + this.defaultValue"
              type="{{ field.type }}"
              matInput
              placeholder="{{ field.label }}"
              (change)="changeValueFormInput($event.target.value, field.fieldName)"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="wrapper-field-2">
        <mat-form-field class="input-field" *ngFor="let field of fields.slice(4); let i = index">
          <mat-label>{{ field.label }}</mat-label>
          <input
            [formControlName]="field.fieldName + '_' + data.value + '_' + this.defaultValue"
            type="{{ field.type }}"
            matInput
            placeholder="{{ field.label }}"
            (change)="changeValueFormInput($event.target.value, field.fieldName)"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
