<ng-container *ngIf="{ mapLegendForms: mapLegendForms$ | async } as vm">
  <button class="btn-legend-trigger" mat-stroked-button [matMenuTriggerFor]="markerLegend"
    [ngClass]="{'hideButton': searchMobileActive$ | async}">
    <mat-icon svgIcon="custom_filter" class="filter-icon"></mat-icon>
  </button>
  <mat-menu class="mat-elevation-z0 legend-panel" #markerLegend="matMenu" hasBackdrop="false" [formGroup]="legendsForm">
    <ng-template matMenuContent>
      <ul class="legend-options" [formGroup]="legendsForm" (click)="$event.stopPropagation()">
        <li>
          <mat-checkbox class="legend-checkbox" color="primary" formControlName="publicCemeteries">
            <img src="/assets/images/cemetery_icon.svg" alt="Public Cemeteries Icon">
            Partner Cemeteries
          </mat-checkbox>

        </li>
        <li>
          <mat-checkbox class="legend-checkbox" color="primary" formControlName="publicSoonCemeteries">
            <img src="/assets/images/public-cem-soon-icon.svg" alt="Public Soon Icon">
            Partner Cemeteries (coming soon)
          </mat-checkbox>
        </li>
        <li>
          <mat-checkbox class="legend-checkbox" color="primary" formControlName="worldCemeteries">
            <img style="padding: 10px;" src="/assets/images/world-cem-icon.svg" alt="World Cemeteries Icon">
            Non-partner Cemeteries
          </mat-checkbox>
        </li>
      </ul>
    </ng-template>
  </mat-menu>
</ng-container>
