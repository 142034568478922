import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        const currentURLPath = this.router.url.split('/');
        const includeWhiteListReq = ['archive'].some(path => req.url.includes(path));
        const allowForbiddenError = currentURLPath.some(path => path === 'access') || includeWhiteListReq || req.url === 'https://dev.chronicle.rip/api/v1/users/getlist/';
        // Allow throwing 403 error for `access page`
        if (error instanceof HttpErrorResponse && error.status === 403 && allowForbiddenError) {
          return throwError(error);
        }

        if (error instanceof HttpErrorResponse && error.status === 403) {
          return EMPTY;
        }

        return throwError(error);
      })
    );
  }
}
