import { Component, OnInit, ChangeDetectionStrategy, Inject, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'cl-snackbar-confirmation',
  templateUrl: './snackbar-confirmation.component.html',
  styleUrls: ['./snackbar-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarConfirmationComponent implements OnInit, AfterViewInit {
  @Output() undoEvent = new EventEmitter();
  @Output() timeoutExpired = new EventEmitter();

  constructor(
    private snackRef: MatSnackBarRef<SnackbarConfirmationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string; }
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.timeoutExpired.emit(true);
      this.snackRef.dismiss();
    }, this.snackRef.containerInstance.snackBarConfig.duration);
  }

  onUndoClick() {
    this.undoEvent.emit(true);
    this.timeoutExpired.emit(false);
    this.snackRef.dismiss();
  }

  close() {
    this.timeoutExpired.emit(true);
    this.snackRef.dismiss();
  }
}
