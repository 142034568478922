import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'cl-table-loading-skeleton',
  templateUrl: './table-loading-skeleton.component.html',
  styleUrls: ['./table-loading-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableLoadingSkeletonComponent implements OnInit {
  @Input() withHeader;
  constructor() {}

  ngOnInit(): void {}
}
