export enum PlotType {
  Monumental = 'Monumental',
  Vault = 'Vault',
  Lawn = 'Lawn',
  Garden = 'Garden',
  Columbarium = 'Columbarium',
  Mausoleum = 'Mausoleum',
  Cremation = 'Cremation ',
  Memorial = 'Memorial',
}
