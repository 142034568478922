import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { FileResponse } from 'src/app/interfaces/file-response';
import { DeleteFileDialogComponent } from 'src/app/upload/delete-file-dialog/delete-file-dialog.component';
import { UploaderType } from 'src/app/upload/enums/uploader-type.enum';
import { ImageUploaderSharedService } from '../services/image-uploader-shared.service';

@Component({
  selector: 'cl-upload-document-shared',
  templateUrl: './upload-document-shared.component.html',
  styleUrls: ['./upload-document-shared.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentSharedComponent {
  @Input() uploaderType = UploaderType.file;
  localFiles: FileResponse[];
  @Input() documents: FileResponse[] = [];
  @Input() title = 'Documents';
  @Input() showTitle = true;
  @Input() public = false;
  @Input() disabled = false;
  @Input() acceptFileType = '*';
  @Input() maxSizePlaceholder = 8000;
  @Output() files = new EventEmitter<FileResponse[]>();
  @Output() isLoadingDoc = new EventEmitter();

  constructor(
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    public dialog: MatDialog,
    private imageUploaderService: ImageUploaderSharedService
  ) {
    this.addIconsThroughMaterialService.add([
      { name: 'custom_document', src: '/assets/images/document_icon.svg' },
      { name: 'custom_close', src: '/assets/images/close_icon.svg' },
    ]);
  }

  inputFiles(files: FileResponse[]) {
    this.localFiles = files;
  }

  uploadFiles(files: FileResponse[]) {
    this.localFiles = files;
    this.files.emit(this.localFiles);
  }

  getLoadingDocEvent(isLoading: boolean) {
    this.isLoadingDoc.emit(isLoading);
  }

  getFileNameFromURL(url: string) {
    return this.imageUploaderService.getImageNameFromLink(url);
  }

  delete(event: Event, file: FileResponse, remove: (file: FileResponse) => void) {
    event.preventDefault();

    this.dialog
      .open(DeleteFileDialogComponent, {
        width: '448px',
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        tap(() => remove(file))
      )
      .subscribe();
  }
}
