import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Uploader } from '../interfaces/uploader';
import { FileResponse } from '../../interfaces/file-response';
import { HttpUtilsService } from '../../core/http-utils.service';

@Injectable()
export class FileUploaderService implements Uploader<FileResponse> {
  constructor(private httpClient: HttpClient, private readonly httpUtils: HttpUtilsService) {}

  upload(file: File) {
    const url = this.httpUtils.getResourceUrl(['record_files']);
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<FileResponse>(url, formData);
  }

  uploadFromPublic(file: File) {
    const url = this.httpUtils.getResourceUrl(['public', 'record_files']);
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<FileResponse>(url, formData);
  }

  remove(item: { id: number }) {
    const url = this.httpUtils.getResourceUrl(['record_files', item.id]);

    return this.httpClient.delete(url);
  }

  removeFromPublic(item: { id: number }) {
    const url = this.httpUtils.getResourceUrl(['record_files', item.id]);

    return this.httpClient.delete(url);
  }
}
