import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, merge } from 'rxjs';
import { CemeteryInfo } from '../interfaces/cemetery-info';
import { MapService } from './map.service';
import { filter, map, pairwise, tap } from 'rxjs/operators';
import { PlotDetails } from '../interfaces/plot-details';
import { DataSection, SectionData, SectionType } from '../interfaces/section-data';
import * as turf from '@turf/turf';
@Injectable({
  providedIn: 'root',
})
export class MapDataService {
  private readonly zoom$ = new ReplaySubject<number>(1);
  private readonly move$ = new ReplaySubject(1);
  private readonly zoomImprove$ = new BehaviorSubject<number>(0);
  private readonly moveImprove$ = new BehaviorSubject<number>(0);
  private readonly rotate$ = new BehaviorSubject<number>(0);
  private map;
  private zoomEventFlag = false;
  private emitMoveend = true;
  private isOrthoExist = false;
  private layers: any;
  private orthoImageGroup: any;

  get zoom() {
    return this.zoom$.asObservable();
  }

  get move() {
    return this.move$.asObservable();
  }

  get rotate() {
    const currentBearing$ = this.mapService.map$.pipe(
      filter(mapInstance => !!mapInstance),
      map(mapInstance => mapInstance.getBearing())
    );

    return merge(currentBearing$, this.rotate$.asObservable()).pipe(
      pairwise(),
      filter(([prev, current]) => {
        const isRotating = (prev === 0 && current > 0) || (prev > 0 && current === 0);
        return isRotating;
      }),
      map(([, current]) => current)
    );
  }

  get zoomImprove() {
    return this.zoomImprove$.asObservable();
  }

  get moveImprove() {
    return this.moveImprove$.asObservable();
  }

  get orthoExist() {
    return this.isOrthoExist;
  }

  set setEmitMoveend(value: boolean) {
    this.emitMoveend = value;
  }

  set setOrthoExist(value: boolean) {
    this.isOrthoExist = value;
  }

  set setLayers(value: any) {
    this.layers = value;
  }

  set setOrthoImageGroup(value: any) {
    this.orthoImageGroup = value;
  }

  constructor(private readonly mapService: MapService) {
    this.mapService.map$
      .pipe(
        tap(mapInstance => {
          this.map = mapInstance;

          if (!mapInstance) {
            return;
          }

          this.initZoomListener(mapInstance);
          this.initMoveListener(mapInstance);
          this.initZoomImpListener(mapInstance);
          this.initMoveImpListener(mapInstance);
          this.initMapRotateListener(mapInstance);
          this.initMoveStart(mapInstance);
        })
      )
      .subscribe();
  }

  clearOrthoImageGroup() {
    try {
      this.layers.removeLayer(this.orthoImageGroup);
      this.mapService.map.removeLayer(this.orthoImageGroup);
      this.setOrthoExist = false;
    } catch (error) {}
  }

  getFirstCemeteryInViewport(leafletMap, cemeteries: CemeteryInfo[]) {
    const list = cemeteries.filter(cemetery => this.isCemeteryInViewport(cemetery));

    if (list.length > 1) {
      return this.getTheNearestCemetery(list);
    }

    return list[0];
  }

  getAreaInsideANicheWallSection(sections: DataSection[]) {
    const listSection = sections.filter(section => {
      if (this.isSectionInViewport(section)) {
        return section;
      }
    });

    if (listSection.length === 0) {
      return undefined;
    }

    if (listSection.length > 1) {
      return this.getTheNearestSection(listSection);
    }

    return listSection[0];
  }

  isSectionInViewport(section: DataSection) {
    const bounds = this.map.getBounds();
    const sectionBoundaries = L.polygon(section.polygon.coordinates).getBounds();
    return bounds.intersects(sectionBoundaries);
  }

  isCemeteryInViewport(cemetery?: CemeteryInfo) {
    if (!this.map || !cemetery || !cemetery.bounds) {
      return false;
    }

    const bounds = this.map.getBounds();
    const cemeteryCoordinates = L.geoJSON(cemetery.bounds).getBounds();

    return bounds.intersects(cemeteryCoordinates);
  }

  getTheNearestCemetery(cemeteries: CemeteryInfo[]) {
    const center = this.map.getCenter();
    const distances: number[] = [];

    // get the distances from the center of the map to each cemetery
    cemeteries.forEach(cemetery => {
      const distance = center.distanceTo(L.geoJSON(cemetery.bounds).getBounds().getCenter());
      distances.push(distance);
    });

    // get the nearest value (minimum distance)
    const nearestDistance = Math.min(...distances);
    const index = distances.indexOf(nearestDistance);

    return cemeteries[index];
  }

  getTheNearestSection(sections: DataSection[]) {
    const center = this.map.getCenter();
    const distances: number[] = [];

    // get the distances from the center of the map to each cemetery
    sections.forEach(section => {
      const distance = center.distanceTo(L.polygon(section.polygon.coordinates).getBounds().getCenter());
      distances.push(distance);
    });

    // get the nearest value (minimum distance)
    const nearestDistance = Math.min(...distances);
    const index = distances.indexOf(nearestDistance);

    return sections[index];
  }

  getTheDistaceBetweenTwoPlot(plot1: PlotDetails, plot2: PlotDetails) {
    const plot1Center = L.geoJSON(plot1.coordinates).getBounds().getCenter();
    const plot2Center = L.geoJSON(plot2.coordinates).getBounds().getCenter();
    return plot1Center.distanceTo(plot2Center);
  }

  private initZoomListener(leafletMap) {
    let zoom = leafletMap.getZoom();
    this.zoom$.next(zoom);

    leafletMap.on('zoomend', () => {
      zoom = leafletMap.getZoom();
      this.zoom$.next(zoom);
    });
  }

  private initMoveListener(leafletMap) {
    leafletMap.on('moveend', () => this.move$.next());
  }

  private initMapRotateListener(leafletMap) {
    leafletMap.on('rotate', () => {
      const bearing = leafletMap.getBearing();
      this.rotate$.next(bearing);
    });
  }

  private initZoomImpListener(leafletMap) {
    leafletMap.on('zoomend', () => {
      this.zoomEventFlag = true;
      this.zoomImprove$.next(leafletMap.getZoom());
    });
  }

  private initMoveImpListener(leafletMap) {
    leafletMap.on('moveend', () => {
      if (!this.zoomEventFlag && this.emitMoveend) {
        this.moveImprove$.next(leafletMap.getZoom());
      } else {
        this.emitMoveend = true;
      }
      this.zoomEventFlag = false;
    });
  }

  private initMoveStart(leaflet) {
    leaflet.on('movestart', () => {
      this.emitMoveend = true;
    });
  }
}
