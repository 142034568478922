import interact from 'interactjs';
import { Marker } from 'leaflet';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, RendererFactory2 } from '@angular/core';
import { environment } from '../../environments/environment';
import { SnackbarNotificationService } from './snackbar-notification.service';

@Injectable({
  providedIn: 'root',
})
export class PanoramaServiceService {
  private activeHangman = new BehaviorSubject(false);
  marker: Marker;
  isDragging = false;
  renderer: any;

  get activeHangman$() {
    return this.activeHangman.asObservable();
  }

  constructor(
    private http: HttpClient,
    private rendererFactory: RendererFactory2,
    private snackBarNotificationService: SnackbarNotificationService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getJsonPanorama(cemeteryUniqueName: string, fileName: string = 'pano-location.json'): Observable<any> {
    return this.http.get<any>(`${environment.PATH_PANORAMA}/${cemeteryUniqueName}/json/${fileName}`);
  }

  pegmanDragDrop(leafletMap, pointsLayer) {
    const pegmanDiv = L.control({ position: 'bottomright' });
    pegmanDiv.onAdd = () => {
      const div = L.DomUtil.create('div');
      div.setAttribute('id', 'pegmanButtonDiv');

      const pegmanMarker = document.createElement('div');
      pegmanMarker.setAttribute('id', 'pegmanMarker');
      pegmanMarker.setAttribute('class', 'draggable drag-drop');

      const pegmanButton = document.createElement('div');
      pegmanButton.setAttribute('id', 'pegmanButton');

      div.appendChild(pegmanMarker);
      div.appendChild(pegmanButton);

      let isFired = false;
      let direction = '';
      let oldx = 0;
      let latLng = null;
      // this.snackBarNotificationService.openSuccessMessage('Drag and drop Pegman onto the circular marker to access 360° views.');
      const mousemovemethod = e => {
        if (e.pageX < oldx) {
          direction = 'left';
          pegmanMarker.classList.add('left');
          pegmanMarker.classList.remove('right');
        } else if (e.pageX > oldx) {
          direction = 'right';
          pegmanMarker.classList.add('right');
          pegmanMarker.classList.remove('left');
        }
        oldx = e.pageX;
        return oldx;
      };

      pegmanMarker.addEventListener('click', e => {
        const activeHangman = this.activeHangman.getValue();
        this.activeHangman.next(!activeHangman);
      });

      interact('.draggable').draggable({
        inertia: false,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true,
          }),
        ],
        onmove: e => {
          document.addEventListener('mousemove', mousemovemethod);
          pegmanMarker.classList.remove('dropped');
          leafletMap.dragging.disable();
          const pTarget = e.target;
          const x = (parseFloat(pTarget.getAttribute('data-x')) || 0) + e.dx;
          const y = (parseFloat(pTarget.getAttribute('data-y')) || 0) + e.dy;

          pTarget.style.webkitTransform = pTarget.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

          pTarget.setAttribute('data-x', x);
          pTarget.setAttribute('data-y', y);
        },
        onend: e => {
          const element = pegmanMarker;
          element.classList.remove('can-drop', 'dragged', 'left', 'right', 'active', 'dropped');
          element.removeAttribute('style');
          element.removeAttribute('data-x');
          element.removeAttribute('data-y');
          leafletMap.dragging.enable();
        },
      });

      interact('.leaflet-container').dropzone({
        accept: '.draggable',
        overlap: 0.75,
        ondropactivate: e => {
          e.target.classList.add('drop-active');
          this.activeHangman.next(true);
        },
        ondragenter: e => {
          const dropzoneElement = e.target;

          dropzoneElement.classList.add('drop-target');
          e.relatedTarget.classList.add('can-drop');
        },
        ondragleave: e => {
          e.target.classList.remove('drop-target');
          e.relatedTarget.classList.remove('can-drop');
        },
        ondrop: e => {
          if (e) {
            let closestMarker = null;
            let minDistance = 2;
            const layer = pointsLayer.getLayers();
            if (latLng && layer.length > 0) {
              layer.forEach(marker => {
                const distance = latLng.distanceTo(marker.getLatLng());
                if (distance < minDistance) {
                  minDistance = distance;
                  closestMarker = marker;
                }
              });

              if (closestMarker) {
                leafletMap.flyTo(closestMarker.getLatLng(), 24);
                closestMarker.fire('click');
                isFired = true;
              } else if (isFired || closestMarker === null) {
                this.snackBarNotificationService.openErrorMessage(
                  'Sorry, 360 view is not available at this location. Please select a different location.'
                );
              }
            }
            this.activeHangman.next(false);
          }

          pegmanMarker.classList.remove('active', 'left', 'right');
          pegmanMarker.classList.add('dropped');

          pegmanMarker.classList.remove('can-drop', 'left', 'right', 'active', 'dropped');
          pegmanMarker.removeAttribute('style');
          pegmanMarker.removeAttribute('data-x');
          pegmanMarker.removeAttribute('data-y');
        },
        ondropdeactivate: e => {
          pegmanMarker.classList.remove('active', 'left', 'right');

          e.target.classList.remove('drop-active');
          e.target.classList.remove('drop-target');
        },
      });

      leafletMap.on('mousemove', e => {
        latLng = leafletMap.mouseEventToLatLng(e.originalEvent);
      });
      return div;
    };

    pegmanDiv.addTo(leafletMap);
  }

  destroyDragandDrop() {
    interact('.leaflet-container').unset();
    interact('.draggable').unset();
    this.activeHangman.next(false);
  }

  showHangmanLegend() {
    const pegmanButtonDiv = document.getElementById('pegmanButtonDiv');
    pegmanButtonDiv.style.visibility = 'visible';
  }

  removeHangmanLegend() {
    const pegmanButtonDiv = document.getElementById('pegmanButtonDiv');
    if (pegmanButtonDiv) {
      pegmanButtonDiv.remove();
      this.activeHangman.next(false);
      interact('.leaflet-container').unset();
      interact('.draggable').unset();
    }
  }
}
