import { Injectable } from '@angular/core';
import { StorageType } from '../enums/storage-type.enum';

@Injectable({
  providedIn: 'root',
})
export class StorageStrategyService {
  constructor() {
    this.initializeStorage();
  }

  private static get longTermStorage() {
    return window[StorageType.local];
  }

  set type(storage: StorageType) {
    if (!StorageStrategyService.longTermStorage) {
      return;
    }
    StorageStrategyService.longTermStorage.setItem('storageType', storage);
  }

  get type() {
    if (!StorageStrategyService.longTermStorage) {
      return null;
    }
    return StorageStrategyService.longTermStorage.getItem('storageType') as StorageType | null;
  }

  get storage() {
    if (!StorageStrategyService.longTermStorage) {
      return null;
    }
    const storageType = StorageStrategyService.longTermStorage.getItem('storageType') || StorageType.local;

    return window[storageType];
  }

  initializeStorage() {
    const isStorageTypeExist = this.type !== null;

    if (isStorageTypeExist) {
      return;
    }

    this.type = StorageType.local;
  }
}
