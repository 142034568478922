import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { google, ics } from 'calendar-link';
import { DataForCalender } from '../interfaces/data-calender.interfaces';

@Injectable({
  providedIn: 'root'
})
export class AddCalenderService {

  constructor( private datePipe: DatePipe) { }

  generateGoogleCal(data: DataForCalender) {
    const dataForGoogleCal = this.convertTimeData(data);

    window.open(google(dataForGoogleCal), '_blank');
  }

  generateIcsCal(data: DataForCalender) {
    const dataForGoogleCal = this.convertTimeData(data);

    window.open(ics(dataForGoogleCal), '_blank');
  }

  private convertTimeData(data: DataForCalender) {
    const dataCal: any = data;
    if (data) {
      dataCal.start = this.convertTimeUTC(data.start);
      dataCal.end = this.convertTimeUTC(data.end);
      return dataCal;
    }
  }

  private convertTimeUTC(date) {
    if (date) {
      return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
    }
  }
}

