import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_SIZE_FOR_READ_MORE } from './default-read-more-size';

@Pipe({
  name: 'readMorePipe',
})
export class ReadMorePipe implements PipeTransform {
  transform(value: string, size: number = DEFAULT_SIZE_FOR_READ_MORE, story: string): string {
    if (!value) {
      return null;
    }

    if (story) {
      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + '...';
    }

    if (value.length <= size) {
      return value;
    }

    return `${value.split('').slice(0, size).join('')}`;
  }
}
