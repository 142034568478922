import { cemeteryInfoBaseValidator } from './cemetery-info-base-validator';
import { boundsValidator } from './bounds-validator';
import { roisValidator } from './rois-validator';
import { plotValidator } from './plot-validator';
import * as Joi from 'joi';

export const plotDetailsValidator = plotValidator.append({
  cemetery_info: cemeteryInfoBaseValidator,
  rois: Joi.array().items(roisValidator),
  coordinates: boundsValidator,
});
