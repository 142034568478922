import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';

@Component({
  selector: 'cl-search-result-nothing-found',
  templateUrl: './search-result-nothing-found.component.html',
  styleUrls: ['./search-result-nothing-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultNothingFoundComponent {
  @Input() paragraph = 'Check search request';
  constructor(private addIconsThroughMaterialService: AddIconsThroughMaterialService) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_list',
        src: '/assets/images/list_icon.svg',
      },
    ]);
  }
}
