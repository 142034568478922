export enum ReligionType {
  anglican = 'Anglican',
  catholic = 'Catholic',
  uniting = 'Uniting',
  jehovahWitness = 'Jehovah Witness',
  allCreeds = 'All Creeds',
  methodist = 'Methodist',
  baptist = 'Baptist',
  quaker = 'Quaker',
  jewish = 'Jewish',
  muslim = 'Muslim',
  presbyterian = 'Presbyterian',
  seventhDayAdventist = 'Seventh Day Adventist',
  salvationArmy = 'Salvation Army',
  other = 'Other'
}
