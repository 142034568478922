import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string | null, maxLength: number): string {
    if (!value || value.length <= maxLength) {
      return value || '';
    }
    return value.substr(0, maxLength) + '...';
  }
}
