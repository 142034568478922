<div class="modal-wrapper">
  <div class="modal-container-title">
    <div class="title">Select a person</div>
  </div>
  <div class="modal-container-content">
    <div class="content">
      Do you want to write a story for {{ this.dialogData.record.person.first_name }} {{ this.dialogData.record.person.last_name }} ({{
        this.dialogData.record.date_of_birth | dateIsoToJs | date: 'yyyy'
      }}
      - {{ this.dialogData.record.date_of_death | dateIsoToJs | date: 'yyyy' }})
    </div>
  </div>
  <div class="button-container">
    <div>
      <button mat-button color="primary" (click)="seeOnMap()">SEE ON MAP</button>
    </div>
    <div>
      <button mat-button color="primary" (click)="cancel()">CANCEL</button>
      <button mat-raised-button color="primary" (click)="confirm()">SELECT</button>
    </div>
  </div>
</div>
