<ng-container [formGroup]="group">
  <div class="wrapper-form">
    <mat-button-toggle-group
      *ngIf="data.value !== 'Nextofkin'"
      class="wrapper-toggle-group"
      (change)="onValChange($event.value)"
      [value]="defaultValue"
    >
      <mat-button-toggle class="button-toggle" value="person">PERSON</mat-button-toggle>
      <mat-button-toggle class="button-toggle" value="business">BUSINESS</mat-button-toggle>
    </mat-button-toggle-group>
    <div class="wrapper-field-container">
      <div class="wrapper-field-1">
        <mat-form-field class="input-field" *ngFor="let field of fields.slice(0, 2); let i = index">
          <mat-label>{{ field.label }}</mat-label>
          <input
            [formControlName]="field.fieldName + '_' + data.value + '_' + this.defaultValue"
            type="{{ field.type }}"
            matInput
            placeholder="{{ field.label }}"
            (change)="changeValueFormInput($event.target.value, field.fieldName)"
          />
        </mat-form-field>
      </div>
      <div class="wrapper-field-2">
        <mat-form-field class="input-field" *ngFor="let field of fields.slice(2); let i = index">
          <mat-label>{{ field.label }}</mat-label>
          <input
            [formControlName]="field.fieldName + '_' + data.value + '_' + this.defaultValue"
            type="{{ field.type }}"
            matInput
            placeholder="{{ field.label }}"
            (change)="changeValueFormInput($event.target.value, field.fieldName)"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
