import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Image } from 'src/app/interfaces/image';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwiperModalComponent } from 'src/app/shared/swiper-modal/swiper-modal.component';
import { SwiperModalData } from 'src/app/shared/swiper-modal/swiper-modal-data';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { SellStoryService } from 'src/app/core/sell-story.service';
import { LoadingService } from 'src/app/loading-overlay/loading.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { Router } from '@angular/router';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { DialogSaveConfirmComponent } from 'src/app/shared/dialog-save-confirm/dialog-save-confirm.component';
import { StoryPublic } from 'src/app/interfaces/story-public';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { RemoveEntityFormDialogComponent } from '../../manage/remove-entity-form-dialog/remove-entity-form-dialog.component';
@Component({
  selector: 'cl-story-modal-form-edit',
  templateUrl: './story-modal-form-edit.component.html',
  styleUrls: ['./story-modal-form-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryModalFormEditComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  public = true;
  isLoading: boolean;
  form: FormGroup;

  intermentStoryImages: Array<Image> = [];

  configs: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '100px',
    width: '100%',
    placeholder: 'Compose an epic..',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    sanitize: true,
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'subscript',
        'superscript',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    showToolbar: true,
  };
  @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { data: any },
    private readonly dialogRef: MatDialogRef<StoryModalFormEditComponent>,
    private formBuilder: FormBuilder,
    private routerUtilsService: RouterUtilsService,
    private router: Router,
    private sellStory: SellStoryService,
    private loadingService: LoadingService,
    private snackBarNotificationService: SnackbarNotificationService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.form = this.creteFormGroup(this.dialogData.data);
    this.form.get('images').setValue(this.dialogData.data.images?.map(({ id }) => id));
    this.intermentStoryImages = this.dialogData.data?.images ? this.dialogData.data?.images : [];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  creteFormGroup(val) {
    const a = val.images?.map(({ id }) => id);

    return (this.form = this.formBuilder.group({
      title: val.title,
      text: val.text,
      images: [a],
    }));
  }

  checkForm() {
    this.dialog
      .open(DialogSaveConfirmComponent, {
        panelClass: 'dialog-save-confirm',
        width: '465px',
        disableClose: true,
        autoFocus: false,
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res?.res === 'confirm') {
          const pathStory = this.routerUtilsService.getPath(this.dialogData.data.cemetery_unique_name, 'story');
          this.isLoading = true;
          this.loadingService.run();

          this.sellStory
            .editStoryPublicAdmin(this.form.value, this.dialogData.data.id)
            .pipe(
              catchError(error => {
                this.openErrorMessage(error.error.message);
                return EMPTY;
              }),
              tap<StoryPublic>(data => {
                this.cancel(data);
                this.router.navigate(pathStory);
                this.amplitudeService.track('Updated Story', { before: this.dialogData.data, after: this.form.value });
                this.openSuccessMessage('Story edit successfully!');
              }),
              finalize(() => {
                this.loadingService.stop();
                this.isLoading = false;
                this.ref.detectChanges();
              }),
              takeUntil(this.destroy$)
            )
            .subscribe();
        }
      });
  }

  uploadIntermentStoryPhotos(images: Image[]) {
    this.intermentStoryImages = images;

    this.form.markAsDirty();
    this.form.get('images').setValue(images?.map(({ id }) => id));
  }

  openImageStoryPreview(index: number) {
    this.dialog
      .open<SwiperModalComponent, SwiperModalData, Image[]>(SwiperModalComponent, {
        panelClass: 'slider-container',
        data: {
          entityTitle: '',
          images: this.intermentStoryImages,
          startFrom: index,
          download: true,
          share: true,
          delete: true,
          public: true,
        },
      })
      .afterClosed()
      .pipe(
        tap(images => {
          if (images) {
            this.onStoryImagesChange(images);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onStoryImagesChange(images: Image[]) {
    this.intermentStoryImages = images;

    const imageIds = images.length ? images.map(image => image.id) : [];

    this.form.get('images').patchValue(imageIds);
  }

  cancel(data) {
    this.dialogRef.close(data);
  }

  back() {
    if (!this.form.dirty) {
      return this.dialogRef.close();
    }

    return this.dialog
      .open(RemoveEntityFormDialogComponent, {
        width: '448px',
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        tap(() => {
          this.dialogRef.close();
        })
      )
      .subscribe();
  }

  private openSuccessMessage(successMessage: string) {
    this.snackBarNotificationService.openSuccessMessage(successMessage);
  }

  private openErrorMessage(errorMessage: string) {
    this.snackBarNotificationService.openErrorMessage(errorMessage);
  }
}
