import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { StoryPublic, storyType } from '../../interfaces/story-public';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { finalize, ignoreElements, map, takeUntil, tap } from 'rxjs/operators';
import { StepperFormAddStoryComponent } from '../../public/stepper-form-add-story/stepper-form-add-story.component';
import { BannerSuccessStoryComponent } from '../../public/banner-success-story/banner-success-story.component';
import { Interment } from 'src/app/interfaces/interment';
import { SellStoryService } from 'src/app/core/sell-story.service';
import { SwiperModalComponent } from '../swiper-modal/swiper-modal.component';
import { SwiperModalData } from '../swiper-modal/swiper-modal-data';
import { Image } from '../../interfaces/image';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalViewStoryComponent } from '../../shared/modal-view-story/modal-view-story.component';
import { BannerImageStoryComponent } from '../banner-image-story/banner-image-story.component';
import { AmplitudeService } from 'src/app/core/amplitude.service';

@Component({
  selector: 'cl-timeline-story-view',
  templateUrl: './timeline-story-view.component.html',
  styleUrls: ['./timeline-story-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineStoryViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('timelineWrapper') timelineWrapper!: ElementRef<any>;
  open = false;

  storyTypeList = storyType;
  status: string;
  edit: string;
  detail: StoryPublic;
  loading = false;
  done: boolean;
  stories: boolean;
  storiesId: string;

  @Input() interment: Interment;
  @Input() storyLists: StoryPublic[];
  @Input() sellStory: boolean;
  @Input() isAus: any;
  @Output() addStory = new EventEmitter<boolean>();
  destroy$ = new Subject<void>();
  upcomingEventId: string;

  get sortByApprovedAt() {
    return this.storyLists?.sort((a, b) => {
      return b.id - a.id;
    });
  }

  constructor(
    private dialog: MatDialog,
    private sellStoryService: SellStoryService,
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.status = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.edit = this.activatedRoute.snapshot.queryParamMap.get('story_id');
    this.storiesId = this.activatedRoute.snapshot?.paramMap?.get('storyId');
    this.upcomingEventId = this.activatedRoute.snapshot?.paramMap?.get('eventId');
  }

  ngAfterViewInit() {
    // if (this.edit) {
    //   this.sellStoryService
    //     .getStoryPublicById(parseInt(this.edit, 10))
    //     .pipe(
    //       tap<StoryPublic>(data => {
    //         this.detail = data;
    //       }),
    //       finalize(() => {
    //         this.loading = false;
    //         this.done = true;
    //       })
    //     )
    //     .subscribe(d => this.onClickNewStory(false));
    // }

    // if (this.status === 'success') {
    //   return this.openBannerSuccess();
    // }

    if (this.storiesId?.length) {
      this.openModalViewStory(this.storiesId);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //   onClickNewStory(newData: boolean) {
  //     this.amplitudeService.track('Button "Write a Life Chronicle" clicked', this.interment);

  //     let obj: any;
  //     if (!newData) {
  //       obj = { interment: this.interment, detail: this.detail };
  //     } else {
  //       obj = { interment: this.interment };
  //     }

  //     this.dialog
  //       .open(BannerImageStoryComponent, {
  //         panelClass: 'banner-image-story',
  //         maxWidth: '80vw',
  //         disableClose: true,
  //         autoFocus: false,
  //         data: { isAus: this.isAus },
  //       })
  //       .afterClosed()
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe(v => {
  //         if (v) {
  //           this.dialog
  //             .open(StepperFormAddStoryComponent, {
  //               panelClass: 'generate-cemetery-report-dialog',
  //               width: '465px',
  //               disableClose: true,
  //               autoFocus: false,
  //               data: obj,
  //             })
  //             .afterClosed()
  //             .pipe(takeUntil(this.destroy$))
  //             .subscribe();
  //         }
  //       });
  //   }

  //   openBannerSuccess() {
  //     this.dialog
  //       .open(BannerSuccessStoryComponent, {
  //         panelClass: 'banner-success-submit-story',
  //         width: '465px',
  //         disableClose: true,
  //         autoFocus: false,
  //         data: { interment: this.interment },
  //       })
  //       .afterClosed()
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe();
  //   }

  openModalViewStory(data) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { stories: this.storiesId ? this.storiesId : data.id },
      queryParamsHandling: 'merge',
    });

    this.dialog
      .open(ModalViewStoryComponent, {
        panelClass: 'modal-view-story',
        width: '608px',
        disableClose: true,
        autoFocus: false,
        data: { story: data, public: true, storiesId: this.storiesId, interment: this.interment },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        this.amplitudeService.track('Public life chronicle button close clicked');
      });
  }
}
