import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './remove-entity-form-dialog.component.html',
  styleUrls: ['./remove-entity-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveEntityFormDialogComponent {
  constructor(public dialogRef: MatDialogRef<RemoveEntityFormDialogComponent>) {}

  quit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
