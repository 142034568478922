import { InvokerState } from './invoker-state';

export default abstract class Invoker {
  private readonly cache: Array<() => void> = [];
  private previousState: InvokerState = 'cache';
  protected abstract state: InvokerState;

  protected run(command: () => void) {
    const state = this.state;

    if (state === 'cache') {
      this.cache.push(command);
    } else if (this.previousState === 'run') {
      command();
    } else if (this.previousState === 'cache') {
      this.cache.push(command);

      while (this.cache.length > 0) {
        const fn = this.cache.pop();
        fn();
      }
    }

    this.previousState = state;
  }

  protected invoke() {
    this.run(() => {});
  }
}
