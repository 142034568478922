import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

import { fromEvent, merge, Subject } from 'rxjs';
import { filter, pluck, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[clDrag]',
})
export class DragDirective implements AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject();
  @Output() files = new EventEmitter<FileList>();
  @Output() isOver = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const events$ = ['dragover', 'dragleave', 'drop'].map(eventName => fromEvent<DragEvent>(this.element, eventName));
    const { top, right, bottom, left } = this.element.getBoundingClientRect();

    merge(...events$)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(event => event.preventDefault()),
        tap(({ type, x, y }) => {
          const isInHeightRange = y > top && y < bottom;
          const isInWidthRange = x > left && x < right;
          const isInRange = isInHeightRange && isInWidthRange;
          const isDrop = type === 'drop';
          const isDraggedOver = isInRange && !isDrop;

          this.isOver.emit(isDraggedOver);
        }),
        filter(({ type }) => type === 'drop'),
        pluck('dataTransfer', 'files'),
        filter(Boolean),
        tap((files: FileList) => this.files.emit(files))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private get element() {
    return this.elementRef.nativeElement;
  }
}
