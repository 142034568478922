import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { HttpUtilsService } from 'src/app/core/http-utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestManagementCemeteryService {
  private socket$!: WebSocketSubject<any>;
  constructor(private readonly httpUtils: HttpUtilsService, private readonly httpClient: HttpClient) {}

  connect(url: string): void {
    this.socket$ = webSocket(url);
  }

  disconnect(): void {
    this.socket$.complete();
  }

  isConnected(): boolean {
    return this.socket$ && !this.socket$.closed;
  }

  onMessage(): Observable<any> {
    return this.socket$.asObservable().pipe(tap(message => message));
  }

  getListRequestManagementCemetery(page, limit, dataFilter) {
    let params = new HttpParams();
    const paramObj = {
      page,
      limit,
    };
    Object.keys(paramObj).forEach(key => {
      params = params.append(key.toString(), paramObj[key]);
    });
    // params.append('page', page.toString());
    // params.append('limit', limit.toString());
    if (dataFilter) {
      for (const key of Object.keys(dataFilter)) {
        if (dataFilter[key] !== null) {
          if (dataFilter[key] instanceof Array) {
            params = params.append(key.toString(), JSON.stringify(dataFilter[key]));
          } else {
            params = params.append(key.toString(), dataFilter[key]);
          }
        }
      }
    }
    const url = this.httpUtils.getResourceUrl(['request-table']);
    return this.httpClient.get(url, { params });
  }

  getRequestManagementCemeteryById(id) {
    const url = this.httpUtils.getResourceUrl(['request-table', id]);
    return this.httpClient.get(url);
  }

  UpdateRequestManagementCemetery(id, data) {
    const url = this.httpUtils.getResourceUrl(['request-table', id]);
    return this.httpClient.put(url, data);
  }

  ApproveRejectRequestManagementCemetery(id, status: string, emailSent: boolean, message: string, plotId: any, cemeteryId: any) {
    const paramsObj = {
      status,
      email_sent: emailSent.toString(),
    };
    let messageObj;
    if (status === 'rejected') {
      messageObj = {
        message,
      };
    }
    if (status === 'approve') {
      messageObj = {
        message,
        plot_id: plotId,
        cemetery: cemeteryId,
      };
    }

    // const messageObj = {
    //   message,
    //   plot_id: plotId,
    //   cemetery: cemeteryId,
    // };
    const params = new HttpParams({ fromObject: paramsObj });
    const url = this.httpUtils.getResourceUrl(['request-table', id]);
    return this.httpClient.put(url, messageObj, { params });
  }

  getAllApplicant(valueSearch, limit, offset) {
    const paramsObj = {
      search: valueSearch,
    };
    const params = new HttpParams({ fromObject: paramsObj });
    const url = this.httpUtils.getResourceUrl(['search', 'users']);
    return this.httpClient.get(url, { params });
  }

  getSubmissionPdfByRequestId(id: number) {
    const url = this.httpUtils.getResourceUrl(['request-table', id, 'download_pdf']);

    return this.httpClient.post(url, null, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'arraybuffer',
    });
  }

  getSubmissionPdfByPlotPurchaseid(id: number) {
    const url = this.httpUtils.getResourceUrl(['plot-purchase', id, 'download_pdf']);

    return this.httpClient.post(url, null, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'arraybuffer',
    });
  }
}
