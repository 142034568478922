import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, first, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';
import { Token } from '../enums/token.enum';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenInterceptor implements HttpInterceptor {
  isProcessing = false;
  accessToken$ = new Subject<string>();

  constructor(private router: Router, private tokenStorageService: TokenStorageService, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        const isUnauthorised = error.status === 401;

        if (isUnauthorised) {
          return this.handleUnregisteredError(request, next, error);
        }

        return throwError(error);
      })
    );
  }

  private handleUnregisteredError(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    const refreshToken = this.tokenStorageService.get(Token.refresh);
    const isRefreshTokenUrl = this.authService.isRefreshTokenUrl(request.url);

    if (!refreshToken || isRefreshTokenUrl) {
      return this.logOut(error);
    }

    if (!this.isProcessing) {
      this.isProcessing = true;

      return this.authService.refreshToken(refreshToken).pipe(
        switchMap(({ access, refresh }: any) => {
          this.tokenStorageService.store(Token.access, access);
          this.tokenStorageService.store(Token.refresh, refresh);
          this.accessToken$.next(access);

          return next.handle(this.addToken(request, access));
        }),
        finalize(() => {
          this.isProcessing = false;
        })
      );
    }

    return this.accessToken$.pipe(
      filter(token => token !== null),
      first(),
      switchMap(token => next.handle(this.addToken(request, token)))
    );
  }

  // private logOut(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   this.tokenStorageService.clear(Token.refresh, Token.access);
  //   return next.handle(request);
  // }

  private logOut(error: any): Observable<HttpEvent<any>> {
    this.tokenStorageService.clear(Token.refresh, Token.access);
    return throwError(error);
  }

  private addToken(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
  }
}
