import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidateResponseService } from '../server-response-validation/validate-response.service';
import { HttpUtilsService } from './http-utils.service';

@Injectable({
  providedIn: 'root',
})
export class SellPlotsService {
  constructor(
    private httpClient: HttpClient,
    private readonly httpUtils: HttpUtilsService,
    private readonly validateResponseService: ValidateResponseService
  ) {}

  createSellPlot(formContent: any) {
    const url = this.httpUtils.getResourceUrl(['ms', 'plots-purchase']);

    return this.httpClient.post(url.slice(0, -1), { ...formContent });
  }

  purchasePlot(id: number, formContent: any) {
    const url = this.httpUtils.getResourceUrl(['cemetery', id, 'public', 'plot_purchase']);
    const httpOptions =  new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(url, formContent, {headers: httpOptions} );
  }

  getCustomFormTemplate(id: number) {
    const url = this.httpUtils.getResourceUrl(['customform', 'public', id]);
    const params = { display_on: 'plot_purchase' };
    return this.httpClient.get(url, { params: new HttpParams({ fromObject: params }) });
  }
}
