<div class="wrapper-pad">
  <div class="signature-pad">
    <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="448px" height="200"></canvas>
  </div>
  <div class="wrapper-button">
    <div>
      <button mat-button [disabled]="isSignaturePadEmpty" color="primary" (click)="undo()">UNDO</button>
      <button mat-button color="primary" [disabled]="isSignaturePadEmpty" (click)="clearPad()">CLEAN</button>
    </div>
    <button mat-button color="primary" (click)="savePad()">DONE</button>
  </div>
</div>