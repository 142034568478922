import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { Interment } from 'src/app/interfaces/interment';
import { environment } from 'src/environments/environment';
import { SnackbarErrorComponent } from '../snackbar-error/snackbar-error.component';
import { IdentifyName } from 'src/app/enums/amplitude.enum';

declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'cl-element-stripe',
  templateUrl: './element-stripe.component.html',
  styleUrls: ['./element-stripe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementStripeComponent implements OnInit, AfterViewInit {
  stripe; // : stripe.Stripe;
  card;
  cardErrors;
  elements;
  confirmation;
  setLoading = false;
  loading = false;
  @ViewChild('cardElement') cardElement: ElementRef;

  constructor(
    private matSnackBar: MatSnackBar,
    private amplitudeService: AmplitudeService,
    private readonly dialogRef: MatDialogRef<ElementStripeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { interment: Interment; clientSecret: any; amountPayment: any; currency: any; emailStory: any; cemeteryName: string }
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.stripe = Stripe(`${environment.STRIPE_PUBLISH_KEY}`);
    const appearance = {
      theme: 'flat',
    };
    const clientSecret = this.dialogData.clientSecret;
    this.elements = this.stripe.elements({ appearance, clientSecret });

    this.card = this.elements.create('payment');
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  async handleForm(e) {
    this.amplitudeService.track('4a. Button "Buy" on payment stripe clicked', null, {
      [IdentifyName.CemeteryName]: this.dialogData.cemeteryName,
    });
    e.preventDefault();
    const encodedPlotId = encodeURIComponent(this.dialogData.interment.plot_id);

    this.setLoading = true;
    const elements = this.elements;

    /**
     * Temp fix error invalid url provided. see: https://stripe.com/docs/error-codes#url-invalid
     * Note: it isn't recommended to use window directly with Angular as it not available on the server side when pre-rendering
     * so will break Angular Universal (important if you are planning on having an SEO friendly site)
     * TODO: change domain name based on environment, maybe use import DOCUMENT from @angular/common
     */
    const returnUrl = `${environment.APP_BASE_URL}/${this.dialogData.interment.cemetery_unique_name}/plots/${encodedPlotId}?from=map&zoom=24&email=${this.dialogData.emailStory}&modal=show&status=success`;

    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
        payment_method_data: {
          billing_details: {
            email: this.dialogData.emailStory,
          },
        },
      },
    });

    // handle error base on error type
    if (error.type === 'invalid_request_error' || error.type === 'card_error' || error.type === 'validation_error') {
      this.openErrorMessage(error.message);
    } else {
      // showMessage("An unexpected error occured.");
    }

    this.setLoading = false;
  }

  openErrorMessage(error: string) {
    this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
      data: { error },
      panelClass: 'custom-snackbar-error',
      duration: 5000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  close() {
    this.dialogRef.close(true);
  }
}
