<div class="left">
  <span>{{ data.error }}</span>
</div>
<a *ngIf="!data.copyUrl" mat-button (click)="gotIt()" class="got-it-link">
  {{ data.buttonLabel }}
</a>

<button (click)="close()" mat-icon-button>
  <mat-icon>close</mat-icon>
</button>
