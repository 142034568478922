import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  QueryList,
  ViewChildren,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PlotType } from '../../enums/plot-type.enum';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { PlotsService } from 'src/app/core/plots.service';
import {
  catchError,
  concatMap,
  debounce,
  debounceTime,
  distinct,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Subject, combineLatest, from, fromEvent, iif } from 'rxjs';
import { FormAdvanceSearch, PersonForm, optionsValue, statuses, BusinessForm } from '../../interfaces/advance-search';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MaskPipe } from 'ngx-mask';
import { RightType } from 'src/app/enums/right-type.enum';
import { AdminDataService } from 'src/app/core/admin-data.service';
import { UserRole } from 'src/app/enums/user-role.enum';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { AdvanceTableServiceService } from 'src/app/core/advance-table-service.service';
import { combine } from '@turf/turf';
import { merge } from 'hammerjs';

const moment = _rollupMoment || _moment;
// export class MyFormats {
//   value = 1;
//   constructor() {}
//   get display() {
//     return this.value === 1
//       ? {
//           dateInput: 'MM/DD/YYYY',
//           monthYearLabel: 'MM YYYY',
//           dateA11yLabel: 'MM/DD/YYYY',
//           monthYearA11yLabel: 'MM YYYY',
//         }
//       : {
//           dateInput: 'YYYY',
//           monthYearLabel: 'YYYY',
//           dateA11yLabel: 'YYYY',
//           monthYearA11yLabel: 'YYYY',
//         };
//   }
//   get parse() {
//     return this.value === 1
//       ? {
//           dateInput: 'YYYY/MM/DD',
//         }
//       : {
//           dateInput: 'DD/MM/YYYY',
//         };
//   }
// }

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'cl-advanced-search-form',
  templateUrl: './advanced-search-form.component.html',
  styleUrls: ['./advanced-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdvancedSearchFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('dateMask') dateMaskTmplRef: QueryList<ElementRef>;
  destroy$ = new Subject();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  selectAllCemetery = false;
  ageFrom = new FormControl('');
  ageTo = new FormControl('');
  cemeteryName: string[];
  AllCemetery: any;
  plotTypes = PlotType as Record<string, string>;
  rightType = RightType as Record<string, string>;
  optionsValue = optionsValue;
  statuses = statuses;
  panelPlotOpenState = false;
  panelDeceasedOpenState = false;
  panelRoiOpenState = false;
  responsive$: boolean;
  PersonForm = PersonForm;
  BusinessForm = BusinessForm;
  dataForm: FormAdvanceSearch;
  sectionData = [];
  rowValue = [];
  filterCemeteryControl: FormControl = new FormControl();

  form = this.formBuilder.group({
    user_cem_ids: new FormControl(null, [Validators.required]),
    plot_id: null,
    plot_type: null,
    section: null,
    row: null,
    plot_number: null,
    price_range: null,
    burial_cap: null,
    entomb_cap: null,
    cremation_cap: null,
    interment_number: null,
    interment_qty: new FormGroup({
      fromQty: new FormControl(),
      toQty: new FormControl(),
    }),
    status: new FormControl(null),
    fn: null,
    ln: null,
    dob: new FormGroup({
      startDob: new FormControl(),
      endDob: new FormControl(),
    }),
    dod: new FormGroup({
      startDod: new FormControl(),
      endDod: new FormControl(),
    }),
    doi: new FormGroup({
      startDoi: new FormControl(),
      endDoi: new FormControl(),
    }),
    age: null,
    roi_certificate: null,
    application_date: new FormGroup({
      startApplicationDate: new FormControl(),
      endApplicationDate: new FormControl(),
    }),
    expiry_date: new FormGroup({
      startExpiryDate: new FormControl(),
      endExpiryDate: new FormControl(),
    }),
    payment_date: new FormGroup({
      startPaymentDate: new FormControl(),
      endPaymentDate: new FormControl(),
    }),
    is_charged: null,
    right_of_interment: null,
    term_of_right: null,
    fee: new FormGroup({
      fromFee: new FormControl(),
      toFee: new FormControl(),
    }),
    rs: null,
    feat_story: null,
    avail_story: null,
    limit: null,
    offset: null,
  });
  isPublicNoOrg = false;
  filteredCemeteries: any;
  selectedCemeteries: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      org: any;
      isOrgLink: any;
      orgParams: any;
      isPublic: any;
      isAdmin: any;
      termOfRights: any;
      isChronicleAdmin: any;
      currentCemetery: any;
      regionalSettings: any;
    },
    // @Inject(MAT_DATE_FORMATS) private config: MyFormats,
    private routerUtilsService: RouterUtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private plotService: PlotsService,
    private ref: ChangeDetectorRef,
    private snackBarNotificationService: SnackbarNotificationService,
    private mobileResponsiveService: MobileResponsiveService,
    private dialogRef: MatDialogRef<AdvancedSearchFormComponent>,
    private ngxMaskPipe: MaskPipe,
    private adminDataService: AdminDataService,
    private amplitudeService: AmplitudeService,
    private advanceTableService: AdvanceTableServiceService
  ) {
    this.plotService.dataAdvanceSearch$
      .pipe(
        tap((data: FormAdvanceSearch) => {
          if (data) {
            this.dataForm = data;
          }
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.adminDataService.user
      .pipe(
        tap(user => {
          if (this.dialogData.isPublic) {
            const publicCemeteries = this.dialogData.org.data.reduce((acc, data) => {
              if (data.status === 'Public') {
                acc.push(data);
              }
              return acc;
            }, []);
            this.AllCemetery = publicCemeteries;
          } else {
            this.AllCemetery = this.dialogData.org.data;
          }


          this.cemeteryName = this.dialogData.org?.data?.map(data => data.name);

          if (this.dataForm !== undefined) {
            if (this.dialogData.orgParams !== undefined) {
              this.selectAllCemetery = true;
              this.isPublicNoOrg = true;
            }
            if (this.dialogData.isChronicleAdmin) {
              this.isPublicNoOrg = false;
            }
            this.patchValues(this.dataForm);
          }
          if (this.dataForm === undefined) {
            if (!this.dialogData.isChronicleAdmin) {
              this.selectAllCemetery = true;
              this.isPublicNoOrg = true;
            }
            const dataCemeteries = this.AllCemetery.map(data => data.name);
            this.form.get('user_cem_ids').patchValue(dataCemeteries);
            if (!this.dialogData.isPublic && !this.dialogData.isChronicleAdmin) {
              this.selectedCemeteries = dataCemeteries;
            }
            this.selectAllCemetery = true;
          }
          if (
            (this.dialogData.isChronicleAdmin && this.dataForm === undefined) ||
            (this.dialogData.isPublic && !this.dialogData.orgParams && this.dataForm === undefined)
          ) {
            this.form.get('user_cem_ids').patchValue(null);
            this.selectAllCemetery = false;
            this.isPublicNoOrg = false;
          }
          if (this.dialogData.orgParams && this.dataForm === undefined) {
            const dataCemeteries = this.AllCemetery.map(data => data.name);
            this.form.get('user_cem_ids').patchValue(dataCemeteries);
            this.selectedCemeteries = dataCemeteries;
            this.selectAllCemetery = true;
            this.isPublicNoOrg = true;
          }

          if (this.dialogData.currentCemetery !== null && this.dataForm === undefined) {
            const currentCemetery = this.AllCemetery.find(data => data.unique_name === this.dialogData.currentCemetery);
            this.form.get('user_cem_ids').patchValue([currentCemetery.name]);
          }
        })
      )
      .subscribe();

    this.filterCemeteryControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const lowerValue = value ? value.toLowerCase() : '';
          this.filteredCemeteries = this.AllCemetery.filter(cem => cem.name.toLowerCase().includes(lowerValue));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    if ((this.dialogData.isPublic && !this.dialogData.orgParams) || this.dialogData.isChronicleAdmin ||
      this.dialogData.currentCemetery !== null) {
      const userCemIdsControl = this.form.get('user_cem_ids');
      const a = this.AllCemetery.filter(data => data.unique_name !== this.dialogData.currentCemetery);

      a.length > 1 && this.dialogData.currentCemetery !== null ? userCemIdsControl?.disable() : userCemIdsControl?.enable();
    }
    // this.form
    //   .get('user_cem_ids')
    //   .valueChanges.pipe(
    //     debounceTime(1000),
    //     distinctUntilChanged(),
    //     concatMap(data => {
    //       if (data) {
    //         const intersection = this.AllCemetery.filter(element => data.includes(element.name));
    //         const getUniqueName = intersection.map(cem => cem.unique_name);
    //         return this.advanceTableService.getSectionsOptions({ cem_id: getUniqueName.toString(), page: 1, limit: 25 }).pipe(
    //           tap((res: any) => {
    //             if (res) {
    //               this.sectionData = res.data;
    //             }
    //           })
    //         );
    //       }
    //     }),
    //     catchError(err => {
    //       return [];
    //     })
    //   )
    //   .subscribe();

    this.getSectionOptions(this.dataForm);
  }

  ngAfterViewInit(): void {
    from(this.dateMaskTmplRef.toArray())
      .pipe(
        mergeMap(tplRef => fromEvent<InputEvent>(tplRef.nativeElement, 'input')),
        tap(event => {
          const target = event.target as HTMLInputElement;
          target.value = this.ngxMaskPipe.transform(target.value, 'd0/M0/0000');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSectionOptions2(dataForm?: FormAdvanceSearch) {
    combineLatest([
      this.form.get('user_cem_ids').valueChanges.pipe(startWith('')),
      this.form.get('section').valueChanges.pipe(startWith('')),
    ])
      .pipe(
        filter(([cemId, sectionId]) => {
          return true;
        }),
        debounceTime(1000),
        distinctUntilChanged(),
        concatMap(([cemId, sectionId]) => {
          const intersection = this.AllCemetery.filter(element => this.form.get('user_cem_ids').value.includes(element.name));
          const getUniqueName = intersection.map(cem => cem.unique_name);
          return this.advanceTableService.getSectionsOptions({ cem_id: getUniqueName.toString(), page: 1, limit: 25 }).pipe(
            tap((res: any) => {
              if (res) {
                this.sectionData = res.data;
              }
            })
          );
        })
      )
      .subscribe();
  }

  getSectionOptions(dataForm?: FormAdvanceSearch) {
    const params$ = combineLatest([
      this.form.get('user_cem_ids').valueChanges.pipe(startWith(this.form.get('user_cem_ids').value)),
      this.form.get('section').valueChanges.pipe(startWith(this.form.get('section').value)),
    ]).pipe(
      filter(([cemId, sectionId]) => {
        return cemId || sectionId;
      }),
      debounceTime(1000),
      distinctUntilChanged(),
      map(([cemId, sectionId]) => [cemId, sectionId])
    );

    params$
      .pipe(
        concatMap(([cemId, sectionId]) => {
          const intersection = this.AllCemetery.filter(element => this.form.get('user_cem_ids').value.includes(element.name));
          const getUniqueName = intersection.map(cem => cem.unique_name);
          if (sectionId) {
            return this.advanceTableService
              .getRowsOptions({ cem_id: getUniqueName.toString(), sectionid: sectionId, page: 1, limit: 25 })
              .pipe(
                tap((res: any) => {
                  if (res) {
                    this.rowValue = res.data;
                    this.ref.detectChanges();
                  }
                })
              );
          }
          if (cemId || sectionId) {
            return this.advanceTableService.getSectionsOptions({ cem_id: getUniqueName.toString(), page: 1, limit: 25 }).pipe(
              tap((res: any) => {
                if (res) {
                  this.sectionData = res.data;
                  this.ref.detectChanges();
                }
              })
            );
          }
        }),
        catchError(err => {
          return [];
        })
      )
      .subscribe();
  }

  getRowOptions(dataForm?: FormAdvanceSearch) {
    this.form
      .get('section')
      .valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        concatMap(data => {
          if (data) {
            const intersection = this.AllCemetery.filter(element => this.form.get('user_cem_ids').value.includes(element.name));
            const getUniqueName = intersection.map(cem => cem.unique_name);
            return this.advanceTableService.getRowsOptions({ cem_id: getUniqueName.toString(), sectionid: data, page: 1, limit: 25 }).pipe(
              tap((res: any) => {
                if (res) {
                  this.rowValue = res.data;
                }
              })
            );
          }
        }),
        catchError(err => {
          return [];
        })
      )
      .subscribe();
  }

  patchValues(data: FormAdvanceSearch) {
    const dateOfBirth = [
      data.dob[0] !== null ? moment(data?.dob[0], 'DD-MM-YYYY') : null,
      data.dob[1] !== null ? moment(data?.dob[1], 'DD-MM-YYYY') : null,
    ];

    const dateOfDeath = [
      data.dod[0] !== null ? moment(data?.dod[0], 'DD-MM-YYYY') : null,
      data.dod[1] !== null ? moment(data?.dod[1], 'DD-MM-YYYY') : null,
    ];

    const dateOfInterment = [
      data.doi[0] !== null ? moment(data?.doi[0], 'DD-MM-YYYY') : null,
      data.doi[1] !== null ? moment(data?.doi[1], 'DD-MM-YYYY') : null,
    ];
    const applicationDate = [
      data.application_date[0] !== null ? moment(data?.application_date[0], 'DD-MM-YYYY') : null,
      data.application_date[1] !== null ? moment(data?.application_date[1], 'DD-MM-YYYY') : null,
    ];
    const expiryDate = [
      data.expiry_date[0] !== null ? moment(data?.expiry_date[0], 'DD-MM-YYYY') : null,
      data.expiry_date[1] !== null ? moment(data?.expiry_date[1], 'DD-MM-YYYY') : null,
    ];
    const paymentDate = [
      data.payment_date[0] !== null ? moment(data?.payment_date[0], 'DD-MM-YYYY') : null,
      data.payment_date[1] !== null ? moment(data?.payment_date[1], 'DD-MM-YYYY') : null,
    ];
    const dataStatus = data?.status?.map(status => status);
    const intersection = this.dialogData.org.data.filter(element => data.user_cem_ids.includes(element.id));
    const dataCemetery = intersection.map(cem => cem.name);
    if (dataCemetery.length) {
      this.selectedCemeteries = dataCemetery;
    }
    this.form.get('user_cem_ids').patchValue(dataCemetery);
    this.form.get('plot_id').patchValue(data.plot_id);
    this.form.get('section').patchValue(data.section);
    this.form.get('row').patchValue(data.row);
    this.form.get('plot_number').patchValue(data.plot_number);
    this.form.get('status').patchValue(dataStatus);
    this.form.get('plot_type').patchValue(data.plot_type);
    this.form.get('price_range').patchValue(data.price_range);
    this.form.get('fn').patchValue(data.fn);
    this.form.get('ln').patchValue(data.ln);
    this.form.get('dob').get('startDob').patchValue(dateOfBirth[0]);
    this.form.get('dob').get('endDob').patchValue(dateOfBirth[1]);
    this.form.get('dod').get('startDod').patchValue(dateOfDeath[0]);
    this.form.get('dod').get('endDod').patchValue(dateOfDeath[1]);
    this.form.get('doi').get('startDoi').patchValue(dateOfInterment[0]);
    this.form.get('doi').get('endDoi').patchValue(dateOfInterment[1]);
    this.form.get('right_of_interment').patchValue(data.right_of_interment);
    this.form.get('roi_certificate').patchValue(data.roi_certificate);
    this.form.get('term_of_right').patchValue(data.term_of_right);
    this.form.get('application_date').get('startApplicationDate').patchValue(applicationDate[0]);
    this.form.get('application_date').get('endApplicationDate').patchValue(applicationDate[1]);
    this.form.get('payment_date').get('startPaymentDate').patchValue(paymentDate[0]);
    this.form.get('payment_date').get('endPaymentDate').patchValue(paymentDate[1]);
    this.form.get('expiry_date').get('startExpiryDate').patchValue(expiryDate[0]);
    this.form.get('expiry_date').get('endExpiryDate').patchValue(expiryDate[1]);
    this.form.get('fee').get('fromFee').patchValue(data.fee[0]);
    this.form.get('fee').get('toFee').patchValue(data.fee[1]);
    this.form.get('is_charged').patchValue(data.is_charged);
    this.form.get('interment_number').patchValue(data.interment_number);
    this.ageFrom.patchValue(data.age[0]);
    this.ageTo.patchValue(data.age[1]);

    this.optionsValue.map(res => {
      const value = data[res.name];
      this.form.get(res.name).patchValue(value);
    });
  }

  onSelectAllCemeteries() {
    if (this.selectAllCemetery) {
      this.selectAllCemetery = false;
      this.selectedCemeteries = [];
      this.form.get('user_cem_ids').patchValue(null);
    } else {
      this.selectAllCemetery = true;
      const listCemeteries = this.AllCemetery.map(data => data.name);
      this.form.get('user_cem_ids').patchValue(listCemeteries);
      this.selectedCemeteries = listCemeteries;
    }
  }

  onValChange(data) {
    this.form.get('status').patchValue(data);
  }

  onClearForm() {
    const exclude = ['user_cem_ids'];
    if (this.dialogData.currentCemetery !== null || this.dialogData.currentCemetery !== undefined) {
      Object.keys(this.form.controls).forEach(key => {
        if (exclude.findIndex(q => q === key) === -1) {
          this.form.get(key).reset();
        }
      });
      this.ageFrom.reset();
      this.ageTo.reset();
    } else {
      this.selectAllCemetery = false;
      this.form.reset();
      this.ageFrom.reset();
      this.ageTo.reset();
    }
  }

  onChangeOpenFormPerson(data) {
    const filteredData = Object.keys(this.form.value)
      .filter(key => {
        if (key.includes(data.value)) {
          return key;
        }
      })
      .filter(key => this.form.value[key] !== null);
    if (filteredData.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  change() {
    // this.config.value = this.config.value == 1 ? 2 : 1;
    // this.date = new FormControl(this.date.value);
  }

  clearInputCemetery() {
    this.filterCemeteryControl.setValue(null);
  }

  selectOptionCemetery(cemeteryName: string) {
    const index = this.selectedCemeteries.indexOf(cemeteryName);
    if (index > -1) {
      this.selectedCemeteries.splice(index, 1);
    } else {
      if (this.isPublicNoOrg || this.selectedCemeteries.length < 3) {
        this.selectedCemeteries.push(cemeteryName);
      }
    }

    this.form.get('user_cem_ids').setValue(this.selectedCemeteries);
  }

  onMatSelectBlur() {
    this.clearInputCemetery();
    this.filteredCemeteries = this.AllCemetery;
    this.form.get('user_cem_ids').setValue(this.selectedCemeteries);
  }

  isCemeteryOptionDisabled(cemName: string) {
    if (this.dialogData.currentCemetery !== null) {
      const a = this.AllCemetery.filter(data => data.unique_name !== this.dialogData.currentCemetery);
      return a.length > 1 ? true : false;
    } else {
      return this.form.get('user_cem_ids')?.value?.length >= 3 && !this.form.get('user_cem_ids')?.value?.find(el => el === cemName);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  async onSearch() {
    const exception = ['dob', 'dod', 'doi'];
    if (this.form.get('user_cem_ids').invalid) {
      this.openErrorMessage('Fill required fields');
      return;
    }
    const checkData = Object.keys(this.form.controls).filter(key => {
      if (key !== 'user_cem_ids') {
        if (key !== 'dob' && key !== 'dod' && key !== 'doi') {
          if (this.form.get(key).value !== null) {
            return key;
          }
        } else {
          const control = this.form.get(key) as FormGroup;
          if (key === 'dob') {
            if (control.get('startDob').value !== null || control.get('endDob').value !== null) {
              return key;
            }
          }
          if (key === 'dod') {
            if (control.get('startDod').value !== null || control.get('endDod').value !== null) {
              return key;
            }
          }
          if (key === 'doi') {
            if (control.get('startDoi').value !== null || control.get('endDoi').value !== null) {
              return key;
            }
          }
        }
      }
    });
    if (checkData.length === 0) {
      this.openErrorMessage('Please fill at least one field except cemetery');
      return;
    }
    if (this.dialogData.isOrgLink) {
      if (this.dialogData.currentCemetery !== null) {
        const path = this.routerUtilsService.getPath(this.dialogData.currentCemetery, 'search', 'advance');
        const queryParam: Params = this.dialogData.orgParams;

        await this.router.navigate(path, {
          queryParams: {
            org: queryParam,
          },
        });
      } else {
        const path = this.routerUtilsService.getPath('search', 'advance');
        const queryParam: Params = this.dialogData.orgParams;
        await this.router.navigate(path, {
          queryParams: {
            org: queryParam,
          },
        });
      }
    } else {
      if (this.dialogData.currentCemetery !== null) {
        const path = this.routerUtilsService.getPath(this.dialogData.currentCemetery, 'search', 'advance');
        await this.router.navigate(path);
      } else {
        const path = this.routerUtilsService.getPath('search', 'advance');
        await this.router.navigate(path);
      }
    }
    this.dialogRef.close();
    const intersection = this.AllCemetery.filter(element => this.form.get('user_cem_ids')?.value?.includes(element.name));
    const status = this.form.get('status').value ? this.form.get('status').value : [];
    const cemId = intersection.map(cem => cem.id);
    const age = [this.ageFrom.value ? this.ageFrom.value : null, this.ageTo.value ? this.ageTo.value : null];
    const dob = [
      this.form.get('dob').get('startDob').value !== null ? moment(this.form.get('dob').get('startDob').value).format('DD-MM-YYYY') : null,
      this.form.get('dob').get('endDob').value !== null ? moment(this.form.get('dob').get('endDob').value).format('DD-MM-YYYY') : null,
    ];
    const doi = [
      this.form.get('doi').get('startDoi').value !== null ? moment(this.form.get('doi').get('startDoi').value).format('DD-MM-YYYY') : null,
      this.form.get('doi').get('endDoi').value !== null ? moment(this.form.get('doi').get('endDoi').value).format('DD-MM-YYYY') : null,
    ];
    const dod = [
      this.form.get('dod').get('startDod').value !== null ? moment(this.form.get('dod').get('startDod').value).format('DD-MM-YYYY') : null,
      this.form.get('dod').get('endDod').value !== null ? moment(this.form.get('dod').get('endDod').value).format('DD-MM-YYYY') : null,
    ];
    const applicationDate = [
      this.form.get('application_date').get('startApplicationDate').value !== null
        ? moment(this.form.get('application_date').get('startApplicationDate').value).format('DD-MM-YYYY')
        : null,
      this.form.get('application_date').get('endApplicationDate').value !== null
        ? moment(this.form.get('application_date').get('endApplicationDate').value).format('DD-MM-YYYY')
        : null,
    ];
    const expiryDate = [
      this.form.get('expiry_date').get('startExpiryDate').value !== null
        ? moment(this.form.get('expiry_date').get('startExpiryDate').value).format('DD-MM-YYYY')
        : null,
      this.form.get('expiry_date').get('endExpiryDate').value !== null
        ? moment(this.form.get('expiry_date').get('endExpiryDate').value).format('DD-MM-YYYY')
        : null,
    ];
    const intermentQty = [
      this.form.get('interment_qty').get('fromQty').value !== null ? this.form.get('interment_qty').get('fromQty').value : null,
      this.form.get('interment_qty').get('toQty').value !== null ? this.form.get('interment_qty').get('toQty').value : null,
    ];

    const fee = [
      this.form.get('fee').get('fromFee').value !== null ? this.form.get('fee').get('fromFee').value : null,
      this.form.get('fee').get('toFee').value !== null ? this.form.get('fee').get('toFee').value : null,
    ];
    const paymentDate = [
      this.form.get('payment_date').get('startPaymentDate').value !== null
        ? moment(this.form.get('payment_date').get('startPaymentDate').value).format('DD-MM-YYYY')
        : null,
      this.form.get('payment_date').get('endPaymentDate').value !== null
        ? moment(this.form.get('payment_date').get('endPaymentDate').value).format('DD-MM-YYYY')
        : null,
    ];

    const data = {
      ...this.form.value,
      status,
      user_cem_ids: cemId,
      age,
      dob,
      doi,
      dod,
      application_date: applicationDate,
      expiry_date: expiryDate,
      interment_qty: intermentQty,
      payment_date: paymentDate,
      fee,

      limit: 10,
    };
    this.plotService.updateisNeed(true);
    this.plotService.updateDataAdvanceSearch(data);
    this.amplitudeService.track('Advanced Search Clicks ', { request: data });
  }

  private openSuccessMessage(successMessage: string) {
    this.snackBarNotificationService.openSuccessMessage(successMessage);
  }

  private openErrorMessage(errorMessage: string) {
    this.snackBarNotificationService.openErrorMessage(errorMessage);
  }
}
