<perfect-scrollbar [config]="verticalPerfectScrollbarConfig">
  <cl-search-results header="Search result">
    <cl-search-cemetery-item
      *ngFor="let cemetery of cemeteries"
      [searchValue]="searchValue"
      [cemeteryInfo]="cemetery"
      (click)="openCemetery(cemetery)"
    ></cl-search-cemetery-item>

    <cl-search-result-nothing-found *ngIf="cemeteries?.length === 0"></cl-search-result-nothing-found>
  </cl-search-results>
</perfect-scrollbar>
