import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'yearInRegionalFormat',
})
export class YearInRegionalFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: Date | string): string {
    const format = 'yyyy';
    const isUTC = value.toString().substr(value.toString().length - 1) === 'Z';

    return isUTC ? new DatePipe('en-US').transform(value, format, 'GMT+1') : this.datePipe.transform(value, format);
  }
}
