import { Injectable } from '@angular/core';
import { fromPromise } from 'rxjs/internal-compatibility';
import { finalize, tap } from 'rxjs/operators';
import { LoadingService } from '../../loading-overlay/loading.service';

@Injectable({
  providedIn: 'root',
})

/**
 * Converting to blob used for images, that stored on another origin (for example saving without converting
 * to blob wouldn't work locally). If we sure, that images will be on the same origin, as web-site,
 * we could use only save() method (pass source link there as param and assign it to href attribute )
 * and throw off converting to blob.
 */
export class DownloadImageService {
  constructor(private readonly loader: LoadingService) {}

  download(url: string) {
    this.loader.run();
    this.toBlob(url)
      .pipe(
        tap(blob => this.save(blob)),
        finalize(() => this.loader.stop())
      )
      .subscribe();
  }

  save(blob, name = 'image.png') {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.target = '_blank';
    a.download = name;
    a.click();
  }

  toBlob(src: string) {
    return fromPromise(
      new Promise(res => {
        const img = document.createElement('img');
        const c = document.createElement('canvas');
        const ctx = c.getContext('2d');
        img.onload = (data: any) => {
          c.width = data.target.naturalWidth;
          c.height = data.target.naturalHeight;
          ctx.drawImage(data.target, 0, 0);
          c.toBlob(b => res(b), 'image/jpeg', 0.75);
        };
        img.crossOrigin = '';
        img.src = src;
      })
    );
  }
}
