export enum orderType {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

export function orderPerson(person: any[], order: orderType) {
  // sorting the person
  // if the date of death ia available then sort by date of death in selected order
  // if not the sort by id in selected order

  // check if the date of death is available on all the person
  const isDateOfDeathAvailable = person.every((p: any) => p.date_of_death);
  if (isDateOfDeathAvailable) {
    person = person.sort((a: any, b: any) => {
      const dateOfDeathA = new Date(a.date_of_death);
      const dateOfDeathB = new Date(b.date_of_death);
      return order === orderType.ASC ? dateOfDeathA.getTime() - dateOfDeathB.getTime() : dateOfDeathB.getTime() - dateOfDeathA.getTime();
    });
  } else {
    person = person.sort((a: any, b: any) => {
      return order === orderType.ASC ? a.id - b.id : b.id - a.id;
    });
  }
  return person;
}
