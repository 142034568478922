import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.PRODUCTION) {
  enableProdMode();
}

export let applicationInjector: Injector;

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(module => (applicationInjector = module.injector))
    .catch(err => console.error(err));
});
