import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
@Component({
  selector: 'cl-copy-clipboard-text',
  templateUrl: './copy-clipboard-text.component.html',
  styleUrls: ['./copy-clipboard-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyClipboardTextComponent implements OnInit {
  @Input() value: string;

  constructor(
    private clipboard: Clipboard,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private snackBarNotificationService: SnackbarNotificationService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'custom_copy_to_clipboard', src: '/assets/images/copy.svg' }]);
  }

  copy() {
    this.clipboard.copy(this.value);
    this.openSuccessCopied('Sucessfully copied to clipboard');
  }

  ngOnInit(): void {}

  private openSuccessCopied(message: string) {
    this.snackBarNotificationService.openSuccessMessage(message);
  }
}
