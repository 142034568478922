import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MapService } from './map.service';
import { tap } from 'rxjs/operators';
import Invoker from '../invoker';
import { InvokerState } from '../invoker-state';
import { CemeteryInfo } from '../interfaces/cemetery-info';
import { PlotDetails } from '../interfaces/plot-details';
import { CemeteryEventResponse } from '../interfaces/cemetery-event';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { DataSection, SectionType } from '../interfaces/section-data';
import { MapDataService } from './map-data.service';
import { AmplitudeService } from './amplitude.service';
import { RouterUtilsService } from './router-utils.service';
import { NavigateBackService } from './navigate-back.service';
import { AppRoutes } from '../enums/app-routes.enum';

@Injectable({
  providedIn: 'root',
})
export class MapActionsService extends Invoker {
  private map;

  eventMarkers;

  currentCenter;

  protected get state(): InvokerState {
    return this.map ? 'run' : 'cache';
  }

  constructor(
    private readonly mapService: MapService,
    private readonly mapDataService: MapDataService,
    private router: Router,
    @Inject(PLATFORM_ID)
    private platformId: any,
    private amplitudeService: AmplitudeService,
    private routerUtilsService: RouterUtilsService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
    this.mapService.map$
      .pipe(
        tap(map => {
          this.map = map;

          if (map) {
            this.invoke();
          }
        })
      )
      .subscribe();
  }

  flyToBounds(bounds: any, options?: any) {
    this.run(() => this.map.flyToBounds(bounds, options));
  }

  flyTo(bounds: any, zoom?: number) {
    const latLng = bounds.getCenter();
    this.run(() => this.map.flyTo(latLng, zoom || null));
  }

  fitBounds(bounds: any, options?: any) {
    this.run(() => this.map.fitBounds(bounds, options));
  }

  setView([lat, lng]: [number, number], zoom: number, rotation?: number) {
    this.run(() => {
      const isSearch = this.router.url.includes('search');
      if (isSearch) {
        this.map.setMaxBounds([
          [-90, -360],
          [90, 360],
        ]);
      }

      this.map.setMaxZoom(zoom > 26 ? 30 : 24);
      this.mapDataService.setEmitMoveend = true;
      this.map.setView([lat, lng], zoom);
      this.map.setBearing(rotation ?? 0);
    });
  }

  setViewPlotNiche([lat, lng]: [number, number], zoom: number, rotation?: number) {
    this.run(() => {
      const isSearch = this.router.url.includes('search');
      if (isSearch) {
        this.map.setMaxBounds([
          [-90, -360],
          [90, 360],
        ]);
      }

      this.map.setMaxZoom(30);
      this.map.setZoom(zoom);
      this.mapDataService.setEmitMoveend = true;
      this.map.setView([lat, lng], zoom);
      this.map.setBearing(rotation ?? 0);
    });
  }

  setViewNicheWallAdvanceSearch(bounds: any, zoom: number, rotate?: number) {
    this.run(() => {
      this.map.setMaxZoom(30);
      this.map.setZoom(27);
      const latLng = bounds.getCenter();
      this.map.setView([latLng.lat, latLng.lng], 27);
      this.map.setBearing(rotate ?? 0);
    });
  }

  setViewNicheWallAdvanceSearchDetail(data: any, bounds: any, zoom: number, rotate?: number) {
    this.run(() => {
      this.map.setMaxZoom(30);
      this.map.setZoom(27);
      const latLng = bounds.getCenter();
      this.map.setView([latLng.lat, latLng.lng], 27);
      this.map.setBearing(rotate ?? 0);

      setTimeout(async () => {
        const routerArr = this.router.url.split('/').filter(r => {
          if (
            r.includes(AppRoutes.ChronicleAdmin) ||
            r.includes(AppRoutes.CustomerAdmin) ||
            r.includes(AppRoutes.CustomerManager) ||
            r.includes(AppRoutes.CustomerOrganization) ||
            r.includes(AppRoutes.CustomerViewer) ||
            r.includes(AppRoutes.Freemium)
          ) {
            return r;
          }
        });

        let pathDest: any;
        if (routerArr.length > 0) {
          pathDest = this.routerUtilsService.getPath(routerArr[0], data.unique_name, 'plots', data.plot_id);
        } else {
          pathDest = this.routerUtilsService.getPath(data.unique_name, 'plots', data.plot_id);
        }
        await this.router.navigate(pathDest, {
          queryParams: { backTo: this.router.url, from: 'map', zoom: 27 },
        });
      }, 1000);
    });
  }

  backToGlobalView() {
    this.run(() => {
      this.map.setBearing(0);
      this.map.setView([0, 0], 2);
    });
  }

  showCemetery(cemetery: CemeteryInfo) {
    if (isPlatformBrowser(this.platformId)) {
      const bounds = L.geoJSON(cemetery.bounds).getBounds();
      this.fitBounds(bounds);
    }
  }
  // koyo new
  showNicheWall(section: DataSection) {
    if (isPlatformBrowser(this.platformId)) {
      const reverseCoords = section.polygon.coordinates.map(coords => coords.map(coord => coord.reverse()));
      const bounds = L.polygon(reverseCoords).getBounds();
      const latLng = bounds.getCenter();
      this.run(() => {
        this.map.setMaxZoom(30);
        this.map.setView([latLng.lat, latLng.lng], 27);
        this.map.setBearing(section.rotate_degree ?? 0);
      });
    }
  }

  showCemeteryWithAnimation(cemetery: CemeteryInfo) {
    if (isPlatformBrowser(this.platformId)) {
      const bounds = L.geoJSON(cemetery.bounds).getBounds();
      this.flyToBounds(bounds);
      this.amplitudeService.track('World Map Cemetery Clicked', cemetery);
    }
  }

  showCemeteryWithoutAnimation(cemetery: CemeteryInfo) {
    if (isPlatformBrowser(this.platformId)) {
      const bounds = L.geoJSON(cemetery.bounds).getBounds();
      this.flyToBounds(bounds, { animate: false });
      this.map.setBearing(0);
    }
  }

  showPlot(plot: PlotDetails) {
    const isSearch = this.router.url.includes('&from=search');
    const isNichewall = plot.section_link.section_type === SectionType.NicheWall;
    const rotation = plot?.section_link?.section_rotate_degree;
    let currZoom = this.map?.getZoom();

    if (!currZoom || currZoom < 21 || isSearch) {
      currZoom = 24;
    }

    if (isNichewall) {
      const regex = /zoom=(\d+)/;
      const zoomFromURL = regex.exec(this.router.url);
      // append query params niche wall to url
      this.router.navigate([], {
        queryParams: { view: 'niche-wall', sectionId: plot.section_link.section_id },
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute,
      });

      currZoom = zoomFromURL && +zoomFromURL[1] > 27 ? +zoomFromURL[1] : this.map?.getZoom() > 26 ? this.map?.getZoom() : 27;
    }

    if (!isNichewall && (this.router.url.includes('search') || this.map?.getZoom() > 26)) {
      currZoom = 24;
    }

    if (isPlatformBrowser(this.platformId) && currZoom < this.map?.getZoom()) {
      const poly = L.geoJSON(plot.coordinates).getBounds().getCenter();
      this.mapDataService.setEmitMoveend = true;
      this.map.setMaxBounds([
        [-90, -360],
        [90, 360],
      ]);
      this.map.setView(poly, currZoom);
      this.map.setMaxZoom(24);
      this.map.setBearing(rotation ?? 0);
      return;
    }

    if (isPlatformBrowser(this.platformId)) {
      if (isNichewall) {
        const poly = L.geoJSON(plot.coordinates).getBounds().getCenter();
        const regex = /zoom=(\d+)/;
        const zoomFromURL = regex.exec(this.router.url);
        currZoom = zoomFromURL && +zoomFromURL[1] > 27 ? +zoomFromURL[1] : this.map?.getZoom() > 26 ? this.map?.getZoom() : 27;
        this.setViewPlotNiche([poly.lat, poly.lng], currZoom, rotation);
      } else {
        if (currZoom < 27) {
          const poly = L.geoJSON(plot.coordinates).getBounds().getCenter();
          this.setView([poly.lat, poly.lng], currZoom, rotation);
        }
      }
    }
  }
  // {
  //   "lat": -34.632319484293404,
  //   "lng": 148.04475606415184
  // }
  showLocationByCoordinate(coordinates: any) {
    this.flyToBounds(L.geoJSON(coordinates).getBounds());
  }

  showLocationByCoordinateAndUpdateRoute(record: any) {
    const standardDuration = 1;
    const standardDistance = 12000000;
    const center = this.map.getBounds().getCenter();
    const distance = center.distanceTo(L.geoJSON(record.coordinates).getBounds().getCenter());
    const timeDuration = (standardDuration * distance) / standardDistance;
    this.flyToBounds(L.geoJSON(record.coordinates).getBounds(), {});

    setTimeout(() => {
      const routerArr = this.router.url.split('/').filter(r => {
        if (
          r.includes(AppRoutes.ChronicleAdmin) ||
          r.includes(AppRoutes.CustomerAdmin) ||
          r.includes(AppRoutes.CustomerManager) ||
          r.includes(AppRoutes.CustomerOrganization) ||
          r.includes(AppRoutes.CustomerViewer) ||
          r.includes(AppRoutes.Freemium)
        ) {
          return r;
        }
      });
      let pathDest: any;
      if (routerArr.length > 0) {
        pathDest = this.routerUtilsService.getPath(routerArr[0], record.unique_name, 'plots', record.plot_id);
      } else {
        pathDest = this.routerUtilsService.getPath(record.unique_name, 'plots', record.plot_id);
      }

      this.router.navigate(pathDest, {
        queryParams: { backTo: this.router.url, from: 'map', zoom: 24 },
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute.parent,
      });
    }, timeDuration * 10000);
  }

  showEvents(events: CemeteryEventResponse[]) {
    // const makers = [L.marker([39.61, -105.02])];
    // this.eventMarkers = L.layerGroup(makers);
    // this.map.addLayer(this.eventMarkers);
  }

  removeEvents() {
    // if (this.eventMarkers) {
    //   this.map.removeLayer(this.eventMarkers);
    // }
  }

  zoomToCemeteries(cemeteries: CemeteryInfo[]) {
    if (isPlatformBrowser(this.platformId)) {
      const bounds = cemeteries.map(cemetery => L.geoJSON(cemetery.bounds).getBounds());
      this.fitBounds(bounds);
    }
  }

  // liat disini
  navigateToCemeteryWithDelay(cemetery: CemeteryInfo) {
    if (this.map.getZoom() >= 15) {
      this.map.setZoom(14);
      setTimeout(() => {
        this.showCemeteryWithAnimation(cemetery);
      }, 300);
    } else {
      this.showCemeteryWithAnimation(cemetery);
    }
  }
}
