<ng-container>
  <cl-toolbar-organization (backToPrevious)="backToPrev()" [showHeader]="false"> </cl-toolbar-organization>
</ng-container>

<div class="main-content">
  <h1>Life Chronicle Story</h1>
  <div class="form-card-wrapper">
    <form [formGroup]="form">
      <div class="form-container">
        <mat-expansion-panel hideToggle [expanded]="true" *ngIf="selectedPerson === null" [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title> Select person </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="search-person">
            <mat-form-field class="example-form-field">
              <mat-label>Search in {{ this.cemeteryDetail?.name }}</mat-label>
              <input (keydown)="$event.stopPropagation()" [formControl]="filterControl" matInput type="text" />
              <button mat-button matPrefix mat-icon-button aria-label="Search">
                <ng-container *ngIf="isLoadingPerson; else searchIcon">
                  <mat-spinner diameter="24" color="primary"></mat-spinner>
                </ng-container>
                <ng-template #searchIcon>
                  <mat-icon svgIcon="custom_autocomplete_base_search"></mat-icon>
                </ng-template>
              </button>
            </mat-form-field>

            <ng-container *ngIf="!isLoadingPerson">
              <div class="records-results">
                <cl-search-record-item
                  *ngFor="let record of records"
                  [searchValue]="searchValue"
                  [photo]="(record.plot?.images?.slice(0, 1))[0]?.url"
                  [dob]="record.date_of_birth"
                  [dod]="record.date_of_death"
                  [plotId]="record.plot.plot_id"
                  [person]="record.person"
                  [plot]="record.plot"
                  [isReturnServicePerson]="checkIsReturnServicePerson(record)"
                  [isFeaturedStory]="checkIsFeaturedStory(record)"
                  [isLifeChronicle]="checkIsLifeChronicle(record)"
                  [org]="organizationCemetery"
                  [cemeteryUniqueName]="cemeteryDetail?.unique_name"
                  (click)="openConfirmDialog(record)"
                ></cl-search-record-item>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle [disabled]="isSectionDisabled" [expanded]="step === 1" (opened)="setStep(1)">
          <mat-expansion-panel-header>
            <mat-panel-title> Contact Details </mat-panel-title>
            <mat-panel-description *ngIf="step !== 1 && step !== 0">
              <div class="name">{{ this.form.get('first_name').value }} {{ this.form.get('last_name').value }}</div>
              <div class="container">
                <div class="label">Phone</div>
                <div class="key-phone">{{ this.form.get('phone_number').value }}</div>
              </div>
              <div class="container">
                <div class="label">Email</div>
                <div class="key-email">{{ this.form.get('email').value }}</div>
              </div>
              <div class="container">
                <div class="label">Relationship to deceased</div>
                <div class="key-relation">{{ this.form.get('relationship_interment').value }}</div>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="form-details-container">
            <div class="container-name">
              <mat-form-field class="example-form-field">
                <mat-label>First Name</mat-label>
                <input type="text" formControlName="first_name" matInput placeholder="First Name" />
                <mat-error *ngIf="form.get('first_name').hasError('required')">First Name is required</mat-error>
              </mat-form-field>
              <mat-form-field class="example-form-field">
                <mat-label>Last Name</mat-label>
                <input type="text" formControlName="last_name" matInput placeholder="Last Name" />
                <mat-error *ngIf="form.get('last_name').hasError('required')">Last Name is required</mat-error>
              </mat-form-field>
            </div>
            <div class="container-addition-data">
              <mat-form-field class="phone-form-field">
                <mat-label>Phone (Mobile)</mat-label>
                <input type="tel" formControlName="phone_number" matInput placeholder="Phone (Mobile)" />
                <mat-error *ngIf="form.get('phone_number').hasError('required')">Phone Number is required</mat-error>
              </mat-form-field>
              <mat-form-field class="email-form-field">
                <mat-label>E-mail</mat-label>
                <input type="email" formControlName="email" matInput placeholder="johndoe@gmail.com" />
                <mat-error *ngIf="form.get('email').hasError('required')">Email is required</mat-error>
              </mat-form-field>
              <mat-form-field class="relation-form-field">
                <mat-label>Relation to deceased</mat-label>
                <input type="text" formControlName="relationship_interment" matInput placeholder="Son" />
                <mat-error *ngIf="form.get('relationship_interment').hasError('required')">Relation to deceased is required</mat-error>
              </mat-form-field>
            </div>
            <div class="button-next-form">
              <button (click)="this.nextStep()" class="button-next" mat-flat-button color="primary">{{ 'continue' | uppercase }}</button>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel hideToggle [disabled]="isSectionDisabled" [expanded]="step === 2" (opened)="setStep(2)">
          <mat-expansion-panel-header>
            <mat-panel-title> Story </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="form-story-container">
            <div class="container-title">
              <mat-form-field class="example-form-field">
                <mat-label>Title</mat-label>
                <input formControlName="title" matInput placeholder="Story about my father" />
              </mat-form-field>
            </div>
            <div class="container-story">
              <mat-form-field>
                <mat-label>Story</mat-label>
                <textarea formControlName="text" matInput placeholder="Story" rows="7"></textarea>
              </mat-form-field>
            </div>
            <div class="container-upload-image">
              <cl-upload-image-shared
                [images]="intermentStoryImages"
                (files)="uploadIntermentStoryPhotos($event)"
                [title]="''"
                (previewIndex)="openImageStoryPreview($event)"
                [public]="public"
              ></cl-upload-image-shared>
            </div>
            <div class="container-preview-story">
              <button (click)="onPreviewStory()" class="button-preview" style="height: 48px" mat-stroked-button color="primary">
                <mat-icon svgIcon="preview-story-icon"></mat-icon>PREVIEW
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </form>

    <div class="right-card-container">
      <div class="card-header">
        <div [ngClass]="this.selectedPerson && this.selectedPerson.plot.images.length > 0 ? 'header-img' : 'header-photo'">
          <img
            [src]="
              this.selectedPerson && this.selectedPerson.plot.images.length > 0
                ? this.selectedPerson.plot.images[0].url || this.selectedPerson.plot.images[0].image_path
                : '/assets/images/placeholder-question-lc.svg'
            "
            alt="plot image"
            [ngClass]="this.selectedPerson && this.selectedPerson.plot.images.length > 0 ? 'plot-img' : 'plot-img-placeholder'"
          />
        </div>
        <ng-container *ngIf="!this.selectedPerson">
          <div class="header-desc">Person is not selected</div>
        </ng-container>
        <ng-container *ngIf="this.selectedPerson">
          <div class="header-desc-person">
            <div class="cemetery-detail">
              {{ this.selectedPerson.cemetery_name || this.selectedPerson.cemetery.name }} -
              <span class="plot-detail">{{ this.selectedPerson.plot_id || this.selectedPerson.plot.plot_id }}</span>
            </div>
            <div class="person-name">
              {{ this.selectedPerson.person.first_name }}
              {{ this.selectedPerson.person.last_name }}
            </div>
            <div class="person-date">
              {{ this.selectedPerson.date_of_birth | date: 'd/MM/yyy' }} -
              {{ this.selectedPerson.date_of_death | date: 'd/MM/yyy' }}
            </div>
          </div>
        </ng-container>
        <div class="header-price">{{ this.cemeteryDetail?.database_location === 'AUS' ? 'AU$65' : 'US$49' }}</div>
      </div>
      <div class="card-desc">
        <p>
          Your application will be assessed by the cemetery administration team before publication. You will be notified within a week of
          approval
        </p>
        <p>
          By submission of this form, you agree with
          <span (click)="onClickTermsAndConditions()" style="color: rgba(103, 58, 183, 1); cursor: pointer">Terms of Use</span>
        </p>
      </div>
      <div class="notification-wrapper">
        <div class="warning-notification">
          <mat-icon style="display: flex; flex: none" svgIcon="warning-icon"></mat-icon>
          <div>Please carefully check your submission. Once submitted your story cannot be edited.</div>
        </div>
        <div class="credit-card-notification">
          <mat-icon style="display: flex; flex: none" svgIcon="credit-card-icon"></mat-icon>
          <div>You will be directed to payment provider to complete this purchase.</div>
        </div>
        <!-- <div class="credit-card-checkbox">
          <mat-checkbox>Save card in my account for future purchases</mat-checkbox>
        </div> -->
      </div>
      <button style="height: 48px" mat-stroked-button color="primary" (click)="onSubmitForm()">SUBMIT A MEMORIAL</button>
    </div>
  </div>
</div>
