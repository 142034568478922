<div class="wrapper-banner-success">
  <div class="container-header">
    <div class="title">Life Chronicle memorial submitted</div>
    <div (click)="onCancel()" class="icon-top-close">
      <mat-icon svgIcon="close"></mat-icon>
    </div>
  </div>
  <div class="container-body">
    <div class="notification-body">
      <div class="info-icon">
        <mat-icon svgIcon="info"></mat-icon>
      </div>
      <div class="text-notification">Your submission is under review and will be published within 7 days if approved.</div>
    </div>
    <div class="desc-body">
      The memories of your loved ones will live forever. By sharing this story you have contributed to the resting place of your loved ones.
    </div>
    <div class="button-body">
      <button (click)="onCancel()" class="done-button" mat-flat-button color="primary">{{ 'done' | uppercase }}</button>
    </div>
  </div>
</div>
