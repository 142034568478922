import { Injectable } from '@angular/core';
import { ImageUploaderSharedService } from './image-uploader-shared.service';
import { FileUploaderSharedService } from './file-uploader-shared.service';
import { UploaderTypeEnum } from 'src/app/enums/uploader-type.enum';

@Injectable({
  providedIn: 'root'
})
export class UploaderManagerFactorySharedService {
  constructor(private imageUploaderService: ImageUploaderSharedService, private fileUploaderService: FileUploaderSharedService) {}

  create(type: UploaderTypeEnum) {
    switch (type) {
      case UploaderTypeEnum.image:
        return this.imageUploaderService;
      case UploaderTypeEnum.file:
        return this.fileUploaderService;
    }
  }
}
