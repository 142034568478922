import * as Joi from 'joi';
import { addressValidator } from './address-validator';
import { regionalSettingsValidator } from './regional-settings-validator';
import { userBaseValidator } from './user-base-validator';

export const organizationDetailsValidator = userBaseValidator.append({
  business_name: Joi.string().allow(null),
  business_code: Joi.string().allow(null),
  business_address: addressValidator,
  postal_address: addressValidator,
  regional_settings: regionalSettingsValidator,
  access_demo: Joi.object({ active: Joi.boolean(), disable: Joi.boolean() }),
});
