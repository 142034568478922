import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CustomInputType } from '../../interfaces/custom-input';
import { MIN_DATE } from '../../interfaces/datepicker-dates';
import { isDateInputValid } from '../date-input-validator';

@Component({
  selector: 'cl-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomInputComponent implements OnInit {
  minDate = MIN_DATE;
  @Input() group: FormGroup;
  @Input() inputType: CustomInputType;
  @Input() listOption: any[];
  @Input() formName: string;
  @Input() value: any;
  @Input() label: string;
  @Input() visibility?: 'Public' | 'Internal' | undefined;
  @Input() for?: 'Plot' | 'Interment' | 'ROI';
  @Input() showAsterisk?: boolean;
  @Input() key: string;
  @Input() allowEnter = true;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.checkIsServiceNeed();
  }

  validateDate(e) {
    if (!isDateInputValid(e.key)) {
      e.preventDefault();
    }
  }

  parseMessage(type: 'Plot' | 'Interment' | 'ROI'): string {
    const lookup = {
      Plot: 'Will be shown on Vacant plot status only for public',
      Interment: 'Will be shown on Occupied plot status only for public',
      ROI: 'Will be shown on Reserved plot status only for public',
    };
    return lookup[type];
  }

  checkBoxChange(e, targetControl: AbstractControl) {
    if (e.checked) {
      targetControl.setValue(true);
    } else {
      targetControl.setValue(null);
    }
  }

  checkIsServiceNeed() {
    const serviceNeed = this.group.get('key')?.value === 'service_need';
    if (serviceNeed) {
      const value1 = this.group.get('values')?.value;
      const value2 = this.group.get('value')?.value;

      if (JSON.stringify(value1[0]) === JSON.stringify(value2) || this.listOption.length === 1) {
        this.group.get(this.formName).setValue(this.listOption[0], { emitEvent: false });
      } else {
        this.group.get(this.formName).setValue(value2, { emitEvent: false });
      }
      this.ref.detectChanges();
    }
  }

  keydown(e: any) {
    if (e.key === 'Enter' && !this.allowEnter) {
      e.preventDefault();
    }
  }
}
