<perfect-scrollbar #perfectScroll>
  <div class="stepper">
    <div class="close-icon" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </div>
    <div class="stepper-wrapper">
      <div class="header-title">{{ changeLabelHeader().main }}</div>
      <div class="body-text">{{ changeLabelHeader().desc }}</div>
      <div class="label-info-wrapper">
        <div class="label-info-interment">
          <div>Interment Name</div>
          <ng-container *ngIf="this.dialogData.listInterments.length > 1">
            <div class="box-info-interment">
              {{ selectedInterment?.person['first_name'] ? selectedInterment?.person['first_name'] : '&nbsp;' }}
              {{ selectedInterment?.person['middle_name'] ? selectedInterment?.person['middle_name'] : '&nbsp;' }}
              {{ selectedInterment?.person['last_name'] ? selectedInterment?.person['last_name'] : '&nbsp;' }}
            </div>
          </ng-container>
          <ng-container *ngIf="this.dialogData.listInterments.length <= 1">
            <div class="box-info-interment">
              {{ dialogData.listInterments[0]?.person['first_name'] ? dialogData.listInterments[0]?.person['first_name'] : '&nbsp;' }}
              {{ dialogData.listInterments[0]?.person['middle_name'] ? dialogData.listInterments[0]?.person['middle_name'] : '&nbsp;' }}
              {{ dialogData.listInterments[0]?.person['last_name'] ? dialogData.listInterments[0]?.person['last_name'] : '&nbsp;' }}
            </div>
          </ng-container>
        </div>
        <div class="label-info-plot">
          <div>Plot ID</div>
          <ng-container *ngIf="this.dialogData.listInterments.length > 1">
            <div class="box-info-plot">{{ selectedInterment?.plot_id ? selectedInterment?.plot_id : '&nbsp;' }}</div>
          </ng-container>
          <ng-container *ngIf="this.dialogData.listInterments.length <= 1">
            <div class="box-info-plot">
              {{ dialogData.listInterments[0]?.plot_id ? dialogData.listInterments[0]?.plot_id : '&nbsp;' }}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="form-outer">
        <form [formGroup]="form">
          <mat-horizontal-stepper orientation="horizontal" [linear]="false" #stepper>
            <mat-step *ngIf="dialogData.listInterments.length > 1">
              <div class="wrapper-interment">
                <mat-selection-list #shoes [multiple]="false">
                  <mat-list-option
                    *ngFor="let interment of dialogData.listInterments"
                    [value]="interment.id"
                    (click)="onSelectType(interment)"
                  >
                    <div class="container-list-interment">
                      <div class="divider"></div>
                      <div class="container-interment-info">
                        <div class="person-name">{{ interment.person | name }}</div>
                        <cl-display-age [interment]="interment"></cl-display-age>
                      </div>
                    </div>
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </mat-step>
            <mat-step>
              <div class="title-contact-detail">Contact details</div>
              <div class="desc-contact-detail">More detail about you</div>

              <div class="form-contact-detail">
                <div class="input-wrapper">
                  <div class="label">First Name</div>
                  <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput placeholder="John" formControlName="first_name" required />
                  </mat-form-field>
                </div>

                <div class="input-wrapper">
                  <div class="label">Last Name</div>
                  <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput placeholder="Doe" formControlName="last_name" required />
                  </mat-form-field>
                </div>

                <div class="input-wrapper">
                  <div class="label">Phone Number</div>
                  <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput type="number" placeholder="1235554433" formControlName="phone_number" required />
                  </mat-form-field>
                </div>
                <div class="input-wrapper">
                  <div class="label">Email</div>
                  <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput type="email" placeholder="Email address" formControlName="email" required [(ngModel)]="emailStory" />
                  </mat-form-field>
                </div>
              </div>
              <div class="relation-contact-detail">
                <div class="label">Relationship to Interment. I am their:</div>
                <!-- <mat-form-field class="story-type">
                    <mat-label>Relationship Type</mat-label>
                    <mat-select formControlName="relationship_interment" placeholder="none" aria-label="Relationship Type">
                      <mat-option [value]="null">none</mat-option>
                      <ng-container *ngFor="let storyType of storyTypeList">
                        <mat-option [value]="storyType.value">{{ storyType.text }}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field> -->
                <mat-form-field appearance="outline" floatLabel="never">
                  <input matInput type="text" placeholder="eg. Son, Daughter, Father" formControlName="relationship_interment" required />
                </mat-form-field>
              </div>
            </mat-step>
            <mat-step>
              <div class="input-title">
                <div>Story title</div>
                <mat-form-field appearance="outline" floatLabel="auto">
                  <input [(ngModel)]="titleStory" formControlName="title" matInput placeholder="A life well lived" />
                </mat-form-field>
              </div>
              <div class="input-story">
                <div class="label-story">Content story</div>
                <angular-editor [config]="configs" formControlName="text" [(ngModel)]="contentStory"></angular-editor>
              </div>
              <div class="input-photos">
                <div>Image Upload</div>
                <cl-upload-image
                  [images]="intermentStoryImages"
                  (files)="uploadIntermentStoryPhotos($event)"
                  [title]="''"
                  (previewIndex)="openImageStoryPreview($event)"
                  [public]="public"
                ></cl-upload-image>
              </div>
            </mat-step>
            <mat-step>
              <div class="preview-wrapper">
                <div class="preview-title">Preview</div>
                <div class="preview-story-card">
                  <div class="title-story">{{ titleStory || 'Insert a title' }}</div>
                  <div class="content-story" [innerHTML]="contentStory | safeHtml"></div>
                  <div class="image-story">
                    <div *ngFor="let image of intermentStoryImages; let i = index">
                      <img [src]="image.url ? image.url : image.image" alt="" class="preview-image-story" />
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
            <mat-step>
              <ng-container *ngIf="isLoadingAction">
                <cl-success-submit-story-skeleton></cl-success-submit-story-skeleton>
              </ng-container>
              <ng-container *ngIf="!isLoadingAction">
                <div class="submit-image-wrapper">
                  <img src="/assets/images/check-box.png" class="submit-image" />
                </div>
                <div class="submit-title">Almost there.</div>
                <div class="submit-description-wrapper">
                  <div class="submit-description-text">
                    Please proceed to payment to submit your story. <br />
                    After submitted, your story will be reviewed by the cemetery admin before being published. Stories considered
                    inappropriate by the cemetery admin may get rejected at their sole discretion.
                    <br />
                    A full refund will be issued if your story is rejected. All stories are publicly visible on map.chronicle.rip
                  </div>
                </div>
                <div class="welcome-checkbox-container">
                  <mat-checkbox class="welcome-checkbox" #confirmed></mat-checkbox>
                  <span (click)="onClickTOC()">Agree to terms and conditions</span>
                </div>
                <div class="submit-payment-button">
                  <a mat-raised-button color="primary" [disabled]="!confirmed.checked" (click)="mountElementStripe()">GO TO PAYMENT</a>
                </div>
              </ng-container>
            </mat-step>
            <ng-container *ngIf="dialogData.listInterments.length <= 1">
              <ng-template matStepperIcon="edit" let-index="index">
                <span>{{ index === 0 ? '1' : index === 1 ? '2' : index === 2 ? '3' : '4' }}</span>
              </ng-template>
              <ng-template matStepperIcon="done" let-index="index">
                <span>{{ index === 0 ? '1' : index === 1 ? '2' : index === 2 ? '3' : '4' }}</span>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="dialogData.listInterments.length > 1">
              <ng-template matStepperIcon="edit" let-index="index">
                <span>{{ index === 0 ? '1' : index === 1 ? '2' : index === 2 ? '3' : index === 3 ? '4' : '5' }}</span>
              </ng-template>
              <ng-template matStepperIcon="done" let-index="index">
                <span>{{ index === 0 ? '1' : index === 1 ? '2' : index === 2 ? '3' : index === 3 ? '4' : '5' }}</span>
              </ng-template>
            </ng-container>
          </mat-horizontal-stepper>
        </form>
        <div [class.hide-button]="isIntermentSingle()" class="button-contact-detail">
          <button *ngIf="activeIndex > 0" mat-button color="primary" (click)="goBack()">Back</button>

          <button
            mat-raised-button
            color="primary"
            (click)="goForward()"
            [disabled]="!this.selectedInterment && this.dialogData.listInterments.length !== 1"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>
