<cl-upload-wrapper-shared
  [files]="images"
  [disabled]="disabled"
  [title]="title"
  [showTitle]="showTitle"
  [uploaderType]="uploaderType.image"
  [public]="public"
  [maxSizePlaceholder]="maxSizePlaceholder"
  [fileType]="acceptFileType"
  (inputFiles)="inputFiles($event)"
  (uploadedFiles)="uploadFiles($event)"
>
  <div *ngIf="localFiles?.length === 0" activeItem>
    <div class="upload-container">
      <button mat-button color="primary" [disabled]="disabled">
        <mat-icon svgIcon="custom_download"></mat-icon>
        <span class="text">{{ 'select photo' | uppercase }}</span>
      </button>

      <p class="additional-text">or drag it here</p>
      <p class="limitation-text">Maximum file size: {{ ((maxSizePlaceholder * 1024) | fileSizeShared) }}</p>
    </div>
  </div>

  <ng-template #filesList let-files="files" let-loadFile="loadFile" let-deleteFromServer="remove">
    <ul *ngIf="files?.length > 0">
      <li *ngFor="let file of files.slice(0, imagesCapacity); let i = index" [class.first-image]="i === 0" (click)="preview(i)">
        <img src="{{ file.url ? file.url : file.image }}" alt="" />
      </li>
      <li *ngIf="files.length < imagesCapacity" class="add-more" (click)="loadFile()">
        <mat-icon *ngIf="!(uploadWrapper.loaders$ | async)?.upload?.isLoading" svgIcon="custom_plus"></mat-icon>
        <mat-spinner [diameter]="32" *ngIf="(uploadWrapper.loaders$ | async)?.upload?.isLoading"></mat-spinner>
      </li>
    </ul>
  </ng-template>
</cl-upload-wrapper-shared>
