<div class="container">
  <p>{{ value }}</p>
  <mat-icon
    matTooltipPosition="above"
    matTooltip="Copy to clipboard"
    (click)="copy()"
    class="copy-icon"
    svgIcon="custom_copy_to_clipboard"
  ></mat-icon>
</div>
