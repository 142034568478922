import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SectionData } from '../../interfaces/section-data';
import { ArrayDataSource } from '@angular/cdk/collections';
import { PlotStatusString } from '../../enums/plot-status-string.enum';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { RegionalSettings } from '../../interfaces/regional-settings';
import { RegionalSettingsService } from '../../core/regional-settings.service';
import { PlotListItem } from '../../interfaces/plot-list-item';
import { AmplitudeService } from 'src/app/core/amplitude.service';

@Component({
  selector: 'cl-shared-all-plots',
  templateUrl: './all-plots.component.html',
  styleUrls: ['./all-plots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllPlotsComponent {
  private localSections: SectionData[];
  treeControl = new FlatTreeControl<SectionData>(
    node => 0,
    node => true
  );
  dataSource: ArrayDataSource<SectionData>;
  plotStatus = PlotStatusString;

  regionalSettings: RegionalSettings;

  @Input() set sections(sections: SectionData[]) {
    this.localSections = sections;
    this.dataSource = new ArrayDataSource(sections);
  }
  get sections() {
    return this.localSections;
  }

  @Input() unknownPlot: PlotListItem;
  @Input() sellPlots: boolean;
  @Input() cemeteryName: string;
  @Input() cemeteryUniqueName: string;
  @Output() goBackToCemetery = new EventEmitter();
  @Output() navigateToPlot = new EventEmitter<{ cemeteryUniqueName: string; plotId: string | null }>();

  get isUnknownPlotAvailable() {
    if (this.sellPlots === true) {
      return;
    }
    return Object.keys(this.unknownPlot).length > 0;
  }

  constructor(
    private regionalSettingsService: RegionalSettingsService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private amplitudeService: AmplitudeService
  ) {
    this.regionalSettings = regionalSettingsService.getRegionalSettingsForCemeteryFromLocalStorage();

    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_account',
        src: '/assets/images/account_gray_icon.svg',
      },
    ]);
  }

  hasChild = (_: number, node) => !!node.plots && node.plots.length > 0;

  onNavigateToPlot(plotId: string | null = null, sellPlots) {
    if (sellPlots) {
      this.amplitudeService.track(`2. Plot Purchase - Plot ${plotId} Selected`, {
        cemetery_unique_name: this.cemeteryUniqueName,
        plotId,
      });
    }
    this.navigateToPlot.emit({ cemeteryUniqueName: this.cemeteryUniqueName, plotId });
  }

  formatPlotId(plotId: string) {
    return plotId.includes('Unassigned') ? 'Unassigned' : plotId;
  }

  goBackToCemeteryInfo() {
    this.goBackToCemetery.emit();
  }
}
