<form class="control-buttons" [formGroup]="form">
  <div
    class="control-button"
    *ngFor="let status of statuses"
    [class]="getStylePlotStatus(status.value)"
    (click)="onSetCheckedStatus(status)"
    [ngClass]="status.checked ? 'checked' : ''"
  >
    <label [for]="status.value | lowercase" class="status-button">
      <input formControlName="status" [id]="status.value | lowercase" type="radio" [attr.aria-label]="status.value | lowercase" />
      <span>{{ status.value }}</span>
    </label>
  </div>
</form>
