import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpUtilsService } from './http-utils.service';
import { DataSection, DataSectionBase, SectionLink } from '../interfaces/section-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { CemeteryInfo } from '../interfaces/cemetery-info';

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  sourceSection: Observable<DataSectionBase>;

  currentNicheWallSection$ = new BehaviorSubject<DataSection>(null);

  currentSectionLink$ = new BehaviorSubject<SectionLink>(null);

  currentNicheWallCemetery$ = new BehaviorSubject<CemeteryInfo>(null);

  constructor(private httpClient: HttpClient, private readonly httpUtils: HttpUtilsService) {}

  setSourceSection(newData: Observable<DataSectionBase>) {
    this.sourceSection = newData;
  }

  cleanSourceSection() {
    this.sourceSection = undefined;
  }

  cleanAllCache() {
    this.cleanSourceSection();
  }

  getAllSections(page?: number, limit?: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'section']);

    if (this.sourceSection) {
      return this.sourceSection;
    }
    const paramsObj = {
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
    };

    const params = new HttpParams({ fromObject: paramsObj });

    const resOb = this.httpClient.get<DataSectionBase>(url.slice(0, -1), { params });
    this.setSourceSection(resOb);
    return resOb;
  }

  getAllSectionsByViewPort(boundary: string, page?: number, limit?: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'sections-in-viewport']);

    const paramsObj = {
      bounds: boundary,
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
    };

    const params = new HttpParams({ fromObject: paramsObj });

    const resOb = this.httpClient.get<DataSectionBase>(url.slice(0, -1), { params });

    return resOb;
  }

  getSectionsByCemetery(cemeteryUniqueName: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryUniqueName, 'sections']);

    return this.httpClient.get(url);
  }

  getSectionsListByCemetery(cemeteryId: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryId, 'section_list']);

    return this.httpClient.get<string[]>(url);
  }

  getSectionsListByCemeteryWithUnknownLocation(cemeteryId: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryId, 'sections_list']);

    return this.httpClient.get<string[]>(url);
  }

  getGeoSectionsListByCemetery(cemeteryId: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryId, 'geo_section_list']);

    return this.httpClient.get<string[]>(url);
  }

  getSectionsByCemeteryWithToken(cemeteryUniqueName: string, token: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryUniqueName, 'sections']);

    return this.httpClient.get(url, {
      headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
    });
  }
}
