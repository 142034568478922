<div class="main-promotion-wrapper">
  <cl-swiper-dynamic
    [ngStyle]="
      responsive$
        ? { display: 'block', position: 'relative', width: '100%', height: '100%' }
        : { display: 'block', position: 'relative', width: '900px', height: '100%' }
    "
    (previewIndex)="openPreview($event)"
    [showInputImage]="false"
    [objectFit]="imageFit"
    [slides]="responsive$ ? imagesMobile : images"
    [autoPlay]="true"
    [blackArrow]="true"
  ></cl-swiper-dynamic>

  <div class="icon-close">
    <mat-icon class="material-icons close-icon" (click)="close()" svgIcon="custom_main_promotion_close"></mat-icon>
  </div>
</div>
