import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { Token } from '../enums/token.enum';
import { HttpUtilsService } from './http-utils.service';
import { RegistrationData } from '../interfaces/registration-data';
import { MFAMethod } from '../enums/mfa.enum';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly refreshTokenUrl = this.httpUtils.getResourceUrl(['auth', 'refresh']);
  private readonly requestNewTokenUrl = this.httpUtils.getResourceUrl(['auth', 'refreshtoken']);
  private isUserLoggedSubject = new BehaviorSubject<boolean>(this.isUserLogged());

  constructor(
    private httpClient: HttpClient,
    private tokenStorageService: TokenStorageService,
    private readonly httpUtils: HttpUtilsService
  ) { }

  login(username: string, password: string, otp?: string, method?: string) {
    const url = this.httpUtils.getResourceUrl(['auth']);
    const body: any = {
      username,
      password,
    };
    if (otp) {
      body.otp = otp;
    }

    if (method) {
      body.method = method;
    }

    // return this.httpClient.post(url, body);
    return this.httpClient.post(url, body).pipe(
      tap(() => {
        this.isUserLoggedSubject.next(true);
      })
    );
  }

  logout(): void {
    this.isUserLoggedSubject.next(false);
    // window.location.reload();
  }

  setupMFA(username: string, password: string, method: MFAMethod) {
    const url = this.httpUtils.getResourceUrl(['auth', 'setup-mfa']);
    const body: any = {
      username,
      password,
      method,
    };

    return this.httpClient.post(url, body);
  }

  setupMFANoPass(username: string, method: MFAMethod) {
    const url = this.httpUtils.getResourceUrl(['auth', 'setup-mfa']);
    const body: any = {
      username,
      method,
    };

    return this.httpClient.post(url, body);
  }

  resendOTP(username: string, password: string) {
    const url = this.httpUtils.getResourceUrl(['auth', 'send-otp']);
    const body: any = {
      username,
      password,
    };

    return this.httpClient.post(url, body);
  }

  verifyMFA(username: string, password: string, method: MFAMethod, otp: string) {
    const url = this.httpUtils.getResourceUrl(['auth', 'verify-mfa-setup']);
    const body: any = {
      username,
      password,
      method,
      otp,
    };

    return this.httpClient.post(url, body);
  }

  verifyMFANoPass(username: string, method: MFAMethod, otp: string) {
    const url = this.httpUtils.getResourceUrl(['auth', 'verify-mfa-setup']);
    const body: any = {
      username,
      method,
      otp,
    };

    return this.httpClient.post(url, body);
  }

  register(data: RegistrationData) {
    const url = this.httpUtils.getResourceUrl(['first_access']);

    return this.httpClient.post(url, data);
  }

  resetPass(email: string, host: string) {
    const url = this.httpUtils.getResourceUrl(['password_reset']);

    return this.httpClient.post(url, {
      email,
      set_password_url: host,
    });
  }

  refreshToken(refreshToken: string) {
    const url = this.refreshTokenUrl;

    return this.httpClient.post(url, {
      refresh: refreshToken,
    });
  }

  newPass(password: string, token: string) {
    const url = this.httpUtils.getResourceUrl(['password_reset', 'confirm']);

    return this.httpClient.post(url, {
      password,
      token,
    });
  }

  isResetPasswordTokenValid(token: string) {
    const url = this.httpUtils.getResourceUrl(['password_reset', 'validate_token']);

    return this.httpClient.post(url, { token });
  }

  checkSignupToken(token: string) {
    const url = this.httpUtils.getResourceUrl(['access', token]);

    return this.httpClient.get(url);
  }

  getSignupToken(id: number) {
    const url = this.httpUtils.getResourceUrl(['get_token', id]);

    return this.httpClient.get<string>(url);
  }

  isUserLogged() {
    return this.tokenStorageService.get(Token.access) !== null;
  }

  get isUserLogged$(): Observable<boolean> {
    return this.isUserLoggedSubject.asObservable();
  }

  isRefreshTokenUrl(url: string) {
    return this.refreshTokenUrl === url;
  }

  requestNewToken(token: string) {
    const url = this.requestNewTokenUrl + token;

    return this.httpClient.put(url, {});
  }

  redirectSso(socialProvider: any) {
    return `${environment.API_URL}/api/v1/sso/${socialProvider}/login`;
  }

  ssoEmailLogin(email: string) {
    const url = this.httpUtils.getResourceUrl(['sso', 'saml', 'email']);
    const body: any = {
      email,
    };

    return this.httpClient.post(url, body);
  }

  accessToDemo() {
    const url = this.httpUtils.getResourceUrl(['auth', 'access-demo']);
    const body: any = {};
    return this.httpClient.post(url, body);
  }

  activeEmailAddress(form: any) {
    const url = this.httpUtils.getResourceUrl(['auth', 'activate-email']);

    return this.httpClient.post(url, form);
  }

  verifyEmail(form: any) {
    const url = this.httpUtils.getResourceUrl(['auth', 'verify-email']);

    return this.httpClient.post(url, form);
  }

  resendOtpAccount(form: any) {
    const url = this.httpUtils.getResourceUrl(['auth', 'resend-otp']);

    return this.httpClient.post(url, form);
  }

  registerAccount(form: any) {
    const url = this.httpUtils.getResourceUrl(['auth', 'register']);

    return this.httpClient.post(url, form);
  }

  activateUserAccount(key: any, token: any) {
    const url = this.httpUtils.getResourceUrl(['auth', 'activate', key, token]);

    return this.httpClient.get<string>(url);
  }
}
