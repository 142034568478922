<div class="access-cemetery">
  <div class="main-info">
    <div class="name">
      {{ accessCemetery.name }}
    </div>
    <div class="status">
      {{ userRole }}
    </div>
  </div>

  <div class="archived-status" *ngIf="accessCemetery.archived">
    archived
  </div>
</div>
