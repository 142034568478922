import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ValidateResponseService } from '../server-response-validation/validate-response.service';
import { HttpUtilsService } from './http-utils.service';
import {
  ApproveRejectStoryResponse,
  CreateEditStoryResponse,
  StoryPublic,
  StoryPublicForm,
  StoryPublicFormEdit,
  StoryPublicResponse,
} from '../interfaces/story-public';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SellStoryService {
  constructor(
    private httpClient: HttpClient,
    private readonly httpUtils: HttpUtilsService,
    private readonly validateResponseService: ValidateResponseService
  ) {}

  /**
   * normalize url from microservice api with remove last character url
   * example: ../api/v1/ms/story/list/?page=1&limit=10 to be ../api/v1/ms/story/list?page=1&limit=10
   */

  getAllStoryPublic(page, cemeteryName: string, intermentId: number) {
    const paramsObj = {
      page: page || undefined,
      limit: '50',
    };
    const params = new HttpParams({ fromObject: paramsObj });
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'list', cemeteryName, intermentId]);

    return this.httpClient.get<StoryPublicResponse>(url.slice(0, -1), { params });
  }

  getAllStoryAdmin(page, cemeteryName: string, status?: string) {
    const paramsObj = {
      page: page || undefined,
      limit: '50',
      status: status || 'waiting',
    };
    const params = new HttpParams({ fromObject: paramsObj });
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'admin', 'list', cemeteryName]);

    return this.httpClient.get<StoryPublicResponse>(url.slice(0, -1), { params });
  }

  getStoryPublicById(storyId: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'detail', storyId]);

    return this.httpClient.get<StoryPublic>(url.slice(0, -1));
  }

  getStoryPublicByEmail(email: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'detail', email, 'email']);

    return this.httpClient.get(url.slice(0, -1));
  }

  createStoryPublic(formContent: StoryPublicForm, cemeteryName: string, intermentId: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'create', cemeteryName, intermentId, 'init']);

    return this.httpClient.post<CreateEditStoryResponse>(url.slice(0, -1), { ...formContent });
  }

  editStoryPublicAdmin(formContent: StoryPublicFormEdit, storyId: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'admin', 'update', storyId]);

    return this.httpClient.put<StoryPublic>(url.slice(0, -1), { ...formContent });
  }

  editStoryPublic(formContent, storyId: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'update', storyId, 'init']);

    return this.httpClient.put<CreateEditStoryResponse>(url.slice(0, -1), { ...formContent });
  }

  approveStory(storyId: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'admin', 'approve', storyId]);

    return this.httpClient.put<ApproveRejectStoryResponse>(url.slice(0, -1), null);
  }

  approveStoryRequestTable(storyId: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'admin', 'approve-request', storyId]);

    return this.httpClient.put<ApproveRejectStoryResponse>(url.slice(0, -1), null);
  }

  rejectStory(storyId: number, reason: string) {
    const data = { reason };
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'admin', 'reject', storyId]);

    return this.httpClient.put<ApproveRejectStoryResponse>(url.slice(0, -1), data);
  }

  rejectStoryRequestTable(storyId: number, reason: string) {
    const data = { reason };
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'admin', 'reject-request', storyId]);

    return this.httpClient.put<ApproveRejectStoryResponse>(url.slice(0, -1), data);
  }

  bannerStory(cemeteryName: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemetery', cemeteryName, 'location']);
    return this.httpClient.get(url.slice(0, -1));
  }
}
