import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'cl-modal-edit-signature',
  templateUrl: './modal-edit-signature.component.html',
  styleUrls: ['./modal-edit-signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEditSignatureComponent implements OnInit, AfterViewInit {
  signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;
  isSignaturePadEmpty = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      value: any;
    },
    private dialogRef: MatDialogRef<ModalEditSignatureComponent>,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dialogRef.beforeClosed().subscribe(() =>
      this.dialogRef.close({
        cancel: true,
        value: this.isSignaturePadEmpty ? null : this.signaturePad.toDataURL(),
      })
    );
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);

    if (this.dialogData && this.dialogData.value) {
      this.signaturePad.fromDataURL(this.dialogData.value, { ratio: 1 });
      this.isSignaturePadEmpty = false;
      this.ref.detectChanges();
    }
    this.signaturePad.addEventListener(
      'beginStroke',
      () => {
        this.isSignaturePadEmpty = false;
        this.ref.detectChanges();
      },
      { once: false }
    );
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
    this.isSignaturePadEmpty = true;
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.dialogRef.close({
      cancel: false,
      value: this.signatureImg,
    });
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (!this.signatureNeeded) {
      this.signatureNeeded = false;
    }
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      if (data.length <= 1) {
        data.pop();
        this.signaturePad.fromData(data);
        this.isSignaturePadEmpty = true;
        return;
      }
      data.pop();
      this.signaturePad.fromData(data);
    }
  }
}
