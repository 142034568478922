import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'cl-modal-confirm-select-person',
  templateUrl: './modal-confirm-select-person.component.html',
  styleUrls: ['./modal-confirm-select-person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmSelectPersonComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      record: any;
    },
    private router: Router,
    private dialogRef: MatDialogRef<ModalConfirmSelectPersonComponent>
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }

  seeOnMap() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${this.dialogData.record.cemetery_unique_name}/plots/${this.dialogData.record.plot_id}`])
    );
    window.open(url, '_blank');
  }
}
