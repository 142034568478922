import { environment } from '../../environments/environment';

export function shareOnSocialMedia(socialMedia: string, data: any, storyOfficial: any, plotDetails: any) {
  switch (socialMedia) {
    case 'facebook':
      let facebookURL;
      if (data) {
        facebookURL = encodeURIComponent(
          `${environment.APP_BASE_URL}/${data.cemetery_unique_name}/plots/${data.interment_record.plot.plot_id}/stories/${data.id}`
        );
      }
      if (storyOfficial) {
        facebookURL = encodeURIComponent(
          `${environment.APP_BASE_URL}/${plotDetails.cemetery_unique_name}/plots/${plotDetails.plot_id}/stories/${storyOfficial.id}`
        );
      }
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${facebookURL}`, 'sharer');
      break;
    case 'twitter':
      let twitterUrl;
      if (data) {
        twitterUrl = encodeURIComponent(
          `${environment.APP_BASE_URL}/${data.cemetery_unique_name}/plots/${encodeURIComponent(
            data.interment_record.plot.plot_id
          )}/stories/${data.id}`
        );
      }
      if (storyOfficial) {
        twitterUrl = encodeURIComponent(
          `${environment.APP_BASE_URL}/${plotDetails.cemetery_unique_name}/plots/${encodeURIComponent(plotDetails.plot_id)}/stories/${
            storyOfficial.id
          }`
        );
      }
      const desc = encodeURIComponent('Chronicle Best Cemetery App');
      window.open(`https://twitter.com/intent/tweet?url=${twitterUrl}&text=${desc}`, 'sharer');
      break;
    case 'linkedin':
      const linkedInURL = encodeURIComponent(
        `${environment.APP_BASE_URL}/${data.cemetery_unique_name}/plots/${data.interment_record.plot.plot_id}/stories/${data.id}`
      );
      const title = 'Chronicle';
      const summary = 'Best app';
      window.open(`https://www.linkedin.com/shareArticle?url=${linkedInURL}&title=${title}&summary=${summary}`, 'sharer');
      break;
  }
}
