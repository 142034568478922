import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormAdvanceSearch } from 'src/app/interfaces/advance-search';

@Component({
  selector: 'cl-business-form',
  templateUrl: './business-form.component.html',
  styleUrls: ['./business-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() data: any;
  @Input() dataForm: FormAdvanceSearch;

  defaultValue = '';
  fields = [];
  model = {
    bn: {
      type: 'text',
      value: '',
      label: 'Business name',
    },
    bc: {
      type: 'text',
      value: '',
      label: 'Business number',
    },
    fn: {
      type: 'text',
      value: '',
      label: 'First name',
    },
    ln: {
      type: 'text',
      value: '',
      label: 'Last name',
    },
    phone: {
      type: 'text',
      value: '',
      label: 'Phone (mobile/home/office)',
    },
    mail: {
      type: 'text',
      value: '',
      label: 'E-mail',
    },
  };
  constructor() {}

  ngOnInit(): void {
    this.getFormControlFields();
    if (this.dataForm !== undefined && this.fields && this.fields.length > 0) {
      this.patchValues();
    }
  }

  patchValues() {
    for (const field of this.fields) {
      const concatFieldName = `${field.fieldName}_${field.data}_${this.defaultValue}`;
      this.group.patchValue({
        [`${field.fieldName}_${field.data}_${this.defaultValue}`]: this.dataForm[concatFieldName],
      });
      field.value = this.dataForm[concatFieldName];
    }
  }

  getFormControlFields() {
    for (const field of Object.keys(this.model)) {
      this.defaultValue = 'business';
      const fieldsProps = this.model[field];
      this.group.addControl(`${field}_${this.data.value}_${this.defaultValue}`, new FormControl(null));
      this.fields.push({ ...fieldsProps, fieldName: field, data: this.data.value });
    }
  }

  changeValueFormInput(value, fieldName) {
    this.fields.find(field => field.fieldName === fieldName).value = value;
    this.group.patchValue(
      {
        [`${fieldName}_${this.data.value}_${this.defaultValue}`]: value.length === 0 ? null : value,
      },
      { onlySelf: true, emitEvent: false }
    );
    // this.group.get(`${fieldName}_${this.data.value}_${this.defaultValue}`).setValue(value, { selfOnly: true, emitEvent: false });
  }
}
