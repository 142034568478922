<div class="left">
  <mat-icon *ngIf="!photo" svgIcon="custom_search_record_plot" class="{{ plot.status | lowercase }}"></mat-icon>
  <img *ngIf="photo" [src]="photo" alt="plot photo" class="photo" />
</div>

<div class="right">
  <div class="cemetery-name" *ngIf="org && !cemeteryUniqueName">{{ plot.cemetery_name }}</div>
  <div class="wrapper-plot">
    <span class="plot-id">
      <div class="main-title">
        <span class="title" clHighlight [content]="plotId" [searchTerm]="searchValue"> {{ plotId }}</span>
        <img *ngIf="isFeaturedStory" title="Official Story" src="/assets/images/featured-story-ban.png" style="width: 20px" alt="" />
        <img
          *ngIf="isLifeChronicle"
          title="Life Chronicle Online Memorial"
          src="/assets/images/life-chronicle-ban.png"
          style="width: 20px"
          alt=""
        />
        <img *ngIf="isReturnServicePerson" title="Return Service Person" src="/assets/images/Veteran.png" style="width: 20px" alt="" />
      </div>
    </span>
  </div>
  <div class="main-content">
    <ng-container *ngIf="isSearchPersonNotRoiHolder(); else noPerson">
      <span class="name" clHighlight [content]="highlightedContent" [searchTerm]="searchValue"
        >{{ person.first_name }} {{ person.middle_name }} {{ person.last_name }}</span
      >
      <span *ngIf="dob && dod" class="years"> ({{ dob | dateIsoToJs | date: 'yyyy' }} - {{ dod | dateIsoToJs | date: 'yyyy' }})</span>
      <span class="more-people" *ngIf="plot.interments_count > 0">+{{ plot.interments_count }} more</span>
    </ng-container>

    <ng-template #noPerson>
      <span class="name">{{ plot.interments[0]?.person | name }}</span>
      <span *ngIf="plot?.interments[0]?.date_of_birth && plot?.interments[0]?.date_of_death" class="years">
        ({{ plot?.interments[0]?.date_of_birth | dateIsoToJs | date: 'yyyy' }} -
        {{ plot?.interments[0]?.date_of_death | dateIsoToJs | date: 'yyyy' }})</span
      >
      <span class="more-people" *ngIf="plot.interments_count > 0">+{{ plot.interments_count }} more</span>
    </ng-template>
  </div>
</div>
