import { Injectable } from '@angular/core';
import { Uploader } from '../interfaces/uploader';
import { HttpClient } from '@angular/common/http';
import { Image } from '../../interfaces/image';
import { HttpUtilsService } from '../../core/http-utils.service';

@Injectable({
  providedIn: 'root',
})
export class ImageUploaderService implements Uploader<Image> {
  constructor(private httpClient: HttpClient, private readonly httpUtils: HttpUtilsService) {}

  upload(image: File) {
    const url = this.httpUtils.getResourceUrl(['images']);
    const formData = new FormData();
    formData.append('image', image);

    return this.httpClient.post<Image>(url, formData);
  }

  uploadFromPublic(image: File) {
    const url = this.httpUtils.getResourceUrl(['noauthimages']);
    // const url = `https://dev.chronicle.rip/api/v1/noauthimages/`;
    const formData = new FormData();
    formData.append('image', image);

    return this.httpClient.post<Image>(url, formData);
  }

  remove(item: { id: number }) {
    const url = this.httpUtils.getResourceUrl(['images', item.id]);

    return this.httpClient.delete(url);
  }

  removeFromPublic(item: { id: number }) {
    const url = this.httpUtils.getResourceUrl(['noauthimages', item.id]);
    // const url = `https://dev.chronicle.rip/api/v1/noauthimages/${item.id}`;

    return this.httpClient.delete(url);
  }

  modifyLinkImage(text: string) {
    // to get only the link of the image
    const pattern = /\?X-Goog-Algorithm.*$/;
    const textResult = text.replace(pattern, '');
    return textResult;
  }

  getImageNameFromLink(text: string) {
    const textLink = this.modifyLinkImage(text);
    const regex = /.*\/images\//;
    const outputText = textLink.replace(regex, '');
    return outputText;
  }
}
