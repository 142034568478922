<ul class="filter">
  <li *ngFor="let item of selectedFilters | keyvalue">
    <span class="content">
      <span class="header">
        {{ getTitleLabel(filterNaming[item.key]) }}
      </span>
      <span class="value">
        {{ getValueLabel(item.value, item.key) }}
      </span>
    </span>
    <button mat-icon-button (click)="removeFilter($event, item.key)">
      <mat-icon>close</mat-icon>
    </button>
  </li>
</ul>
