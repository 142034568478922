import { Pipe, PipeTransform } from '@angular/core';

export interface KeyValueArray {
  key: string;
  value: string;
}

@Pipe({
  name: 'sortKeyValueToLast'
})
export class SortKeyValueToLastPipe implements PipeTransform {

  transform(value: KeyValueArray[], key: string): KeyValueArray[] {
    return value.sort((a, b) => (a.key === key ? 1 : b.key === key ? -1 : 0));
  }

}
