import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface PageMetaData {
  title: string;
  description: string;
  author: string;
  keywords: string[];
  type: string;
  image: string;
  metaTitle?: string;
}

export const defaultMetaData: PageMetaData = {
  title: 'Chronicle Cemetery Map | Deceased Records & Grave Locator',
  description:
    'Find a grave, access cemetery indexes, search deceased records, buy plots, and create memorials for your loved ones with the Chronicle Cemetery Map.',
  author: 'Chronicle team',
  keywords: ['Chronicle Cemetery Map'],
  type: 'website',
  image: `${environment.APP_BASE_URL}/assets/images/chronicle_logo.svg1`,
};

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private doc: any
  ) {}

  public updateMetadata(metadata: Partial<PageMetaData>, index: boolean = true): void {
    const pageMetadata: PageMetaData = { ...defaultMetaData, ...metadata };
    const metatags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);
    const newMetaTags: MetaDefinition[] = [
      ...metatags,
      { property: 'og:url', content: `${environment.APP_BASE_URL}/${this.router.url}` },
      { name: 'robots', content: index ? 'index, follow' : 'noindex' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'twitter:card', content: 'summary_large_image' },
    ];

    this.updateMetaTags(newMetaTags);
    this.title.setTitle(pageMetadata.title);
  }

  createLinkForCanonicalURL() {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', environment.APP_BASE_URL);
  }

  private updateMetaTags(metaTags: MetaDefinition[]) {
    metaTags.forEach(m => this.meta.updateTag(m));
  }

  private generateMetaDefinitions(metadata: PageMetaData): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { name: 'description', content: metadata.description },
      { name: 'author', content: metadata.author },
      { name: 'keywords', content: metadata.keywords.join(', ') },

      { property: 'og:title', content: metadata.metaTitle ? metadata.metaTitle : metadata.title },
      { property: 'og:description', content: metadata.description },
      { property: 'og:author', content: metadata.author },
      { property: 'og:type', content: metadata.type },
      { property: 'og:image', content: metadata.image },
    ];
  }
}
