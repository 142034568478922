import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cl-plot-form',
  templateUrl: './plot-form.component.html',
  styleUrls: ['./plot-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlotFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() plotIdControlName: string;
  @Input() statusControlName: string;
  @Input() plotTypeControlName: string;
  @Input() priceControlName: string;
  @Input() burialCapacityControlName: string;
  @Input() entombmentCapacityControlName: string;
  @Input() cremateCapacityControlName: string;
  @Input() intermentCapacityControlName: string;
  @Input() statuses;
  @Input() plotTypes;
  @Input() isAdmin;
  @Input() sectionData;
  @Input() rowValue;
  @Input() fromAdvanceSearch;
  constructor() {}

  ngOnInit(): void {}
}
