import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IntermentService } from 'src/app/admin/manage/interment.service';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { CemeteryService } from 'src/app/core/cemetery.service';
import { NavigateBackService } from 'src/app/core/navigate-back.service';
import { PlotsService } from 'src/app/core/plots.service';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { SellStoryService } from 'src/app/core/sell-story.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { TokenStorageService } from 'src/app/core/token-storage.service';
import { Token } from 'src/app/enums/token.enum';
import { Interment } from 'src/app/interfaces/interment';
import { CreateEditStoryResponse, StoryPublicForm } from 'src/app/interfaces/story-public';
import { LoadingService } from 'src/app/loading-overlay/loading.service';
import { environment } from 'src/environments/environment';
import { ElementStripeComponent } from '../element-stripe/element-stripe.component';
import { ModalViewStoryComponent } from '../modal-view-story/modal-view-story.component';
import { SwiperModalData } from '../swiper-modal/swiper-modal-data';
import { SwiperModalComponent } from '../swiper-modal/swiper-modal.component';
import { ModalConfirmSelectPersonComponent } from './modal-confirm-select-person/modal-confirm-select-person.component';
import { ModalExitLifeChronicleComponent } from './modal-exit-life-chronicle/modal-exit-life-chronicle.component';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';

@Component({
  selector: 'cl-life-chronicle-page',
  templateUrl: './life-chronicle-page.component.html',
  styleUrls: ['./life-chronicle-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeChroniclePageComponent implements OnInit {
  destroy$ = new Subject<void>();
  isLoadingPerson = false;
  filterControl = new FormControl();
  records: any;
  searchValue: string;
  cemeteryDetail: any;
  organizationCemetery: any;
  isSectionDisabled = true;
  step = 0;
  intermentStoryImages: Array<any> = [];
  selectedPerson = null;
  public = true;
  paymentUrl: string;
  amountPayment: number;
  currency: string;
  clientSecret: string;
  isLoadingAction;
  intermentParams$: Observable<string>;
  isAus$: Observable<string>;
  location: string;
  intermentId;
  cemeteryName$: Observable<string>;
  fromInterment = false;
  storyDetail: any;
  storyId: string;
  responsive$: boolean;
  form = this.formBuilder.group({
    first_name: [null, Validators.required],
    last_name: [null, Validators.required],
    phone_number: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],
    email: [null, [Validators.required, Validators.email]],
    relationship_interment: [null, Validators.required],
    title: null,
    text: null,
    success_page: null,
    error_page: null,
    images: [[]],
  });

  constructor(
    private navigateBackService: NavigateBackService,
    private plotService: PlotsService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService,
    private amplitudeService: AmplitudeService,
    private sellStoryService: SellStoryService,
    private intermentService: IntermentService,
    private cemeteryService: CemeteryService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private routerUtilsService: RouterUtilsService,
    private snackBarNotificationService: SnackbarNotificationService,
    private mobileResponsiveServie: MobileResponsiveService,
    private readonly addIconsThroughMaterialService: AddIconsThroughMaterialService,

    @Inject(PLATFORM_ID)
    private platformId: any
  ) {
    this.addIconsThroughMaterialService.add([
      { name: 'warning-icon', src: '/assets/images/warning_icon.svg' },
      { name: 'credit-card-icon', src: '/assets/images/credit_card.svg' },
      { name: 'preview-story-icon', src: '/assets/images/preview_story.svg' },
      { name: 'custom_autocomplete_base_search', src: '/assets/images/search_icon.svg' },
      { name: 'custom_shared_plot_details_close', src: '/assets/images/close_icon_white.svg' },
    ]);
  }
  ngOnInit(): void {
    const intermentId = this.route.snapshot.paramMap.get('intermentId');
    const cemeteryName = this.route.snapshot.paramMap.get('cemeteryName');
    this.storyId = this.route.snapshot.paramMap.get('storyId');
    this.cemeteryName$ = this.route.paramMap.pipe(map(params => params.get('cemeteryName')));
    this.intermentParams$ = this.route.queryParamMap.pipe(map(params => params.get('from')));
    // this.location = this.route.snapshot.queryParamMap.get('isAus');
    this.mobileResponsiveServie.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.intermentParams$
      .pipe(
        filter(from => from === 'interment'),
        tap(() => {
          this.fromInterment = true;
          this.intermentService
            .get(Number(intermentId), cemeteryName)
            .pipe(
              tap(data => {
                this.selectedPerson = data;
                this.isSectionDisabled = false;
                this.setStep(1);
                this.cdr.detectChanges();
                // this.cdr.markForCheck();
              }),
              finalize(() => {
                this.cdr.markForCheck();
              }),
              takeUntil(this.destroy$)
            )
            .subscribe();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.filterControl.valueChanges
      .pipe(
        debounceTime(650),
        distinctUntilChanged(),
        tap(value => {
          this.searchValue = value;
          this.onSearchPerson(value);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.cemeteryName$
      .pipe(
        switchMap(cemeteryNameLabel => {
          return this.cemeteryService.getCemetery(cemeteryNameLabel);
        }),
        tap(data => {
          this.cemeteryDetail = data;
          this.cdr.detectChanges();
        }),
        finalize(() => {
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    if (this.storyId) {
      this.getDataStoryById(Number(this.storyId));
    }
  }

  getDataStoryById(id: number) {
    this.sellStoryService
      .getStoryPublicById(id)
      .pipe(
        tap(data => {
          this.selectedPerson = data.interment_record;
          this.storyDetail = data;
          this.patchForm();
          this.isSectionDisabled = false;
          this.setStep(1);
          this.cdr.detectChanges();
        }),
        finalize(() => {
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getIntermentById(id: number) {
    this.intermentService
      .get(id, this.cemeteryDetail.unique_name)
      .pipe(
        tap(data => {
          this.selectedPerson = data;
          this.isSectionDisabled = false;
          this.setStep(1);
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  patchForm() {
    const images = this.storyDetail?.images?.map(({ id }) => id);
    this.intermentStoryImages = this.storyDetail?.images ? this.storyDetail.images : [];
    this.form.patchValue({
      first_name: this.storyDetail.created_user.first_name,
      last_name: this.storyDetail.created_user.last_name,
      phone_number: this.storyDetail.created_user.phone_number,
      email: this.storyDetail.created_user.email,
      relationship_interment: this.storyDetail.relationship_interment,
      title: this.storyDetail.title,
      text: this.storyDetail.text,
      success_page: null,
      error_page: null,
      images,
    });
  }

  backToPrev() {
    // this.navigateBackService.goBack();
    this.dialog
      .open(ModalExitLifeChronicleComponent, {
        panelClass: 'modal-exit-life-chronicle',
        width: '365px',
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        tap(() => {
          this.amplitudeService.track('3b. Button "Close" on Life Chronicle clicked');
          if (this.fromInterment) {
            const path = this.routerUtilsService.getPath(this.cemeteryDetail.unique_name, 'plots', this.selectedPerson.plot_id);
            this.router.navigate(path, { queryParams: { fromLc: 'true' } });
          } else {
            const path = this.routerUtilsService.getPath(this.cemeteryDetail.unique_name);
            this.router.navigate(path, { queryParams: { fromLc: 'true' } });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  backToCemetery() {
    const path = this.routerUtilsService.getPath(this.cemeteryDetail.unique_name);
    this.router.navigate(path, { queryParams: { fromLc: 'true' } });
  }

  onSearchChange(searchPerson: string) {
    this.onSearchPerson(searchPerson);
  }

  openConfirmDialog(record: Interment) {
    this.dialog
      .open(ModalConfirmSelectPersonComponent, {
        data: {
          record,
        },
        panelClass: 'modal-confirm-select-person',
        autoFocus: false,
        width: '365px',
      })
      .afterClosed()
      .pipe(
        tap(data => {
          if (data) {
            this.selectedPerson = record;
            this.isSectionDisabled = false;
            this.setStep(1);
            this.cdr.detectChanges();
            this.cdr.markForCheck();
          }
        })
      )
      .subscribe();
  }

  onSearchPerson(searchPerson: string) {
    this.isLoadingPerson = true;
    this.plotService
      .searchPlotsWithRecordsV2({
        search: searchPerson,
        cemeteryIdentifier: this.cemeteryDetail.unique_name,
        offset: 0,
        limit: 40,
        search_type: 'person',
      })
      .pipe(
        tap(data => {
          this.records = data.persons;
          this.isLoadingPerson = false;
          this.cdr.detectChanges();
        }),
        finalize(() => {}),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.cdr.detectChanges();
  }

  checkIsReturnServicePerson(person: any) {
    if (person.returned_serviceman) {
      return true;
    }
    return false;
  }

  checkIsFeaturedStory(person: Interment) {
    if (person.story !== null) {
      return true;
    }
    return false;
  }

  checkIsLifeChronicle(person: Interment) {
    if (person.life_chronicle !== null) {
      return true;
    }
    return false;
  }

  uploadIntermentStoryPhotos(images) {
    this.intermentStoryImages = images;

    this.form.markAsDirty();
    this.form.get('images').setValue(images?.map(({ id }) => id));
  }

  openImageStoryPreview(index: number) {
    this.dialog
      .open<SwiperModalComponent, SwiperModalData, any[]>(SwiperModalComponent, {
        panelClass: 'slider-container',
        data: {
          entityTitle: '',
          images: this.intermentStoryImages,
          startFrom: index,
          download: true,
          share: true,
          delete: true,
          public: true,
        },
      })
      .afterClosed()
      .pipe(
        tap(images => {
          if (images) {
            this.onStoryImagesChange(images);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onStoryImagesChange(images: any[]) {
    this.intermentStoryImages = images;

    const imageIds = images.length ? images.map(image => image.id) : [];

    this.form.get('images').patchValue(imageIds);
  }
  onClickTermsAndConditions() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    //   if (this.dialogData.listInterments.length > 1) {
    //     this.amplitudeService.track('3f. Link "terms and conditions" on Life Chronicle clicked');
    //   }
    //   if (this.dialogData.listInterments.length <= 1) {
    //     this.amplitudeService.track('3e. Link "terms and conditions" on Life Chronicle clicked');
    //   }
    // this.amplitudeService.track('Link "terms and conditions" on Life Chronicle clicked');
    window.open(`${environment.APP_BASE_URL}/static/toc.html`, '_blank');
  }

  onPreviewStory() {
    this.dialog
      .open(ModalViewStoryComponent, {
        panelClass: 'modal-view-story',
        width: '608px',
        disableClose: false,
        autoFocus: false,
        data: {
          //   story: this.selectedPerson,
          interment: this.selectedPerson,
          detailStory: {
            title: this.form.get('title').value,
            text: this.form.get('text').value,
            relationship_interment: this.form.get('relationship_interment').value,
            created_user: {
              first_name: this.form.get('first_name').value,
              last_name: this.form.get('last_name').value,
            },
            cemetery: {
              name: this.selectedPerson.cemetery_name,
            },
            interment_record: {
              plot: {
                plot_id: this.selectedPerson.plot_id,
              },
            },
          },
          storyOfficial: {
            images: this.intermentStoryImages,
          },
          public: true,
          status: 'preview',
          admin: false,
          fromRequestTable: false,
          fromCta: false,
          fromPreviewLifeChronicle: true,
        },
      })
      .afterClosed()
      .subscribe();
  }

  updateStory() {
    this.sellStoryService
      .editStoryPublic(this.form.value, this.storyDetail.id)
      .pipe(
        catchError(error => {
          this.openErrorMessage(error.error.message);
          return EMPTY;
        }),
        tap<CreateEditStoryResponse>(data => {
          /* tslint:disable:no-string-literal */
          // this.paymentUrl = data['data'].payment_url;
          this.clientSecret = data.client_secret;
          this.amountPayment = data.amount;
          this.currency = data.currency;

          /* tslint:disable:no-string-literal */
          // this.amplitudeService.track('Updated life Chronicle', { before: this.dialogData, after: this.form.value });
          this.openSuccessMessage('Life Chronicle submit successfully!');
        }),
        finalize(() => {
          this.loadingService.stop();
          this.isLoadingAction = false;
          this.backToCemetery();
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onSubmitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.step = 1;
      return;
    }
    if (this.storyId) {
      this.updateStory();
      return;
    }

    const encodedPlotId = encodeURIComponent(this.selectedPerson.plot_id);
    this.form
      .get('success_page')
      .setValue(
        `http://localhost:4200/${this.selectedPerson.cemetery_unique_name}/plots/${encodedPlotId}?from=map&zoom=24&email=${
          this.form.get('email').value
        }&modal=show&status=success`
      );
    this.form
      .get('error_page')
      .setValue(
        `http://localhost:4200/${this.selectedPerson.cemetery_unique_name}/plots/${encodedPlotId}?from=map&zoom=24&email=${
          this.form.get('email').value
        }&modal=show&status=cancel`
      );

    const ampUserId = this.tokenStorageService.get(Token.ampliUserId);
    const formContent: StoryPublicForm = { ...this.form.value, amplitude_user_id: ampUserId };
    this.sellStoryService
      .createStoryPublic(formContent, this.selectedPerson.cemetery_unique_name, this.selectedPerson.id)
      .pipe(
        catchError(error => {
          this.openErrorMessage(error.error.message);
          return EMPTY;
        }),
        tap<CreateEditStoryResponse>(data => {
          /* tslint:disable:no-string-literal */
          // this.paymentUrl = data['data'].payment_url;
          this.clientSecret = data.client_secret;
          this.amountPayment = data.amount;
          this.currency = data.currency;

          /* tslint:disable:no-string-literal */
          //   this.amplitudeService.track('3d. Created life Chronicle');
          //   this.amplitudeService.track('3c. Created Life Chronicle');
          //   this.amplitudeService.track('Created life Chronicle', this.form.value);
          const valueToSend = {
            formValue: this.form.value,
            cemeteryName: this.selectedPerson.cemetery_name,
            selectedPersonId: this.selectedPerson.id,
          };
          this.amplitudeService.track('3a. Button "Submit" Life Chronicle clicked', valueToSend);
          this.openSuccessMessage('Life Chronicle submit successfully!');
        }),
        finalize(() => {
          this.loadingService.stop();
          this.isLoadingAction = false;
          this.mountElementStripe();
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  mountElementStripe() {
    // if (this.dialogData.listInterments.length > 1) {
    //   this.amplitudeService.track('3e. Button "Go to payment" on input Life Chronicle clicked', null, {
    //     [IdentifyName.CemeteryName]: this.dialogData.cemeteryName,
    //   });
    // }
    // if (this.dia.listInterments.length <= 1) {
    //   this.amplitudeService.track('3d. Button "Go to payment" on input Life Chronicle clicked', null, {
    //     [IdentifyName.CemeteryName]: this.dialogData.cemeteryName,
    //   });
    // }
    this.dialog
      .open(ElementStripeComponent, {
        panelClass: 'element-stripe',
        minWidth: '500px',
        disableClose: true,
        autoFocus: false,
        data: {
          interment: this.selectedPerson,
          clientSecret: this.clientSecret,
          amountPayment: this.amountPayment,
          currency: this.currency,
          emailStory: this.form.get('email').value,
          cemeteryName: this.selectedPerson.cemetery_name,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  private openSuccessMessage(successMessage: string) {
    this.snackBarNotificationService.openSuccessMessage(successMessage);
  }

  private openErrorMessage(errorMessage: string) {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.snackBarNotificationService.openErrorMessage(errorMessage);
    this.cdr.markForCheck();
  }
}
