<ng-container *ngIf="isLoading">
  <cl-modal-view-story-skeleton></cl-modal-view-story-skeleton>
</ng-container>

<ng-container *ngIf="!isLoading">
  <!-- <div class="story-edit-icon" #editIcon *ngIf="isChronicleAdmin && dialogData.status !== 'declined'">
    <mat-icon (click)="onOpenFormEditStory()">edit</mat-icon>
  </div> -->
  <!-- <div class="share-story-menu" *ngIf="!isChronicleAdmin">
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="share"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="shareStory('facebook')">
        <mat-icon svgIcon="facebook"></mat-icon>
        <span>Facebook</span>
      </button>
      <button mat-menu-item (click)="shareStory('twitter')">
        <mat-icon svgIcon="twitter"></mat-icon>
        <span>Twitter</span>
      </button>
      <button mat-menu-item (click)="shareOnLinkedIn()">
        <mat-icon svgIcon="linkedin"></mat-icon>
        <span>LinkedIn</span>
      </button>
    </mat-menu>
  </div> -->

  <!-- <div class="story-wrapper" *ngIf="!show"> -->
  <!-- <div class="story-header">
      <div class="story-cemetery-name">{{ detailStory.cemetery.name }}</div>
      <div class="story-interment-id">
        <span class="icon-mark"> <mat-icon svgIcon="custom_place"></mat-icon></span>{{ detailStory.interment_record.plot.plot_id }}
      </div>
    </div> -->

  <!-- <div class="story-title-header">
      <div class="story-title">{{ detailStory.title }}</div>
      <div class="story-author">
        By. {{ detailStory.created_user.first_name }} {{ detailStory.created_user.last_name }} ({{ detailStory.relationship_interment }} by
        deceased)
      </div>
      <div class="story-approved-at">{{ detailStory.approved_at | date }}</div>
    </div> -->

  <!-- <mat-divider></mat-divider>

    <div class="content-story" [innerHTML]="detailStory.text | safeHtml"></div> -->

  <!-- <div class="image-story">
      <div *ngFor="let image of detailStory.images; let i = index" (click)="openPreview(i)">
        <img src="{{ image.image }}" alt="" class="preview-image-story" />
      </div>
    </div> -->
  <!-- </div> -->
  <!-- <div class="button-wrapper" *ngIf="isChronicleAdmin" #buttonWrapper>
    <button
      mat-button
      *ngIf="!(dialogData.status === 'declined' || dialogData.status === 'approved')"
      (click)="onOpenStoryDialogConfirmation('decline')"
    >
      Reject
    </button>
    <button
      *ngIf="!(dialogData.status === 'declined' || dialogData.status === 'approved')"
      mat-raised-button
      color="primary"
      (click)="onOpenStoryDialogConfirmation('approve')"
    >
      Approve
    </button>
  </div> -->

  <div class="story-container" *ngIf="!show">
    <div class="icon-close">
      <mat-icon class="material-icons close-icon" (click)="onClose()" svgIcon="custom_shared_plot_details_close"></mat-icon>
    </div>
    <div class="story-detail-wrapper">
      <cl-swiper-dynamic
        [showInputImage]="false"
        [ngStyle]="
          responsive$
            ? containImage(storyData, dialogData)
              ? { display: 'block', position: 'relative', width: '100%', height: '320px', overflow: 'hidden' }
              : { display: 'block', position: 'relative', width: '100%', height: '120px', overflow: 'hidden' }
            : containImage(storyData, dialogData)
            ? { display: 'block', position: 'relative', width: '544px', height: '320px', overflow: 'hidden' }
            : { display: 'block', position: 'relative', width: '544px', height: '120px', overflow: 'hidden' }
        "
        [objectFit]="imageFit"
        [slides]="storyData ? storyData?.images : dialogData?.storyOfficial?.images"
        (previewIndex)="openPreview($event)"
      ></cl-swiper-dynamic>
      <div class="info-story-container">
        <div class="info-story-left">
          <div class="cemetery-name">
            <span *ngIf="this.dialogData.fromPreviewLifeChronicle" class="draft-preview">{{ 'draft' | uppercase }}</span>
            <span class="icon"><mat-icon svgIcon="custom_place"></mat-icon></span>
            <div class="detail-info">
              {{ detailStory ? detailStory?.cemetery?.name : dialogData?.plotDetails?.cemetery_info?.name }} -
              {{ detailStory ? detailStory?.interment_record?.plot?.plot_id : dialogData?.interment?.plot_id }}
            </div>
            <div class="plot-info">
              <div class="official-story-label" *ngIf="dialogData.adminStory">OFFICIAL STORY</div>
              <a *ngIf="dialogData?.fromCta" (click)="goToDetailPlot()" class="btn-plot">Go to plot</a>
            </div>
          </div>
          <div class="title-header-name" *ngIf="!dialogData.adminStory">
            <div>
              In memory of
              {{ dialogData?.interment ? dialogData?.interment?.person?.first_name : detailStory?.interment_record?.person?.first_name }}
              {{ dialogData?.interment ? dialogData?.interment?.person?.middle_name : detailStory?.interment_record?.person?.middle_name }}
              {{ dialogData?.interment ? dialogData?.interment?.person?.last_name : detailStory?.interment_record?.person?.last_name }}
            </div>
          </div>
        </div>
        <div class="info-story-right" *ngIf="this.dialogData.status !== 'preview'">
          <div class="story-edit-icon" #editIcon *ngIf="isChronicleAdmin && dialogData.status !== 'declined' && !dialogData.adminStory">
            <mat-icon (click)="onOpenFormEditStory()">edit</mat-icon>
          </div>
          <div class="share-menu" *ngIf="!isChronicleAdmin">
            <button *ngIf="hideShare" mat-button [matMenuTriggerFor]="menu">
              <mat-icon class="material-icons-outlined share-icon" color="primary">share_outlined</mat-icon>
              <span class="share-text">SHARE</span>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="shareStory('facebook')">
                <mat-icon svgIcon="facebook"></mat-icon>
                <span>Facebook</span>
              </button>
              <button mat-menu-item (click)="shareStory('twitter')">
                <mat-icon svgIcon="twitter"></mat-icon>
                <span>Twitter</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div #contentStory (scroll)="onScroll($event)" class="story-content-wrapper" id="scroll">
        <div class="story-title">{{ detailStory ? detailStory.title : 'Chronicle Official Story' }}</div>
        <div class="content-story-text" [innerHTML]="stories | safeHtml"></div>
        <div *ngIf="detailStory" class="story-created-by">
          By. {{ detailStory?.created_user?.first_name }} {{ detailStory?.created_user?.last_name }} ({{
            detailStory.relationship_interment
          }}
          by deceased)
        </div>
        <div *ngIf="!detailStory" class="story-created-by">By. Admin Chronicle</div>
        <div class="story-created">
          {{ detailStory?.created_at | date }}
        </div>
        <div class="share-banner-full" *ngIf="!isChronicleAdmin && !this.dialogData.fromPreviewLifeChronicle">
          <div>
            Do you want more people to know about {{ dialogData?.interment?.person?.first_name }}
            {{ dialogData?.interment?.person?.middle_name }} {{ dialogData?.interment?.person?.last_name }}?
          </div>
          <button *ngIf="isOutsideButton" (click)="toggleShare()" #shareButton mat-flat-button color="primary">SHARE THE STORY</button>
          <div class="container-button-share-banner" #listShareButton *ngIf="isShareButton">
            <button class="share-facebook" mat-menu-item (click)="shareStory('facebook')">
              <mat-icon svgIcon="facebook"></mat-icon>
              <span>Facebook</span>
            </button>
            <button class="share-twitter" mat-menu-item (click)="shareStory('twitter')">
              <mat-icon svgIcon="twitter"></mat-icon>
              <span>Twitter</span>
            </button>
          </div>
        </div>
        <div class="button-wrapper" *ngIf="isChronicleAdmin && dialogData.admin" #buttonWrapper>
          <button
            mat-button
            *ngIf="!(dialogData.status === 'declined' || dialogData.status === 'approved')"
            (click)="onOpenStoryDialogConfirmation('decline')"
          >
            Reject
          </button>
          <button
            *ngIf="!(dialogData.status === 'declined' || dialogData.status === 'approved')"
            mat-raised-button
            color="primary"
            (click)="onOpenStoryDialogConfirmation('approve')"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
