<ng-container *ngIf="{loaders: loaders$ | async } as vm">
    <div class="modal-wrapper">
        <div class="modal-header">
            <h4 class="modal-heading">
                Add Access
                <span *ngIf="dialogData.cemeteries.length === 1">
                    to {{dialogData.cemeteries[0].name}}
                </span>
            </h4>
        </div>

        <div class="modal-body">
            <ng-container [formGroup]="accessForm">
                <div class="emails">
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list #emailsChipList aria-label="Email selection" data-id="email-list"
                            formArrayName="emails">
                            <mat-chip *ngFor="let email of emailsArray.controls; let i = index"
                                [selectable]="chipsConfig.selectable" [removable]="chipsConfig.removable"
                                (removed)="removeSelection(i, 'emails')">
                                {{email.value}}
                                <mat-icon matChipRemove *ngIf="chipsConfig.removable">cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="User e-mail(s)" [matChipInputFor]="emailsChipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="chipsConfig.addOnBlur"
                                (matChipInputTokenEnd)="addSelection($event, 'emails')">
                        </mat-chip-list>
                        <mat-error>
                            <span *ngIf="fc.emails.errors?.validateArrayNotEmpty?.valid === false">Please enter at least one e-mail</span>
                            <span *ngIf="fc.emails.errors?.emailValidator?.valid === false">Please enter valid e-mail</span>
                            <span *ngIf="fc.emails.errors?.validateUniqueEmails?.valid === false">Duplicate e-mail</span>
                        </mat-error>
                        <mat-hint>Separate by comma “,” or “Enter”</mat-hint>
                    </mat-form-field>
                </div>

                <div class="role">
                    <mat-form-field appearance="standard">
                        <mat-label>Role</mat-label>
                        <mat-select formControlName="role">
                            <mat-option [value]="roles.CUSTOMER_ADMIN">Admin</mat-option>
                            <mat-option [value]="roles.CUSTOMER_MANAGER">Manager</mat-option>
                            <mat-option [value]="roles.CUSTOMER_VIEWER">Viewer</mat-option>
                        </mat-select>
                        <mat-error>Role is required</mat-error>
                    </mat-form-field>
                </div>


                <div class="all-org-checkbox" *ngIf="dialogData.cemeteries.length > 1">
                    <mat-checkbox class="example-margin" formControlName="addAllCemeteries">All Cemeteries in
                        organisation</mat-checkbox>
                </div>

                <div class="cemeteries-selection"
                    *ngIf="!fc.addAllCemeteries.value && dialogData.cemeteries.length > 1">
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list #cemeteriesChipList aria-label="Email selection" data-id="cemeteries-list" formArrayName="cemeteries">
                            <mat-chip *ngFor="let cemetery of cemeteriesArray.controls; let i = index"
                                [selectable]="chipsConfig.selectable" [removable]="chipsConfig.removable"
                                (removed)="removeSelection(i, 'cemeteries')">{{cemetery.value.name}}
                                <mat-icon matChipRemove *ngIf="chipsConfig.removable">cancel</mat-icon>
                            </mat-chip>
                            <input #inputRef placeholder="Cemetery(ies)" [formControl]="cemeteryInput"
                                [matAutocomplete]="cemeteriesAutoComplete" [matChipInputFor]="cemeteriesChipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="chipsConfig.addOnBlur"
                                (matChipInputTokenEnd)="addSelection($event, 'cemeteries')">
                        </mat-chip-list>
                        <mat-autocomplete 
                            #cemeteriesAutoComplete="matAutocomplete"
                            (optionSelected)="selected($event);inputRef.blur();"
                            (closed)="cemeteryInput.setValue(null)"
                            >
                            <mat-option class="cemetery-option" *ngFor="let cemetery of filteredCemetery$ | async"
                                [value]="cemetery">
                                {{cemetery.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error>Please enter at least one cemetery</mat-error>
                        <mat-hint>Separate by comma “,” or “Enter”</mat-hint>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>

        <div class="modal-action">
            <button mat-button (click)="cancel()" [disabled]="vm.loaders?.inviteAccess?.isLoading">Cancel</button>
            <button 
                mat-flat-button color="primary" 
                [disabled]="!accessForm.valid || vm.loaders?.inviteAccess?.isLoading" 
                (click)="invite()">Invite
            </button>
        </div>
    </div>
</ng-container>