<div class="container-dialog">
  <div class="header">{{ dialogData.title }}</div>
  <div class="plot-card" *ngIf="dialogData.plotDetails">
    <div class="img">
      <img class="plot-img" *ngIf="!dialogData.plotDetails.images.length" src="/assets/images/photo_stub.svg" alt="plot image" />
      <img class="plot-img" *ngIf="dialogData.plotDetails.images.length" [src]="dialogData.plotDetails.images[0].url" alt="plot image" />
    </div>
    <div class="plot-info">
      <p class="cemetery-name">{{ dialogData?.cemeteryInfo?.name }}</p>
      <h3 class="plot-name">{{ dialogData?.plotDetails?.plot_id }}</h3>
    </div>
  </div>
  <div class="body">
    <div class="text-sent">✅ Request was sent</div>
    <div>Cemetery administration will contact you</div>
  </div>
  <div class="footer">
    <button mat-button color="primary" (click)="viewPdf()">VIEW SUBMISSION AS PDF</button>
    <button mat-raised-button color="primary" (click)="closeDialog()">CONTINUE</button>
  </div>
</div>
