import { Component, ChangeDetectionStrategy, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { IntermentStory } from '../../interfaces/interment-story';
import { SwiperModalComponent } from '../swiper-modal/swiper-modal.component';
import { SwiperModalData } from '../swiper-modal/swiper-modal-data';
import { Image } from '../../interfaces/image';
import { MatDialog } from '@angular/material/dialog';
import { ModalViewStoryComponent } from '../modal-view-story/modal-view-story.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Interment } from 'src/app/interfaces/interment';
import { ActivatedRoute, Router } from '@angular/router';
import { PlotDetails } from 'src/app/interfaces/plot-details';
import { AmplitudeService } from 'src/app/core/amplitude.service';

@Component({
  selector: 'cl-interment-story-view',
  templateUrl: './interment-story-view.component.html',
  styleUrls: ['./interment-story-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntermentStoryViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() story: IntermentStory;
  @Input() interment: Interment;
  @Input() plotDetails: PlotDetails;
  @Input() fromAdmin: boolean;
  destroy$ = new Subject<void>();
  storiesId: string;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}
  ngOnInit(): void {
    this.storiesId = this.activatedRoute.snapshot?.paramMap?.get('storyId');
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  openPreview(index: number) {
    this.dialog.open<SwiperModalComponent, SwiperModalData, Image[]>(SwiperModalComponent, {
      panelClass: 'slider-container',
      data: {
        entityTitle: '',
        images: this.story.images,
        startFrom: index,
        download: true,
        share: true,
      },
    });
  }

  openModalViewStory() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { stories: this.storiesId ? this.storiesId : this.story?.id },
      queryParamsHandling: 'merge',
    });
    if (this.fromAdmin) {
      this.amplitudeService.track('admin - Official story clicked', this.story);
    } else {
      this.amplitudeService.track('Official story clicked', this.story);
    }
    this.dialog
      .open(ModalViewStoryComponent, {
        panelClass: 'modal-view-story',
        width: '608px',
        disableClose: true,
        autoFocus: false,
        data: { storyOfficial: this.story, public: true, interment: this.interment, plotDetails: this.plotDetails, adminStory: true },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(a => {
        if (this.fromAdmin) {
          this.amplitudeService.track('admin - Official story button close clicked', this.story);
        } else {
          this.amplitudeService.track('Official story button close clicked', this.story);
        }
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { stories: null },
          queryParamsHandling: 'merge',
        });
      });
  }
}
