<div class="custom-input-container" [formGroup]="group">
  <ng-container *ngIf="['text', 'text_field', 'email'].includes(inputType)">
    <mat-form-field>
      <mat-label>{{ label }}<span *ngIf="showAsterisk" style="color: red"> *</span></mat-label>
      <input
        (keydown)="keydown($event)"
        [type]="inputType"
        formControlName="{{ formName }}"
        matInput
        autocomplete="off"
        aria-label="label"
      />
      <!-- <mat-hint>required*</mat-hint> -->
      <mat-hint *ngIf="visibility === 'Public'">{{ parseMessage(for) }}</mat-hint>
      <mat-error *ngIf="group.get('' + formName + '')?.touched && group.get('' + formName + '').hasError('required')"> Required </mat-error>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="inputType === 'textarea'">
    <mat-form-field>
      <mat-label>{{ label }}<span *ngIf="showAsterisk" style="color: red"> *</span></mat-label>
      <textarea [type]="inputType" formControlName="{{ formName }}" matInput autocomplete="off" aria-label="label"></textarea>
      <!-- <mat-hint>required*</mat-hint> -->
      <mat-hint *ngIf="visibility === 'Public'">{{ parseMessage(for) }}</mat-hint>
      <mat-error *ngIf="group.get('' + formName + '')?.touched && group.get('' + formName + '').hasError('required')"> Required </mat-error>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="inputType === 'number'">
    <mat-form-field>
      <mat-label>{{ label }}<span *ngIf="showAsterisk" style="color: red"> *</span></mat-label>
      <input formControlName="{{ formName }}" type="number" matInput autocomplete="off" aria-label="label" />
      <!-- <mat-hint>required*</mat-hint> -->
      <mat-error *ngIf="group.get('' + formName + '')?.touched && group.get('' + formName + '').hasError('required')"> Required </mat-error>
      <mat-hint *ngIf="visibility === 'Public'">{{ parseMessage(for) }}</mat-hint>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="inputType === 'select'">
    <mat-form-field>
      <mat-label>{{ label }}<span *ngIf="showAsterisk" style="color: red"> *</span></mat-label>
      <mat-select formControlName="{{ formName }}">
        <mat-option *ngFor="let item of listOption" [value]="item">{{ key && key === 'service_need' ? item[0] : item }} </mat-option>
        <!-- <mat-option *ngFor="let item of listOption" [value]="item">{{ item }} </mat-option> -->
      </mat-select>
      <!-- <mat-hint>required*</mat-hint> -->
      <mat-error *ngIf="group.get('' + formName + '')?.touched && group.get('' + formName + '').hasError('required')"> Required </mat-error>
      <mat-hint *ngIf="visibility === 'Public'">{{ parseMessage(for) }}</mat-hint>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="inputType === 'date'">
    <mat-form-field>
      <mat-label>{{ label }}<span *ngIf="showAsterisk" style="color: red"> *</span></mat-label>
      <input
        formControlName="{{ formName }}"
        matInput
        [matDatepicker]="applicationDate"
        [min]="minDate"
        #applicationDateInput
        (keypress)="validateDate($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="applicationDate"></mat-datepicker-toggle>
      <mat-datepicker #applicationDate color="primary"></mat-datepicker>
      <!-- <mat-hint>required*</mat-hint> -->
      <mat-error *ngIf="group.get('' + formName + '')?.touched && group.get('' + formName + '').hasError('required')"> Required </mat-error>
      <mat-hint *ngIf="visibility === 'Public'">{{ parseMessage(for) }}</mat-hint>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="inputType === 'datetime'">
    <mat-form-field>
      <mat-label>{{ label }}<span *ngIf="showAsterisk" style="color: red"> *</span></mat-label>
      <input
        formControlName="{{ formName }}"
        matInput
        [matDatepicker]="dateTimePicker"
        [min]="minDate"
        #dateTimePickerInput
        (keypress)="validateDate($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="dateTimePicker"></mat-datepicker-toggle>
      <mat-datepicker #dateTimePicker color="primary"></mat-datepicker>
      <!-- <mat-hint>required*</mat-hint> -->
      <mat-error *ngIf="group.get('' + formName + '')?.touched && group.get('' + formName + '').hasError('required')"> Required </mat-error>
      <mat-hint *ngIf="visibility === 'Public'">{{ parseMessage(for) }}</mat-hint>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="inputType === 'switch'">
    <mat-slide-toggle formControlName="{{ formName }}" color="primary" [checked]="value">
      {{ label }}<span *ngIf="showAsterisk" style="color: red"> *</span></mat-slide-toggle
    >
    <div class="mat-form-field" *ngIf="visibility === 'Public'">
      <small class="mat-hint">{{ parseMessage(for) }}</small>
    </div>
  </ng-container>
  <ng-container *ngIf="['checkbox'].includes(inputType)">
    <mat-checkbox
      formControlName="{{ formName }}"
      color="primary"
      (change)="checkBoxChange($event, group.get('' + formName + ''))"
      [checked]="value"
      >{{ label }}
      <span style="color: red" *ngIf="group.get('' + 'required' + '')?.value">*</span>
    </mat-checkbox>
    <p
      class="mat-error"
      style="font-size: 12px; margin: 0; color: red"
      *ngIf="
        !group.get('' + formName + '')?.value && group.get('' + formName + '').hasError('required') && group.get('' + formName + '').touched
      "
    >
      Required
    </p>
    <!-- <mat-slide-toggle formControlName="{{ formName }}" color="primary" [checked]="value"> {{ label }}</mat-slide-toggle> -->
    <div class="mat-form-field" *ngIf="visibility === 'Public'">
      <small class="mat-hint">{{ parseMessage(for) }}</small>
    </div>
  </ng-container>
</div>
