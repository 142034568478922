import * as Joi from 'joi';
import { personValidator } from './person-validator';
import { fileValidator } from './file-validator';

export const roisValidator = Joi.object({
  id: Joi.number(),
  applicant: personValidator.allow(null),
  application_date: Joi.date().iso().allow(null),
  certificate_number: Joi.string().allow(null),
  cemetery_unique_name: Joi.string(),
  plot_id: Joi.string(),
  fee: Joi.number().allow(null),
  fee_paid: Joi.boolean(),
  payment_date: Joi.date().iso().allow(null),
  right_type: Joi.string().allow(null),
  term_of_right: Joi.string().allow(null),
  service_need: Joi.string().allow(null),
  roi_holders: Joi.array().items(personValidator),
  files: Joi.array().items(fileValidator),
  note: Joi.string().allow(null),
}).allow(null);
