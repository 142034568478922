import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RouterUtilsService } from './router-utils.service';

@Injectable()
export class NotFoundInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router, private readonly routerUtilsService: RouterUtilsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          const path = this.routerUtilsService.getPath('not-found');
          this.router.navigate(path);

          return EMPTY;
        }

        return throwError(error);
      })
    );
  }
}
