import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Interment } from 'src/app/interfaces/interment';

@Component({
  selector: 'cl-banner-success-story',
  templateUrl: './banner-success-story.component.html',
  styleUrls: ['./banner-success-story.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerSuccessStoryComponent implements OnInit {
  zoom: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { interment: Interment },
    private readonly dialogRef: MatDialogRef<BannerSuccessStoryComponent>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry
      .addSvgIcon('close', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/close_cancel.svg'))
      .addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/info-notif.svg'));
  }

  ngOnInit(): void {
    this.zoom = this.activatedRoute.snapshot.queryParamMap.get('zoom');
  }

  onCancel() {
    this.dialogRef.close(true);
    // return this.router.navigate(['/', this.dialogData.interment.cemetery_unique_name, 'plots', this.dialogData.interment.plot_id], {
    //   queryParams: { from: 'map', zoom: this.zoom },
    // });
  }
}
