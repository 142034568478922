export enum CemeteriesListSortParams {
  'Creation date (newest first)',
  'Creation date (oldest first)',
  'Cemetery name (a - z)',
  'Cemetery name (z - a)',
  'Cemetery status (public first)',
  'Cemetery status (private first)',
}

export const CEMETERIES_LIST_DEFAULT_SORT_PARAM = 0;
