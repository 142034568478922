import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private readonly isOpen = new BehaviorSubject(false);
  private readonly isCalendarView = new BehaviorSubject(false);
  private readonly isMovePlot = new BehaviorSubject(false);
  private readonly selectedMenu = new BehaviorSubject('');
  private readonly searchMobileActive = new BehaviorSubject(false);
  private readonly isShowTogleButton = new BehaviorSubject(true);
  private readonly isOpenSearchMobile = new BehaviorSubject(false);
  private isButton = false;
  isOpenSideMenu = new BehaviorSubject(false);
  move: boolean;

  get isOpen$() {
    return this.isOpen.asObservable();
  }

  get isOpenSideMenu$() {
    return this.isOpenSideMenu.asObservable();
  }

  get isCalendarView$() {
    return this.isCalendarView.asObservable();
  }

  get isMovePlot$() {
    return this.isMovePlot.asObservable();
  }

  get searchMobileActive$() {
    return this.searchMobileActive.asObservable();
  }

  get isOpenSearchMobile$() {
    return this.isOpenSearchMobile.asObservable();
  }

  get isButtonClick() {
    return this.isButton;
  }

  get selectedMenu$() {
    return this.selectedMenu;
  }

  get showTogleButton$() {
    return this.isShowTogleButton.asObservable();
  }

  set buttonClick(status: boolean) {
    this.isButton = status;
  }

  showTogleButton() {
    this.isShowTogleButton.next(true);
  }

  hideTogleButton() {
    this.isShowTogleButton.next(false);
  }

  open() {
    this.isOpen.next(true);
  }

  close() {
    this.isOpen.next(false);
  }

  toggle() {
    const { value } = this.isOpen;
    this.isOpen.next(!value);
  }

  toggleSideMenu() {
    const { value } = this.isOpenSideMenu;
    this.isOpenSideMenu.next(!value);
  }

  setSearchMobileActive(isValue: boolean) {
    this.searchMobileActive.next(isValue);
  }

  closeSideMenu() {
    this.isOpenSideMenu.next(false);
  }

  closeMovePlot(status: boolean) {
    this.isMovePlot.next(status);
  }

  toggleCalendarView(status: boolean) {
    this.isCalendarView.next(status);
  }

  setSelectedMenu(menu: string) {
    this.selectedMenu.next(menu);
  }

  setOpenSearchMobile() {
    this.isOpenSearchMobile.next(true);
  }
}
