import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, OnDestroy, ViewChildren, QueryList, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { AccessService } from 'src/app/admin/access.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { UserRole } from 'src/app/enums/user-role.enum';
import { AccessRoleCemetery } from 'src/app/interfaces/access-org';
import { LoadingService } from 'src/app/loading-overlay/loading.service';

@Component({
  selector: 'cl-add-access-org-edit-modal',
  templateUrl: './add-access-org-edit-modal.component.html',
  styleUrls: ['./add-access-org-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAccessOrgEditModalComponent implements OnInit, AfterViewInit, OnDestroy {
  get fc() {
    return this.accessForm.controls;
  }

  get emailsArray() {
    return this.accessForm.get('emails') as FormArray;
  }

  get cemeteriesArray() {
    return this.accessForm.get('cemeteries') as FormArray;
  }
  private destroy$ = new Subject();

  @ViewChildren(MatChipList) chiplists: QueryList<MatChipList>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  readonly roles = UserRole;

  chipsConfig = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
  };

  cemeteryInput = new FormControl();

  accessForm = this.formBuilder.group({
    emails: this.formBuilder.array([this.dialogData.email_user]),
    cemeteries: this.formBuilder.array([], [this.validateArrayNotEmpty]),
    role: [null, Validators.required],
    addAllCemeteries: [false],
  });

  filteredCemetery$: Observable<AccessRoleCemetery[]>;

  loaders$ = new BehaviorSubject({});

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoadingService,
    private dialog: MatDialogRef<AddAccessOrgEditModalComponent>,
    private accessService: AccessService,
    private snackbarService: SnackbarNotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { cemeteries: AccessRoleCemetery[]; organization_id: number; email_user: string }
  ) {}

  ngOnInit(): void {
    const cemeteryInputValueChange$ = this.cemeteryInput.valueChanges.pipe(startWith(this.cemeteryInput.value));
    const cemeteriesArrayValueChange$ = this.cemeteriesArray.valueChanges.pipe(startWith([]));
    this.filteredCemetery$ = combineLatest([cemeteryInputValueChange$, cemeteriesArrayValueChange$]).pipe(
      map(([inputValue, cemeteriesArrayValue]) => {
        return this.dialogData.cemeteries.filter(cemetery => {
          const isNotInArray = !cemeteriesArrayValue?.find(arrayValue => arrayValue.name === cemetery.name);
          const matchesInput =
            !inputValue || cemetery.name.toLowerCase().includes(inputValue?.name?.toLowerCase() || inputValue?.toLowerCase());
          return isNotInArray && matchesInput;
        });
      })
    );
  }

  ngAfterViewInit(): void {
    this.updateChipListsErrorState();
    this.setCemeteriesSelection();
  }

  addSelection(event: MatChipInputEvent, target: 'emails' | 'cemeteries'): void {
    const input = event?.input;
    const value = event?.value;

    if (value && value.trim() && target === 'emails') {
      this.emailsArray.push(this.formBuilder.control(value.trim()));
    }

    if (input) {
      input.value = '';
    }
  }

  removeSelection(currentIndex: number, target: 'emails' | 'cemeteries'): void {
    const targetArray: FormArray = target === 'emails' ? this.emailsArray : this.cemeteriesArray;
    targetArray.removeAt(currentIndex);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.cemeteriesArray.push(this.formBuilder.group(event.option.value));
    this.cemeteryInput.setValue(null);
  }

  invite(): void {
    const parsedForm = {
      email: this.fc.emails.value,
      role: this.fc.role.value,
      cemetery: this.cemeteriesArray.controls.map(control => control.value.id),
    } as { email: string[]; role: number; cemetery: number[] };

    this.accessService
      .addAccess(this.dialogData.organization_id, parsedForm)
      .pipe(
        this.loaderService.setLoaders('inviteAccess', this.loaders$),
        catchError(error => {
          if (error) {
            this.snackbarService.openErrorMessage(error.error.message || error);
            this.dialog.close();
            return;
          }
          return EMPTY;
        }),
        filter(response => !!response),
        tap(result => {
          const countUserFromResponse = result.reduce(
            (acc, curr) => {
              if (curr.message) {
                const email = curr.message.split(' ')[2];
                acc.userExist.count++;
                acc.userExist.emails.push(email);
              }

              if (curr.id) {
                acc.userSuccess.count++;
                acc.userSuccess.emails.push(curr.email);
              }

              return acc;
            },
            { userSuccess: { count: 0, emails: [] }, userExist: { count: 0, emails: [] } }
          );

          if (countUserFromResponse.userExist.count) {
            this.snackbarService.openWarningMessage(
              `${
                countUserFromResponse.userSuccess.count > 1
                  ? `Success invited ${countUserFromResponse.userSuccess.count} users.`
                  : !countUserFromResponse.userSuccess.count
                  ? ''
                  : `Success invited ${countUserFromResponse.userSuccess.count} user.`
              } Failed to add ${countUserFromResponse.userExist.emails.join(',')}, ${
                countUserFromResponse.userExist.count > 1 ? 'users' : 'user'
              } already exist in cemetery`,
              -1
            );
            this.dialog.close(result);
            return;
          }

          this.snackbarService.openSuccessMessage('Successfully invited');
          this.dialog.close(result);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  cancel(): void {
    this.dialog.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private validateArrayNotEmpty(control: FormControl) {
    if (!control.value.length) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  private validateUniqueEmails(control: FormControl) {
    const emails = control.value;
    const uniqueEmails = new Set(emails);
    if (emails.length !== uniqueEmails.size) {
      return {
        validateUniqueEmails: { valid: false },
      };
    }
    return null;
  }

  private emailValidator(control: FormControl) {
    const emailRegex = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);

    const emails = control.value;

    const invalidEmails = emails.filter(email => !emailRegex.test(email));

    if (invalidEmails.length) {
      return {
        emailValidator: { valid: false },
      };
    }
    return null;
  }

  private updateChipListsErrorState(): void {
    combineLatest([
      this.emailsArray.statusChanges.pipe(startWith(false)),
      this.cemeteriesArray.statusChanges.pipe(startWith(false)),
      of(this.chiplists.toArray()),
    ])
      .pipe(
        tap(([emailsStatus, cemeteriesStatus, chipLists]) => {
          chipLists[0].errorState = emailsStatus === 'INVALID';
          if (chipLists.length > 1) {
            chipLists[1].errorState = cemeteriesStatus === 'INVALID';
          }
        })
      )
      .subscribe();
  }

  private setCemeteriesSelection(): void {
    combineLatest([this.fc.addAllCemeteries.valueChanges.pipe(startWith(false)), of(this.dialogData.cemeteries)])
      .pipe(
        tap(([checkBoxValue, dialogData]) => {
          if (checkBoxValue) {
            dialogData.forEach(cemetery => this.cemeteriesArray.push(this.formBuilder.group(cemetery)));
            return;
          }

          if (dialogData.length === 1) {
            this.cemeteriesArray.push(this.formBuilder.control(dialogData[0]));
            return;
          }

          this.cemeteriesArray.clear();
        })
      )
      .subscribe();
  }
}
