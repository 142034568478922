import { NgModule } from '@angular/core';
import { LoadingOverlayComponent } from './loading-overlay.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule, MatProgressBarModule],
  declarations: [LoadingOverlayComponent],
  exports: [LoadingOverlayComponent],
})
export class LoadingOverlayModule {}
