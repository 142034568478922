<mat-card class="event-card">
  <mat-card-subtitle class="main-title">
    <div class="right">
      <ngx-skeleton-loader
        count="1"
        appearance="circle"
        [theme]="{
          height: '60px',
          width: '60px'
        }"
      >
      </ngx-skeleton-loader>
    </div>
  </mat-card-subtitle>

  <!-- <mat-card-title class="event-title">
    <ngx-skeleton-loader [theme]="{ width: '40px' }"></ngx-skeleton-loader>
  </mat-card-title> -->
  <mat-card-subtitle class="category-title">
    <div class="category">
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </div>
  </mat-card-subtitle>
  <mat-card-footer class="card-footer">
    <div class="aggrement">
      <div class="checkbox">
        <ngx-skeleton-loader [theme]="{ width: '20px' }"></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader [theme]="{ width: '180px' }"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="button-footer">
      <ngx-skeleton-loader [theme]="{ width: '60px', height: '30px' }"></ngx-skeleton-loader>
    </div>
  </mat-card-footer>
</mat-card>
