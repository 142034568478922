import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingStatus$ = new BehaviorSubject(false);
  private runningLoaders = 0;

  private store$: BehaviorSubject<Record<string, any>> = new BehaviorSubject({});

  run() {
    this.loadingStatus$.next(true);
    this.runningLoaders += 1;
  }

  stop() {
    this.runningLoaders -= 1;

    console.assert(this.runningLoaders >= 0, 'Inconsistent run and stops, too many stops');

    if (this.runningLoaders > 0) {
      return;
    }

    this.loadingStatus$.next(false);
  }

  isLoading$() {
    return this.loadingStatus$.asObservable();
  }

  setLoaders(
    actionName: string,
    store?: BehaviorSubject<Record<string, any>>,
    identifer?: string
  ) {
    return (source: Observable<any>) => {
      const setLoaderValue = (value: boolean) => {
        return identifer ? { [identifer]: { isLoading: value } } : { isLoading: value };
      };

      const localStore = store || this.store$;
      localStore.next({
        ...localStore.value,
        [actionName]: { ...localStore.value[actionName], ...setLoaderValue(true) },
      });
      return source.pipe(
        finalize(() => {
          localStore.next({
            ...localStore.value,
            [actionName]: { ...localStore.value[actionName], ...setLoaderValue(false) },
          });
        })
      );
    };
  }

  get loaders$() {
    return this.store$.asObservable();
  }
}
