import { PlotListItem } from './plot-list-item';
// import { Bounds } from '../interfaces/bounds';
import { ShapeType } from '../enums/shape-type.enum';
import { Observable } from 'rxjs';
import { CemeteryInfoBase } from './cemetery-info-base';

export enum SectionType {
  NicheWall = 'NicheWall',
  Lawn = 'Lawn',
}

export interface SectionData {
  id: number | null;
  name: string;
  plots: PlotListItem[];
}

export interface DataSectionBase {
  data: DataSection[];
  paginate: Paginate;
}

export interface DataSection {
  cemetery_id: number;
  id: number;
  name: string;
  polygon: Bounds;
  cemetery?: CemeteryInfoBase;
  type: SectionType;
  background?: string;
  rotate_degree?: number;
}

export interface Paginate {
  page: number;
  limit: number;
  total: number;
}

export interface Bounds {
  type: ShapeType.polygon;
  coordinates: any;
}

export interface SectionLink {
  section_id: number;
  section_name: string;
  section_type: SectionType.Lawn | SectionType.NicheWall;
  section_rotate_degree?: number;
}
