import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { MapActionsService } from 'src/app/core/map-actions.service';
import { transformName, transformNameAdvanceSearch } from '../name-transform-function';
import { PlotsService } from 'src/app/core/plots.service';
import {
  filter,
  finalize,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  catchError,
  startWith,
  distinctUntilChanged,
  concatMap,
  map,
  share,
  take,
} from 'rxjs/operators';
import { EMPTY, Subject, combineLatest, iif, of } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdvanceSearchResult, Counter, Data, FormAdvanceSearch, Page } from 'src/app/interfaces/advance-search';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { MapService } from 'src/app/core/map.service';
import { SectionType } from 'src/app/interfaces/section-data';
import { SectionsService } from 'src/app/core/sections.service';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'cl-advance-search-result',
  templateUrl: './advance-search-result.component.html',
  styleUrls: ['./advance-search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvanceSearchResultComponent implements OnInit, OnDestroy {
  private unsubscribeSearch: Subject<void> = new Subject();
  searchValue: string;
  isLoading = false;
  isLoadingNewPage = false;
  resultRecords: Data[];
  totalCemetery: Counter[];
  pagination: Page;
  totalData = 0;
  totalPage = 0;
  dataPerPage = 15;
  currentPage = 1;
  dataForm: FormAdvanceSearch;
  searchList: Data[];
  isLifeChronicle = false;
  isFeaturedStory = false;
  isReturnServicePerson = false;
  isPrevNicheWall = false;
  currentBounds: any;
  toogleOn = new FormControl(false);

  destroy$ = new Subject<void>();

  constructor(
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private cdr: ChangeDetectorRef,
    private mapActionsService: MapActionsService,
    private mapService: MapService,
    private plotService: PlotsService,
    private sectionService: SectionsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerUtilsService: RouterUtilsService,
    private amplitudeService: AmplitudeService
  ) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_shared_plot_details_close',
        src: '/assets/images/close_icon_white.svg',
      },
      {
        name: 'custom_search_record_plot',
        src: '/assets/images/plot_icon.svg',
      },
      {
        name: 'custom_people',
        src: '/assets/images/people.svg',
      },
      {
        name: 'custom_shared_search_item_plot',
        src: '/assets/images/plot_icon.svg',
      },
    ]);
  }

  ngOnInit(): void {
    const org = this.activatedRoute.snapshot.queryParamMap.get('org');
    const queryParam: Params = { org };
    this.isLoading = true;
    this.plotService.isToggleOn$
      .pipe(
        take(1),
        tap(value => {
          this.toogleOn.setValue(value);
        })
      )
      .subscribe();
    this.getToggleValue();
    this.getDataAdvanceSearch(org, queryParam);
    // this.getData(org, queryParam);

    // this.plotService.dataAdvanceSearch$
    //   .pipe(
    //     filter(data => {
    //       if (data === null || data === undefined) {
    //         if (org === null) {
    //           this.router.navigate(['']);
    //         } else {
    //           this.router.navigate([''], {
    //             queryParams: {
    //               org: queryParam.org,
    //             },
    //           });
    //         }
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     }),
    //     switchMap((data: FormAdvanceSearch) => {
    //       this.plotService.updateisLoad(true);
    //       this.isLoading = true;
    //       this.searchList = [];
    //       this.currentPage = 1;
    //       this.totalPage = 0;
    //       this.dataForm = data;
    //       this.searchValue = data?.fn + ' ' + data?.ln;
    //       this.cdr.detectChanges();

    //       return this.plotService.advanceSearchPublic(data, 1, 15);
    //     }),
    //     shareReplay(1),
    //     tap((res: AdvanceSearchResult) => {
    //       this.searchList = res.data;
    //       this.totalCemetery = res.counter;
    //       this.plotService.updateDataAdvanceSearchResult(res);
    //       //   this.resultRecords = res.data;
    //       this.pagination = res.paginate;
    //       this.totalPage = Math.ceil(this.pagination.total_data / this.dataPerPage);
    //       this.plotService.updateisNeed(false);
    //       this.isLoading = false;
    //       this.plotService.updateisLoad(false);
    //       this.cdr.markForCheck();
    //       this.cdr.detectChanges();
    //     }),
    //     finalize(() => {}),
    //     takeUntil(this.unsubscribeSearch)
    //   )
    //   .subscribe();
  }

  ngOnDestroy(): void {
    this.plotService.updateDataAdvanceSearchResult(null);
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDataAdvanceSearch(org, queryParam) {
    const boundariesData$ = this.getBoundaries();
    boundariesData$
      .pipe(
        filter(([data, boundaries, toggleOn]) => {
          if (data === '' || data === null || data === undefined) {
            if (org === null) {
              this.router.navigate(['']);
            } else {
              this.router.navigate([''], {
                queryParams: {
                  org: queryParam.org,
                },
              });
            }
            return false;
          } else {
            return true;
          }
        }),
        distinctUntilChanged(),
        switchMap(([data, bounds]) => {
          this.plotService.updateisLoad(true);
          this.currentBounds = bounds;
          this.isLoading = true;
          this.searchList = [];
          this.currentPage = 1;
          this.totalPage = 0;
          this.dataForm = data;
          this.searchValue = data?.fn + ' ' + data?.ln;
          this.cdr.detectChanges();

          return this.plotService.advanceSearchPublic(data, 1, 15, this.toogleOn.value ? bounds : null);
        }),
        map((res: AdvanceSearchResult) => {
          this.searchList = res.data;
          this.totalCemetery = res.counter;
          this.resultRecords = res.data;
          this.pagination = res.paginate;
          this.totalPage = Math.ceil(this.pagination.total_data / this.dataPerPage);
          //   this.plotService.updateisNeed(false);
          this.isLoading = false;
          this.plotService.updateisLoad(false);
          //   this.plotService.updateDataAdvanceSearchResult(null);
          //   this.cdr.markForCheck();
          this.cdr.detectChanges();
        }),
        takeUntil(this.unsubscribeSearch),
        finalize(() => {})
      )
      .subscribe();
  }

  getToggleValue() {
    this.toogleOn.valueChanges
      .pipe(
        tap(value => {
          this.plotService.updateIsToggleOn(value);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getBoundaries() {
    return this.plotService.dataBoundsAdvanceSearch$.pipe(
      switchMap(bounds => {
        return combineLatest([
          this.plotService.dataAdvanceSearch$,
          this.plotService.dataBoundsAdvanceSearch$,
          this.toogleOn.valueChanges.pipe(startWith('')),
        ]).pipe(
          map(([data, boundaries, toggleOn]) => {
            this.currentBounds = boundaries;
            return [data, boundaries, toggleOn];
          })
        );
      })
    );
  }

  onClick(record) {
    this.amplitudeService.track(`See All Story Clicked`, record);
    if (record.section_data?.section_type === SectionType.NicheWall) {
      const parseCoords = L.geoJSON(record.coordinates).getBounds();
      this.isPrevNicheWall = true;
      this.mapService.map.setMaxBounds([
        [-90, -360],
        [90, 360],
      ]);
      this.sectionService.currentSectionLink$.next(record.section_data);
      this.mapActionsService.setViewNicheWallAdvanceSearchDetail(record, parseCoords, 28, record.section_data.section_rotate_degree);
      return;
    }

    if (this.isPrevNicheWall || this.sectionService.currentNicheWallSection$.getValue()?.id) {
      this.mapService.map.setMaxZoom(24);
      this.mapService.map.setZoom(24);
      this.mapService.map.setBearing(0);
      setTimeout(() => {
        this.mapActionsService.showLocationByCoordinateAndUpdateRoute(record);
        this.sectionService.currentNicheWallSection$.next(null);
        this.sectionService.currentSectionLink$.next(null);
        this.isPrevNicheWall = false;
      }, 400);
      return;
    }

    this.mapActionsService.showLocationByCoordinateAndUpdateRoute(record);

    setTimeout(() => {
      document.querySelector('.cdk-overlay-container')?.classList?.add('bring-to-back');
    }, 0);
  }

  transform(person) {
    return transformName(person);
  }

  onScrollDown() {
    if (this.currentPage < this.totalPage) {
      this.currentPage += 1;
      this.loadAdvanceSearchList();
    }
  }

  loadAdvanceSearchList() {
    this.isLoadingNewPage = true;
    this.plotService.updateisLoad(true);
    this.cdr.detectChanges();
    this.plotService
      .advanceSearchPublic(this.dataForm, this.currentPage, this.dataPerPage, this.toogleOn.value ? this.currentBounds : null)
      .pipe(
        catchError(error => {
          this.searchList = [];
          return EMPTY;
        }),
        tap<AdvanceSearchResult>(searchData => {
          this.totalData = searchData.paginate.total_data;
          this.totalPage = Math.ceil(this.totalData / this.dataPerPage);
          this.searchList = this.searchList.concat(searchData.data);

          const newObData: AdvanceSearchResult = {
            ...searchData,
            data: this.searchList,
          };
          //   this.plotService.updateDataAdvanceSearchResult(newObData);

          this.cdr.detectChanges();
        }),
        finalize(() => {
          this.plotService.updateisLoad(false);
          this.isLoadingNewPage = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  highlightedContent(person) {
    return transformNameAdvanceSearch(person);
  }

  checkIfFeaturedStory(persons: any) {
    const total = persons.filter(person => person.is_admin === 1);

    if (total.length > 0) {
      return true;
    }
    return false;
  }

  checkIfLifeChronicle(persons: any) {
    const total = persons.filter(person => person.life_chronicle === 1);

    if (total.length > 0) {
      return true;
    }
    return false;
  }

  checkIfReturnServicePerson(persons: any) {
    const total = persons.filter(person => person.returned_serviceman === 1);

    if (total.length > 0) {
      return true;
    }
    return false;
  }

  formatPlotId(plotId: string) {
    return plotId.includes('Unassigned') ? 'Unassigned' : plotId;
  }

  close() {
    this.unsubscribe();
    this.plotService.updateDataAdvanceSearch(null);
    this.plotService.updateDataAdvanceSearchResult(null);
    const org = this.activatedRoute.snapshot.queryParamMap.get('org');
    const queryParam: Params = { org };
    const cemeteryName = this.activatedRoute.snapshot.paramMap.get('cemeteryName');
    const cemeteryPath = this.routerUtilsService.getPath(cemeteryName);
    if (org) {
      this.router.navigate([''], {
        queryParams: {
          org: queryParam.org,
        },
      });
      return;
    }
    if (cemeteryName && cemeteryName.length > 1) {
      this.router.navigate(cemeteryPath);
      return;
    }
    this.router.navigate(['/']);
  }

  public unsubscribe(): void {
    this.unsubscribeSearch.next();
  }
}
