import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingUploaderSharedService {
  private readonly isLoadingLocal = new BehaviorSubject(false);

  private store$: BehaviorSubject<Record<string, any>> = new BehaviorSubject({});

  isLoading$ = this.isLoadingLocal.asObservable();

  constructor() {}

  get isLoading() {
    return this.isLoadingLocal.value;
  }

  get loaders$() {
    return this.store$.asObservable();
  }

  run() {
    this.isLoadingLocal.next(true);
  }

  stop() {
    this.isLoadingLocal.next(false);
  }

  setLoaders(
    actionName: string,
    store?: BehaviorSubject<Record<string, any>>,
    identifer?: string
  ) {
    return (source: Observable<any>) => {
      const setLoaderValue = (value: boolean) => {
        return identifer ? { [identifer]: { isLoading: value } } : { isLoading: value };
      };

      const localStore = store || this.store$;
      localStore.next({
        ...localStore.value,
        [actionName]: { ...localStore.value[actionName], ...setLoaderValue(true) },
      });
      return source.pipe(
        finalize(() => {
          localStore.next({
            ...localStore.value,
            [actionName]: { ...localStore.value[actionName], ...setLoaderValue(false) },
          });
        })
      );
    };
  }
}
