import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cl-delete-file-dialog-shared',
  templateUrl: './delete-file-dialog-shared.component.html',
  styleUrls: ['./delete-file-dialog-shared.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteFileDialogSharedComponent {
  constructor(public dialogRef: MatDialogRef<DeleteFileDialogSharedComponent>) {}

  delete() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
