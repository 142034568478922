import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EMPTY, Subject } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OrganizationService } from 'src/app/admin/chronicle-admin/organization.service';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { NavigateBackService } from 'src/app/core/navigate-back.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { LoadingService } from 'src/app/loading-overlay/loading.service';
import { DialogRequestSentComponent } from '../dialog-request-sent/dialog-request-sent.component';
import { RouterUtilsService } from 'src/app/core/router-utils.service';

@Component({
  selector: 'cl-form-general-purpose',
  templateUrl: './form-general-purpose.component.html',
  styleUrls: ['./form-general-purpose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGeneralPurposeComponent implements OnInit, OnDestroy {
  sourceForm: any;
  cemeteryInfo: any;
  plotDetails: any;
  allSectionValid = false;
  formValue: any;
  destroy$: Subject<void> = new Subject<void>();
  result: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private organizationService: OrganizationService,
    private snackBarNotificationService: SnackbarNotificationService,
    private loadingService: LoadingService,
    private amplitudeService: AmplitudeService,
    private navigateBackService: NavigateBackService,
    private routerUtilsService: RouterUtilsService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.sourceForm = this.activatedRoute.snapshot.data?.dataGeneralForm;
    this.cemeteryInfo = this.activatedRoute.snapshot.data?.cemetery;
    this.plotDetails = this.activatedRoute.snapshot.data?.plotDetails;
  }

  ngOnInit(): void {}

  sectionValid(event) {
    this.allSectionValid = event.isValid;
    this.formValue = event.value;
  }

  submit() {
    const payload: { custom_form_value: any; request_type: string; cemetery: any; plot_id?: any; name?: any } = {
      custom_form_value: this.formValue.sections,
      request_type: this.sourceForm.event_type,
      cemetery: this.cemeteryInfo.id,
      name: this.sourceForm.title,
    };

    if (this.plotDetails) {
      payload.plot_id = this.plotDetails.plot_id;
    }
    this.loadingService.run();
    this.organizationService
      .saveGeneralCustomForm(payload)
      .pipe(
        catchError(error => {
          this.loadingService.stop();
          this.snackBarNotificationService.openErrorMessage(error.error.message);
          return EMPTY;
        }),
        tap(data => {
          this.result = data;
          if (data) {
            this.amplitudeService.track(`General Form - Button "Submit" Clicked`, { ...payload });
          }
        }),
        switchMap(() => {
          const navigationPath =
            this.plotDetails && this.plotDetails.plot_id
              ? this.routerUtilsService.getPath(this.cemeteryInfo.unique_name, 'plots', this.plotDetails.plot_id)
              : this.routerUtilsService.getPath(this.cemeteryInfo.unique_name);
          return this.router.navigate(navigationPath);
        }),
        finalize(() => {
          this.loadingService.stop();
          this.router.events
            .pipe(
              filter(event => event instanceof NavigationEnd),
              take(1)
            )
            .subscribe(() => {
              this.dialog.open(DialogRequestSentComponent, {
                width: '480px',
                disableClose: true,
                data: {
                  cemeteryInfo: this.cemeteryInfo,
                  plotDetails: this.plotDetails,
                  title: this.sourceForm.title,
                  result: this.result,
                },
              });
            });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  backToPrev() {
    this.navigateBackService.goBack();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
