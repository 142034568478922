import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, NgZone, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, Subject, combineLatest, fromEvent } from 'rxjs';
import { filter, map, scan, skip, startWith, takeUntil, tap, toArray } from 'rxjs/operators';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { LegendService } from 'src/app/core/legend.service';
import { MapService } from 'src/app/core/map.service';
import { SidebarService } from 'src/app/core/sidebar.service';

@Component({
  selector: 'cl-legend-measurement',
  templateUrl: './legend-measurement.component.html',
  styleUrls: ['./legend-measurement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendMeasurementComponent implements OnInit, OnDestroy {
  get formControl() {
    return this.measureLegendForm.controls;
  }

  private destroy$ = new Subject();

  private measureControlConfig = null;

  private measureVertexes = null;

  @ViewChild('trigger') trigger: MatMenuTrigger;

  @ViewChild('measureOptionsTpl') measureOptionsTpl: TemplateRef<any>;

  measureEvent$ = new Subject();

  measureResultModel$ = new BehaviorSubject<any>(null);

  isCalendarView$ = this.sidebarService.isCalendarView$;

  isSideBarOpen$ = this.sidebarService.isOpen$;

  measureLegendForm = this.fb.group({
    distanceUnit: ['metersCustom'],
    areaUnit: ['sqmetersCustom'],
  });

  captureMarker = null;

  measurementOptions = {
    distance: [
      { value: 'feetCustom', viewValue: 'Feet (ft)' },
      { value: 'milesCustom', viewValue: 'Miles (mi)' },
      { value: 'metersCustom', viewValue: 'Meters (m)' },
      { value: 'kilometersCustom', viewValue: 'Kilometers (km)' },
    ],
    area: [
      { value: 'acresCustom', viewValue: 'Acres (ac)' },
      { value: 'hectaresCustom', viewValue: 'Hectares (ha)' },
      { value: 'sqfeetCustom', viewValue: 'Square feet (ft²)' },
      { value: 'sqmetersCustom', viewValue: 'Square meters (m²)' },
      { value: 'sqmilesCustom', viewValue: 'Square miles (mi²)' },
    ],
  };

  customUnit = {
    feetCustom: {
      factor: 3.2808,
      display: 'ft',
      decimals: 2,
    },
    milesCustom: {
      factor: 3.2808 / 5280,
      display: 'mi',
      decimals: 2,
    },
    metersCustom: {
      factor: 1,
      display: 'm',
      decimals: 1,
    },
    kilometersCustom: {
      factor: 0.001,
      display: 'km',
      decimals: 2,
    },
    acresCustom: {
      factor: 0.00024711,
      display: 'ac',
      decimals: 2,
    },
    hectaresCustom: {
      factor: 0.0001,
      display: 'ha',
      decimals: 2,
    },
    sqfeetCustom: {
      factor: 10.7639,
      display: 'ft²',
      decimals: 0,
    },
    sqmetersCustom: {
      factor: 1,
      display: 'm²',
      decimals: 0,
    },
    sqmilesCustom: {
      factor: 0.000000386102,
      display: 'mi²',
      decimals: 2,
    },
  };

  constructor(
    private fb: FormBuilder,
    private legendService: LegendService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private mapService: MapService,
    private dialogService: MatDialog,
    private sidebarService: SidebarService
  ) {
    this.addIconsThroughMaterialService.add([
      { name: 'custom_filter', src: '/assets/images/filter_icon.svg' },
      { name: 'check_circle', src: '/assets/images/check_circle.svg' },
      { name: 'close_cancel_purple', src: '/assets/images/close_cancel_purple.svg' },
      { name: 'gear_icon', src: '/assets/images/gear-icon.svg' },
      { name: 'ruler', src: '/assets/images/ruler-measure.svg' },
      { name: 'warning', src: '/assets/images/warning-yellow.svg' },
    ]);
  }

  ngOnInit(): void {
    this.mapService.map$
      .pipe(
        filter(mapRef => !!mapRef),
        tap((mapRef: any) => {
          L.Control.Measure.include({
            _setCaptureMarkerIcon() {
              this._captureMarker.options.autoPanOnFocus = false;
              this._captureMarker.setIcon(
                L.divIcon({
                  iconSize: this._map.getSize().multiplyBy(2),
                })
              );
            },
            // set the unit of measurement
            _setOptionsUnit(areaUnit, distanceUnit) {
              this.options.primaryLengthUnit = areaUnit;
              this.options.primaryAreaUnit = distanceUnit;
            },
            // access the capture marker
            _getCaptureMarker() {
              return this._captureMarker;
            },
            _getMeasureVertexes() {
              return this._measureVertexes;
            },
          });

          this.measureControlConfig = L.control.measure({
            position: 'bottomright',
            units: this.customUnit,
            primaryLengthUnit: this.formControl.distanceUnit.value,
            primaryAreaUnit: this.formControl.areaUnit.value,
            secondaryLengthUnit: null,
            activeColor: '#9F09FF',
            completedColor: '#9F09FF',
          });

          // Add measureControlConfig instance to map
          this.measureControlConfig.addTo(mapRef);

          // Config to listen measurement event
          mapRef.on('measurestart', event => {
            this.measureEvent$.next(event);
          });

          mapRef.on('measurefinish', event => {
            this.measureEvent$.next(event);
            this.measureResultModel$.next(null);
          });

          const containerEl = this.measureControlConfig.getContainer();
          containerEl.style.display = 'none';
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    fromEvent(document, 'click')
      .pipe(
        filter((event: any) => {
          const selectors = ['.leaflet-container', '.mat-menu-trigger.btn-legend-trigger', '.measure-option-modal', '.measure-option'];
          const isOutsideAll = selectors.every(selector => !event.target.closest(selector));
          const isInsideLeafletControl = event.target.closest('.leaflet-control');
          return isOutsideAll || isInsideLeafletControl;
        }),
        tap(() => this.trigger.closeMenu()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  startMeasure() {
    this.measureControlConfig._startMeasure();
    const captureMarker = this.measureControlConfig._getCaptureMarker();
    const measureVertexes = this.measureControlConfig._getMeasureVertexes();
    const layer = document.getElementsByClassName('leaflet-control');
    const list = document.getElementsByClassName('leaflet-control-layers-list');
    const descLegend = document.getElementsByClassName('desc legend');

    this.measureVertexes = measureVertexes;
    // Remove the dblclick event listener; this to prevent
    captureMarker.off('dblclick');

    captureMarker.on('click', () => this.measureResultModel$.next(this.measureControlConfig._resultsModel));

    if (layer[0].classList.contains('expanded-on')) {
      layer[0].classList.remove('expanded-on');
      list[0]?.classList.remove('expanded-list');
    }

    if (!descLegend[0].classList.contains('none')) {
      descLegend[0].classList.add('none');
    }
  }

  finishMeasure() {
    this.clearMeasure();
  }

  clearMeasure() {
    this.measureControlConfig._finishMeasure();
    this.measureResultModel$.next(null);
    this.measureVertexes = null;
  }

  parseLatLng(latlng: string) {
    return latlng.toString().replace(/\d+\.\d+/g, match => {
      return Number(match).toFixed(5);
    });
  }

  openMeasureOptionModal() {
    this.dialogService
      .open(this.measureOptionsTpl, {
        width: '360px',
        panelClass: 'measure-option-modal',
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        filter(result => !!result),
        tap(() => {
          const distanceUnit = this.formControl.distanceUnit.value;
          const areaUnit = this.formControl.areaUnit.value;
          this.measureControlConfig._setOptionsUnit(distanceUnit, areaUnit);

          if (this.measureControlConfig._resultsModel) {
            this.measureControlConfig._updateResults();
            this.measureResultModel$.next(this.measureControlConfig._resultsModel);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.measureControlConfig && this.measureVertexes) {
      this.measureControlConfig._finishMeasure();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
