<div class="left">
  <mat-icon *ngIf="!image" svgIcon="custom_shared_search_item_plot" class="{{ status | lowercase }}"></mat-icon>
  <img *ngIf="image" [src]="image" alt="" />
</div>

<div class="right">
  <div *ngIf="(isAdmin && !cemeteryUniqueName) || (org && !cemeteryUniqueName)" class="cemetery-name">{{ plot.cemetery_name }}</div>
  <div class="wrapper-plot">
    <span class="plot-id">
      <div class="main-title">
        <span class="title" clHighlight [content]="formatPlotId(plotId)" [searchTerm]="searchValue"> {{ formatPlotId(plotId) }}</span>
        <img *ngIf="isFeaturedStory" title="Official Story" src="/assets/images/featured-story-ban.png" style="width: 20px" alt="" />
        <img
          *ngIf="isLifeChronicle"
          title="Life Chronicle Online Memorial"
          src="/assets/images/life-chronicle-ban.png"
          style="width: 20px"
          alt=""
        />
        <img *ngIf="isReturnServicePerson" title="Return Service Person" src="/assets/images/Veteran.png" style="width: 20px" alt="" />
        <span *ngIf="status && plot?.interments?.length === 0" class="plot-label {{ status | lowercase }}">{{ status }}</span>
        <div *ngIf="isAdmin" class="separator"></div>
        <div *ngIf="isAdmin" class="total-capacity">
          <img class="icon" src="/assets/images/person_icon_grey.svg" alt="" />
          <span class="amount"> {{ plot.interments.length }} / {{ plot.total_capacity }} </span>
        </div>
        <div *ngIf="isAdmin" class="separator"></div>
        <div *ngIf="isAdmin" class="plot-type">
          {{ plot.plot_type }}
        </div>
      </div>
    </span>
  </div>

  <div class="person-detail">
    <span class="plot-price" *ngIf="plot.status === 'For Sale' && plot.show_price === 1">${{ plot.price }}</span>
    <span class="person-name" clHighlight [content]="transFormName" [searchTerm]="searchValue"></span>
    <div class="person-birth" *ngIf="plot?.interments[0]?.date_of_birth && plot?.interments[0]?.date_of_death">
      ({{ plot?.interments[0]?.date_of_birth | dateIsoToJs | date: 'yyyy' }} -
      {{ plot?.interments[0]?.date_of_death | dateIsoToJs | date: 'yyyy' }})
    </div>
    <div *ngIf="plot?.interments_count > 0" class="more-person">+{{ plot?.interments_count }} more</div>
  </div>

  <div class="roi-details" *ngIf="isAdmin && plot.roi.length > 0">
    <!-- <ng-container *ngFor="let roi of plot.roi"> -->
    <span class="roi-name" clHighlight [content]="highlightedContentRoi" [searchTerm]="searchValue">
      {{ plot.roi[0] | name }}
    </span>
    <span *ngIf="this.plot.roi.length > 0" class="role">({{ getRoiHolderLabelTitle(plot.roi[0]) }})</span>
    <span class="more-roi" *ngIf="this.plot.roi.length > 1" class="role">+{{ plot.roi.length - 1 }} more</span>
    <!-- </ng-container> -->
  </div>
</div>
