export enum Token {
  access = 'accessToken',
  refresh = 'refreshToken',
  ampliUserId = 'AMP_ui',
  mainPromo = 'mainPromo',
  advTableFilter = 'advTableFilter',
  advTableSort = 'advTableSort',
  lastTabAdv = 'lastTabAdv',
  listAccessToken = 'listAccessToken',
  roleAccess = 'roleAccess',
  isDemo = 'isDemo',
  hideLCBanner = 'hideLCBanner',
}
