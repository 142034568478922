import { Injectable } from '@angular/core';
import { StorageStrategyService } from './storage-strategy.service';
import { RegionalSettings } from '../interfaces/regional-settings';
import { StorageType } from '../enums/storage-type.enum';
import { DateFormats } from '../enums/date-formats.enum';
import { REGIONAL_SETTINGS_KEY } from '../shared/regional-settings-key.conts';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from './http-utils.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrganizationService } from '../admin/chronicle-admin/organization.service';
import { Organization } from '../interfaces/organization';

export const DEFAULT_REGIONAL_SETTINGS = {
  cemetery: 'Cemetery',
  cemeteries: 'Cemeteries',
  section: 'Section',
  sections: 'Sections',
  row: 'Row',
  rows: 'Rows',
  plot: 'Plot',
  plots: 'Plots',
  roi: 'Roi',
  rois: 'Rois',
  roi_holder: 'Roi Holder',
  roi_holders: 'Roi Holders',
  roi_applicant: 'Roi Applicant',
  roi_applicants: 'Roi Applicants',
  right_of_interment: 'Right of Interment',
  right_of_interments: 'Right of Interments',
  date_format: DateFormats.DayMonthYear,
  always_show_section: false,
  is_invoice_integration_enabled: false,
  returned_service_person: 'Returned Service Person',
  unassigned_plot: false,
};

@Injectable({
  providedIn: 'root',
})
export class RegionalSettingsService {
  regionalSettingsForCemetery$ = new BehaviorSubject<RegionalSettings>(DEFAULT_REGIONAL_SETTINGS);
  regionalSettingsForUser$ = new BehaviorSubject<RegionalSettings>(DEFAULT_REGIONAL_SETTINGS);

  constructor(
    private storageStrategyService: StorageStrategyService,
    private httpClient: HttpClient,
    private httpUtils: HttpUtilsService,
    private organizationService: OrganizationService
  ) {
    this.storageStrategyService.initializeStorage();
    this.storageStrategyService.type = StorageType.local;
  }

  setForCemetery(regionalSettings: RegionalSettings) {
    this.regionalSettingsForCemetery$.next(regionalSettings);
  }

  setForUser(regionalSettings: RegionalSettings) {
    // regionalSettings is undefined for public user and chronicle admin user

    this.regionalSettingsForUser$.next(regionalSettings !== undefined ? regionalSettings : DEFAULT_REGIONAL_SETTINGS);
  }

  saveRegionalSettingsForCemeteryToLocalStorage(regionalSettings: RegionalSettings | undefined) {
    const regionalSettingsForSet = JSON.stringify(regionalSettings);
    if (!this.storageStrategyService.storage) {
      return;
    }
    this.storageStrategyService.storage.setItem(REGIONAL_SETTINGS_KEY, regionalSettingsForSet);
  }

  get(): RegionalSettings {
    if (!this.storageStrategyService.storage) {
      return DEFAULT_REGIONAL_SETTINGS;
    }
    const localization = this.storageStrategyService.storage.getItem(REGIONAL_SETTINGS_KEY);

    // localization is null for generate report for public user

    if (localization === null) {
      return DEFAULT_REGIONAL_SETTINGS;
    }

    return JSON.parse(localization);
  }

  getRegionalSettingsForCemeteryFromLocalStorage(): RegionalSettings {
    if (!this.storageStrategyService.storage) {
      return DEFAULT_REGIONAL_SETTINGS;
    }
    const localization = this.storageStrategyService.storage.getItem(REGIONAL_SETTINGS_KEY);

    // localization is null for generate report for public user

    if (localization === null) {
      return DEFAULT_REGIONAL_SETTINGS;
    }

    return JSON.parse(localization);
  }

  getRegionalSettingsForCemetery(cemeteryUniqueName: string): Observable<RegionalSettings> {
    const url = this.httpUtils.getResourceUrl(['users', 'regional_settings_by_cemetery', cemeteryUniqueName]);

    return this.httpClient.get<RegionalSettings>(url).pipe(
      tap(value => {
        this.setForCemetery(value);
        this.saveRegionalSettingsForCemeteryToLocalStorage(value);
      })
    );
  }

  getRegionalSettingsByOrganization(orgId: number): Observable<RegionalSettings> {
    const url = this.httpUtils.getResourceUrl(['organization-regional', orgId]);

    return this.httpClient.get<RegionalSettings>(url);
  }

  getMyDateFormats() {
    return {
      parse: {
        dateInput: this.get().date_format,
      },
      display: {
        dateInput: this.get().date_format,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'YYYY-MM-DD',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    };
  }

  getDatePipeFormat(): string {
    if (this.get().date_format === DateFormats.DayMonthYear) {
      return 'dd/MM/yyyy';
    } else {
      return 'MM/dd/yyyy';
    }
  }
}
