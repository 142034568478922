import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterFormData } from '../../interfaces/filter-form-data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'cl-filter',
  templateUrl: './filter-without-statuses.component.html',
  styleUrls: ['./filter-without-statuses.component.scss'],
})
export class FilterWithoutStatusesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  filterForm: FormGroup;

  get isFormValuesExist() {
    return Object.values(this.filterForm.value || {}).filter(Boolean).length > 0;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<FilterWithoutStatusesComponent, FilterFormData | null>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public filterFormData: FilterFormData
  ) {}

  ngOnInit(): void {
    this.filterForm = this.getForm();
    const isFilterFormAvailable = this.filterFormData !== undefined;
    const isDataAvailable = isFilterFormAvailable && Object.keys(this.filterFormData).length > 0;

    if (isDataAvailable) {
      this.filterForm.patchValue(this.filterFormData, { emitEvent: true });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getForm(): FormGroup {
    return this.formBuilder.group({
      dob: [],
      dod: [],
      age: [],
      rs: [],
    });
  }

  close() {
    this.dialogRef.close(null);
  }

  onReset() {
    this.filterForm.reset();
    this.filterForm.markAsDirty();
  }

  onSubmit() {
    const value = this.filterForm.getRawValue();
    const data = this.getDataForRequest(value);
    this.dialogRef.close(data);
  }

  getDataForRequest(value): FilterFormData {
    const data: FilterFormData = {};

    // todo: check this statement, seems that rs property of result(data) will be empty always
    if (data.rs) {
      data.rs = value.rs;
    }

    if (value.age) {
      data.age = value.age;
    }

    if (value.dob) {
      data.dob = value.dob.map(el => moment(el).format('YYYY-MM-DDTHH:mm:ss') + 'Z');
    }

    if (value.dod) {
      data.dod = value.dod.map(el => moment(el).format('YYYY-MM-DDTHH:mm:ss') + 'Z');
    }

    return data;
  }
}
