import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { FilterFormData } from '../../interfaces/filter-form-data';
import { PlotStatusString } from '../../enums/plot-status-string.enum';
import * as moment from 'moment';

@Component({
  selector: 'cl-filter',
  templateUrl: './filter-with-statuses.component.html',
  styleUrls: ['./filter-with-statuses.component.scss'],
})
export class FilterWithStatusesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  filterForm: FormGroup;
  isFiltersDisabled = false;

  get isFormValuesExist() {
    return Object.values(this.filterForm.value || {}).filter(Boolean).length > 0;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<FilterWithStatusesComponent, FilterFormData | null>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public filterFormData: FilterFormData
  ) {}

  ngOnInit(): void {
    this.filterForm = this.getForm();
    const isFilterFormAvailable = this.filterFormData !== undefined;
    const isDataAvailable = isFilterFormAvailable && Object.keys(this.filterFormData).length > 0;

    this.filterForm
      .get('status')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        tap(value => {
          ['dob', 'dod', 'age', 'rs'].forEach(controlName => {
            const control = this.filterForm.get(controlName);
            const options = { emitEvent: false };
            this.isFiltersDisabled ? control.disable(options) : control.enable(options);
          });
          this.isFiltersDisabled = this.getIsFilterDisabled(value);
        })
      )
      .subscribe();

    if (isDataAvailable) {
      this.filterForm.patchValue(this.filterFormData, { emitEvent: true });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getForm(): FormGroup {
    return this.fb.group({
      status: [],
      dob: [],
      dod: [],
      age: [],
      rs: [],
      feature: [],
      story: [],
    });
  }

  close() {
    this.dialogRef.close(null);
  }

  onReset() {
    this.filterForm.reset();
  }

  onSubmit() {
    const value = this.filterForm.getRawValue();
    const data = this.getDataForRequest(value);
    this.dialogRef.close(data);
  }

  getDataForRequest(value): FilterFormData {
    const data: FilterFormData = {};

    if (value.status) {
      data.status = value.status;
    }

    if (this.isFiltersDisabled) {
      return data;
    }

    if (data.rs) {
      data.rs = value.rs;
    }

    if (value.age) {
      data.age = value.age;
    }

    if (value.dob) {
      data.dob = value.dob.map(el => moment(el).format('YYYY-MM-DDTHH:mm:ss') + 'Z');
    }

    if (value.dod) {
      data.dod = value.dod.map(el => moment(el).format('YYYY-MM-DDTHH:mm:ss') + 'Z');
    }

    if (value.feature) {
      data.feature = value.feature;
    }

    if (value.story) {
      data.story = value.story;
    }

    return data;
  }

  getIsFilterDisabled(status): boolean {
    return status && status !== PlotStatusString.Occupied;
  }
}
