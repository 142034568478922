export enum AppRoutes {
  Public = '',
  ChronicleAdmin = 'chronicle-admin',
  CustomerOrganization = 'customer-organization',
  CustomerAdmin = 'customer-admin',
  CustomerManager = 'customer-manager',
  CustomerViewer = 'customer-viewer',
  Freemium = 'freemium',
  Login = 'login',
}
