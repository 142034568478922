import { DateTime } from 'luxon';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Interment } from '../../../interfaces/interment';
import { EMPTY, forkJoin, from, Subject } from 'rxjs';
import { PlotsService } from 'src/app/core/plots.service';
import { MapActionsService } from 'src/app/core/map-actions.service';
import { MapDataService } from 'src/app/core/map-data.service';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { SidebarService } from 'src/app/core/sidebar.service';
import { SellStoryService } from 'src/app/core/sell-story.service';
import { BannerImageStoryComponent } from 'src/app/shared/banner-image-story/banner-image-story.component';
import { MatDialog } from '@angular/material/dialog';
import { StepperFormAddStoryComponent } from 'src/app/public/stepper-form-add-story/stepper-form-add-story.component';
import { StoryPublic, StoryPublicResponse } from 'src/app/interfaces/story-public';
import { PlotDetails } from 'src/app/interfaces/plot-details';
import { CustomFieldType, ICustomField } from 'src/app/interfaces/custom-field';
import { RegionalSettings } from 'src/app/interfaces/regional-settings';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { SurveyFormComponent } from '../../survey-form/survey-form.component';
import { IdentifyName } from 'src/app/enums/amplitude.enum';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cl-shared-interment-item',
  templateUrl: './interment-item.component.html',
  styleUrls: ['./interment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedIntermentItemComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input() interment: Interment;
  @Input() amountOfInterments: number;
  @Input() sellStory: boolean;
  @Input() plotDetails: PlotDetails;
  @Input() regionalSettings: RegionalSettings;

  isIntermentDetailsOpened: boolean;
  panelOpenState = false;
  storyLists: StoryPublic[];
  hasSurveyScript: boolean = environment.SURVEY_MONKEY_SCRIPT && environment.SURVEY_MONKEY_SCRIPT.length > 0;

  nextStoryLink = '';

  isLoading = false;

  isAus: any;

  get isAgeAvailable() {
    return this.interment.age || (this.interment.date_of_birth && this.interment.date_of_death);
  }

  get areDatesAvailable() {
    return this.interment.date_of_birth || this.interment.date_of_death;
  }

  get age() {
    if (this.interment.age) {
      return this.interment.age;
    }

    const dob = DateTime.fromISO(this.interment.date_of_birth);
    const dod = DateTime.fromISO(this.interment.date_of_death);
    const result = dod.diff(dob, 'year').years;

    return Math.trunc(result);
  }

  get isShowAgeYearsOldText() {
    return this.age !== null && !isNaN(Number(this.age));
  }

  get year_of_birth() {
    return this.interment.date_of_birth;
  }

  get year_of_death() {
    return this.interment.date_of_death;
  }

  get year_of_interment() {
    return this.interment.interment_date;
  }

  get customFieldValues(): ICustomField[] {
    const customFields = this.interment.custom_fields
      ?.filter(field => {
        const cemeteries = field?.cemeteries?.map(cm => cm.unique_name);
        if (
          cemeteries.includes(this.interment.cemetery_unique_name) &&
          field.field_type === CustomFieldType.interment &&
          field.fields.visibility === 'Public'
        ) {
          return field;
        }
      })
      .map(cf => cf.fields.key);

    return this.interment.interment_custom_fields?.filter(field => {
      if (customFields.includes(field.key)) {
        return field;
      }
    });
  }

  constructor(
    private router: Router,
    private routerUtilService: RouterUtilsService,
    private sellStoryService: SellStoryService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private amplitudeService: AmplitudeService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'collapse',
        src: '/assets/images/collapse.svg',
      },
    ]);
  }

  ngOnInit() {
    this.loadStoryPublic();
    this.showBanner();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangeExpandedState() {
    this.isIntermentDetailsOpened = !this.isIntermentDetailsOpened;
  }

  loadStoryPublic() {
    this.isLoading = true;
    this.ref.detectChanges();
    this.sellStoryService
      .getAllStoryPublic(1, this.interment.cemetery_unique_name, this.interment.id)
      .pipe(
        catchError(error => {
          return EMPTY;
        }),
        tap<StoryPublicResponse>(stories => {
          this.storyLists = stories.data;
          this.ref.detectChanges();
        }),
        finalize(() => {
          this.isLoading = false;
          this.ref.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  showBanner() {
    this.isLoading = true;
    this.sellStoryService
      .bannerStory(this.interment.cemetery_unique_name)
      .pipe(
        catchError(error => {
          return EMPTY;
        }),
        tap(data => {
          const key = 'isAustralia';
          // this.onOpenBannerStory(data[key]);
          this.isAus = data[key];
          this.ref.detectChanges();
        }),
        finalize(() => {
          this.isLoading = false;
          this.ref.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onAddStory(e) {
    const newRouter = this.routerUtilService.getPath(
      this.interment.cemetery_unique_name,
      'plots',
      this.interment.plot_id,
      this.interment.id.toString(),
      'new-story'
    );
    this.router.navigate(newRouter);
  }

  createMemorialInterment(interment: Interment) {
    const obj = { listInterments: [interment], cemeteryName: this.interment.cemetery_unique_name };
    this.amplitudeService.track('1a. Button "Write A Life Chronicle" Clicked', this.plotDetails, {
      [IdentifyName.CemeteryName]: this.plotDetails.cemetery_info.name,
    });
    this.dialog
      .open(BannerImageStoryComponent, {
        panelClass: 'banner-image-story',
        maxWidth: '80vw',
        disableClose: true,
        autoFocus: false,
        data: { isAus: this.isAus, plotDetails: this.plotDetails },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        if (v) {
          //   const dialogRef = this.dialog.open(StepperFormAddStoryComponent, {
          //     panelClass: 'modal-view-story',
          //     width: '465px',
          //     disableClose: true,
          //     autoFocus: false,
          //     data: obj,
          //   });
          //   const sub = dialogRef.componentInstance.notifyParent.subscribe(data => {
          //     this.amplitudeService.track('Button "Write a Life Chronicle" clicked', this.interment);
          //   });
          //   sub.add();
          //   dialogRef.afterClosed().subscribe(data => {
          //     if (data === undefined) {
          //       this.dialog
          //         .open(SurveyFormComponent, {
          //           panelClass: 'survey-form=component',
          //           width: '756px',
          //         })
          //         .afterClosed()
          //         .subscribe();
          //     }
          //   });
          const newRouter = this.routerUtilService.getPath(
            this.interment.cemetery_unique_name,
            'plots',
            this.interment.plot_id,
            this.interment.id.toString(),
            'life-chronicle'
          );
          this.router.navigate(newRouter, { queryParams: { from: 'interment' } });
        } else if (this.hasSurveyScript) {
          this.dialog
            .open(SurveyFormComponent, {
              panelClass: 'survey-form=component',
              width: '756px',
            })
            .afterClosed()
            .subscribe();
        }
      });
  }
}
