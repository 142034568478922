import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { CemeteryEvent, CemeteryEventResponse } from 'src/app/interfaces/cemetery-event';
import { google, ics } from 'calendar-link';
import { shareEventOnSocialMedia } from 'src/app/shared/share-event-utils';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { AddCalenderService } from 'src/app/core/add-calender.service';
import { DataForCalender } from 'src/app/interfaces/data-calender.interfaces';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'cl-upcoming-event-detail-dialog',
  templateUrl: './upcoming-event-detail-dialog.component.html',
  styleUrls: ['./upcoming-event-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcomingEventDetailDialogComponent implements OnInit {
  isLoading = false;
  hideGoToPlot: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<UpcomingEventDetailDialogComponent>,
    private readonly snackbar: MatSnackBar,
    private clipboard: Clipboard,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private amplitudeService: AmplitudeService,
    private router: Router,
    private calenderService: AddCalenderService,
    @Inject(MAT_DIALOG_DATA) public data: CemeteryEventResponse,
  ) {
    this.addIconsThroughMaterialService.add([
      { name: 'event-calender', src: '/assets/images/event-calender.svg' },
      { name: 'custom_close_btn', src: '/assets/images/close_icon_white.svg' },
      { name: 'custom_place', src: '/assets/images/place_icon.svg' },
      { name: 'facebook', src: '/assets/images/facebook.svg' },
      { name: 'linkedin', src: '/assets/images/linkedin.svg' },
      { name: 'twitter', src: '/assets/images/twitter.svg' },
      { name: 'share', src: '/assets/images/share.svg' },
      { name: 'copy', src: '/assets/images/copy.svg' },
    ]);
  }

  ngOnInit(): void {
    const url = this.router.url.split('/');
    if (url.length && url[2] === 'plots') {
      this.hideGoToPlot = true;
    } else {
      this.hideGoToPlot = this.data?.plot ? false : true;
    }
  }

  shareEvent(socialMedia: string) {
    shareEventOnSocialMedia(socialMedia, this.data);
    switch (socialMedia) {
      case 'facebook':
        this.amplitudeService.track('Button share Facebook is clicked on dialog detail upcoming event', this.data);
        break;
      case 'twitter':
        this.amplitudeService.track('Button share Twitter is clicked on dialog detail upcoming event', this.data);
        break;
      case 'copy':
        const eventPlot = `${environment.APP_BASE_URL}/${this.data?.cemetery_unique_name}/plots/${this.data?.plot?.plot_id}/upcoming-event/${this.data?.id}`;
        const eventDefault = `${environment.APP_BASE_URL}/${this.data?.cemetery_unique_name}/upcoming-events/${this.data?.id}`;

        const eventLink = this.data.plot ? eventPlot : eventDefault;

        if (navigator.share === undefined) {
          this.clipboard.copy(eventLink);
          this.snackbar.open('Link`s copied to the clipboard', '', { duration: 4000 });
        } else {
          navigator.share({ url: eventLink });
        }
        this.amplitudeService.track('Button share Copy Link is clicked on dialog detail upcoming event', this.data);
        break;
    }
  }

  goToDetailPlot() {
    const plotId = this.data.plot.plot_id;
    this.router.navigate([this.data.cemetery_unique_name, 'plots', plotId]).then(() => {
      this.dialogRef.close();
    });
  }

  addReminderCalendar(type: string) {
    const event: DataForCalender = {
      title: this.data?.event_name,
      description: this.data?.descriptions,
      start: this.data?.start_time,
      end: this.data?.end_time,
      location: `${this.data?.cemetery?.name} ${this.data.plot ? ' - ' + this.data.plot.plot_id : ''}`,
    };

    switch (type) {
      case 'google':
        this.amplitudeService.track('Add reminder using google calendar on dialog detail upcoming event', this.data);
        this.calenderService.generateGoogleCal(event);
        break;
      case 'ics':
        this.amplitudeService.track('Add reminder by download calendar file on dialog detail upcoming event', this.data);
        this.calenderService.generateIcsCal(event);
        break;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
