<form class="control-buttons" [formGroup]="form">
  <div
    class="control-button"
    *ngFor="let story of stories"
    [class]="story.title | lowercase"
    (click)="onSetCheckedStatus(story)"
    [ngClass]="story.checked ? 'checked' : ''"
  >
    <label [for]="story.title | lowercase" class="status-button">
      <input formControlName="story" [id]="story.title | lowercase" type="radio" [attr.aria-label]="story.title | lowercase" />
      <span>{{ story.title }}</span>
    </label>
  </div>
</form>
