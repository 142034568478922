import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cl-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FeaturedComponent),
      multi: true,
    },
  ],
})
export class FeaturedComponent implements ControlValueAccessor, OnDestroy {
  private readonly destroy$ = new Subject();
  features = [
    {
      value: 1,
      checked: false,
      title: 'Featured',
    },
  ];
  form: FormGroup;

  get feature() {
    return this.form.get('feature') as FormControl;
  }

  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      feature: [''],
    });

    this.feature.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        tap(value => {
          this.onChange(value);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  onSetCheckedStatus(item) {
    this.feature.setValue(item.value);
    this.features.forEach(element => (element.checked = false));
    item.checked = !item.checked;
  }

  writeValue(value) {
    if (!value) {
      this.feature.setValue(0);
      this.features.forEach(element => (element.checked = false));
      this.changeDetectorRef.detectChanges();
      return;
    }

    const neededStatus = this.features.find(status => status.value === value);

    if (neededStatus === undefined) {
      return;
    }

    neededStatus.checked = true;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
}
