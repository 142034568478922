import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'cl-snackbar-error',
  templateUrl: './snackbar-error.component.html',
  styleUrls: ['./snackbar-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarErrorComponent {
  constructor(
    private snackRef: MatSnackBarRef<SnackbarErrorComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { error: string; buttonLabel?: string }
  ) {
    if (data.buttonLabel === undefined) {
      data.buttonLabel = 'Got it!';
    }

    if (data.error === undefined || data.error === null) {
      data.error = 'Something went wrong';
    }
  }

  gotIt() {
    this.snackRef.dismiss();
  }
}
