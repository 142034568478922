<ul class="conditions">
  <li [class.is-ok]="isLengthCorrect">
    at least 8 characters
  </li>
  <li [class.is-ok]="isUpperLowerPresent">
    contains both lower (a-z) and upper case letters (A-Z)
  </li>
  <li [class.is-ok]="isNumbersPresent">
    contains at least one number (0-9)
  </li>
  <li [class.is-ok]="isSpecialNumbersPresent">
    contains special symbol (!@#$%^&*/+-=)
  </li>
</ul>
