import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subject, from } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare const Cesium: any;
@Component({
  selector: 'cl-globe-map',
  templateUrl: './globe-map.component.html',
  styleUrls: ['./globe-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobeMapComponent implements OnInit, OnDestroy {
  viewer: any;
  cemeteryUniqueName = this.activatedRoute.snapshot.paramMap.get('cemeteryName');
  destroy$ = new Subject<void>();

  constructor(
    private routerUtilsService: RouterUtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    Cesium.buildModuleUrl.setBaseUrl('/assets/cesium/');
    Cesium.Ion.defaultAccessToken = environment.CESIUM_ION_TOKEN;

    this.viewer = new Cesium.Viewer('cesiumContainer', {
      timeline: false,
      animation: false,
      infoBox: true,
      fullscreenButton: true,
      homeButton: false,
      geocoder: false,
      navigationHelpButton: true,
      baseLayerPicker: false,
      navigationInstructionsInitiallyVisible: false,
      scene3DOnly: true,
      terrain: Cesium.Terrain.fromWorldTerrain(),
    });

    from(Cesium.IonResource.fromAssetId(2529015))
      .pipe(
        switchMap(resource => from(Cesium.GeoJsonDataSource.load(resource, {
          clampToGround: true,
          strokeWidth: 0,
          stroke: Cesium.Color.WHITE.withAlpha(0)
        })))
      ).subscribe(async (dataSource: any) => {
        await this.viewer.dataSources.add(dataSource);

        const entities = dataSource.entities.values;
        entities.forEach((data, i) => {
          const entity = entities[i];

          if (entity.polygon || entity.polyline) {
            // Get the current positions
            const positions = entity.polygon ? entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
              : entity.polyline.positions.getValue(Cesium.JulianDate.now());

            // Define the height offset
            const heightOffset = 48.5;

            const newPositions = [];

            // Loop through the positions and adjust the height
            positions.forEach((p, j) => {
              const position = positions[j];
              const cartographic = Cesium.Cartographic.fromCartesian(position);
              cartographic.height += heightOffset;
              newPositions.push(Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, cartographic.height));
            });

            if (entity.polygon) {
              entity.polygon.hierarchy = new Cesium.PolygonHierarchy(newPositions);
              entity.polygon.material = Cesium.Color.WHITE.withAlpha(0.01);
              entity.polygon.fill = true;
            } else if (entity.polyline) {
              entity.polyline.positions = newPositions;
              entity.polyline.material = Cesium.Color.WHITE.withAlpha(0);
            }
          }
        });
        await this.viewer.zoomTo(dataSource);
      }, error => {
      });


    from(Cesium.Cesium3DTileset.fromIonAssetId(2502017))
      .pipe(
        tap((tileset: any) => {
          this.viewer.scene.primitives.add(tileset);
          this.viewer.zoomTo(
            tileset, new Cesium.HeadingPitchRange(0, -0.5, tileset.boundingSphere.radius * 4.0)
          );
        }),
        takeUntil(this.destroy$))
      .subscribe();

    const handler = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas);
  }

  backToCemetery() {
    const path = this.routerUtilsService.getPath(this.cemeteryUniqueName);
    this.router.navigate(path);
  }

  ngOnDestroy() {
    if (this.viewer) {
      this.viewer.destroy();
      this.viewer = null;
    }
  }
}
