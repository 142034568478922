<form [formGroup]="form">
  <div class="container-advanced-search">
    <div class="title">
      Advanced Search
      <span *ngIf="responsive$" class="close-modal-mobile" (click)="onClose()"><img src="./assets/images/close_cancel.png" alt="" /></span>
    </div>
    <p class="public-cemeteries" *ngIf="this.dialogData?.isPublic">Only available for cemeteries with published records</p>
    <mat-form-field class="example-chip-list" [ngClass]="{'mt-0': this.dialogData?.isPublic}">
      <mat-label>Cemeteries</mat-label>
      <mat-select [formControl]="form.get('user_cem_ids')" multiple (closed)="onMatSelectBlur()">
        <div class="input-container">
          <div class="input-wrapper">
            <input
              #searchInput
              class="input-search"
              (keydown)="$event.stopPropagation()"
              matInput
              [formControl]="filterCemeteryControl"
              placeholder="Start typing to search"
              type="text"
            />
            <button
              *ngIf="filterCemeteryControl.value && filterCemeteryControl.value.length > 0"
              type="button"
              class="close-btn"
              (click)="clearInputCemetery()"
            >
              <mat-icon svgIcon="custom_autocomplete_base_close"></mat-icon>
            </button>
            <mat-icon
              *ngIf="(!filterCemeteryControl.value || filterCemeteryControl.value.length === 0)"
              class="search-icon"
              svgIcon="custom_autocomplete_base_search"
            ></mat-icon>
          </div>
          <mat-divider></mat-divider>
        </div>
        <mat-option
          *ngFor="let cemetery of filteredCemeteries | order: 'asc':'name'"
          (click)="selectOptionCemetery(cemetery.name)"
          [value]="cemetery.name"
          [disabled]="
            (this.dialogData.isPublic && !this.dialogData.orgParams) ||
            this.dialogData.isChronicleAdmin ||
            this.dialogData.currentCemetery !== null
              ? isCemeteryOptionDisabled(cemetery.name)
              : false
          "
          >{{ cemetery.name }}
        </mat-option>
      </mat-select>
      <mat-hint>Note: Search in multiple cemeteries takes longer time</mat-hint>
      <mat-error *ngIf="form.get('user_cem_ids').hasError('required')">*Select one cemetery</mat-error>
    </mat-form-field>
    <div class="all-cemeteries" (click)="onSelectAllCemeteries()" *ngIf="isPublicNoOrg">
      {{ selectAllCemetery ? 'Deselect all cemeteries' : 'Select all cemeteries' }}
    </div>
    <div class="container-accordion">
      <mat-accordion multi [ngClass]="dialogData.isAdmin ? 'container-accordion-admin' : 'container-accordion-public'">
        <div [ngClass]="dialogData.isAdmin ? 'left-container-admin' : 'left-container-public'">
          <mat-expansion-panel
            (opened)="panelPlotOpenState = true"
            (closed)="panelPlotOpenState = false"
            [ngClass]="{ 'border-wrapper': panelPlotOpenState || !panelPlotOpenState }"
            [expanded]="dialogData.isAdmin"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Plot </mat-panel-title>
            </mat-expansion-panel-header>
            <cl-plot-form
              [group]="form"
              [plotIdControlName]="'plot_id'"
              [statusControlName]="'status'"
              [plotTypeControlName]="'plot_type'"
              [priceControlName]="'price_range'"
              [burialCapacityControlName]="'burial_cap'"
              [entombmentCapacityControlName]="'entomb_cap'"
              [cremateCapacityControlName]="'cremation_cap'"
              [intermentCapacityControlName]="'interment_qty'"
              [statuses]="statuses"
              [plotTypes]="plotTypes"
              [isAdmin]="dialogData.isAdmin"
              [sectionData]="sectionData"
              [rowValue]="rowValue"
              [fromAdvanceSearch]="true"
            ></cl-plot-form>
          </mat-expansion-panel>
          <mat-expansion-panel
            (opened)="panelRoiOpenState = true"
            (closed)="panelRoiOpenState = false"
            [ngClass]="{ 'border-wrapper': panelRoiOpenState || !panelRoiOpenState }"
            [expanded]="true"
            *ngIf="dialogData.isAdmin"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> ROI </mat-panel-title>
            </mat-expansion-panel-header>
            <cl-roi-form
              [group]="form"
              [roiNumberControlName]="'roi_certificate'"
              [rightTypeControlName]="'right_of_interment'"
              [torTypeControlName]="'term_of_right'"
              [chargedControlName]="'is_charged'"
              [isAdmin]="dialogData.isAdmin"
              [isChronicleAdmin]="dialogData.isChronicleAdmin"
              [plotTypes]="plotTypes"
              [rightType]="rightType"
              [termOfRights]="dialogData.termOfRights"
              [destroy$]="destroy$"
            ></cl-roi-form>
          </mat-expansion-panel>
        </div>
        <div [ngClass]="dialogData.isAdmin ? 'right-container-admin' : 'right-container-public'">
          <mat-expansion-panel
            (opened)="panelDeceasedOpenState = true"
            (closed)="panelDeceasedOpenState = false"
            [ngClass]="{ 'border-wrapper': panelDeceasedOpenState || !panelDeceasedOpenState }"
            [expanded]="true"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> {{ dialogData.isAdmin ? 'Interment' : 'Deceased Person' }} </mat-panel-title>
            </mat-expansion-panel-header>
            <cl-interment-form
              [group]="form"
              [intermentIdControlName]="'interment_number'"
              [ageFrom]="ageFrom"
              [ageTo]="ageTo"
              [optionsValue]="optionsValue"
              [destroy$]="destroy$"
              [isAdmin]="dialogData.isAdmin"
              [regionalSettings]="dialogData.regionalSettings"
            >
            </cl-interment-form>
          </mat-expansion-panel>
          <ng-container *ngFor="let data of PersonForm">
            <mat-expansion-panel
              [ngClass]="{ 'border-wrapper': panelDeceasedOpenState || !panelDeceasedOpenState }"
              [expanded]="onChangeOpenFormPerson(data)"
              *ngIf="dialogData.isAdmin"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{ data.viewValue }} </mat-panel-title>
              </mat-expansion-panel-header>
              <cl-person-form [group]="form" [data]="data" [dataForm]="dataForm"></cl-person-form>
            </mat-expansion-panel>
          </ng-container>
          <ng-container *ngFor="let data of BusinessForm">
            <mat-expansion-panel
              [ngClass]="{ 'border-wrapper': panelDeceasedOpenState || !panelDeceasedOpenState }"
              [expanded]="onChangeOpenFormPerson(data)"
              *ngIf="dialogData.isAdmin"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{ data.viewValue }} </mat-panel-title>
              </mat-expansion-panel-header>
              <cl-business-form [group]="form" [data]="data" [dataForm]="dataForm"></cl-business-form>
            </mat-expansion-panel>
          </ng-container>
        </div>
      </mat-accordion>
      <div class="container-banner">
        <div class="bg-gradient"></div>
        <img src="./assets/images/balloon-chat.png" />
        <div class="wrapper-text">
          <div class="text">Do you have a suggestion?</div>
          <button mat-stroked-button color="primary">
            <a class="email" href="mailto:support@chronicle.rip">GET IN TOUCH</a>
          </button>
        </div>
      </div>
      <ng-container *ngIf="!responsive$">
        <div class="container-button">
          <div class="left-button">
            <button mat-button color="primary" (click)="onClearForm()">CLEAR ALL</button>
          </div>
          <div class="right-button">
            <button mat-button color="primary" (click)="onClose()">CANCEL</button>
            <button mat-raised-button color="primary" (click)="onSearch()">SEARCH</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="responsive$">
    <div class="container-button">
      <div class="left-button">
        <button mat-button color="primary" (click)="onClearForm()">CLEARS ALL</button>
      </div>
      <div class="right-button">
        <button mat-raised-button color="primary" (click)="onSearch()">SEARCH</button>
      </div>
    </div>
  </ng-container>
</form>
