<div class="filter-header">
  <div class="title">
    Filter
  </div>
  <span class="material-icons close" (click)="close()">
    clear
  </span>
</div>

<div>
  <form [formGroup]="filterForm">
    <div class="form-control range">
      <div class="control-label">
        Date of birth
      </div>

      <cl-mat-calendar-picker formControlName="dob"></cl-mat-calendar-picker>
    </div>

    <div class="form-control range">
      <div class="control-label">
        Date of death
      </div>
      <cl-mat-calendar-picker formControlName="dod"></cl-mat-calendar-picker>
    </div>

    <div class="form-buttons">
      <button class="clear-button" mat-button [disabled]="!isFormValuesExist" (click)="onReset()">
        Clear all
      </button>
      <button class="submit-button" mat-raised-button color="primary" [disabled]="!filterForm.dirty" (click)="onSubmit()">
        Done
      </button>
    </div>
  </form>
</div>
