<ng-container [formGroup]="form">
  <h1 class="title" *ngIf="showTitle">Pre-need Plot Purchase</h1>
  <ng-container formArrayName="sections">
    <div
      class="row"
      [ngClass]="{ dynamic: ['roi_holder', 'deceased'].includes(section.value.type) }"
      *ngFor="let section of sectionsArray.controls; let i = index; let isLast = last"
    >
      <mat-expansion-panel
        [formGroupName]="i"
        disabled
        hideToggle
        [expanded]="currentActiveFormSection.isSelected(i) || isSectionReadOnly.isSelected(i)"
        class="mat-elevation-z0"
        (opened)="currentActiveFormSection.select(i)"
      >
        <mat-expansion-panel-header class="bg-white" [class.active]="currentActiveFormSection.isSelected(i)">
          <mat-panel-title [class.active]="currentActiveFormSection.isSelected(i) || isSectionReadOnly.isSelected(i)">
            <div>
              <ng-container *ngIf="section.get('type').value !== 'applicant'">
                {{ section.get('name').value }}
              </ng-container>
              <ng-container *ngIf="section.get('type').value === 'applicant'">
                {{ getAplicantTitle(section.value)}}
              </ng-container>
              <span *ngIf="isDynamic.includes(section.value.type) && displayAddSection(section.value.type, i).sectionLength > 1">
                {{ displayAddSection(section.value.type, i).text }}
              </span>
            </div>
            <div
              class="remove-btn"
              *ngIf="['roi_holder', 'deceased'].includes(section.value.type) && displayAddSection(section.value.type, i).displayRemove"
            >
              <button (click)="removeSection(i)" mat-button>REMOVE</button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Edit mode -->
        <div
          *ngIf="currentActiveFormSection.isSelected(i)"
          class="section-body"
          [class.group]="groupSectionTypes.includes(section.get('type').value) || section.get('type').value === 'custom_fields'"
          formArrayName="form_detail"
        >
          <ng-container *ngFor="let detail of section.get('form_detail').controls; let j = index">
            <ng-container [formGroupName]="j">
              <!-- Render `text` type -->
              <p
                class="detail-type-text"
                *ngIf="
                  detail.get('type').value === 'text' &&
                  detail.get('values').value &&
                  detail.get('display').value &&
                  detail.get('order')?.value
                "
                [innerHTML]="parseTextValue(detail.get('values').value)"
              >
                <!-- {{ detail.get('values').value }} -->
              </p>

              <!-- Render `input` type  -->
              <cl-custom-input
                *ngIf="
                  supportedTypesEditMode.includes(detail.get('type').value) &&
                  !excludeByKey.includes(detail.get('key').value) &&
                  detail.get('type').value !== 'checkbox' &&
                  detail.get('display').value &&
                  detail.get('order')?.value
                "
                class="detail-type-text-field"
                [class]="detail.get('type').value"
                [ngClass]="{ 'grid-col-1': detail.get('key').value === 'event_name' }"
                [group]="detail"
                [formName]="'value'"
                [key]="detail.get('key').value"
                [showAsterisk]="detail.get('required').value"
                [inputType]="detail.get('type').value"
                [listOption]="detail.get('values').value"
                [label]="detail.get('label').value"
              >
              </cl-custom-input>

              <!-- Datetime type -->
              <div
                class="datetime-container"
                formGroupName="value"
                *ngIf="detail.get('type').value === 'datetime' && detail.get('order')?.value"
              >
                <mat-form-field>
                  <mat-label>Date <span class="asterisk">*</span></mat-label>
                  <input matInput formControlName="date" [matDatepicker]="picker" aria-label="Date" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="
                      detail.get('type').value === 'datetime' &&
                      detail.get('value').get('date').errors?.required &&
                      detail.get('value').get('date').touched
                    "
                    style="font-size: 12px"
                  >
                    Required
                  </mat-error>
                </mat-form-field>
                <div class="time-container">
                  <mat-form-field>
                    <mat-label>Start time</mat-label>
                    <input formControlName="startTime" class="time" type="time" matInput autocomplete="off" aria-label="Start time" />
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>End time</mat-label>
                    <input formControlName="endTime" class="time" type="time" matInput autocomplete="off" aria-label="End Time" />
                  </mat-form-field>
                </div>
              </div>

              <!-- Checkbox type -->
              <div
                class="custom-checkbox-container"
                *ngIf="
                  (detail.get('type').value === 'checkbox' || detail.get('type').value === 'checkbox_button') &&
                  detail.get('display').value &&
                  detail.get('order')?.value
                "
              >
                <mat-checkbox
                  formControlName="value"
                  class="detail-type-text-field"
                  [required]="detail.get('required').value"
                  [class]="detail.get('type').value"
                  (change)="checkTypeCheckbox(detail.get('type').value === 'checkbox_button', detail.get('key').value, i)"
                  >{{ detail.get('label').value }} <span style="color: red" *ngIf="detail.get('required')?.value">*</span>
                </mat-checkbox>
                <mat-error
                  *ngIf="detail.get('type').value === 'checkbox' && detail.get('value').errors?.required && detail.get('value').touched"
                  style="font-size: 12px"
                >
                  Required
                </mat-error>
              </div>

              <!-- Toggle type -->
              <div
                class="custom-checkbox-container"
                [ngClass]="{ 'event-service': section.get('type').value === 'event_service' && detail.get('key').value === 'public' }"
                *ngIf="['toggle', 'switch'].includes(detail.get('type').value) && detail.get('display').value && detail.get('order')?.value"
              >
                <mat-slide-toggle
                  formControlName="value"
                  class="detail-type-text-field"
                  [required]="detail.get('required').value"
                  [class]="detail.get('type').value"
                  >{{ detail.get('label').value }} <span style="color: red" *ngIf="detail.get('required')?.value">*</span>
                </mat-slide-toggle>
                <mat-error
                  *ngIf="
                    ['toggle', 'switch'].includes(detail.get('type').value) &&
                    detail.get('value').errors?.required &&
                    detail.get('value').touched
                  "
                  style="font-size: 12px"
                >
                  Required
                </mat-error>
              </div>

              <!-- Render `upload type` -->
              <ng-container *ngTemplateOutlet="uploadFileTpl; context: { $implicit: detail, section: section }"></ng-container>

              <!-- Render `signature` type -->
              <div class="signature-read-wrapper" *ngIf="section.get('type').value === 'signature'">
                <div *ngIf="detail.get('type').value === 'signature'" class="signature-container" (click)="openSignatureDialog(detail)">
                  <img class="signature-image" [src]="detail.get('value').value" />
                </div>
                <ng-template *ngTemplateOutlet="uploadFileTpl; context: { $implicit: detail }"></ng-template>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <!-- Readonly mode -->
        <div *ngIf="isSectionReadOnly.isSelected(i)" class="section-readonly-wrapper" (click)="panelBodyClicked(i)">
          <ng-container *ngIf="section.value.type === 'text'">
            <ng-container *ngTemplateOutlet="textSectionTemplate; context: { $implicit: section.value.form_detail }"></ng-container>
          </ng-container>
          <ng-container *ngIf="groupSectionTypes.includes(section.value.type)">
            <ng-container *ngTemplateOutlet="groupSectionTemplate; context: { $implicit: section.value.form_detail }"></ng-container>
          </ng-container>
          <ng-container *ngIf="section.value.type === 'custom_fields'">
            <ng-container *ngTemplateOutlet="customFieldsSectionTemplate; context: { $implicit: section.value.form_detail }"></ng-container>
          </ng-container>
          <ng-container *ngIf="section.value.type === 'signature'">
            <ng-container *ngTemplateOutlet="signatureSectionTemplate; context: { $implicit: section.value.form_detail }"></ng-container>
          </ng-container>
        </div>

        <!-- Continue Button -->
        <button
          *ngIf="currentActiveFormSection.isSelected(i)"
          color="primary"
          class="section-continue-btn"
          mat-flat-button
          (click)="goToNextSection(section, i)"
        >
          continue
        </button>
      </mat-expansion-panel>

      <!-- display after new section -->
      <div class="add-section" *ngIf="isDynamic.includes(section.value.type) && displayAddSection(section.value.type, i).displayButton">
        <button mat-button color="primary" (click)="addNewSection(section.value.type)">
          <mat-icon svgIcon="add-icon"></mat-icon> ADD {{ section.value.name | uppercase }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #uploadFileTpl let-detail let-section="section">
  <div
    class="upload-document-container"
    *ngIf="
      (detail.get('type').value === 'file' || detail.get('type').value === 'image') &&
      detail.get('display').value &&
      detail.get('order')?.value
    "
  >
    <div class="upload-section" [class.errors]="detail.get('value').errors && detail.get('value').touched">
      <!-- Render `file` upload type -->
      <p class="upload-title">{{ detail.get('label')?.value }}</p>
      <cl-upload-document-shared
        style="width: 100%"
        [documents]="detail.get('value').value || []"
        [disabled]="false"
        [public]="true"
        [uploaderType]="section.get('type').value === 'plot' && detail.get('key').value === 'image' ? 1 : 0"
        [maxSizePlaceholder]="detail.get('values').value?.maxSize || defaultFileUploadConfig.maxSize"
        [acceptFileType]="validateAcceptFileTypes(detail.get('values').value?.fileTypes, detail.value)"
        [title]="detail.get('label').value"
        [showTitle]="false"
        (files)="recievedFileUpload($event, detail)"
      >
      </cl-upload-document-shared>
    </div>
    <p class="mat-error custom-form-error" *ngIf="detail.get('value').errors?.required && detail.get('value').touched">Required</p>
    <p class="mat-error custom-form-error" *ngIf="detail.get('value').errors?.maxlength && detail.get('value').touched">
      Required {{ detail.get('value').errors?.maxlength?.requiredLength }} attachments
    </p>
  </div>
</ng-template>

<ng-template #fileTypeTemplate let-files>
  <!-- Document preview -->
  <h4 *ngIf="files.value?.length > 0" style="color: #673ab7; font-size: 14px; margin: 0">
    {{ files.label }}
  </h4>
  <ng-container *ngIf="files.value?.length > 0">
    <ul>
      <li *ngFor="let file of files.value">
        <a class="item" [href]="file.url" target="_blank">
          <div class="icon">
            <mat-icon svgIcon="custom_document"></mat-icon>
          </div>
          <span class="name" [title]="file.file_name">{{ file.file_name }}</span>
        </a>
      </li>
    </ul>
  </ng-container>
</ng-template>

<ng-template #textSectionTemplate let-formDetails>
  <ng-container *ngFor="let detail of formDetails">
    <div class="section-readonly">
      <p class="detail-type-text">{{ detail.values }}</p>
    </div>
  </ng-container>
</ng-template>

<ng-template #groupSectionTemplate let-formDetails>
  <p class="roi-applicant-name" *ngIf="getFullName(formDetails)">{{ getFullName(formDetails) }}</p>
  <ng-container *ngFor="let detail of formDetails">
    <div
      class="section-readonly"
      *ngIf="supportedTypesReadonlyMode.includes(detail.type) && detail.order && detail.display && !excludeByKey.includes(detail.key)"
    >
      <p class="left" *ngIf="detail.order !== null">{{ detail.label }}</p>
      <p
        class="right flex-center"
        *ngIf="['checkbox', 'toggle', 'switch', 'checkbox_button'].includes(detail.type) && detail.value && detail.order !== null"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M2.5 12.5L7.60001 17.6C8.40001 18.4 9.59999 18.4 10.4 17.6L21.5 6.5"
            stroke="#181429"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Yes
      </p>
      <p class="right" *ngIf="['checkbox', 'toggle', 'switch'].includes(detail.type) && !detail.value && detail.order !== null">No</p>
      <p class="right" *ngIf="['date', 'datetime'].includes(detail.type) && detail.order !== null">
        <span *ngIf="detail.type === 'date'">{{ (detail.value | date: 'mediumDate') || '-' }}</span>
        <span *ngIf="detail.type === 'datetime' && detail.value"
          >{{ (detail.value?.date | date: 'mediumDate') || 'n/a' }} {{ '(' + (detail.value?.startTime || 'n/a') }} -
          {{ (detail.value?.endTime || 'n/a') + ')' }}
        </span>
      </p>
      <p
        *ngIf="!['checkbox', 'toggle', 'switch', 'date', 'datetime', 'checkbox_button'].includes(detail.type) && detail.order !== null"
        class="right"
        [ngClass]="{ email: detail.type === 'email' && detail.value }"
      >
        {{ detail.key === 'service_need' ? detail.value[0] : detail.value || '-' }}
      </p>
    </div>
    <div *ngIf="detail.type === 'text' && detail.values && detail.display && detail.order">
      <small class="detail-type-text clamped" [innerHTML]="parseTextValue(detail.values)"></small>
      <p class="read-more-text" *ngIf="detail.values.length > 100" (click)="readMoreClicked($event, detail.values)">Read more</p>
    </div>
    <ng-container *ngIf="['file', 'image'].includes(detail.type) && detail.value?.length">
      <div class="files-type-readonly">
        <ng-container *ngTemplateOutlet="fileTypeTemplate; context: { $implicit: detail }"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #customFieldsSectionTemplate let-formDetails>
  <ng-container *ngFor="let detail of formDetails">
    <div class="section-readonly" *ngIf="supportedTypesReadonlyMode.includes(detail.type) && detail.order && detail.display">
      <p class="left">{{ detail.label }}</p>
      <p class="right flex-center" *ngIf="['checkbox', 'toggle', 'switch'].includes(detail.type) && detail.value">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M2.5 12.5L7.60001 17.6C8.40001 18.4 9.59999 18.4 10.4 17.6L21.5 6.5"
            stroke="#181429"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </p>
      <p class="right" *ngIf="['checkbox', 'toggle', 'switch'].includes(detail.type) && !detail.value">No</p>
      <p class="right" *ngIf="['date', 'datetime'].includes(detail.type) && detail.order !== null">
        <span *ngIf="detail.type === 'date'">{{ (detail.value | date: 'mediumDate') || '-' }}</span>
        <span *ngIf="detail.type === 'datetime' && detail.value"
          >{{ (detail.value?.date | date: 'mediumDate') || 'n/a' }} {{ '(' + (detail.value?.startTime || 'n/a') }} -
          {{ (detail.value?.endTime || 'n/a') + ')' }}
        </span>
      </p>
      <p class="right" *ngIf="!['checkbox', 'toggle', 'switch', 'date', 'datetime', 'checkbox_button'].includes(detail.type)">
        {{ detail.value || '-' }}
      </p>
    </div>
    <div *ngIf="detail.type === 'text' && detail.values && detail.display && detail.order">
      <small class="detail-type-text clamped" [innerHTML]="parseTextValue(detail.values)"></small>
      <p class="read-more-text" *ngIf="detail.values.length > 100" (click)="readMoreClicked($event, detail.values)">Read more</p>
    </div>
    <ng-container *ngIf="detail.type === 'file' && detail.value?.length">
      <div class="files-type-readonly">
        <ng-container *ngTemplateOutlet="fileTypeTemplate; context: { $implicit: detail }"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #signatureSectionTemplate let-formDetails>
  <div class="signature-read-wrapper" *ngIf="formDetails[0].type === 'signature'">
    <div class="signature-container">
      <img class="signature-image" [src]="formDetails[0].value ? formDetails[0].value : null" />
    </div>
  </div>
</ng-template>

<ng-template #readMoreDialogTpl let-data>
  <div class="read-more-dialog-container">
    <h4 mat-dialog-title>Read More</h4>
    <div mat-dialog-content>
      <small class="detail-type-text" [innerHTML]="data?.text"></small>
    </div>
  </div>
</ng-template>
