import { Injectable } from '@angular/core';
import { RegisteredGuard } from './registered.guard';
import { UserRole } from './enums/user-role.enum';
import { AdminDataService } from './core/admin-data.service';
import { Router } from '@angular/router';
import { AppRoutes } from './enums/app-routes.enum';
import { TokenStorageService } from './core/token-storage.service';
import { first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerOrganizationGuard extends RegisteredGuard {
  role = UserRole.CUSTOMER_ORGANIZATION;
  nextRoute = AppRoutes.CustomerAdmin;

  constructor(readonly adminDataService: AdminDataService, readonly router: Router, readonly tokenStorageService: TokenStorageService) {
    super(adminDataService, router, tokenStorageService);
    this.adminDataService.user
      .pipe(
        first(),
        tap(user => {
          if (user.role === UserRole.CUSTOMER_ORGANIZATION && user.subscription === 'Free') {
            this.router.navigate([AppRoutes.Freemium]);
          }
        }),
      )
      .subscribe();
  }
}
