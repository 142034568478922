import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cl-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordValidationComponent implements OnInit, OnDestroy {
  get isLengthCorrect() {
    return this.control ? !this.control.hasError('incorrectLength') : false;
  }

  get isUpperLowerPresent() {
    return this.control ? !this.control.hasError('lowerUpper') : false;
  }

  get isNumbersPresent() {
    return this.control ? !this.control.hasError('numbers') : false;
  }

  get isSpecialNumbersPresent() {
    return this.control ? !this.control.hasError('symbols') : false;
  }

  private readonly destroy$ = new Subject<void>();

  @Input() control: AbstractControl;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.control.statusChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.cdr.markForCheck())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
