import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { SidebarService } from 'src/app/core/sidebar.service';

@Component({
  selector: 'cl-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Input() header: string;
  destroy$ = new Subject<void>();

  constructor(
    private mobileResponsiveServie: MobileResponsiveService,
    private sidebarService: SidebarService
  ) {

  }

  ngOnInit(): void {
    this.mobileResponsiveServie.isResponsive$
      .pipe(
        tap(res => {
          if (res) {
            this.sidebarService.setOpenSearchMobile();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
