import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Inject,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  PLATFORM_ID,
  EventEmitter,
  Output,
} from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CreateEditStoryResponse, StoryPublicForm, storyType } from '../../interfaces/story-public';
import { Image } from '../../interfaces/image';
import { SwiperModalComponent } from 'src/app/shared/swiper-modal/swiper-modal.component';
import { SwiperModalData } from 'src/app/shared/swiper-modal/swiper-modal-data';
import { catchError, filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Interment } from 'src/app/interfaces/interment';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { SellStoryService } from '../../core/sell-story.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { LoadingService } from 'src/app/loading-overlay/loading.service';
import { ElementStripeComponent } from '../../../app/shared/element-stripe/element-stripe.component';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { RemoveEntityFormDialogComponent } from 'src/app/admin/manage/remove-entity-form-dialog/remove-entity-form-dialog.component';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { TokenStorageService } from 'src/app/core/token-storage.service';
import { Token } from 'src/app/enums/token.enum';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { IdentifyName } from 'src/app/enums/amplitude.enum';

@Component({
  selector: 'cl-stepper-form-add-story',
  templateUrl: './stepper-form-add-story.component.html',
  styleUrls: ['./stepper-form-add-story.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperFormAddStoryComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject();
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();

  storyTypeList = storyType;
  intermentStoryImages: Array<Image> = [];
  intermentData: Interment;
  titleStory: string;
  contentStory: string;
  public = true;
  isLoadingAction = false;
  cemeteryUniqueName: string;
  intermentId: string;
  emailStory: string;
  paymentUrl: string;
  amountPayment: number;
  currency: string;
  clientSecret: string;
  selectedInterment: Interment;
  form = this.formBuilder.group({
    first_name: [null, Validators.required],
    last_name: [null, Validators.required],
    phone_number: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],
    email: [null, [Validators.required, Validators.email]],
    relationship_interment: [null, Validators.required],
    title: null,
    text: null,
    success_page: null,
    error_page: null,
    images: [[]],
  });

  configs: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '100px',
    width: '100%',
    placeholder: 'Compose an epic..',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    sanitize: true,
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'subscript',
        'superscript',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    showToolbar: true,
  };

  activeIndex = 0;

  listTitle = [
    {
      main: 'For whom',
      desc: 'Please select the person you want to write a Life Chronicle for.',
    },
    {
      main: 'Get started',
      desc: 'Please fill out all your details below. Your name will be published beside the story',
    },
    {
      main: 'Add your story',
      desc: `Share your loved one's story in your very own words`,
    },
    {
      main: 'Review your story',
      desc: 'By clicking save, you will be emailed a link allowing you to return to edit or continue this story any time',
    },
    {
      main: 'Proceed to Payment',
      desc: 'proceed to payment to have your story published',
    },
  ];
  responsive$: boolean;

  @ViewChild('stepper') stepper;
  @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { interment: Interment; detail: any; listInterments: any; cemeteryName: string },
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private readonly dialogRef: MatDialogRef<StepperFormAddStoryComponent>,
    private sellStoryService: SellStoryService,
    private snackBarNotificationService: SnackbarNotificationService,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private tokenStorageService: TokenStorageService,
    private amplitudeService: AmplitudeService,
    private mobileResponsiveService: MobileResponsiveService,

    @Inject(PLATFORM_ID)
    private platformId: any
  ) {}

  ngOnInit(): void {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.changeDetectorRef.detectChanges();
          if (!isPlatformBrowser(this.platformId)) {
            return;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.intermentStoryImages = this.dialogData.detail?.images ? this.dialogData.detail?.images : [];
  }

  ngAfterViewInit() {
    this.intermentStoryImages = this.dialogData.detail?.images ? this.dialogData.detail?.images : [];
    if (this.dialogData?.detail?.id) {
      return this.editFormGroup();
    }

    if (this.stepper) {
      this.stepper.selectedIndex = 0;
    }
    if (this.responsive$) {
      this.perfectScroll.disabled = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  editFormGroup() {
    const a = this.dialogData?.detail?.images?.map(({ id }) => id);

    this.form.setValue({
      first_name: this.dialogData.detail.created_user.first_name,
      last_name: this.dialogData.detail.created_user.last_name,
      phone_number: this.dialogData.detail.created_user.phone_number,
      email: this.dialogData.detail.created_user.email,
      relationship_interment: this.dialogData.detail.relationship_interment,
      title: this.dialogData.detail.title,
      text: this.dialogData.detail.text,
      success_page: null,
      error_page: null,
      images: a,
    });
  }

  uploadIntermentStoryPhotos(images: Image[]) {
    this.intermentStoryImages = images;

    this.form.markAsDirty();
    this.form.get('images').setValue(images?.map(({ id }) => id));
  }

  openImageStoryPreview(index: number) {
    this.dialog
      .open<SwiperModalComponent, SwiperModalData, Image[]>(SwiperModalComponent, {
        panelClass: 'slider-container',
        data: {
          entityTitle: '',
          images: this.intermentStoryImages,
          startFrom: index,
          download: true,
          share: true,
          delete: true,
          public: true,
        },
      })
      .afterClosed()
      .pipe(
        tap(images => {
          if (images) {
            this.onStoryImagesChange(images);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onStoryImagesChange(images: Image[]) {
    this.intermentStoryImages = images;

    const imageIds = images.length ? images.map(image => image.id) : [];

    this.form.get('images').patchValue(imageIds);
  }

  close() {
    this.dialogRef.close();
  }

  goBack() {
    switch (this.stepper.selectedIndex) {
      case 1:
        this.amplitudeService.track('Button "Back" on step 2 input Life Chronicle clicked');
        break;
      case 2:
        this.amplitudeService.track('Button "Back" on step 3 input Life Chronicle clicked');
        break;
    }

    this.stepper.previous();
    this.perfectScroll.directiveRef.update();
    this.perfectScroll.directiveRef.scrollToTop(0, 0);
    this.activeIndex = this.stepper.selectedIndex;
  }

  goForward() {
    const dataInterment = this.selectedInterment;
    // validate phone number
    if (this.form.hasError('minlength', 'phone_number') || this.form.hasError('minlength', 'phone_number')) {
      this.openErrorMessage('Phone number must be at least 5 - 20 numbers long!');
      return;
    }

    // validate email format
    if (this.form.hasError('email', 'email')) {
      this.openErrorMessage('Invalid format email!');
      return;
    }

    if (this.stepper.selectedIndex === 2 && !this.form.get('title').value?.length && this.dialogData.listInterments.length > 1) {
      this.openErrorMessage('Required title story');
      return;
    }

    if (this.stepper.selectedIndex === 1 && !this.form.get('title').value?.length && this.dialogData.listInterments.length <= 1) {
      this.openErrorMessage('Required title story');
      return;
    }

    if (this.stepper.selectedIndex === 2 && !this.form.get('text').value?.length && this.dialogData.listInterments.length > 1) {
      this.openErrorMessage('Required content story!');
      return;
    }

    if (this.stepper.selectedIndex === 1 && !this.form.get('text').value?.length && this.dialogData.listInterments.length <= 1) {
      this.openErrorMessage('Required content story!');
      return;
    }

    // validate form required
    if (this.form.invalid && this.stepper.selectedIndex === 1 && this.dialogData.listInterments.length > 1) {
      // this.form.updateValueAndValidity();
      // this.form.markAllAsTouched();
      this.openErrorMessage(`Required fields aren't filled in!`);
      // this.changeDetectorRef.markForCheck();
      return;
    }

    if (this.form.invalid && this.stepper.selectedIndex === 0 && this.dialogData.listInterments.length <= 1) {
      // this.form.updateValueAndValidity();
      // this.form.markAllAsTouched();
      this.openErrorMessage(`Required fields aren't filled in!`);
      // this.changeDetectorRef.markForCheck();
      return;
    }

    this.stepper.next();
    this.perfectScroll.directiveRef.update();
    this.perfectScroll.directiveRef.scrollToTop(0, 0);
    this.activeIndex = this.stepper.selectedIndex;

    const dataStepIntermentSelected = {
      dataInterment,
    };
    const dataStep1 = {
      first_name: this.form.get('first_name').value,
      last_name: this.form.get('last_name').value,
      phone_number: this.form.get('phone_number').value,
      email: this.form.get('email').value,
      relationship_interment: this.form.get('relationship_interment').value,
      interment: this.dialogData.listInterments[0],
    };

    let dataStep2;
    if (this.stepper.selectedIndex === 2) {
      dataStep2 = {
        title: this.form.get('title').value,
        text: this.form.get('text').value,
        images: this.form.get('images').value,
      };
    }
    if (this.dialogData.listInterments.length > 1) {
      switch (this.stepper.selectedIndex) {
        case 1:
          this.amplitudeService.track('3a. Button "Next" on step 1 selected interment clicked', dataStepIntermentSelected);
          break;
        case 2:
          this.amplitudeService.track('3b. Button "Next" on step 2 input Life Chronicle clicked', dataStep1);
          break;
        case 3:
          this.amplitudeService.track('3c. Button "Next" on step 3 input Life Chronicle clicked', dataStep2);
          break;
      }
    }

    if (this.dialogData.listInterments.length <= 1) {
      switch (this.stepper.selectedIndex) {
        case 1:
          this.amplitudeService.track('3a. Button "Next" on step 1 input Life Chronicle clicked', dataStep1);
          break;
        case 2:
          this.amplitudeService.track('3b. Button "Next" on step 2 input Life Chronicle clicked', dataStep2);
          break;
      }
    }

    if (this.isIntermentSingle()) {
      return this.checkForm();
    }
  }

  checkForm() {
    if (this.dialogData.listInterments.length <= 1) {
      this.selectedInterment = this.dialogData.listInterments[0];
    }

    const encodedPlotId = encodeURIComponent(this.selectedInterment.plot_id);

    this.form.markAsDirty();
    this.form
      .get('success_page')
      .setValue(
        `http://localhost:4200/${this.selectedInterment.cemetery_unique_name}/plots/${encodedPlotId}?from=map&zoom=24&email=${this.emailStory}&modal=show&status=success`
      );
    this.form
      .get('error_page')
      .setValue(
        `http://localhost:4200/${this.selectedInterment.cemetery_unique_name}/plots/${encodedPlotId}?from=map&zoom=24&email=${this.emailStory}&modal=show&status=cancel`
      );

    this.isLoadingAction = true;
    this.loadingService.run();
    if (this.dialogData?.detail?.id) {
      return this.sellStoryService
        .editStoryPublic(this.form.value, this.dialogData?.detail?.id)
        .pipe(
          catchError(error => {
            this.openErrorMessage(error.error.message);
            return EMPTY;
          }),
          tap<CreateEditStoryResponse>(data => {
            /* tslint:disable:no-string-literal */
            // this.paymentUrl = data['data'].payment_url;
            this.clientSecret = data.client_secret;
            this.amountPayment = data.amount;
            this.currency = data.currency;

            /* tslint:disable:no-string-literal */
            this.amplitudeService.track('Updated life Chronicle', { before: this.dialogData, after: this.form.value });
            this.openSuccessMessage('Life Chronicle submit successfully!');
          }),
          finalize(() => {
            this.loadingService.stop();
            this.isLoadingAction = false;
            this.changeDetectorRef.markForCheck();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }

    const ampUserId = this.tokenStorageService.get(Token.ampliUserId);
    const formContent: StoryPublicForm = { ...this.form.value, amplitude_user_id: ampUserId };

    this.sellStoryService
      .createStoryPublic(formContent, this.selectedInterment.cemetery_unique_name, this.selectedInterment.id)
      .pipe(
        catchError(error => {
          this.openErrorMessage(error.error.message);
          return EMPTY;
        }),
        tap<CreateEditStoryResponse>(data => {
          /* tslint:disable:no-string-literal */
          // this.paymentUrl = data['data'].payment_url;
          this.clientSecret = data.client_secret;
          this.amountPayment = data.amount;
          this.currency = data.currency;

          /* tslint:disable:no-string-literal */
          if (this.dialogData.listInterments.length > 1) {
            this.amplitudeService.track('3d. Created life Chronicle');
          }
          if (this.dialogData.listInterments.length <= 1) {
            this.amplitudeService.track('3c. Created Life Chronicle');
          }
          //   this.amplitudeService.track('Created life Chronicle', this.form.value);
          this.openSuccessMessage('Life Chronicle submit successfully!');
        }),
        finalize(() => {
          this.loadingService.stop();
          this.isLoadingAction = false;
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  mountElementStripe() {
    if (this.dialogData.listInterments.length > 1) {
      this.amplitudeService.track('3e. Button "Go to payment" on input Life Chronicle clicked', null, {
        [IdentifyName.CemeteryName]: this.dialogData.cemeteryName,
      });
    }
    if (this.dialogData.listInterments.length <= 1) {
      this.amplitudeService.track('3d. Button "Go to payment" on input Life Chronicle clicked', null, {
        [IdentifyName.CemeteryName]: this.dialogData.cemeteryName,
      });
    }
    this.dialog
      .open(ElementStripeComponent, {
        panelClass: 'element-stripe',
        minWidth: '500px',
        disableClose: true,
        autoFocus: false,
        data: {
          interment: this.selectedInterment,
          clientSecret: this.clientSecret,
          amountPayment: this.amountPayment,
          currency: this.currency,
          emailStory: this.emailStory,
          cemeteryName: this.dialogData.cemeteryName,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  onClickTOC() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.dialogData.listInterments.length > 1) {
      this.amplitudeService.track('3f. Link "terms and conditions" on Life Chronicle clicked');
    }
    if (this.dialogData.listInterments.length <= 1) {
      this.amplitudeService.track('3e. Link "terms and conditions" on Life Chronicle clicked');
    }
    // this.amplitudeService.track('Link "terms and conditions" on Life Chronicle clicked');
    window.open(`${environment.APP_BASE_URL}/static/toc.html`, '_blank');
  }

  onCancel() {
    this.amplitudeService.track('3g. Button "Close" on input Life Chronicle clicked');

    if (!this.form.dirty) {
      return this.dialogRef.close();
    }

    return this.dialog
      .open(RemoveEntityFormDialogComponent, {
        width: '448px',
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        tap(() => {
          this.dialogRef.close();
        })
      )
      .subscribe();
  }

  onSelectType(e) {
    this.selectedInterment = this.dialogData.listInterments.find(index => index.id === e.id);
    this.notifyParent.emit(this.selectedInterment);
  }

  isIntermentSingle() {
    if (this.activeIndex === 4 && this.dialogData.listInterments.length > 1) {
      return true;
    }

    if (this.activeIndex === 3 && this.dialogData.listInterments.length <= 1) {
      return true;
    }
  }

  changeLabelHeader() {
    if (this.dialogData.listInterments.length > 1) {
      return this.listTitle[this.activeIndex];
    }

    if (this.dialogData.listInterments.length <= 1) {
      return this.listTitle.slice(1)[this.activeIndex];
    }
  }

  private openSuccessMessage(successMessage: string) {
    this.snackBarNotificationService.openSuccessMessage(successMessage);
  }

  private openErrorMessage(errorMessage: string) {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.snackBarNotificationService.openErrorMessage(errorMessage);
    this.changeDetectorRef.markForCheck();
  }
}
