import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CemeteryInfo } from '../../interfaces/cemetery-info';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'cl-search-cemetery-item',
  templateUrl: './search-cemetery-item.component.html',
  styleUrls: ['./search-cemetery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchCemeteryItemComponent {
  @Input() searchValue: string;
  @Input() cemeteryInfo: CemeteryInfo | undefined;
  userLogIn = false;

  constructor(
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private authService: AuthService
  ){
    this.userLogIn = this.authService.isUserLogged();
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_cemetery',
        src: '/assets/images/search_cemetery_icon.svg',
      },
    ]);
  }

  getCemeteryStatus(status) {
    const statusPubclic = !this.userLogIn ? 'Partner' : 'Public';
    switch (status) {
      case 'Public':
          return statusPubclic;
      case 'Coming_Soon':
          return 'Coming Soon';
      case 'Visible':
          return 'Non-Partner';
      default:
          return status;
    }
  }
}
