import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, AfterViewInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LeafletAdapterService } from 'src/app/core/leaflet-adapter.service';
import { MapService } from 'src/app/core/map.service';
import { CemeteryInfo } from 'src/app/interfaces/cemetery-info';
import { Marker } from 'leaflet';
import { RouterUtilsService } from 'src/app/core/router-utils.service';

@Component({
  selector: 'cl-map-panorama',
  templateUrl: './map-panorama.component.html',
  styleUrls: ['./map-panorama.component.scss'],
})
export class MapPanoramaComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('miniMap', { static: true }) mapContainer: ElementRef;
  @Input() currentLocation: string;
  @Input() cemeteryInfo: CemeteryInfo;
  @Input() markerPoint: any;
  layerPano = L.layerGroup();
  arrayLocation: string[];
  leafletMap: any;
  markerPegman: Marker;

  constructor(
    private leafletAdapterService: LeafletAdapterService,
    private readonly mapService: MapService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private routerUtilsService: RouterUtilsService
  ) { }

  ngOnInit() {
    this.leafletMap = this.createMap(this.mapContainer, this.arrayLocation);
    this.mapService.init(this.leafletMap);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.leafletMap);
    if (this.cemeteryInfo) {
      const bounds = L.geoJSON(this.cemeteryInfo.bounds).getBounds().pad(1);
      const orthoImageLayer = this.leafletAdapterService.getOverlayTileLayer(this.cemeteryInfo.ortho_layer, bounds);
      orthoImageLayer.addTo(this.leafletMap);

      if (this.markerPoint.length) {
        const canvasLayer = L.canvas();
        this.markerPoint.forEach((data) => {
          L.circleMarker(data.coordinates, {
            renderer: canvasLayer,
            radius: 8,
            color: '#ff6868',
            fillColor: '#f03',
            fillOpacity: 0.5,
            weight: 0.5
          })
            .on('click', () => {
              const path = this.routerUtilsService.getPath(this.cemeteryInfo.unique_name, 'panorama', data.id, data.coordinates.join(','));
              this.router.navigate(path);
              this.changeDetectorRef.detectChanges();
            })
            .addTo(this.layerPano);
        });
      }
      this.layerPano.addTo(this.leafletMap);
    }
    this.addMarkerPegman();

    this.leafletMap.on('zoomend', () => {
      const zoomLevel = this.leafletMap.getZoom();
      this.updateMarkerRadius(zoomLevel);
    });
  }

  updateMarkerRadius(zoomLevel) {
    if (this.layerPano.getLayers().length) {
      this.layerPano.getLayers().forEach((marker) => {
        if (zoomLevel <= 19) {
          marker.setRadius(1);
        } else if (zoomLevel === 20) {
          marker.setRadius(4);
        } else {
          marker.setRadius(8);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.arrayLocation = this.currentLocation.split(',');
    if (this.markerPegman) {
      this.markerPegman.remove();
      this.addMarkerPegman();
    }
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  addMarkerPegman() {
    this.markerPegman = L.marker(this.arrayLocation, {
      icon: L.icon({
        iconUrl: '..../../assets/images/pegman-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      })
    });
    this.markerPegman.addTo(this.leafletMap);
  }


  ngOnDestroy() {
    this.mapService.destroy();
  }

  private createMap(element: ElementRef, setView: any) {
    return L.map(element.nativeElement, {
      minZoom: 19,
      maxZoom: 25,
      compassControl: false,
    }).setView(setView, 23);
  }
}
