import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { filter, findIndex, tap } from 'rxjs/operators';
import { ModalEditSignatureComponent } from '../modal-edit-signature/modal-edit-signature.component';
import { Subject } from 'rxjs';
import moment from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { DateIsoToJsPipe } from '../date-iso-to-js.pipe';
@Component({
  selector: 'cl-render-custom-form',
  templateUrl: './render-custom-form.component.html',
  styleUrls: ['./render-custom-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'LL',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
})
export class RenderCustomFormComponent implements OnInit, OnDestroy {
  get fc() {
    return this.form.controls;
  }

  get sectionsArray() {
    const formArray = this.form.get('sections') as FormArray;
    formArray.controls.sort((a, b) => a.value.order - b.value.order);
    return formArray;
  }

  private destroy$ = new Subject<void>();

  @ViewChild('readMoreDialogTpl') readMoreDialogTpl: TemplateRef<any>;

  @Input() sourceForm: any;

  @Input() showTitle = true;

  @Input() tempApplicantLabel: any;

  @Output() allSectionValid = new EventEmitter<{ isValid: boolean; value: any }>();

  currentActiveFormSection = new SelectionModel<number>(false, [0]);

  isSectionReadOnly = new SelectionModel<number>(true, []);

  form: FormGroup = this.formBuilder.group({
    sections: this.formBuilder.array([]),
  });

  supportedTypesEditMode = ['text_field', 'email', 'toggle', 'select', 'date', 'number', 'textarea', 'checkbox', 'checkbox_button'];

  excludeByKey = ['max_holder', 'max_deceased', 'event_type', 'sub_type', 'role'];

  isDynamic = ['roi_holder', 'deceased'];

  supportedTypesReadonlyMode = [
    'text_field',
    'email',
    'toggle',
    'select',
    'date',
    'datetime',
    'number',
    'textarea',
    'checkbox',
    'switch',
    'checkbox_button',
  ];

  groupSectionTypes = [
    'plot',
    'roi',
    'roi_holder',
    'roi_applicant',
    'interment',
    'deceased',
    'interment_applicant',
    'next_of_kin',
    'funeral_director',
    'funeral_minister',
    'event_service',
    'applicant',
  ];

  acceptedFileTypes = {
    ['PDF']: ['application/pdf'],
    ['DOCX/DOC']: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    ['JPG/JPEG']: ['image/jpeg', 'image/jpg'],
    ['PNG']: ['image/png'],
    ['HEIC']: ['image/heic'],
  };

  postalAddressTemplate = [
    {
      key: 'postal_street',
      label: 'Postal Address',
    },
    {
      key: 'postal_suburb',
      label: 'Postal City / Town / Suburb',
    },
    {
      key: 'postal_state',
      label: 'Postal State',
    },
    {
      key: 'postal_country',
      label: 'Postal Country',
    },
    {
      key: 'postal_postcode',
      label: 'Postal Post Code',
    },
  ];

  defaultFileUploadConfig = {
    maxAmount: 2,
    maxSize: 4096,
    acceptedFileTypes: {
      documents: [
        { name: 'PDF', selected: true },
        { name: 'DOCX/DOC', selected: true },
      ],
      image: [
        { name: 'JPG/JPEG', selected: true },
        { name: 'PNG', selected: true },
        { name: 'HEIC', selected: true },
      ],
    },
  };

  signatureImageSrc: string;

  tempDocumentUpload = [];

  tempImageUpload = [];

  isAlsoRoiHolder = false;

  findRoiHolder = false;

  findRoiHolderIndex = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'add-icon', src: '/assets/images/plus_icon.svg' }]);
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.sourceForm.sections.forEach(section => {
      this.sectionsArray.push(
        this.formBuilder.group({
          ...section,
          form_detail: this.formBuilder.array(
            section.form_detail
              .sort((a, b) => {
                // sort not null values first
                if (a.order === null) {
                  return 1;
                } else if (b.order === null) {
                  return -1;
                } else {
                  return a.order - b.order;
                }
              })
              .map(detail => {
                // parse select values if it has `value` property into array of string
                if (
                  detail.type === 'select' &&
                  detail.values?.length &&
                  Array.isArray(detail.values) &&
                  detail.values?.every(value => {
                    return value.hasOwnProperty('value');
                  })
                ) {
                  detail.values = detail.values.map(value => value.value);
                }

                if (['burials_capacity', 'entombment_capacity', 'cremation_capacity', 'total_capacity'].includes(detail.key)) {
                  detail.value = 0;
                }

                const detailGroup = this.formBuilder.group({
                  key: [detail.key],
                  type: [detail.type],
                  label: [detail.label],
                  order: [detail.order],
                  values: [detail.values || null],
                  value: this.constructValue(detail),
                  display: [detail.display],
                  required: [detail.required],
                  is_default: [detail.is_default],
                });
                this.setFormValidation(detail, detailGroup);
                this.patchFormValue(detail, detailGroup, section.type);
                this.parseFormValue(detailGroup);
                return detailGroup;
              })
          ),
        })
      );
    });

    const applicantSection = this.sectionsArray.controls.find(section => section.get('type').value === 'applicant');
    const findApplicant = this.sourceForm.sections.find(sec => sec.type === 'applicant');
    if (this.tempApplicantLabel !== null && findApplicant.name !== 'Applicant' && findApplicant.name !== 'Applicant Details') {
      this.tempApplicantLabel = findApplicant.name;
    } else {
      applicantSection.get('name').setValue(applicantSection.get('form_detail').value[0].value);
    }
  }

  constructValue(detail: any): FormGroup | FormControl {
    if (detail.type === 'datetime') {
      return this.formBuilder.group({
        date: [null],
        startTime: [null],
        endTime: [null],
      });
    }
    return new FormControl(detail.value === 0 ? 0 : detail?.value ? detail.value : null);
  }

  patchFormValue(detail: any, formGroup: FormGroup, sectionType?: string) {
    if (detail.key === 'event_type') {
      formGroup.get('value').setValue(detail.values?.selected_value, { emitEvent: false });
    }

    if (detail.key === 'sub_type') {
      formGroup.get('value').setValue(detail.values, { emitEvent: false });
    }

    if (sectionType === 'roi_applicant' && detail.values && !this.sourceForm.is_default) {
      formGroup.get('value').setValue(detail.values, { emitEvent: false });
    }
  }

  setFormValidation(detail: any, formGroup: FormGroup) {
    const isVisible = detail?.display && detail?.order;
    const excludeByKey = ['max_holder', 'max_deceased'];

    if (detail.required && isVisible && !excludeByKey.includes(detail.key)) {
      formGroup.get('value').setValidators(Validators.required);

      if (detail.type === 'datetime' && detail.required) {
        formGroup.get('value').get('date').setValidators(Validators.required);
      }
    }

    if (['file', 'image'].includes(detail.type) && isVisible) {
      const validators = [];
      if (detail.required) {
        validators.push(Validators.required);
      }

      if ((detail.values && detail.values?.maxAmount) || detail.values?.length) {
        validators.push(Validators.maxLength(detail.values.maxAmount));
      }

      if (!detail.values || !detail.values?.length) {
        validators.push(Validators.maxLength(this.defaultFileUploadConfig.maxAmount));
      }

      formGroup.get('value').setValidators(validators);
    }
  }

  validateAcceptFileTypes(fileTypes: any[], detail: any) {
    const mappedFileTypes = fileTypes?.length
      ? fileTypes
      : !fileTypes?.length && detail.type === 'file' && ['file', 'documents'].includes(detail.key)
      ? this.defaultFileUploadConfig.acceptedFileTypes.documents
      : this.defaultFileUploadConfig.acceptedFileTypes.image;
    const acceptedFileTypes = mappedFileTypes.reduce((accumulator, fileType) => {
      if (fileType.selected) {
        accumulator.push(...this.acceptedFileTypes[fileType.name]);
      }
      return accumulator;
    }, []);
    return acceptedFileTypes;
  }

  parseFormValue(formGroup: FormGroup) {
    formGroup.valueChanges
      .pipe(
        tap(value => {
          if (['date'].includes(value.type)) {
            const date = value.value ? moment(value.value).format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
            formGroup.get('value').setValue(date, { emitEvent: false });
          }

          const capacityKeys = ['burials_capacity', 'entombment_capacity', 'cremation_capacity'];
          if (capacityKeys.includes(value.key)) {
            const formArray = formGroup.parent as FormArray;
            const totalCapacityIndex = formArray.controls.findIndex(ctrl => ctrl.get('key').value === 'total_capacity');
            const accumulate = formArray.controls
              .filter(ctrl => capacityKeys.includes(ctrl.get('key').value))
              .reduce((accumulator, ctrl) => (accumulator += ctrl.value.value), 0);
            formArray.at(totalCapacityIndex).get('value').setValue(accumulate, { emitEvent: false });
          }
        })
      )
      .subscribe();
  }

  addNewSection(sectionType: string) {
    const currentSectionIndex = this.sectionsArray.controls.findIndex(section => section.get('type').value === sectionType);
    const getFormObj = this.sectionsArray.controls[currentSectionIndex];

    let newSection;
    if (!getFormObj) {
      const newSectionDetail = this.sourceForm.sections.find(section => section.type === sectionType);
      newSection = this.formBuilder.group({
        ...newSectionDetail,
        form_detail: this.formBuilder.array(
          newSectionDetail.form_detail.map(detail => {
            const detailGroup = this.formBuilder.group({
              key: [detail.key],
              type: [detail.type],
              label: [detail.label],
              order: [detail.order],
              values: [detail.values || null],
              value: this.constructValue(detail),
              display: [detail.display],
              required: [detail.required],
              is_default: [detail.is_default],
            });

            this.setFormValidation(detail, detailGroup);
            this.patchFormValue(detail, detailGroup);
            this.parseFormValue(detailGroup);
            return detailGroup;
          })
        ),
      });
    } else {
      newSection = this.formBuilder.group({
        ...getFormObj.value,
        form_detail: this.formBuilder.array(
          getFormObj.value.form_detail.map(detail => {
            const detailGroup = this.formBuilder.group({
              key: [detail.key],
              type: [detail.type],
              label: [detail.label],
              order: [detail.order],
              values: [detail.values || null],
              value: this.constructValue(detail),
              display: [detail.display],
              required: [detail.required],
              is_default: [detail.is_default],
            });

            this.setFormValidation(detail, detailGroup);
            this.patchFormValue(detail, detailGroup);
            this.parseFormValue(detailGroup);
            return detailGroup;
          })
        ),
      });
    }

    const getIndex = this.currentActiveFormSection.selected[0];
    const isSameSectionType = this.sectionsArray.at(getIndex).get('type').value === sectionType;

    if (isSameSectionType && !this.sectionsArray.at(getIndex).valid) {
      const sectionFormDetail = this.sectionsArray.at(getIndex).get('form_detail') as FormArray;
      this.checkValidityFormArray(sectionFormDetail);
      return;
    }

    if (isSameSectionType || !getFormObj) {
      if (!getFormObj) {
        this.sectionsArray.insert(getIndex, newSection);
        this.currentActiveFormSection.select(getIndex);
        this.isSectionReadOnly.clear();
        this.isSectionReadOnly.select(...Array.from({ length: getIndex }, (_, i) => i));
        this.cdr.detectChanges();
        return;
      }
      this.sectionsArray.insert(getIndex + 1, newSection);
      this.currentActiveFormSection.select(getIndex + 1);
      this.isSectionReadOnly.toggle(getIndex);
      this.cdr.detectChanges();
      return;
    }
  }

  removeSection(index: number, isAlsoRoiHolder = false, totalRoiHolderValue?: number) {
    if (isAlsoRoiHolder && totalRoiHolderValue > 1) {
      const totalLength = index + 1;
      this.sectionsArray.removeAt(index);
      this.currentActiveFormSection.select(totalLength + 1);
      this.isSectionReadOnly.clear();
      this.isSectionReadOnly.select(...Array.from({ length: totalLength + 1 }, (_, i) => i));
      return;
    }
    this.sectionsArray.removeAt(index);
    this.currentActiveFormSection.select(index);
    this.isSectionReadOnly.clear();
    this.isSectionReadOnly.select(...Array.from({ length: index }, (_, i) => i));
  }

  displayAddSection(sectionType: string, index: number) {
    const keyLookUp = {
      deceased: 'max_deceased',
      roi_holder: 'max_holder',
    };

    const { lastIndex, sectionLength } = this.sectionsArray.controls.reduce(
      (acc, section, idx) => {
        if (section.get('type').value === sectionType) {
          acc.lastIndex = idx;
          acc.sectionLength++;
        }
        return acc;
      },
      { lastIndex: -1, sectionLength: 0 }
    );

    const currentSection = this.sectionsArray.at(index).get('form_detail') as FormArray;
    const currentSectionMax = currentSection.controls.find(control => control.get('key').value === keyLookUp[sectionType]);
    const text = `${Math.abs(lastIndex - index - sectionLength)} of ${sectionLength}`;
    return {
      displayButton: lastIndex === index && currentSectionMax.get('values').value > sectionLength,
      displayRemove: sectionLength > 1,
      text,
      sectionLength,
    };
  }

  checkValidityFormArray(formArray: FormArray) {
    formArray.controls.forEach(control => {
      control.get('value').markAsTouched();
      control.get('value').markAsDirty();
      control.get('value').markAllAsTouched();
      control.get('value').updateValueAndValidity({ onlySelf: true });
      this.cdr.detectChanges();
    });

    const errorText = document.querySelector('.mat-error');
    if (errorText) {
      errorText.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  goToNextSection(section: FormGroup, index: number) {
    const sectionFormDetails = section.get('form_detail') as FormArray;
    const invalid = this.sectionsArray.controls.findIndex(sect => !sect.valid);

    if (!section.valid) {
      section.markAsTouched();
      this.checkValidityFormArray(sectionFormDetails);
      return;
    }

    this.isSectionReadOnly.toggle(index);
    this.currentActiveFormSection.select(index + 1);
    this.emitAllSectionValid();
    this.cdr.detectChanges();
  }

  panelBodyClicked(index: number) {
    const isPrevIndex = this.currentActiveFormSection.selected[0] - 1 === index;
    if (isPrevIndex) {
      this.isSectionReadOnly.toggle(index);
      this.currentActiveFormSection.select(index);
    }

    this.emitAllSectionValid();
  }

  emitAllSectionValid() {
    if (this.currentActiveFormSection.selected[0] === this.sectionsArray.length) {
      this.allSectionValid.emit({ isValid: true, value: this.form.value });
    } else {
      this.allSectionValid.emit({ isValid: false, value: this.form.value });
    }
  }

  openSignatureDialog(currentFormGroup: FormGroup) {
    const signatureDialogRef = this.dialog.open(ModalEditSignatureComponent, {
      data: {
        value: currentFormGroup.get('value').value ? currentFormGroup.get('value').value : null,
      },
    });
    signatureDialogRef
      .afterClosed()
      .pipe(
        tap(result => {
          currentFormGroup.get('value').setValue(result.value);
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  getFullName(details: any[]): string | null {
    const firstName = details.find(detail => detail.key === 'first_name')?.value;
    const lastName = details.find(detail => detail.key === 'last_name')?.value;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return null;
  }

  recievedFileUpload(event, currentFormGroup: FormGroup) {
    currentFormGroup.get('value').setValue(event);
  }

  parseTextValue(text: string) {
    // replace \n as a <br> tag with numbered style and remove any number with format of 1. or 1)
    return this.sanitizer.bypassSecurityTrustHtml(text.replace(/\n/g, '<br/>'));
  }

  readMoreClicked(event: any, text: string) {
    event.stopPropagation();
    this.dialog.open(this.readMoreDialogTpl, {
      width: '720px',
      panelClass: 'read-more-dialog',
      data: {
        text: this.parseTextValue(text),
      },
    });
  }

  getAplicantTitle(sectionData) {
    if (sectionData?.form_detail?.length < 0) {
      return;
    }

    if (this.tempApplicantLabel) {
      return this.tempApplicantLabel;
    }

    const isRole = sectionData.form_detail?.findIndex(form => form.key === 'role');
    if (isRole > -1) {
      return sectionData?.form_detail[isRole].value;
    }
  }

  checkTypeCheckbox(typeForm: any, key: any, indexSection: number) {
    if (typeForm === 'checkbox_button') {
      this.checkboxClicked();
    } else if (key === 'postal_address_different') {
      this.differentAddressClicked(indexSection);
    } else {
      return null;
    }
  }

  differentAddressClicked(index: number) {
    this.sectionsArray.controls.forEach((section, indexSection) => {
      if (indexSection === index) {
        const postalAddressDifferent = section.get('form_detail') as FormArray;

        let orderCounter = 0;
        postalAddressDifferent.controls.map((control, indexControl) => {
          orderCounter += 1;

          const indexPostalDifferent = postalAddressDifferent.controls.findIndex(
            ctrl => ctrl.get('key').value === 'postal_address_different'
          );
          if (control.get('key').value === 'postal_address_different') {
            if (control.get('order').value !== null) {
              control.get('order').setValue(orderCounter);
            }
            this.postalAddressTemplate.reverse().forEach(postalAddress => {
              orderCounter += 1;
              const existingIndex = postalAddressDifferent.controls.findIndex(ctrl => ctrl.get('key').value === postalAddress.key);

              if (existingIndex === -1 && control.get('value').value) {
                const validators = control.get('required').value ? [Validators.required] : [];

                postalAddressDifferent.insert(
                  indexControl + 1,
                  this.formBuilder.group({
                    key: postalAddress.key,
                    type: 'text_field',
                    label: postalAddress.label,
                    order: orderCounter,
                    values: null,
                    value: [null, ...validators],
                    display: true,
                    required: control.get('required').value,
                    is_default: false,
                  })
                );
              } else {
                postalAddressDifferent.removeAt(existingIndex);
              }
            });
          } else {
            if (control.get('order').value !== null) {
              const indexData = indexControl + 1;
              const reIndexOrder =
                indexControl > indexPostalDifferent && control.get('key').value === 'postal_address_different' && control.get('value').value
                  ? indexControl + this.postalAddressTemplate.length
                  : indexData;

              control.get('order').setValue(reIndexOrder);
            }
          }
        });
        this.cdr.detectChanges();
      }
    });
  }

  checkboxClicked() {
    this.isAlsoRoiHolder = !this.isAlsoRoiHolder;
    const totalRoiHolder = this.sectionsArray.controls.filter(section => section.get('type').value === 'roi_holder').length;
    const findFirstRoiHolder = this.sectionsArray.controls.findIndex(section => section.get('type').value === 'roi_holder');
    if (findFirstRoiHolder === -1) {
      this.addNewSection('roi_holder');
      return;
    }
    const findMaxRoiHolder = this.sectionsArray.at(findFirstRoiHolder).get('form_detail') as FormArray;
    const totalRoiHolderValue = findMaxRoiHolder.controls.find(control => control.get('key').value === 'max_holder').get('values').value;

    if (totalRoiHolder >= totalRoiHolderValue && this.isAlsoRoiHolder) {
      this.removeSection(findFirstRoiHolder, true, totalRoiHolderValue);
    }
    this.sectionsArray.controls.forEach(section => {
      const roiHolderSection = section.get('type').value === 'roi_holder';
      if (roiHolderSection) {
        const roiHolderFormDetail = section.get('form_detail') as FormArray;
        if (this.isAlsoRoiHolder) {
          roiHolderFormDetail.controls.forEach(detail => {
            if (detail.get('key').value === 'max_holder') {
              return detail.get('values').setValue(detail.get('values').value - 1);
            }
          });
        } else {
          roiHolderFormDetail.controls.forEach(detail => {
            if (detail.get('key').value === 'max_holder') {
              this.addNewSection('roi_holder');
              return detail.get('values').setValue(detail.get('values').value + 1);
            }
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
