<ng-container [formGroup]="group">
  <mat-form-field class="input-plot-id">
    <mat-label>Plot ID</mat-label>
    <input formControlName="{{ plotIdControlName }}" type="" matInput placeholder="Plot ID" value="B AC 71" />
  </mat-form-field>

  <div>
    <cl-filter-section-row [formGroup]="group" [sectionData]="sectionData" [rowValue]="rowValue" [fromAdvanceSearch]="fromAdvanceSearch">
    </cl-filter-section-row>
  </div>

  <mat-form-field class="input-status-id">
    <mat-label>Status</mat-label>
    <mat-select [formControl]="group.get('status')" multiple>
      <mat-option *ngFor="let status of statuses" [value]="status.value">{{ status.value }} </mat-option>
    </mat-select>
    <!-- <mat-error *ngIf="statusCtrl.invalid && statusCtrl.touched">*Select one status</mat-error> -->
  </mat-form-field>
  <div class="container-type-price">
    <div class="wrapper-bottom">
      <mat-form-field appearance="standard" class="plot-type">
        <mat-label>Plot type</mat-label>
        <mat-select formControlName="{{ plotTypeControlName }}">
          <mat-option *ngFor="let type of plotTypes | keyvalue" [value]="type.key">{{ type.value }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="price-plot">
        <mat-label>Price ($)</mat-label>
        <input formControlName="{{ priceControlName }}" matInput placeholder="Price ($)" value="10000" />
        <mat-hint>Equal or less</mat-hint>
      </mat-form-field>
    </div>
    <div class="capacity-type" *ngIf="isAdmin">
      <div class="title-capacity">Capacity</div>
      <div class="capacity-input-wrapper">
        <mat-form-field class="input-capacity-type">
          <mat-label>Burials</mat-label>
          <input formControlName="{{ burialCapacityControlName }}" type="" matInput placeholder="Burials" />
        </mat-form-field>
        <mat-form-field class="input-capacity-type">
          <mat-label>Entombments</mat-label>
          <input formControlName="{{ entombmentCapacityControlName }}" type="" matInput placeholder="Entombments" />
        </mat-form-field>
        <mat-form-field class="input-capacity-type">
          <mat-label>Cremations</mat-label>
          <input formControlName="{{ cremateCapacityControlName }}" type="" matInput placeholder="Cremations" />
        </mat-form-field>
      </div>
    </div>
    <div class="interment-qty" *ngIf="isAdmin">
      <div class="title-qty">Interments Qty.</div>
      <div class="qty-input-wrapper" [formGroup]="group.get('interment_qty')">
        <mat-form-field class="qty-input">
          <mat-label>From</mat-label>
          <input formControlName="fromQty" matInput />
        </mat-form-field>
        <div class="separator">-</div>
        <mat-form-field class="qty-input">
          <mat-label>to</mat-label>
          <input formControlName="toQty" matInput />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
