import { Component, OnInit, ChangeDetectionStrategy, Input, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';
import { from, fromEvent } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { RightType } from 'src/app/enums/right-type.enum';

@Component({
  selector: 'cl-roi-form',
  templateUrl: './roi-form.component.html',
  styleUrls: ['./roi-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoiFormComponent implements OnInit, AfterViewInit {
  @ViewChildren('dateMask') dateMaskTmplRef: QueryList<ElementRef>;

  @Input() group: FormGroup;
  @Input() roiNumberControlName: string;
  @Input() rightTypeControlName: string;
  @Input() torTypeControlName: string;
  @Input() chargedControlName: string;
  @Input() plotTypes: any;
  @Input() rightType: RightType;
  @Input() termOfRights: any;
  @Input() isAdmin: any;
  @Input() isChronicleAdmin: boolean;
  @Input() destroy$: any;
  constructor(private ngxMaskPipe: MaskPipe) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    from(this.dateMaskTmplRef.toArray())
      .pipe(
        mergeMap(tplRef => fromEvent<InputEvent>(tplRef.nativeElement, 'input')),
        tap(event => {
          const target = event.target as HTMLInputElement;
          target.value = this.ngxMaskPipe.transform(target.value, 'd0/M0/0000');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
