<div class="table-head" *ngIf="withHeader">
  <ngx-skeleton-loader [theme]="{ width: '200px', height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader [theme]="{ width: '144px', height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader [theme]="{ width: '144px', height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader [theme]="{ width: '184px', height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader [theme]="{ width: '120px', height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader [theme]="{ width: '96px', height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader [theme]="{ width: '184px', height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader [theme]="{ width: '80px', height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
</div>

<div class="body-table">
  <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
    <ngx-skeleton-loader [theme]="{ height: '48px', margin: '0px', 'background-color': '##eff1f6' }"></ngx-skeleton-loader>
  </ng-container>
</div>
