<p class="person-dates_age">
  <ng-container *ngIf="isAgeAvailable; else na">
    <ng-container *ngIf="areDatesAvailable"
      >{{
        interment.date_of_birth
          ? interment.custom_date_of_birth === 'year'
            ? (year_of_birth | yearInRegionalFormat)
            : (interment.date_of_birth | dateInRegionalFormat)
          : 'n/a'
      }}
      -
      {{
        interment.date_of_death
          ? interment.custom_date_of_death === 'year'
            ? (year_of_death | yearInRegionalFormat)
            : (interment.date_of_death | dateInRegionalFormat)
          : 'n/a'
      }}</ng-container
    >
    <ng-container *ngIf="isAgeAvailable"> ({{ age }} {{ isShowAgeYearsOldText ? 'y.o' : '' }}) </ng-container>
  </ng-container>
</p>
<ng-template #na>n/a</ng-template>
