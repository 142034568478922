import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { RegionalSettingsService } from '../../core/regional-settings.service';

@Component({
  selector: 'cl-search-plots-records-annotation',
  templateUrl: './search-plots-records-annotation.component.html',
  styleUrls: ['./search-plots-records-annotation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPlotsRecordsAnnotationComponent {
  @Input() cemeteryName: string;
  @Input() org: any;
  @Input() cemeteryUniqueName: string;
  get regionalSettingsForUser() {
    return this.regionalSettingsService.regionalSettingsForUser$.value;
  }

  get regionalSettingsForCemetery() {
    return this.regionalSettingsService.regionalSettingsForCemetery$.value;
  }

  constructor(
    private regionalSettingsService: RegionalSettingsService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([
      {
        name: 'custom_search_autocomplete_account',
        src: '/assets/images/account_icon.svg',
      },
      { name: 'custom_search_autocomplete_plot', src: '/assets/images/plot_icon.svg' },
    ]);
  }
}
