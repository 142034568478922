import { Pipe, PipeTransform } from '@angular/core';
import { CremationLocation } from '../enums/cremation-location.enum';
export interface LocationCremationArray {
  key: string;
  value: string;
}
export type Location = keyof typeof CremationLocation;

@Pipe({
  name: 'sortCremationLocation',
})
export class SortCremationLocationPipe implements PipeTransform {
  transform(value: LocationCremationArray[]): LocationCremationArray[] {
    return value.sort((a, b) => {
      return a.value.length - b.value.length;
    });
  }
}
