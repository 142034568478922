<div class="content">
  <div class="left">
    <a href="/">
      <img src="../../../assets/images/chronicle_logo.svg" alt=""  [ngClass]="responsive$ ? 'logo' : 'logo2'" />
    </a>
    <a href="/">
    <img src="../../../assets/images/full_logo.svg" alt=""  [ngClass]="responsive$ ? 'logo-responsive' : 'logo'"  />
    </a>
  </div>

  <div [ngClass]="responsive$ ? 'right-responsive' : 'right'">
    <ng-content></ng-content>
  </div>
</div>
