<form [formGroup]="form">
  <div class="wrapper-modal">
    <ng-container *ngIf="this.dialogData.modalCondition !== 'delete'; else template">
      <div mat-dialog-title class="text-title">{{ changeTextTab() }}</div>
      <mat-dialog-content>
        <div class="container-field" formGroupName="fields">
          <mat-form-field class="field-input">
            <mat-label>Field name</mat-label>
            <input required matInput formControlName="name" placeholder="description" />
            <mat-error *ngIf="form.controls.fields.get('name').touched && form.controls.fields.get('name').hasError('pattern')"
              >Please remove any special characters.</mat-error
            >
            <mat-error *ngIf="form.controls.fields.get('name').touched && form.controls.fields.get('name').hasError('required')"
              >Required.</mat-error
            >
          </mat-form-field>
          <mat-form-field class="field-type">
            <mat-label>Field type</mat-label>
            <mat-select required formControlName="type">
              <mat-option *ngFor="let type of types" [value]="type.value">
                {{ type.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.fields.get('type').touched && form.controls.fields.get('type').hasError('required')"
              >Required.</mat-error
            >
          </mat-form-field>
          <mat-form-field class="field-type" *ngIf="this.dialogData.tab === 'Interment'">
            <mat-label>Category</mat-label>
            <mat-select required formControlName="category">
              <mat-option *ngFor="let category of categories" [value]="category.value">
                {{ category.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.fields.get('category').touched && form.controls.fields.get('category').hasError('required')"
              >Required.</mat-error
            >
          </mat-form-field>
          <div class="select-value-container" *ngIf="this.form.get('fields').get('type').value === 'select'">
            <mat-form-field class="field-type">
              <mat-label>Values</mat-label>
              <input matInput formControlName="values" placeholder="" type="text" />
              <mat-error *ngIf="form.controls.fields.get('values').touched && form.controls.fields.get('values').hasError('required')"
                >Required.</mat-error
              >
            </mat-form-field>
            <img
              class="tooltip"
              src="/assets/images/info_icon.svg"
              matTooltip="Please separate value by comma"
              matTooltipPosition="right"
              alt=""
            />
          </div>
          <mat-checkbox class="example-margin" formControlName="visibility">Public</mat-checkbox>
        </div>
        <div class="container-cemeteries">
          <div class="title">Cemeteries</div>
          <ul class="list-cemeteries">
            <mat-checkbox *ngIf="this.dialogData.listCemeteries.length > 1" [checked]="isSelectAll" (change)="onSelectAll($event)"
              >All</mat-checkbox
            >
            <ng-container *ngFor="let cemetery of cemeteries.controls; let i = index">
              <mat-checkbox [formControl]="cemetery">
                {{ cemeteryValue[i].name }}
              </mat-checkbox>
            </ng-container>
          </ul>
        </div>
      </mat-dialog-content>
      <mat-divider></mat-divider>
      <mat-dialog-actions>
        <div class="container-button">
          <button mat-button color="primary" (click)="onCancel()">CANCEL</button>
          <button [disabled]="dialogData.disabled" mat-raised-button color="primary" (click)="onAdd()">
            {{ dialogData.modalCondition === 'add' ? 'ADD' : 'SAVE' }}
          </button>
        </div>
      </mat-dialog-actions>
    </ng-container>

    <ng-template #template>
      <div class="text-title">Delete Custom Field</div>
      <div class="text-content-delete">
        Related data will be removed permanently and field will no longer be available for entering new data
      </div>
      <div class="container-button delete">
        <button [disabled]="dialogData.disabled" mat-button color="primary" (click)="onAdd()">DELETE</button>
        <button mat-raised-button color="primary" (click)="onCancel()">CANCEL</button>
      </div>
    </ng-template>
  </div>
</form>
