import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[clRemoveAutofill]',
})
export class RemoveAutofillDirective implements AfterViewInit {
  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

  @HostListener('focus')
  onFocus() {
    this.removeReadonly();
  }

  ngAfterViewInit() {
    this.setReadonly();
  }

  private setReadonly() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'readonly', '');
  }

  private removeReadonly() {
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'readonly');
  }
}
