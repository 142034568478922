import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { LoadingService } from './loading-overlay/loading.service';
import { EMPTY, from, interval, Observable, of, Subject, timer } from 'rxjs';
import { NavigateBackService } from './core/navigate-back.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from './google-analytics.service';
import { catchError, filter, finalize, map, mergeMap, pluck, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SeoService } from './core/seo.service';
import { CemeteryInfo } from 'src/app/interfaces/cemetery-info';
import { PlotDetails } from './interfaces/plot-details';
import { isPlatformBrowser } from '@angular/common';
import { MobileResponsiveService } from './core/mobile-responsive.service';
import { StoryPublic } from './interfaces/story-public';
import { CemeteryService } from './core/cemetery.service';
import { SectionsService } from './core/sections.service';
import { TokenStorageService } from './core/token-storage.service';
import { EncryptService } from './core/encrypt.service';
import { AdminDataService } from './core/admin-data.service';
import { StorageStrategyService } from './core/storage-strategy.service';
import { Token } from './enums/token.enum';
import { UserRole } from './enums/user-role.enum';
import { AppRoutes } from './enums/app-routes.enum';

@Component({
  selector: 'cl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  responsive$: boolean;
  destroy$: Subject<void> = new Subject<void>();
  appVh = window?.innerHeight;
  refreshInterval$: Observable<number> = interval(1 * 60 * 1000);
  orgQuery: string;

  isLoadingGateway = false;
  accessTokenGlobal: string;
  refreshTokenGlobal: string;
  authTokenGlobal: any;
  globalPath: any;
  isDemo: any;

  constructor(
    loadingService: LoadingService,
    private router: Router,
    private navigateBackService: NavigateBackService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public breakpointObserver: BreakpointObserver,
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private mobileResponsiveService: MobileResponsiveService,
    private cemeteryService: CemeteryService,
    private sectionsService: SectionsService,
    private tokenStorageService: TokenStorageService,
    private encryptService: EncryptService,
    private adminDataService: AdminDataService,

    @Inject(PLATFORM_ID)
    private platformId: any
  ) {
    this.isLoading$ = loadingService.isLoading$();

    this.navigateBackService.getPreviousUrl();

    // if (environment.GOOGLE_ANALYTICS_ID) {
    //   this.initGoogleAnalytics();
    // }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        switchMap(res => {
          const encAccess = res.a;
          const encRefresh = res.r;

          if (encAccess && encRefresh) {
            this.isLoadingGateway = true;
            this.tokenStorageService.clear(Token.access);
            this.tokenStorageService.clear(Token.refresh);
            this.tokenStorageService.clear(Token.listAccessToken);
            this.tokenStorageService.clear(Token.isDemo);

            const accessToken = this.encryptService.tokenRecover(encAccess);
            const refreshToken = this.encryptService.tokenRecover(encRefresh);

            this.accessTokenGlobal = accessToken;
            this.refreshTokenGlobal = refreshToken;
            this.isDemo = res.d;

            return this.adminDataService.updateUserInfoServer(accessToken, refreshToken);
          } else {
            return EMPTY;
          }
        }),

        filter(data => !!data),
        pluck('role'),
        switchMap(role => {
          let path;

          this.tokenStorageService.store(Token.access, this.accessTokenGlobal);
          this.tokenStorageService.store(Token.refresh, this.refreshTokenGlobal);

          if (this.isDemo && this.isDemo === '1') {
            this.tokenStorageService.store(Token.isDemo, '1');
          } else {
            this.tokenStorageService.store(Token.isDemo, '0');
          }

          switch (role) {
            case UserRole.CHRONICLE_ADMIN:
              path = AppRoutes.ChronicleAdmin;
              break;
            case UserRole.CUSTOMER_ORGANIZATION:
              path = AppRoutes.CustomerOrganization;
              break;
            case UserRole.CUSTOMER_ADMIN:
              path = AppRoutes.CustomerAdmin;
              break;
            case UserRole.CUSTOMER_MANAGER:
              path = AppRoutes.CustomerManager;
              break;
            case UserRole.CUSTOMER_VIEWER:
              path = AppRoutes.CustomerViewer;
              break;
            case UserRole.PUBLIC_USER:
              path = '';
              break;
          }

          this.globalPath = path;

          return from(this.router.navigate(['/', path]));
        }),
        tap(isNavigate => {
          if (!isNavigate) {
            this.isLoadingGateway = false;
            this.ref.detectChanges();
            this.router.navigate(['/', this.globalPath]);
          }
          if (isNavigate) {
            this.isLoadingGateway = false;
            this.ref.detectChanges();
          }
        }),
        catchError(error => {
          return of(error, (window.location.href = `${environment.DEMO_FRONTEND}`));
        }),
        finalize(() => {
          this.isLoadingGateway = false;
          this.ref.detectChanges();
        })
      )
      .subscribe();

    of([])
      .pipe(
        filter(() => Object.prototype.hasOwnProperty.call(window, 'ga')),
        take(1),
        switchMap(() => {
          return this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            tap((e: NavigationEnd) => {
              this.googleAnalyticsService.logPageView(e.url);
            })
          );
        })
      )
      .subscribe();

    this.breakpointObserver.observe(['(max-width: 425px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.mobileResponsiveService.update(true);
      } else {
        this.mobileResponsiveService.update(false);
      }
    });

    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          if (!isPlatformBrowser(this.platformId)) {
            return;
          }

          document.documentElement.style.setProperty('--appVh', `${this.appVh}px`);
          window.addEventListener('resize', () => {
            document.documentElement.style.setProperty('--appVh', `${this.appVh}px`);
          });

          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(e => this.activatedRoute),
        map(route => {
          this.orgQuery = route.snapshot.queryParamMap.get('org');
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (!data.typeSeo) {
          if (this.orgQuery !== null) {
            return this.seoService.updateMetadata({
              title: `${this.orgQuery}`,
              description: this.orgQuery,
              image: null,
            });
          }
          return this.seoService.updateMetadata({});
        }
        if (data.typeSeo === 'cemetery-detail') {
          const seoData = data.cemetery as CemeteryInfo;

          let address = '';
          if (
            seoData &&
            seoData.address &&
            seoData.address.country &&
            seoData.address.state &&
            seoData.address.country.length > 0 &&
            seoData.address.state.length > 0
          ) {
            address = `${seoData.address.country} ${seoData.address.state}`;
          } else {
            if (seoData.address && seoData.address.street) {
              address = seoData.address.street;
            } else {
              address = '';
            }
          }

          return this.seoService.updateMetadata({
            title: `${seoData.name}`,
            description: `Browse ${seoData.total_interment} deceased records at the ${seoData.name} in ${address}. Locate grave, read stories, add memorial, buy plot.`,
            image: seoData.images.length > 0 ? seoData.images[0].url : null,
            metaTitle: `${seoData.name} Map in ${address} `,
          });
        }
        if (data.typeSeo === 'plot-detail') {
          const seoData = data.plotDetails as PlotDetails;
          return this.seoService.updateMetadata({
            title: `${seoData.cemetery_info.name} | ${seoData.plot_id}`,
            description: seoData.interments.map(int => `${int.person.first_name} ${int.person.last_name}`).join(', '),
            image: seoData.images.length > 0 ? seoData.images[0].url : null,
          });
        }
        if (data.typeSeo === 'plot-story') {
          const seoData = data.stories as StoryPublic;
          return this.seoService.updateMetadata({
            title: seoData.title,
            description: seoData.text.replace(/<[^>]*>/g, ''),
            image: seoData.images.length > 0 ? seoData.images[0].image : null,
          });
        }
      });

    if (isPlatformBrowser(this.platformId)) {
      this.refreshInterval$
        .pipe(
          tap(data => {
            this.cemeteryService.cleanAllCache();
            this.sectionsService.cleanAllCache();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }

    this.seoService.createLinkForCanonicalURL();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initGoogleAnalytics() {
    // register google tag manager
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GOOGLE_ANALYTICS_ID}`;
    document.head.appendChild(gTagManagerScript);

    // register google analytics
    const gtagScript = document.createElement('script');
    gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.GOOGLE_ANALYTICS_ID}');
    `;
    document.head.appendChild(gtagScript);
  }
}
