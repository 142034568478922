<ng-container [formGroup]="group">
  <div class="container-interment-id" *ngIf="isAdmin">
    <mat-form-field class="input-interment-id">
      <mat-label>Interment number</mat-label>
      <input formControlName="{{ intermentIdControlName }}" type="text" matInput placeholder="Interment ID" value="B AC 71" />
    </mat-form-field>
  </div>
  <div class="wrapper-middle">
    <div *ngIf="isAdmin" class="title-deceased">Deceased Person</div>
    <div class="container-name">
      <mat-form-field class="input-first-name">
        <mat-label>First name</mat-label>
        <input formControlName="fn" matInput placeholder="John" />
      </mat-form-field>
      <mat-form-field class="input-last-name">
        <mat-label>Last name</mat-label>
        <input formControlName="ln" matInput placeholder="Doe" />
      </mat-form-field>
    </div>
    <div class="wrapper-date">
      <mat-form-field appearance="fill" class="calendar-date">
        <mat-label>Date of birth</mat-label>
        <mat-date-range-input [formGroup]="group.get('dob')" [rangePicker]="pickerDob">
          <input matStartDate #dateMask formControlName="startDob" placeholder="Start date" />
          <input matEndDate #dateMask formControlName="endDob" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="pickerDob"></mat-datepicker-toggle>
        <mat-date-range-picker #pickerDob></mat-date-range-picker>
        <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
        <mat-error *ngIf="group.get('dob').invalid">Invalid date</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="calendar-date">
        <mat-label>Date of death</mat-label>
        <mat-date-range-input [formGroup]="group.get('dod')" [rangePicker]="pickerDod">
          <input matStartDate #dateMask formControlName="startDod" placeholder="Start date" />
          <input matEndDate #dateMask formControlName="endDod" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="pickerDod"></mat-datepicker-toggle>
        <mat-date-range-picker #pickerDod></mat-date-range-picker>
        <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
        <mat-error *ngIf="group.get('dod').invalid">Invalid date</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="calendar-date">
        <mat-label>Date of interment</mat-label>
        <mat-date-range-input [formGroup]="group.get('doi')" [rangePicker]="pickerDoi">
          <input matStartDate #dateMask formControlName="startDoi" placeholder="Start date" />
          <input matEndDate #dateMask formControlName="endDoi" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="pickerDoi"></mat-datepicker-toggle>
        <mat-date-range-picker #pickerDoi></mat-date-range-picker>
        <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
        <mat-error *ngIf="group.get('doi').invalid">Invalid date</mat-error>
      </mat-form-field>
    </div>
    <div class="wrapper-age">
      <div class="age">Age</div>
      <div class="container-input-age">
        <mat-form-field class="input-age">
          <mat-label>From</mat-label>
          <input [formControl]="ageFrom" matInput placeholder="20" type="number" />
        </mat-form-field>
        <div class="separator">-</div>
        <mat-form-field class="input-age">
          <mat-label>To</mat-label>
          <input [formControl]="ageTo" matInput placeholder="1000" type="number" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="wrapper-checkbox">
    <ul class="container-item" style="list-style-type: none; padding: 0px; margin: 0px">
      <ng-container *ngFor="let data of optionsValue">
        <li class="item-checkbox">
          <mat-checkbox [formControlName]="data.name">
            {{
              data.viewValue === 'Returned Service Person' && regionalSettings !== null && regionalSettings !== undefined
                ? regionalSettings.returned_service_person
                : data.viewValue
            }}
          </mat-checkbox>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>
