import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { from, of, Subject } from 'rxjs';
import { catchError, concatMap, finalize, takeUntil, tap, toArray } from 'rxjs/operators';
import { UploaderManagerFactoryService } from '../services/uploader-manager-factory.service';
import { UploaderType } from '../enums/uploader-type.enum';
import { Uploader } from '../interfaces/uploader';
import { LoadingService } from '../services/loading.service';
import { FileResponse } from '../../interfaces/file-response';
import { Image } from '../../interfaces/image';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { SnackbarNotificationService } from '../../core/snackbar-notification.service';
import { convertHeic } from 'src/app/shared/file-converter-utils';

@Component({
  selector: 'cl-upload-wrapper',
  templateUrl: './upload-wrapper.component.html',
  styleUrls: ['./upload-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadWrapperComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private uploaderService: Uploader<FileResponse | Image>;
  private localFiles: any[] = [];
  spinnerDiameter = 32;
  isOver$ = new Subject<boolean>();
  @Input() public;
  @Input() set files(files: any[]) {
    this.localFiles = files || [];
    this.inputFiles.emit(files);
  }
  get files() {
    return this.localFiles;
  }
  @Input() disabled = false;
  @Input() maxFileSize: number;
  @Input() title: string;
  @Input() uploaderType = UploaderType.file;
  @Input() fileType = '*';
  @Output() inputFiles = new EventEmitter<any[]>();
  @Output() uploadedFiles = new EventEmitter<any[]>();
  @Output() isLoadingDoc = new EventEmitter<boolean>();
  @Output() invalidSize = new EventEmitter<boolean>();

  @ViewChild('file') inputFile: ElementRef;
  @ContentChild('filesList') templateRef: TemplateRef<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private uploaderManagerFactoryService: UploaderManagerFactoryService,
    public loadingService: LoadingService,
    private snackBarNotificationService: SnackbarNotificationService,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'custom_download', src: '/assets/images/download_icon.svg' }]);
  }

  ngOnInit() {
    this.uploaderService = this.uploaderManagerFactoryService.create(this.uploaderType);
  }

  isOver(isDraggedOver) {
    if (this.disabled) {
      return;
    }

    this.isOver$.next(isDraggedOver);
  }

  openFileLoad() {
    if (this.loadingService.isLoading || this.disabled) {
      return;
    }

    this.inputFile.nativeElement.click();
  }

  async uploadFiles(fileList: FileList) {
    if (this.loadingService.isLoading || this.disabled) {
      return;
    }

    if (this.maxFileSize) {
      const hasOversizedFile = Array.from(fileList).some(file => (file.size / 1000) > this.maxFileSize);
      if (hasOversizedFile) {
        this.invalidSize.emit(true);
        return;
      }
    }

    this.loadingService.run();
    this.isLoadingDoc.emit(true);

    const filesPromise = Array.from(fileList).map(async file => {
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        return convertHeic(file, 'png');
      } else {
        return file;
      }
    });

    const newFiles = await Promise.all(filesPromise);

    if (this.public) {
      return from(newFiles)
        .pipe(
          concatMap((file: File) => {
            return this.uploaderService.uploadFromPublic(file);
          }),
          toArray(),
          tap(files => {
            this.localFiles = this.files.concat(files);
            this.uploadedFiles.emit(this.files);
          }),
          finalize(() => {
            this.inputFile.nativeElement.value = '';
            this.loadingService.stop();
            this.isLoadingDoc.emit(false);
            this.changeDetectorRef.detectChanges();
          }),
          catchError(error => {
            if ((error.status === 400 || error.status === 415) && error.message) {
              this.openErrorMessage(error.error?.message);
            }

            return of(error);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }

    from(newFiles)
      .pipe(
        concatMap((file: File) => {
          return this.uploaderService.upload(file);
        }),
        toArray(),
        tap(files => {
          this.localFiles = this.files.concat(files);
          this.uploadedFiles.emit(this.files);
        }),
        finalize(() => {
          this.inputFile.nativeElement.value = '';
          this.loadingService.stop();
          this.isLoadingDoc.emit(false);
          this.changeDetectorRef.detectChanges();
        }),
        catchError(error => {
          if ((error.status === 400 || error.status === 415) && error.message) {
            this.openErrorMessage(error.error?.message);
          }

          return of(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  remove(file: { id: number }) {
    if (this.public) {
      this.files = this.files.filter(item => item.id !== file.id);
      this.uploaderService.removeFromPublic(file).subscribe();
      this.uploadedFiles.emit(this.files);
      return;
    }

    this.files = this.files.filter(item => item.id !== file.id);
    this.uploaderService.remove(file).subscribe();
    this.uploadedFiles.emit(this.files);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private openErrorMessage(errorMessage: string) {
    this.snackBarNotificationService.openErrorMessage(errorMessage);
  }
}
